import React, { useState, useRef, useEffect } from 'react';
import TopHeaderMain from '../../../../Layout/TopHeader/TopHeaderMain';
import {
    TextField,
    Button,
    Box,
    IconButton,
    InputAdornment,
    Input,
    Snackbar,
    Alert,
    Tabs,
    Tab
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import TopHeaderInternal from '../../../../Layout/TopHeader/TopHeaderInternal';
import axiosConfig from '../../../../axios';
import authorization from '../../../../authorization';
import { useNavigate, useParams } from 'react-router-dom';
import config from '../../../../config';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ImageField from "../../../../Layout/ImageField/ImageField";
import FullScreenLoader from '../../../../Layout/FullScreenLoader/FullScreenLoader';

export default function EditCategory() {
    const [dragActive, setDragActive] = useState(false);
    const [file, setFile] = useState(null);
    // const [categoryName, setCategoryName] = useState('');
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [errorFileUploader, setErrorFileUploader] = useState(false);
    const [categoryDetails, setCategoryDetails] = useState({});
    const [updatedCategoryName, setUpdatedCategoryName] = useState("");
    const [categoryNameEn, setCategoryNameEn] = useState('')
    const [categoryNameAr, setCategoryNameAr] = useState('')
    const [categoryNameIt, setCategoryNameIt] = useState('')
    const [categoryNameJa, setCategoryNameJa] = useState('')

    const [categoryNameEnError, setCategoryNameEnError] = useState(false);
    const [categoryNameArError, setCategoryNameArError] = useState(false);
    const [categoryNameItError, setCategoryNameItError] = useState(false);
    const [categoryNameJaError, setCategoryNameJaError] = useState(false);

    const [handleChangeTab, setHandleChangeTab] = useState(false)

    const [buttonloader, setButtonLoader] = useState(false)
    const [fullScreenLoader, setFullScreenLoader] = useState(true)

    const [value, setValue] = React.useState('1');
    let history = useNavigate()
    const { id } = useParams();
    let CategoryId = id

    useEffect(() => {
        axiosConfig
            .get(`/admin/tour/category/details/${CategoryId}`, {
                headers: {
                    Authorization: authorization.authorizationUrl,
                },
            })
            .then((response) => {
                let data = response.data.data
                setCategoryDetails(response.data.data);
                // console.log("category details", response.data);
                setTimeout(() => {
                    setFullScreenLoader(false)
                }, 800);

                setFile(null)
                if (data.title_en !== null) {
                    setCategoryNameEn(data.title_en)
                } else {
                    setCategoryNameEn('')
                    setCategoryNameEnError(true)
                }

                if (data.title_ar !== null) {
                    setCategoryNameAr(data.title_ar)
                } else {
                    setCategoryNameAr('')
                    setCategoryNameArError(true)
                }

                if (data.title_it !== null) {
                    setCategoryNameIt(data.title_it)
                } else {
                    // console.log("else in it")
                    setCategoryNameIt('')
                    setCategoryNameItError(true)
                }

                if (data.title_ja !== null) {
                    setCategoryNameJa(data.title_ja)
                } else {
                    setCategoryNameJa('')
                    setCategoryNameJaError(true)
                }
                // setCategoryNameEn(data.title_en !== null ? data.title_en : )
                // setCategoryNameAr(data.title_ar !== null ? data.title_ar : setCategoryNameArError(true))
                // setCategoryNameIt(data.title_it !== null ? data.title_it : setCategoryNameItError(true))
                // setCategoryNameJa(data.title_ja !== null ? data.title_ja : setCategoryNameJaError(true))

            })
            .catch((error) => console.log(error));
    }, []);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const inputRef = useRef(null);


    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            const droppedFile = e.dataTransfer.files[0];
            if (validateFile(droppedFile)) {
                setCategoryDetails({ ...categoryDetails, image: droppedFile });
                setFile(droppedFile)
                // console.log("droped log", droppedFile);
                setErrorFileUploader(false)
            } else {
                setErrorFileUploader(true);
            }
        }
    };

    const handleChange = (e) => {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            const selectedFile = e.target.files[0];
            if (validateFile(selectedFile)) {
                setCategoryDetails({ ...categoryDetails, image: selectedFile });
                setFile(selectedFile)
                // console.log(selectedFile);
                setErrorFileUploader(false)
            } else {
                setErrorFileUploader(true);
            }
        }
    };

    const onButtonClick = () => {
        inputRef.current.click();
    };


    const validateFile = (file) => {
        const allowedTypes = ["image/png", "image/jpeg", "image/gif", "image/bmp", "image/svg+xml", "image/webp", "image/x-icon"];
        const maxFileSize = 5 * 1024 * 1024; // 5MB

        if (!allowedTypes.includes(file.type)) {
            return false;
        }

        if (file.size > maxFileSize) {
            return false;
        }

        return true;
    };

    const handleCategoryNameEnChange = (e) => {
        const value = e.target.value;
        setCategoryNameEn(value);
        if (value == '') {
            setCategoryNameEnError(true);
        } else {
            setCategoryNameEnError(false);
        }
    };


    const handleCategoryNameArChange = (e) => {
        const value = e.target.value;
        setCategoryNameAr(value);
        if (value == '') {
            setCategoryNameArError(true);
        } else {
            setCategoryNameArError(false);
        }
    };



    const handleCategoryNameItChange = (e) => {
        const value = e.target.value;
        setCategoryNameIt(value);
        if (value == '') {
            setCategoryNameItError(false);
        } else {
            setCategoryNameItError(false);
        }
    };



    const handleCategoryNameJaChange = (e) => {
        const value = e.target.value;
        setCategoryNameJa(value);
        if (value == '') {
            setCategoryNameJaError(false);
        } else {
            setCategoryNameJaError(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Reset error messages

        // Check for any errors in category names
        if (categoryNameEnError || categoryNameArError ) {
            // console.log("Error", categoryNameEnError, categoryNameArError, categoryNameArError, categoryNameJaError)
            return; // If any error is true, prevent form submission
        } else {
            setButtonLoader(true)
            // console.log("true", categoryNameEnError, categoryNameArError, categoryNameItError, categoryNameJaError)
            handleApiCall();
        }


    };

    const handleApiCall = () => {




        axiosConfig
            .post(`/admin/tour/category/${CategoryId}`, {
                title_en: categoryNameEn,
                title_ar: categoryNameAr,
                title_ja: categoryNameEn,
                title_it: categoryNameEn,
                image: file
            }, {
                headers: {
                    Authorization: authorization.authorizationUrl,
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                // console.log("Edit category updated response", response.data.data);
                // console.log("form data", updatedData)
                if (response.data.status) {
                    setSuccessSnackbarOpen(true);
                    setButtonLoader(false)
                    setTimeout(() => {
                        history('/tours/categories')
                    }, 800);
                } else {
                    setErrorSnackbarOpen(true);
                    setButtonLoader(false)
                }
            })
            .catch(error => {
                console.log(error);
                setErrorSnackbarOpen(true);

            });
    };

    const handleClose = (e) => {
        e.preventDefault()
        setFile(null)
        setHandleChangeTab(true)
    }


    return (
        <>
            <div className="container-fluid d-flex">
                <div className="content-wrapper">
                    <div className="content-components-wrapper">
                        {fullScreenLoader ? <FullScreenLoader /> : ''}
                        <TopHeaderInternal title={`Edit Category `} linkToBackBtn={'/tours/categories'} />
                        <div className="tour-wrapper sub-page-details-wrapper mt-4">
                            <div className="row">
                                <div className="col-xl-5 col-lg-6 col-md-8 col-sm-12">
                                    <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={handleSubmit}>
                                        <TabContext value={value}>
                                            <Tabs value={value} onChange={handleTabChange} variant="scrollable" scrollButtons allowScrollButtonsMobile aria-label="nav tabs example">
                                                <Tab label="English" value="1" className={categoryNameEnError ? "error-msg-dot" : ""} />
                                                <Tab label="Arabic" value="2" className={categoryNameArError ? "error-msg-dot" : ""} />
                                                {/* <Tab label="Italian" value="3" className={categoryNameItError ? "error-msg-dot" : ""} /> */}
                                                {/* <Tab label="Japanese" value="4" className={categoryNameJaError ? "error-msg-dot" : ""} /> */}
                                            </Tabs>
                                            <TabPanel className='p-3' value="1">
                                                <h5 className='sub-page-title'>Category Details In English</h5>
                                                <div className='around-border'>
                                                    <div className="row english-details add-border my-3">
                                                        <div className="col-4"><p className='input-title'>Category Name:</p></div>
                                                        <div className="col-8 mb-3">
                                                            <Input
                                                                disableUnderline
                                                                multiline
                                                                sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                                className='table-inputs-content'
                                                                size='small'
                                                                placeholder='Enter category name in English'
                                                                required
                                                                fullWidth
                                                                value={categoryNameEn}
                                                                onChange={handleCategoryNameEnChange}
                                                            />
                                                            {categoryNameEnError && (
                                                                <span className='error-msg'>Category name is required.</span>
                                                            )}

                                                        </div>
                                                        <div className="col-4"><p className='input-title'>Cover Image:</p></div>
                                                        <ImageField
                                                            image={categoryDetails.image}
                                                            handleChange={handleChange}
                                                            handleDrop={handleDrop}
                                                            errorFileUploader={errorFileUploader}
                                                            // submitError={errors.image?.[0]}
                                                            dragActive={dragActive}
                                                            setDragActive={setDragActive}
                                                        />
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel className='p-3' value="2">
                                                <h5 className='sub-page-title'>Category Details In Arabic</h5>
                                                <div className='around-border'>
                                                    <div className="row arabic-details add-border my-3">
                                                        <div className="col-4"><p className='input-title'>Category Name:</p></div>
                                                        <div className="col-8 mb-3">
                                                            <Input
                                                                dir='rtl'
                                                                disableUnderline
                                                                multiline
                                                                sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                                className='table-inputs-content'
                                                                size='small'
                                                                placeholder='Enter category name in Arabic'
                                                                required
                                                                fullWidth
                                                                value={categoryNameAr}
                                                                onChange={handleCategoryNameArChange}
                                                            />
                                                            {categoryNameArError && (
                                                                <span className='error-msg'>Category name is required.</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            {/* <TabPanel className='p-3' value="3">
                                                <h5 className='sub-page-title'>Category Details In Italian</h5>
                                                <div className='around-border'>
                                                    <div className="row italian-details add-border my-3">
                                                        <div className="col-4"><p className='input-title'>Category Name:</p></div>
                                                        <div className="col-8 mb-3">
                                                            <Input
                                                                disableUnderline
                                                                sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                                className='table-inputs-content'
                                                                size='small'
                                                                placeholder='Enter category name in Italian'
                                                                required
                                                                fullWidth
                                                                value={categoryNameEn}
                                                                onChange={handleCategoryNameItChange}
                                                            />
                                                            {categoryNameItError && (
                                                                <span className='error-msg'>Category name is required.</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel className='p-3' value="4">
                                                <h5 className='sub-page-title'>Category Details In Japanese</h5>
                                                <div className='around-border'>
                                                    <div className="row japanese-details add-border my-3">
                                                        <div className="col-4"><p className='input-title'>Category Name:</p></div>
                                                        <div className="col-8 mb-3">
                                                            <Input
                                                                disableUnderline
                                                                sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                                className='table-inputs-content'
                                                                size='small'
                                                                placeholder='Enter category name in Japanese'
                                                                required
                                                                fullWidth
                                                                value={categoryNameEn}
                                                                onChange={handleCategoryNameJaChange}
                                                            />
                                                            {categoryNameJaError && (
                                                                <span className='error-msg'>Category name is required.</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel> */}
                                        </TabContext>
                                        <div className='submit-button-sub-page'>
                                            <button className={`PrimaryButton ${buttonloader ? 'button-loader' : ' '}`} type="submit" variant="contained" fullWidth>
                                                {buttonloader ? '' : 'Update'}
                                            </button>
                                        </div>
                                    </form>
                                    <Snackbar open={errorSnackbarOpen} autoHideDuration={3000} onClose={() => setErrorSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                                        <Alert severity="error" sx={{ width: '100%' }}>
                                            error
                                        </Alert>
                                    </Snackbar>
                                    <Snackbar open={successSnackbarOpen} autoHideDuration={3000} onClose={() => setSuccessSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                                        <Alert severity="success" sx={{ width: '100%' }}>
                                            Category Updated successfully created!
                                        </Alert>
                                    </Snackbar>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}