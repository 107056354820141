import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,

    IconButton,
    Pagination,

    Snackbar,
    Alert,
    Input,
    Button,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import PeopleIcon from '@mui/icons-material/People';
import AddIcon from '@mui/icons-material/Add';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

import DeleteIcon from '@mui/icons-material/Delete';
import TopHeaderMain from '../../../Layout/TopHeader/TopHeaderMain';
import axiosConfig from '../../../axios';
import authorization from '../../../authorization';
import ClearIcon from '@mui/icons-material/Clear'; // Import the ClearIcon from Material-UI icons
import DeleteModal from '../../../Layout/Modal/Modal';
import FullScreenLoader from '../../../Layout/FullScreenLoader/FullScreenLoader';

const StyledTextField = styled(TextField)({
    width: '100%',
    marginBottom: '1rem',
});


function InquiryList() {
    const [tourInquiries, setTourInquiries] = useState([]);
    const [sortColumn, setSortColumn] = useState('id');
    const [sortOrder, setSortOrder] = useState('asc');
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [deleteFlag, setDeleteFlag] = useState(false);
    const [searchbarData, setSearchbarData] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    const [fullScreenLoader, setFullScreenLoader] = useState(true)

    const token = localStorage.getItem("Token-for-login")


    const handleDelete = (id) => {
        setSelectedId(id);
        setShowModal(true);
    };

    const handleDeleteClick = () => {
        // console.log("selectedId", `/admin/tour-inquiry/${selectedId}`)
        axiosConfig.delete(`/admin/tour-inquiry/${selectedId}`, {
            headers: {
                'Authorization': authorization.authorizationUrl
            }
        })
            .then(response => {
                // console.log("delete inquiry", response.data)
                if (response.data.status) {
                    setShowModal(false)
                    setOpenSnackbar(true)
                    handleGetList()
                }
                // setDeleteFlag(true)
            })
            .catch(error => console.log(error));



    };

    useEffect(() => {
        handleGetList()
    }, [page, searchbarData]);

    const handleGetList = () => {
        axiosConfig.get(`/admin/tour-inquiry?page=${page}${searchbarData ? `&search=${searchbarData}` : ''}`, {
            headers: {
                'Authorization': authorization.authorizationUrl
            }
        })
            .then(response => {
                // console.log("inquiry list", response.data.data.data)
                setTourInquiries(response.data.data.data);
                setTotalPages(response.data.data.last_page);
                setTimeout(() => {
                    setFullScreenLoader(false)
                }, 800);
            })
            .catch(error => console.log(error));
    }

    const handleSearchBar = (e) => {
        const newValue = e.target.value;
        setSearchbarData(newValue); // Update the state with the new search value
        setPage(1)
        // setTimeout(() => {
        //     // console.log("searchbar value", newValue); // Log the new search value
        //     handleGetList(); // Call the tour list function after a short delay with the updated search value
        // }, 400);
    };
    const handleClearSearch = () => {
        setSearchbarData(''); // Clear the search bar
        setPage(1)
        handleGetList(); // Call the tour list function with empty search term
    };


    const close = () => {
        setShowModal(false);
    };

    const history = useNavigate();
    const handleEditClick = (id) => {
        // logic to handle edit click
        history(`/inquiry/tour-inquiry/${id}`);
        // console.log(`Edit clicked for user with id ${id}`);
    };

    const handleDetailClick = (user) => {
        history(`/inquiry/tour-inquiry/details/${user.id}`);
    }



    function formatDate(dateStr) {
        const dateObj = new Date(dateStr);

        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const formattedDate = dateObj.toLocaleDateString('en-GB', options);

        return formattedDate;
    }

    return (
        <>
            <div className="container-fluid d-flex">
                <div className="content-wrapper">
                    <div className="content-components-wrapper">
                        {fullScreenLoader ? <FullScreenLoader /> : ''}
                        <TopHeaderMain title='Tour Inquiry' icons={<ContactPhoneIcon fontSize="large" />} />
                        <div className="user-content mt-4">
                            <div className="row">
                                <div className="col-3">
                                    <div className="position-relative">
                                        <Input
                                            disableUnderline
                                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                            className='table-inputs-content mb-4'
                                            size='small'
                                            placeholder='Search'
                                            required
                                            fullWidth
                                            value={searchbarData}
                                            onChange={handleSearchBar}
                                        />
                                        {searchbarData && ( // Render the ClearIcon only if there's a search term
                                            <IconButton onClick={handleClearSearch}
                                                sx={{ position: 'absolute', top: '30%', right: '0px', transform: 'translateY(-50%)' }}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        )}
                                    </div>
                                </div>

                            </div>
                            <div className="user-table-content">
                                <TableContainer component={Paper} sx={{ boxShadow: 'none', border: '1px solid #E4E4E4' }}>
                                    <Table >
                                        <TableHead style={{ backgroundColor: '#F2F2F2' }}>
                                            <TableRow >
                                                {/* <TableCell>
                                                    Transaction  ID
                                                </TableCell> */}
                                                <TableCell>Date</TableCell>
                                                <TableCell>
                                                    Customer Name
                                                </TableCell>
                                                <TableCell>Email</TableCell>
                                                <TableCell>Phone no</TableCell>
                                                <TableCell>Days</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {tourInquiries.length > 0 ? (
                                            <TableBody className='table-body-wrapper tours-table-body-wrapper'>
                                                {tourInquiries.map((user, index) => (
                                                    <TableRow key={index}>
                                                        {/* <TableCell>{user.id}</TableCell> */}
                                                        <TableCell>{formatDate(user.created_at)}</TableCell>
                                                        <TableCell><span style={{ cursor: 'pointer' }} onClick={() => handleDetailClick(user)}>{user.name}</span></TableCell>
                                                        <TableCell>{user.email}</TableCell>
                                                        <TableCell>{user.phone_number}</TableCell>
                                                        <TableCell>{user.no_of_days}</TableCell>
                                                        <TableCell className='action-dropdown-btn'>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" size="sm" style={{ color: '#000000', border: 'none', background: 'transparent' }}>
                                                                    <MoreVertIcon />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item className='px-0'>
                                                                        {/* <IconButton
                                                                            color="primary"
                                                                            aria-label="View"
                                                                            onClick={() => handleDetailClick(user)}
                                                                            style={{ fontSize: '14px' }}
                                                                        >
                                                                            <InfoIcon style={{ fontSize: 16 }} className='me-1' /> View
                                                                        </IconButton> */}
                                                                        <Button
                                                                            color="primary"
                                                                            aria-label="View"
                                                                            onClick={() => handleDetailClick(user)}
                                                                            style={{ fontSize: "14px", width: "100%", justifyContent: "start", paddingLeft: "18px" }}
                                                                            startIcon={<InfoIcon style={{ fontSize: 16 }} />}
                                                                        >
                                                                            View
                                                                        </Button>
                                                                    </Dropdown.Item>

                                                                    <Dropdown.Item className='px-0'>
                                                                        <Button
                                                                            color="secondary"
                                                                            aria-label="Delete"
                                                                            onClick={() => handleDelete(user.id)}
                                                                            style={{ fontSize: "14px", width: "100%", justifyContent: "start", paddingLeft: "18px" }}
                                                                            startIcon={<DeleteIcon style={{ fontSize: 16 }} />}
                                                                        >
                                                                            Delete
                                                                        </Button>

                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        ) : (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell colSpan={6} align="center">
                                                        No Tours found.
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )}

                                    </Table>
                                </TableContainer>
                                <div className="pagination-wrapper">
                                    {totalPages > 1 ? <Pagination className="custom-pagination" count={totalPages} variant="outlined" shape="rounded" page={page} onChange={(event, newPage) => setPage(newPage)} /> : ""}
                                    {/* <TablePagination
                                        component="div"
                                        count={totalPages * rowsPerPage}
                                        page={page - 1}
                                        onPageChange={(event, newPage) => setPage(newPage + 1)}
                                        rowsPerPage={rowsPerPage}
                                        rowsPerPageOptions={[]}
                                    /> */}
                                </div>
                                <Snackbar
                                    open={openSnackbar}
                                    autoHideDuration={5000}
                                    onClose={() => setOpenSnackbar(false)}
                                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                >
                                    <Alert
                                        severity="success"
                                        sx={{ width: "100%" }}
                                    >
                                        Contact Inquiry Deleted successfully!
                                    </Alert>
                                </Snackbar>
                                <Snackbar
                                    open={openErrorSnackbar}
                                    autoHideDuration={5000}
                                    onClose={() => setOpenErrorSnackbar(false)}
                                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                >
                                    <Alert
                                        severity="error"
                                        sx={{ width: "100%" }}
                                    >
                                        Delete failed!
                                    </Alert>
                                </Snackbar>
                                <DeleteModal
                                    showModal={showModal}
                                    onClose={close}
                                    onSubmit={handleDeleteClick}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default InquiryList;