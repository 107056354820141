import React, { useState, useRef, useEffect } from 'react';
import TopHeaderMain from '../../../../Layout/TopHeader/TopHeaderMain';
import {
    TextField,
    Button,
    Box,
    IconButton,
    InputAdornment,
    Snackbar,
    Input,
    Alert,
    Tabs,
    Tab
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useNavigate, useParams } from 'react-router-dom';
import axiosConfig from '../../../../axios';
import authorization from '../../../../authorization';
import TopHeaderInternal from '../../../../Layout/TopHeader/TopHeaderInternal';
import config from '../../../../config';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ImageField from "../../../../Layout/ImageField/ImageField";
import FullScreenLoader from '../../../../Layout/FullScreenLoader/FullScreenLoader';

export default function EditCategory() {
    const [dragActive, setDragActive] = useState(false);
    const [file, setFile] = useState(null);
    const [categoryName, setCategoryName] = useState('');
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [value, setValue] = React.useState('1');
    const [locationDetails, setLocationDetails] = useState([])
    const [updatedLocationName, setUpdatedLocationName] = useState("")

    const [locationNameEn, setLocationNameEn] = useState('')
    const [locationNameAr, setLocationNameAr] = useState('')
    const [locationNameIt, setLocationNameIt] = useState('')
    const [locationNameJa, setLocationNameJa] = useState('')

    const [locationNameEnError, setLocationNameEnError] = useState(false);
    const [locationNameArError, setLocationNameArError] = useState(false);
    const [locationNameItError, setLocationNameItError] = useState(false);
    const [locationNameJaError, setLocationNameJaError] = useState(false);

    const [locationNameEnErrorMsg, setLocationNameEnErrorMsg] = useState('');
    const [locationNameArErrorMsg, setLocationNameArErrorMsg] = useState('');
    const [locationNameItErrorMsg, setLocationNameItErrorMsg] = useState('');
    const [locationNameJaErrorMsg, setLocationNameJaErrorMsg] = useState('');
    const [fileError, setFileError] = useState('')

    const [handleChangeTab, setHandleChangeTab] = useState(false)

    const [errorFileUploader, setErrorFileUploader] = useState(false);

    const [buttonloader, setButtonLoader] = useState(false)
    const [fullScreenLoader, setFullScreenLoader] = useState(true)

    let history = useNavigate()
    const { id } = useParams();
    let LocationId = id

    useEffect(() => {
        axiosConfig
            .get(`/admin/tour/location/details/${LocationId}`, {
                headers: {
                    Authorization: authorization.authorizationUrl,
                },
            })
            .then((response) => {
                let data = response.data.data
                setLocationDetails(response.data.data);
                setTimeout(() => {
                    setFullScreenLoader(false)
                }, 800);
                // console.log("location details", response.data.data);
                setLocationNameEn(data.location_en !== null ? data.location_en : setLocationNameEnError(true))
                setLocationNameAr(data.location_ar !== null ? data.location_ar : setLocationNameArError(true))
                setLocationNameIt(data.location_it !== null ? data.location_it : setLocationNameItError(true))
                setLocationNameJa(data.location_ja !== null ? data.location_ja : setLocationNameJaError(true))

            })
            .catch((error) => console.log(error));


    }, []);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };


    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
        setHandleChangeTab(true)
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            const droppedFile = e.dataTransfer.files[0];
            if (validateFile(droppedFile)) {
                setLocationDetails({ ...locationDetails, uploadedimage: droppedFile });
                setFile(droppedFile)
                // console.log("droped log", droppedFile);
                setHandleChangeTab(true)
                setErrorFileUploader(false)
            } else {
                setErrorFileUploader(true);
            }
        }
    };

    const handleChange = (e) => {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            const selectedFile = e.target.files[0];
            if (validateFile(selectedFile)) {
                setLocationDetails({ ...locationDetails, uploadedimage: selectedFile });
                setFile(selectedFile)
                // console.log(selectedFile);
                setErrorFileUploader(false)
                setHandleChangeTab(true)
            } else {
                setErrorFileUploader(true);
            }
        }
    };



    const validateFile = (file) => {
        const allowedTypes = ["image/png", "image/jpeg", "image/gif", "image/bmp", "image/svg+xml", "image/webp", "image/x-icon"];
        const maxFileSize = 5 * 1024 * 1024; // 5MB

        if (!allowedTypes.includes(file.type)) {
            return false;
        }

        if (file.size > maxFileSize) {
            return false;
        }

        return true;
    };


    const handleLocationNameEnChange = (e) => {
        const value = e.target.value;
        setLocationNameEn(value);
        // setLocationNameEnError(!validateLocationNameEn(value));
    };

    const validateLocationNameEn = (value) => {
        return value.trim() !== "" && value.length > 3;
    };
    const handleLocationNameArChange = (e) => {
        const value = e.target.value;
        setLocationNameAr(value);
        // setLocationNameArError(!validateLocationNameAr(value));
    };

    const validateLocationNameAr = (value) => {
        return value.trim() !== "" && value.length > 3;
    };

    const handleLocationNameItChange = (e) => {
        const value = e.target.value;
        setLocationNameIt(value);
        // setLocationNameItError(!validateLocationNameIt(value));
    };

    const validateLocationNameIt = (value) => {
        return value.trim() !== "" && value.length > 3;
    };

    const handleLocationNameJaChange = (e) => {
        const value = e.target.value;
        setLocationNameJa(value);
        // setLocationNameJaError(!validateLocationNameJa(value));
    };

    const validateLocationNameJa = (value) => {
        return value.trim() !== "" && value.length > 3;
    };

    const handleSubmit = (e) => {
        e.preventDefault();



        setLocationNameEnErrorMsg('');
        setLocationNameArErrorMsg('');
        setLocationNameItErrorMsg('');
        setLocationNameJaErrorMsg('');
        setFileError('');

        // Additional validations
        let HandleError = false
        if (!locationNameEn) {
            // console.log('Category name is required.')
            setLocationNameEnErrorMsg('Location name is required.');
            HandleError = true

        } else if (locationNameEn.length < 3) {
            // console.log('Category name must be at least 3 characters long.')
            setLocationNameEnErrorMsg('Location name must be at least 3 characters long.');
            HandleError = true
        }
        if (!locationNameAr) {
            // console.log('Category name is required.')
            setLocationNameArErrorMsg('Location name is required.');
            HandleError = true

        } else if (locationNameAr.length < 3) {
            // console.log('Category name must be at least 3 characters long.')
            setLocationNameArErrorMsg('Location name must be at least 3 characters long.');
            HandleError = true
        }
        // if (!locationNameIt) {
        //     // console.log('Category name is required.')
        //     setLocationNameItErrorMsg('Location name is required.');
        //     HandleError = true

        // } else if (locationNameIt.length < 3) {
        //     // console.log('Category name must be at least 3 characters long.')
        //     setLocationNameItErrorMsg('Location name must be at least 3 characters long.');
        //     HandleError = true
        // }
        // if (!locationNameJa) {
        //     // console.log('Category name is required.')
        //     setLocationNameJaErrorMsg('Location name is required.');
        //     HandleError = true

        // } else if (locationNameJa.length < 3) {
        //     // console.log('Category name must be at least 3 characters long.')
        //     setLocationNameJaErrorMsg('Location name must be at least 3 characters long.');
        //     HandleError = true
        // }

        if (file === null && !locationDetails.image) {
            // console.log('Please select an image file.')
            setFileError('Please select an image file.');
            HandleError = true
        }


        if (HandleError) {
            // console.log("error")
            return;
        } else {
            setButtonLoader(true)
            // console.log("tyrue")
            handleApiCall();

        }

        // Validation successful, make the API call

    };

    const handleApiCall = () => {
        // console.log("handleChangeTab", handleChangeTab)

        let imageData = locationDetails.image
        let updatedData = {

            location_en: locationNameEn,
            location_ar: locationNameAr,
            location_it: locationNameEn,
            location_ja: locationNameEn,
            image: file

        }
        // if (handleChangeTab) {
        //     updatedData.image = locationDetails.uploadedimage;
        //     console.log("that handlechange condition", updatedData.image = locationDetails.uploadedimage)
        // }

        // console.log("updatedData", updatedData)

        axiosConfig
            .post(`/admin/tour/location/${LocationId}`, {
                location_en: locationNameEn,
                location_ar: locationNameAr,
                location_it: locationNameEn,
                location_ja: locationNameEn,
                image: file
            }, {
                headers: {
                    Authorization: authorization.authorizationUrl,
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                // console.log("location handleSubmit", response.data);
                if (response.data.status) {
                    setSuccessSnackbarOpen(true);
                    setTimeout(() => {
                        setButtonLoader(false)
                        history('/tours/locations')
                    }, 800);
                } else {
                    setErrorSnackbarOpen(true);
                    setButtonLoader(false)
                }
            })
            .catch((error) => {
                console.log(error)
                setErrorSnackbarOpen(true);
            });
    }

    const handleClose = (e) => {
        e.preventDefault()
        setFile(null)
        setHandleChangeTab(true)
    }

    return (
        <>
            <div className="container-fluid d-flex">
                <div className="content-wrapper">
                    <div className="content-components-wrapper">
                        {fullScreenLoader ? <FullScreenLoader /> : ''}
                        <TopHeaderInternal title={`Edit Location `} linkToBackBtn={'/tours/locations'} />
                        <div className="tour-wrapper sub-page-details-wrapper mt-4">
                            {/* {JSON.stringify(locationDetails)} */}
                            <div className="row">
                                <div className="col-xl-5 col-lg-6 col-md-8 col-sm-12">
                                    <form id="form-file-upload" onDragEnter={handleDrag}>
                                        <TabContext value={value}>
                                            <Tabs value={value} onChange={handleTabChange} variant="scrollable" scrollButtons allowScrollButtonsMobile aria-label="nav tabs example">
                                                <Tab label="English" value="1" className={locationNameEnErrorMsg ? "error-msg-dot" : ""} />
                                                <Tab label="Arabic" value="2" className={locationNameArErrorMsg ? "error-msg-dot" : ""} />
                                                {/* <Tab label="Italian" value="3" className={locationNameItErrorMsg ? "error-msg-dot" : ""} /> */}
                                                {/* <Tab label="Japanese" value="4" className={locationNameJaErrorMsg ? "error-msg-dot" : ""} /> */}
                                            </Tabs>
                                            <TabPanel className='p-3' value="1">
                                                <h5 className='sub-page-title'>Location Details In English</h5>
                                                <div className='around-border'>
                                                    <div className="row english-details add-border my-3">
                                                        <div className="col-4"><p className='input-title'>Location Name:</p></div>
                                                        <div className="col-8 mb-3">
                                                            <Input
                                                                disableUnderline
                                                                sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                                className='table-inputs-content'
                                                                size='small'
                                                                placeholder='Enter Location name in English'
                                                                required
                                                                fullWidth
                                                                value={locationNameEn}
                                                                onChange={handleLocationNameEnChange}
                                                            />
                                                            {locationNameEnErrorMsg && (
                                                                <span className='error-msg'>{locationNameEnErrorMsg}</span>
                                                            )}

                                                        </div>
                                                        <div className="col-4"><p className='input-title'>Cover Image:</p></div>
                                                        <ImageField
                                                            image={file ? file : locationDetails.image}
                                                            handleChange={handleChange}
                                                            handleDrop={handleDrop}
                                                            errorFileUploader={errorFileUploader}
                                                            // submitError={errors.image?.[0]}
                                                            dragActive={dragActive}
                                                            setDragActive={setDragActive}
                                                        />

                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel className='p-3' value="2">
                                                <h5 className='sub-page-title'>Location Details In Arabic</h5>
                                                <div className='around-border'>
                                                    <div className="row arabic-details add-border my-3">
                                                        <div className="col-4"><p className='input-title'>Location Name:</p></div>
                                                        <div className="col-8 mb-3">
                                                            <Input
                                                                dir='rtl'
                                                                disableUnderline
                                                                sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                                className='table-inputs-content'
                                                                size='small'
                                                                placeholder='Enter location name in Arabic'
                                                                required
                                                                fullWidth
                                                                value={locationNameAr}
                                                                onChange={handleLocationNameArChange}
                                                            />
                                                            {locationNameArErrorMsg && (
                                                                <span className='error-msg'>{locationNameArErrorMsg}</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            {/* <TabPanel className='p-3' value="3">
                                                <h5 className='sub-page-title'>Location Details In Italian</h5>
                                                <div className='around-border'>
                                                    <div className="row italian-details add-border my-3">
                                                        <div className="col-4"><p className='input-title'>Location Name:</p></div>
                                                        <div className="col-8 mb-3">
                                                            <Input
                                                                disableUnderline
                                                                sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                                className='table-inputs-content'
                                                                size='small'
                                                                placeholder='Enter location name in Italian'
                                                                required
                                                                fullWidth
                                                                value={locationNameIt}
                                                                onChange={handleLocationNameItChange}
                                                            />
                                                            {locationNameItErrorMsg && (
                                                                <span className='error-msg'>{locationNameItErrorMsg}</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel className='p-3' value="4">
                                                <h5 className='sub-page-title'>Location Details In Japanese</h5>
                                                <div className='around-border'>
                                                    <div className="row japanese-details add-border my-3">
                                                        <div className="col-4"><p className='input-title'>Location Name:</p></div>
                                                        <div className="col-8 mb-3">
                                                            <Input
                                                                disableUnderline
                                                                sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                                className='table-inputs-content'
                                                                size='small'
                                                                placeholder='Enter location name in Japanese'
                                                                required
                                                                fullWidth
                                                                value={locationNameJa}
                                                                onChange={handleLocationNameJaChange}
                                                            />
                                                            {locationNameJaErrorMsg && (
                                                                <span className='error-msg'>{locationNameJaErrorMsg}</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel> */}

                                        </TabContext>
                                        <div className='submit-button-sub-page'>
                                            <button className={`PrimaryButton ${buttonloader ? 'button-loader' : ' '}`} type="submit" variant="contained" onClick={handleSubmit} fullWidth>
                                                {buttonloader ? '' : 'Submit'}
                                            </button>
                                        </div>
                                    </form>
                                    <Snackbar open={errorSnackbarOpen} autoHideDuration={3000} onClose={() => setErrorSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                                        <Alert severity="error" sx={{ width: '100%' }}>
                                            Error Updating Location
                                        </Alert>
                                    </Snackbar>
                                    <Snackbar open={successSnackbarOpen} autoHideDuration={3000} onClose={() => setSuccessSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                                        <Alert severity="success" sx={{ width: '100%' }}>
                                            Location Updated successfully!
                                        </Alert>
                                    </Snackbar>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );



}