import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import config from "../../../config";

const ImageUploaderContainer = styled(Paper)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "5px",
  margin: "0px",
  boxShadow: "none",

  "& .image-preview": {
    display: "flex",
    flexWrap: "wrap",
    marginTop: "16px",
    "& .image-preview-item": {
      position: "relative",
      margin: "7px",
      maxWidth: "135px",
      maxHeight: "90px",
      overflow: "hidden",

      "& img": {
        maxWidth: "100%",
        maxHeight: "100%",
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
      "& .delete-button": {
        position: "absolute",
        top: "-8px",
        right: "-8px",
        background: "#ffffffad",
      },
    },
  },
  "& .upload-image-button-gallery": {
    backgroundColor: "transparent",
    color: "#5C0F87",
    border: "none",
    boxShadow: "none",
    fontFamily: "Kufam",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "24px",

    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  "& .below-note": {
    textAlign: "center",
    display: "block",
    color: "#6F727A",
    fontFamily: "Kufam",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
  },
});

function ImageUploader({
  files,
  onFilesSelected: setFiles,
  galleryDeletedFiles,
  setGalleryDeletedFiles,
}) {

  const [errorMsg, setErrorMsg] = useState()
  const token = localStorage.getItem("Token-for-login");

  function handleFileUpload(event) {
    const fileList = event.target.files;
    const readerArray = [];
    const validFiles = [];

    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];

      if (file.size <= 5 * 1024 * 1024) { // Check if file size is less than 5MB
        const reader = new FileReader();
        reader.onloadend = () => {
          const dataURL = reader.result;
        };
        readerArray.push(reader);
        validFiles.push(file);
        reader.readAsDataURL(file);
        setFiles([...files, ...fileList]);
        setErrorMsg('')
      } else {
        // Display an error message for oversized image
        setErrorMsg(`Image '${file.name}' is larger than 5MB and cannot be uploaded.`)
        // alert(`Image '${file.name}' is larger than 5MB and cannot be uploaded.`);
      }
    }
  }

  function handleDrop(event) {
    event.preventDefault();
    const fileList = event.dataTransfer.files;
    const readerArray = [];
    const validFiles = [];

    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];

      if (file.size <= 5 * 1024 * 1024) { // Check if file size is less than 5MB
        const reader = new FileReader();
        reader.onloadend = () => {
          const dataURL = reader.result;
        };
        readerArray.push(reader);
        validFiles.push(file);
        reader.readAsDataURL(file);
        setFiles([...files, ...fileList]);
        setErrorMsg('')
      } else {
        // Display an error message for oversized image
        setErrorMsg(`Image '${file.name}' is larger than 5MB and cannot be uploaded.`)
        // alert(`Image '${file.name}' is larger than 5MB and cannot be uploaded.`);
      }
    }
  }

  function handleDelete(index) {
    if (!!files[index].tour_id) setGalleryDeletedFiles([...galleryDeletedFiles, files[index].id]);
    setFiles(() => {
      const newFiles = [...files];
      newFiles.splice(index, 1);
      // onFilesSelected(files);
      return newFiles;
    });
  }

  // function handleallConsole(e) {
  //   e.preventDefault();
  //   console.log("files on console gallery", files);
  //   setFiles(files);
  // }

  return (
    <ImageUploaderContainer
      onDrop={handleDrop}
      onDragOver={(event) => event.preventDefault()}
      className="gallery-image-uploader"
    >
      <input
        type="file"
        id="file-upload"
        accept="image/*"
        onChange={handleFileUpload}
        style={{ display: "none" }}
        multiple
      />
      <label htmlFor="file-upload">
        <Button
          className="upload-image-button-gallery"
          variant="text"
          component="span"
        // startIcon={<CloudUploadIcon />}
        >
          Click Here To Upload Images
        </Button>
        <span className="below-note">Upload Image Maximun size is 5MB*</span>
      </label>
      <Box className="image-preview">
        {files?.map((file, index) => (
          <Box className="image-preview-item" key={file?.name ?? file.id}>
            {file.image_name ? (
              <img
                src={config.imageBaseURL + file.image_name}
                alt={`Preview ${index}`}
              />
            ) : typeof file === "object" ? (
              <img src={URL.createObjectURL(file)} alt={`Preview ${index}`} />
            ) : null}
            <IconButton
              className="delete-button"
              onClick={() => handleDelete(index)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
      </Box>
      {errorMsg ? <span className="error-msg">{errorMsg}</span> : ''}
      {/* {files?.length > 1 ? (
        <button className="SecondaryButton" onClick={handleallConsole}>
          Done
        </button>
      ) : (
        ""
      )} */}
    </ImageUploaderContainer>
  );
}

export default ImageUploader;
