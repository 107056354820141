import React, { useEffect, useState } from 'react'
import MetaInfo from '../../../Layout/MetaInfo/MetaInfo'
import FullScreenLoader from '../../../Layout/FullScreenLoader/FullScreenLoader'
import TopHeaderMain from '../../../Layout/TopHeader/TopHeaderMain'
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';

const FaqsPage = () => {

    const [fullScreenLoader, setFullScreenLoader] = useState(true)

    const handleGotData = (data) => {
        if (data) {
            setFullScreenLoader(false)
        }
    }

    useEffect(() => {
        setFullScreenLoader(false)
    }, [])
    return (
        <div className="container-fluid d-flex">
            <div className="content-wrapper privacy-policy-wrapper">
                <div className="content-components-wrapper">
                    {fullScreenLoader ? <FullScreenLoader /> : ''}
                    <TopHeaderMain title={`FAQ's`} icons={<QuizOutlinedIcon fontSize="large" />} />
                    <div className='user-content sub-page-details-wrapper mt-4 '>
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <MetaInfo slug="faqs-page" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default FaqsPage