import React from 'react'
import CompanyDetails from '../../../Layout/CompanyDetails/CompanyDetails'

const ContactUs = () => {
    return (
        <>
            <CompanyDetails />
        </>
    )
}

export default ContactUs