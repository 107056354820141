import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    FormControl,
    Select,
    MenuItem,
    Menu,
    InputLabel,
    TableSortLabel,
    TableFooter,
    Checkbox,
    IconButton,
    Pagination,
    TablePagination,
    Snackbar,
    Alert,
    Button,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import PeopleIcon from '@mui/icons-material/People';
import AddIcon from '@mui/icons-material/Add';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

import DeleteIcon from '@mui/icons-material/Delete';

import axiosConfig from '../../../axios';
import authorization from '../../../authorization';
import TopHeaderMain from '../../../Layout/TopHeader/TopHeaderMain';
import DeleteModal from '../../../Layout/Modal/Modal';
import FullScreenLoader from '../../../Layout/FullScreenLoader/FullScreenLoader';

const StyledTextField = styled(TextField)({
    width: '100%',
    marginBottom: '1rem',
});

const StyledFormControl = styled(FormControl)({
    width: '100%',
    marginBottom: '1rem',
});

function BlogCategory() {
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortColumn, setSortColumn] = useState('id');
    const [sortOrder, setSortOrder] = useState('asc');
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);

    const [blogCategoryId, setBlogCategoryId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    const [fullScreenLoader, setFullScreenLoader] = useState(true)

    useEffect(() => {
        handleBlogCategoryList()
    }, [page, rowsPerPage]);

    const handleBlogCategoryList = () => {
        axiosConfig.get(`/admin/category?page=${page}`, {
            headers: {
                'Authorization': authorization.authorizationUrl
            }
        })
            .then(response => {
                setUsers(response.data.data.data);
                setTotalPages(response.data.data.last_page);

                setTimeout(() => {
                    setFullScreenLoader(false)
                }, 800);
            })
            .catch(error => console.log(error));
    }

    const close = () => {
        setShowModal(false);
    };

    const handleDeleteClick = (id) => {
        setBlogCategoryId(id);
        setShowModal(true);
    };

    const handleDeleteCategoryClick = () => {
        // setOpenSnackbar(true)

        axiosConfig.delete(`/admin/category/${blogCategoryId}`, {
            headers: {
                'Authorization': authorization.authorizationUrl
            }
        })
            .then(response => {
                // console.log(response.data);
                if (response.data.status) {
                    setOpenSnackbar(true)
                    setShowModal(false)
                    handleBlogCategoryList()
                }
            })
            .catch(error => console.log(error));





    };


    const history = useNavigate();
    const handleEditClick = (id) => {
        // logic to handle edit click
        history(`/blogs/edit-blog-category/${id}`);
        // console.log(`Edit clicked for user with id ${id}`);
    };


    return (
        <>
            <div className="container-fluid d-flex">
                <div className="content-wrapper">
                    <div className="content-components-wrapper">
                        {fullScreenLoader ? <FullScreenLoader /> : ''}
                        <TopHeaderMain title='Blog Categories' icons={<TextSnippetOutlinedIcon fontSize="large" />} />

                        <div className="user-content mt-4">
                            <div className="row mb-4">
                                <div className="col-6">

                                </div>
                                <div className="col-6 text-end">
                                    <Link to='/blogs/create-blog-category' className='nounderline'>
                                        <button className='PrimaryButton ms-2'><AddIcon /> Create New Category</button>
                                    </Link>
                                </div>
                            </div>
                            <div className="user-table-content">
                                <TableContainer
                                    component={Paper}
                                    sx={{ boxShadow: "none", border: "1px solid #E4E4E4" }}
                                >
                                    <Table >
                                        <TableHead style={{ backgroundColor: "#F2F2F2" }}>
                                            <TableRow>
                                                <TableCell>
                                                    <TableSortLabel>
                                                        No.
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell>
                                                    <TableSortLabel>
                                                        Category Name
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {users.length > 0 ? (
                                            <TableBody className="table-body-wrapper tours-table-body-wrapper">
                                                {users.map((user, index) => (
                                                    <TableRow key={user.id}>
                                                        <TableCell className="table-data table-data-bold">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell className="table-data">
                                                            {user.title_en}
                                                        </TableCell>
                                                        <TableCell className="table-data">

                                                        </TableCell>
                                                        <TableCell className="table-data">
                                                            {/* {user.user_location} */}
                                                        </TableCell>
                                                        <TableCell className="table-data">
                                                            {/* {user.latitude} */}
                                                        </TableCell>
                                                        <TableCell className="table-data">
                                                            {/* {user.longitude} */}
                                                        </TableCell>
                                                        <TableCell className="action-dropdown-btn">
                                                            <Dropdown>
                                                                <Dropdown.Toggle
                                                                    variant="secondary"
                                                                    id="dropdown-basic"
                                                                    size="sm"
                                                                    style={{
                                                                        color: "#000000",
                                                                        border: "none",
                                                                        background: "transparent",
                                                                    }}
                                                                >
                                                                    <MoreVertIcon />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>

                                                                    <Dropdown.Item className='px-0'>
                                                                        <Button
                                                                            color="primary"
                                                                            aria-label="View"
                                                                            onClick={() => handleEditClick(user.id)}
                                                                            style={{ fontSize: "14px", width: "100%", justifyContent: "start", paddingLeft: "18px" }}
                                                                            startIcon={<EditIcon style={{ fontSize: 16 }} />}
                                                                        >
                                                                            Edit
                                                                        </Button>
                                                                        {/* <IconButton
                                                                            color="primary"
                                                                            aria-label="Edit"
                                                                            onClick={() => handleEditClick(user.id)}
                                                                            style={{ fontSize: "14px" }}
                                                                        >
                                                                            <EditIcon
                                                                                style={{ fontSize: 16 }}
                                                                                className="me-1"
                                                                            />{" "}
                                                                            Edit
                                                                        </IconButton> */}
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className='px-0'>
                                                                        <Button
                                                                            color="secondary"
                                                                            aria-label="Delete"
                                                                            onClick={() => handleDeleteClick(user.id)}
                                                                            style={{ fontSize: "14px", width: "100%", justifyContent: "start", paddingLeft: "18px" }}
                                                                            startIcon={<DeleteIcon style={{ fontSize: 16 }} />}
                                                                        >
                                                                            Delete
                                                                        </Button>
                                                                        {/* <IconButton
                                                                            color="secondary"
                                                                            aria-label="Delete"
                                                                            onClick={() => handleDeleteClick(user.id)}
                                                                            style={{ fontSize: "14px" }}
                                                                        >
                                                                            <DeleteIcon
                                                                                style={{ fontSize: 16 }}
                                                                                className="me-1"
                                                                            />{" "}
                                                                            Delete
                                                                        </IconButton> */}
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        ) : (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell colSpan={6} align="center">
                                                        No Tours found.
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )}
                                    </Table>
                                </TableContainer>
                                <div className="pagination-wrapper">
                                    {totalPages > 1 ? (
                                        <Pagination
                                            className="custom-pagination"
                                            count={totalPages}
                                            variant="outlined"
                                            shape="rounded"
                                            page={page}
                                            onChange={(event, newPage) => setPage(newPage)}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>

                            </div>
                            <DeleteModal
                                showModal={showModal}
                                onClose={close}
                                onSubmit={handleDeleteCategoryClick}
                            />
                            <Snackbar
                                open={openSnackbar}
                                autoHideDuration={5000}
                                onClose={() => setOpenSnackbar(false)}
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            >
                                <Alert
                                    severity="success"
                                    sx={{ width: "100%" }}
                                >
                                    Blog Category Deleted successfully!
                                </Alert>
                            </Snackbar>
                            <Snackbar
                                open={openErrorSnackbar}
                                autoHideDuration={5000}
                                onClose={() => setOpenErrorSnackbar(false)}
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            >
                                <Alert
                                    severity="error"
                                    sx={{ width: "100%" }}
                                >
                                    Delete failed!
                                </Alert>
                            </Snackbar>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default BlogCategory;