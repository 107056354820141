import React, { useState, useEffect, useRef } from 'react';
import TopHeaderMain from '../../../Layout/TopHeader/TopHeaderMain'
import {
    Box,
    Button,
    Tab,
    Tabs,
    TextField,
    Snackbar,
    Alert,
    Input,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import TopHeaderInternal from '../../../Layout/TopHeader/TopHeaderInternal';
import { TabContext, TabPanel } from '@mui/lab';
import axiosConfig from '../../../axios';
import authorization from '../../../authorization';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import config from '../../../config';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FullScreenLoader from '../../../Layout/FullScreenLoader/FullScreenLoader';


export default function EditTestimony() {
    const history = useNavigate();
    const [testimonialData, setTestimonialData] = useState([])
    const [value, setValue] = React.useState('1');
    const [categoryName, setCategoryName] = useState('');
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [imageinit, setImageInit] = useState()
    const [customerName, setCustomerName] = useState('');
    const [file, setFile] = useState(null);
    const [location, setLocation] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [description, setDescription] = useState('');
    const [descriptionAr, setDescriptionAr] = useState('');
    const [descriptionIt, setDescriptionIt] = useState('');
    const [descriptionJa, setDescriptionJa] = useState('');
    const [dragActive, setDragActive] = useState(false);
    const [errorFileUploader, setErrorFileUploader] = useState(false);
    const [handleChangeTab, setHandleChangeTab] = useState(false)

    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');

    const [buttonloader, setButtonLoader] = useState(false)
    const [fullScreenLoader, setFullScreenLoader] = useState(true)

    const [errTab, setErrTab] = useState({
        errcustomerName: '',
        errlocation: '',
        errdescription: '',
        errdescriptionAr: '',
        errdescriptionIt: '',
        errdescriptionJa: '',
        errlatitude: '',
        errlongitude: '',
        errImage: '',
    })
    const [valErrTabEn, setValErrTabEn] = useState(true)
    const [valErrTabAr, setValErrTabAr] = useState(true)
    const [valErrTabIt, setValErrTabIt] = useState(true)
    const [valErrTabJa, setValErrTabJa] = useState(true)

    let { id } = useParams();
    var gotFromUrlId = id
    let theDate
    useEffect(() => {
        // console.log("gotFromUrlId", gotFromUrlId)
        axiosConfig
            .get(`/admin/testimonial/${gotFromUrlId}`, {
                headers: {
                    Authorization: authorization.authorizationUrl,
                },
            })
            .then((response) => {
                // setSelectedDate(dayjs('2023-09-19'))
                let testimony = response.data.data
                setTestimonialData(response.data.data);
                setCustomerName(testimony.user_name)
                setLocation(testimony.user_location)
                setImageInit(testimony.user_image)
                setDescription(testimony.text_en)
                setDescriptionAr(testimony.text_ar)
                setDescriptionIt(testimony.text_it)
                setDescriptionJa(testimony.text_ja)
                setLatitude(testimony.latitude)
                setLongitude(testimony.longitude)

                if (response.data.status) {
                    setTimeout(() => {
                        setFullScreenLoader(false)
                    }, 800);
                }
                // console.log("category details", response.data.data);
            })
            .catch((error) => console.log(error));
    }, []);

    const token = localStorage.getItem("Token-for-login");

    const handleupdateData = (e) => {


        // console.log("handleupdateData gotFromUrlId", gotFromUrlId)
        e.preventDefault();
        setButtonLoader(true)

        const emptyFieldMessages = [];
        const shortFieldMessages = [];

        setValErrTabEn(true)
        setValErrTabAr(true)
        setValErrTabIt(true)
        setValErrTabJa(true)

        let errcustomerName = '';
        let errlocation = '';
        let errdescription = '';
        let errdescriptionAr = '';
        let errdescriptionIt = '';
        let errdescriptionJa = '';
        let errlatitude = '';
        let errlongitude = '';
        let errImage = '';

        const fieldsToValidate = [
            { name: 'customerName', value: customerName, titles: 'Customer Name' },
            { name: 'location', value: location, titles: 'Location' },
            { name: 'description', value: description, titles: 'English Description' },
            { name: 'descriptionAr', value: descriptionAr, titles: 'Arabic Description' },
            // { name: 'descriptionIt', value: descriptionIt, titles: 'Italian Description' },
            // { name: 'descriptionJa', value: descriptionJa, titles: 'Japanese Description' },
            { name: 'latitude', value: latitude, titles: 'Latitude' },
            { name: 'longitude', value: longitude, titles: 'Longitude' },

        ];

        fieldsToValidate.forEach((field) => {
            if (!field.value || field.value.trim().length === 0) {
                if (field.name === 'customerName') {
                    setValErrTabEn(false)
                    errcustomerName = `${field.titles} is empty.`;
                } else if (field.name === 'location') {
                    setValErrTabEn(false)
                    errlocation = `${field.titles} is empty.`;
                } else if (field.name === 'description') {
                    setValErrTabEn(false)
                    errdescription = `${field.titles} is empty.`;
                } else if (field.name === 'descriptionAr') {
                    setValErrTabAr(false)
                    errdescriptionAr = `${field.titles} is empty.`;
                } 
                // else if (field.name === 'descriptionIt') {
                //     setValErrTabIt(false)
                //     errdescriptionIt = `${field.titles} is empty.`;
                // } else if (field.name === 'descriptionJa') {
                //     setValErrTabJa(false)
                //     errdescriptionJa = `${field.titles} is empty.`;
                // } 
                else if (field.name === 'latitude') {
                    setValErrTabEn(false)
                    errlatitude = `${field.titles} is empty.`;
                } else if (field.name === 'longitude') {
                    setValErrTabEn(false)
                    errlongitude = `${field.titles} is empty.`;
                }
            } else if (field.value.trim().length < 3) {
                if (field.name === 'customerName') {
                    setValErrTabEn(false)
                    errcustomerName = `${field.titles} has less than 3 characters.`;
                } else if (field.name === 'location') {
                    setValErrTabEn(false)
                    errlocation = `${field.titles} has less than 3 characters.`;
                } else if (field.name === 'description') {
                    setValErrTabEn(false)
                    errdescription = `${field.titles} has less than 3 characters.`;
                } else if (field.name === 'descriptionAr') {
                    setValErrTabAr(false)
                    errdescriptionAr = `${field.titles} has less than 3 characters.`;
                } 
                // else if (field.name === 'descriptionIt') {
                //     setValErrTabIt(false)
                //     errdescriptionIt = `${field.titles} has less than 3 characters.`;
                // } 
                // else if (field.name === 'descriptionJa') {
                //     setValErrTabJa(false)
                //     errdescriptionJa = `${field.titles} has less than 3 characters.`;
                // } 
                else if (field.name === 'latitude') {
                    setValErrTabEn(false)
                    errlatitude = `${field.titles} has less than 3 characters.`;
                } else if (field.name === 'longitude') {
                    setValErrTabEn(false)
                    errlongitude = `${field.titles} has less than 3 characters.`;
                }
            }
            // if (file) {
            //     setHandleChangeTab(false)
            // } else if (handleChangeTab === true) {
            //     if (!file) {
            //         errImage = `Upload an Image.`;
            //     }
            // }


            if (field.name === 'latitude' && (isNaN(parseFloat(field.value)) || Math.abs(parseFloat(field.value)) > 90)) {
                setValErrTabEn(false)
                errlatitude = 'Invalid latitude value.';
            }

            if (field.name === 'longitude' && (isNaN(parseFloat(field.value)) || Math.abs(parseFloat(field.value)) > 180)) {
                setValErrTabEn(false)
                errlongitude = 'Invalid longitude value.';
            }
        });

        // Set the error messages in state
        setErrTab({
            errcustomerName,
            errlocation,
            errdescription,
            errdescriptionAr,
            // errdescriptionIt,
            // errdescriptionJa,
            errlatitude,
            errlongitude,
            errImage,
        });

        // Check if any validation failed
        const validationFailed =
            errcustomerName !== '' ||
            errlocation !== '' ||
            errdescription !== '' ||
            errdescriptionAr !== '' ||
            // errdescriptionIt !== '' ||
            // errdescriptionJa !== '' ||
            errlatitude !== '' ||
            errlongitude !== '' ||
            errImage !== '';

        if (validationFailed) {
            // console.log(errTab.errImage, "handleChangeTab", handleChangeTab)
            setErrorMsg("Fields are Empty")
            setErrorSnackbarOpen(true)
            setValErrTabEn(false)
            setButtonLoader(false)
            return;
        }

        axiosConfig
            .post(`/admin/testimonial/${gotFromUrlId}`, {
                user_name: customerName !== testimonialData.user_name ? customerName : testimonialData.user_name,
                user_location: location !== testimonialData.user_location ? location : testimonialData.user_location,
                text_en: description,
                text_ar: descriptionAr !== testimonialData.text_ar ? descriptionAr : testimonialData.text_ar,
                text_it:  description,
                text_ja:  description,
                user_image: file,
                latitude: latitude !== testimonialData.latitude ? latitude : testimonialData.latitude,
                longitude: longitude !== testimonialData.longitude ? longitude : testimonialData.longitude
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                // console.log("testimonial update Response", response.data);
                if (response.data.status) {
                    setSuccessMsg("Testimony Updated Successfully!")
                    setSuccessSnackbarOpen(true)
                    setButtonLoader(false)
                    setTimeout(() => history("/testimony"), 1000);
                } else {
                    setErrorMsg(response.data.message)
                    setErrorSnackbarOpen(true)
                    setButtonLoader(false)
                }
                setErrTab({
                    errcustomerName: '',
                    errlocation: '',
                    errdescription: '',
                    errdescriptionAr: '',
                    errdescriptionIt: '',
                    errdescriptionJa: '',
                    errlatitude: '',
                    errlongitude: '',
                });
            })
            .catch((error) => console.log(error));

        // console.log("UPDATE CLICKED", updatedData);

    };
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const inputRef = useRef(null);


    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            const droppedFile = e.dataTransfer.files[0];
            if (validateFile(droppedFile)) {
                setFile(droppedFile);
                displayFile(droppedFile);
                // console.log("droped log", droppedFile);
                setErrorFileUploader(false)
            } else {
                setErrorFileUploader(true);
            }
        }
    };

    const handleChange = (e) => {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            const selectedFile = e.target.files[0];
            if (validateFile(selectedFile)) {
                setFile(selectedFile);
                displayFile(selectedFile);
                // console.log(selectedFile);
                setErrorFileUploader(false)
            } else {
                setErrorFileUploader(true);
            }
        }
    };

    const onButtonClick = () => {
        inputRef.current.click();
    };

    const displayFile = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            const img = document.getElementById("uploaded-img");
            img.src = e.target.result;
        };
    };

    const validateFile = (file) => {
        const allowedTypes = ["image/png", "image/jpeg", "image/gif", "image/bmp", "image/svg+xml", "image/webp", "image/x-icon"];
        const maxFileSize = 5 * 1024 * 1024; // 5MB

        if (!allowedTypes.includes(file.type)) {
            return false;
        }

        if (file.size > maxFileSize) {
            return false;
        }

        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // TODO: upload the file and handle the response
        if (categoryName && file) {
            setCategoryName('');
            setFile(null);
            setSuccessSnackbarOpen(true);
        } else {
            setErrorSnackbarOpen(true);
        }
    };

    const handleClose = (e) => {
        e.preventDefault()
        setFile(null)
        setHandleChangeTab(true)
    }


    return (
        <>
            <div className="container-fluid d-flex">
                <div className="content-wrapper">
                    <div className="content-components-wrapper">
                        {fullScreenLoader ? <FullScreenLoader /> : ''}
                        <TopHeaderInternal title={`Edit Testimony (${gotFromUrlId})`} linkToBackBtn={'/testimony'} />
                        {/* <TopHeaderMain title={`Edit Testimony (${gotFromUrlId})`} /> */}
                        <div className="user-content sub-page-details-wrapper mt-4">
                            <div className="row">

                                <div className="col-xl-6 col-lg-6 col-md-7 col-sm-12">

                                    <form id="form-file-upload" onDragEnter={handleDrag}>
                                        <TabContext value={value}>
                                            <Tabs value={value} onChange={handleTabChange} variant="scrollable" scrollButtons allowScrollButtonsMobile aria-label="nav tabs example">
                                                <Tab label="English" value="1" className={`error-msg ${valErrTabEn ? '' : 'error-msg-dot'}`} />
                                                <Tab label="Arabic" value="2" className={`error-msg ${valErrTabAr ? '' : 'error-msg-dot'}`} />
                                                {/* <Tab label="Italian" value="3" className={`error-msg ${valErrTabIt ? '' : 'error-msg-dot'}`} /> */}
                                                {/* <Tab label="Japanese" value="4" className={`error-msg ${valErrTabJa ? '' : 'error-msg-dot'}`} /> */}
                                            </Tabs>
                                            <TabPanel className='p-3' value="1">
                                                <h5 className='sub-page-title'>Testimony Details In English</h5>
                                                <div className='around-border'>
                                                    {testimonialData ? (
                                                        <div className="row english-details my-3">


                                                            <div className="col-4"><p className="input-title" >Customer name :</p></div>
                                                            <div className="col-8 mb-3">
                                                                <Input
                                                                    disableUnderline
                                                                    sx={{
                                                                        border: "1px solid #C2C8CC;",
                                                                        borderRadius: 1,
                                                                        padding: "8px 15px",
                                                                        width: "100%",
                                                                        maxWidth: "100%",
                                                                    }}
                                                                    className="table-inputs-content"
                                                                    size="small"
                                                                    placeholder="Enter Customer name"
                                                                    value={customerName}
                                                                    onChange={(e) => setCustomerName(e.target.value)}
                                                                />
                                                                <span className='error-msg'>{errTab.errcustomerName}</span>
                                                            </div>
                                                            <div className="col-4"><p className="input-title" >Image :</p></div>
                                                            <div className="col-8">
                                                                {/* <div>
                                                                    <img src={config.imageBaseURL + imageinit} alt={config.imageBaseURL + imageinit} className='image' />
                                                                </div> */}
                                                                <div className='cover-image-wrapper mb-3'>
                                                                    <input
                                                                        ref={inputRef}
                                                                        type="file"
                                                                        accept="image/png, image/jpeg, image/gif"
                                                                        onChange={handleChange}
                                                                        style={{ display: 'none' }}
                                                                        {...(handleChangeTab && { required: true })}
                                                                    />
                                                                    <label
                                                                        htmlFor="input-file-upload"
                                                                        className={dragActive ? "drag-active label-file-upload" : "label-file-upload"}
                                                                        onDragEnter={handleDrag}
                                                                        onDragLeave={handleDrag}
                                                                        onDragOver={handleDrag}
                                                                        onDrop={handleDrop}
                                                                    >
                                                                        {!handleChangeTab ? (
                                                                            <div className="current-image">
                                                                                <img src={config.imageBaseURL + imageinit} alt={imageinit} className='current-img-tag' />
                                                                                <button className='closebutton' onClick={handleClose}>
                                                                                    <HighlightOffIcon />
                                                                                </button>
                                                                            </div>
                                                                        ) : file ? (
                                                                            <Box className="current-image">
                                                                                <img src={URL.createObjectURL(file)} alt="Uploaded file" id="uploaded-img" style={{ maxWidth: '100%' }} />
                                                                                <p className='selected-file' style={{ fontSize: '12px', paddingTop: '5px' }}>Selected file: {file.name}</p>
                                                                                <button className='closebutton' onClick={handleClose}>
                                                                                    <HighlightOffIcon />
                                                                                </button>
                                                                            </Box>
                                                                        ) : (
                                                                            <div className="text-center">
                                                                                <p className="m-0">Drag and drop your image here or</p>
                                                                                <Button
                                                                                    className="upload-button"
                                                                                    variant="text"
                                                                                    component="span"
                                                                                    onClick={onButtonClick}
                                                                                >
                                                                                    Upload an Image
                                                                                </Button>
                                                                            </div>
                                                                        )}
                                                                    </label>
                                                                    {dragActive && (
                                                                        <div
                                                                            id="drag-file-element"
                                                                            onDragEnter={handleDrag}
                                                                            onDragLeave={handleDrag}
                                                                            onDragOver={handleDrag}
                                                                            onDrop={handleDrop}
                                                                        ></div>
                                                                    )}
                                                                    <span className='error-msg'>{errTab.errImage}</span>
                                                                    {errorFileUploader && <p>Invalid file type or size</p>}
                                                                </div>
                                                                <span className='error-msg'></span>
                                                            </div>
                                                            <div className="col-4"><p className="input-title" >Location :</p></div>
                                                            <div className="col-8 mb-3">
                                                                <Input disableUnderline
                                                                    sx={{
                                                                        border: "1px solid #C2C8CC;",
                                                                        borderRadius: 1,
                                                                        padding: "8px 15px",
                                                                        width: "100%",
                                                                        maxWidth: "100%",
                                                                    }}
                                                                    className="table-inputs-content"
                                                                    size="small" placeholder='Enter Location' value={location} onChange={(e) => setLocation(e.target.value)} />
                                                                <span className='error-msg'>{errTab.errlocation}</span>
                                                            </div>
                                                            <div className="col-4"><p className='input-title detail-title-en'>Description :</p></div>
                                                            <div className="col-8 mb-3">
                                                                <Input disableUnderline
                                                                    sx={{
                                                                        border: "1px solid #C2C8CC;",
                                                                        borderRadius: 1,
                                                                        padding: "8px 15px",
                                                                        width: "100%",
                                                                        maxWidth: "100%",
                                                                    }}
                                                                    multiline
                                                                    minRows={6}
                                                                    maxRows={8}
                                                                    className="table-inputs-content"
                                                                    size="small" placeholder='Enter English description' value={description} onChange={(e) => setDescription(e.target.value)} />
                                                                <span className='error-msg'>{errTab.errdescription}</span>
                                                            </div>
                                                            <div className="col-4"><p className="input-title" >Latitude :</p></div>
                                                            <div className="col-8 mb-3">
                                                                <Input disableUnderline
                                                                    sx={{
                                                                        border: "1px solid #C2C8CC;",
                                                                        borderRadius: 1,
                                                                        padding: "8px 15px",
                                                                        width: "100%",
                                                                        maxWidth: "100%",
                                                                    }}
                                                                    className="table-inputs-content"
                                                                    size="small" placeholder='Enter Latitude' value={latitude} onChange={(e) => setLatitude(e.target.value)} />
                                                                <span className='error-msg'>{errTab.errlatitude}</span>
                                                            </div>
                                                            <div className="col-4"><p className="input-title" >Longitude :</p></div>
                                                            <div className="col-8 mb-3">
                                                                <Input disableUnderline
                                                                    sx={{
                                                                        border: "1px solid #C2C8CC;",
                                                                        borderRadius: 1,
                                                                        padding: "8px 15px",
                                                                        width: "100%",
                                                                        maxWidth: "100%",
                                                                    }}
                                                                    className="table-inputs-content"
                                                                    size="small" placeholder='Enter Longitude' value={longitude} onChange={(e) => setLongitude(e.target.value)} />
                                                                <span className='error-msg'>{errTab.errlongitude}</span>
                                                            </div>

                                                        </div>
                                                    ) : (
                                                        <p>Loading user data...</p>
                                                    )}
                                                </div>
                                            </TabPanel>
                                            <TabPanel className='p-3' value="2">
                                                <h5 className='sub-page-title'>Testimony Details In Arabic</h5>
                                                <div className='around-border'>
                                                    <div className="row arabic-details my-3">

                                                        <div className="col-4"><p className="input-title" >Customer name :</p></div>
                                                        <div className="col-8 mb-3">
                                                            <Input
                                                                disabled
                                                                disableUnderline
                                                                sx={{
                                                                    border: "1px solid #C2C8CC;",
                                                                    borderRadius: 1,
                                                                    padding: "8px 15px",
                                                                    width: "100%",
                                                                    maxWidth: "100%",
                                                                }}
                                                                className="table-inputs-content"
                                                                size="small"
                                                                placeholder="Enter Customer name"
                                                                value={customerName}
                                                                onChange={(e) => setCustomerName(e.target.value)}
                                                            />
                                                            <span className='error-msg'>{errTab.errcustomerName}</span>
                                                        </div>
                                                        <div className="col-4"><p className='input-title detail-title-en'>Description :</p></div>
                                                        <div className="col-8 mb-3">
                                                            <Input disableUnderline
                                                                sx={{
                                                                    border: "1px solid #C2C8CC;",
                                                                    borderRadius: 1,
                                                                    padding: "8px 15px",
                                                                    width: "100%",
                                                                    maxWidth: "100%",
                                                                }}
                                                                multiline
                                                                minRows={6}
                                                                maxRows={8}
                                                                className="table-inputs-content"
                                                                size="small" placeholder='Enter Arabic description' value={descriptionAr} onChange={(e) => setDescriptionAr(e.target.value)} /> <span className='error-msg'>{errTab.errdescriptionAr}</span> </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            {/* <TabPanel className='p-3' value="3">
                                                <h5 className='sub-page-title'>Testimony Details In Italian</h5>
                                                <div className='around-border'>
                                                    <div className="row italian-details my-3">

                                                        <div className="col-4"><p className="input-title" >Customer name :</p></div>
                                                        <div className="col-8 mb-3">
                                                            <Input
                                                                disabled
                                                                disableUnderline
                                                                sx={{
                                                                    border: "1px solid #C2C8CC;",
                                                                    borderRadius: 1,
                                                                    padding: "8px 15px",
                                                                    width: "100%",
                                                                    maxWidth: "100%",
                                                                }}
                                                                className="table-inputs-content"
                                                                size="small"
                                                                placeholder="Enter Customer name"
                                                                value={customerName}
                                                                onChange={(e) => setCustomerName(e.target.value)}
                                                            />
                                                            <span className='error-msg'>{errTab.errcustomerName}</span>
                                                        </div>
                                                        <div className="col-4"><p className='input-title detail-title-en'>Description :</p></div>
                                                        <div className="col-8 mb-3"> <Input disableUnderline
                                                            sx={{
                                                                border: "1px solid #C2C8CC;",
                                                                borderRadius: 1,
                                                                padding: "8px 15px",
                                                                width: "100%",
                                                                maxWidth: "100%",
                                                            }}
                                                            multiline
                                                            minRows={6}
                                                            maxRows={8}
                                                            className="table-inputs-content"
                                                            size="small" placeholder='Enter Italian description' value={descriptionIt} onChange={(e) => setDescriptionIt(e.target.value)} /><span className='error-msg'>{errTab.errdescriptionIt}</span> </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel className='p-3' value="4">
                                                <h5 className='sub-page-title'>Testimony Details In Japanese</h5>
                                                <div className='around-border'>
                                                    <div className="row japanese-details add-border my-3">

                                                        <div className="col-4"><p className="input-title" >Customer name :</p></div>
                                                        <div className="col-8 mb-3"> <Input
                                                            disabled
                                                            disableUnderline
                                                            sx={{
                                                                border: "1px solid #C2C8CC;",
                                                                borderRadius: 1,
                                                                padding: "8px 15px",
                                                                width: "100%",
                                                                maxWidth: "100%",
                                                            }}
                                                            className="table-inputs-content"
                                                            size="small"
                                                            placeholder="Enter Customer name"
                                                            value={customerName}
                                                            onChange={(e) => setCustomerName(e.target.value)}
                                                        />
                                                            <span className='error-msg'>{errTab.errcustomerName}</span>
                                                        </div>
                                                        <div className="col-4"><p className='input-title detail-title-en'>Description :</p></div>
                                                        <div className="col-8 mb-3"> <Input disableUnderline
                                                            sx={{
                                                                border: "1px solid #C2C8CC;",
                                                                borderRadius: 1,
                                                                padding: "8px 15px",
                                                                width: "100%",
                                                                maxWidth: "100%",
                                                            }}
                                                            multiline
                                                            minRows={6}
                                                            maxRows={8}
                                                            className="table-inputs-content"
                                                            size="small" placeholder='Enter Japanese description' value={descriptionJa} onChange={(e) => setDescriptionJa(e.target.value)} /> <span className='error-msg'>{errTab.errdescriptionJa}</span></div>
                                                    </div>
                                                </div>
                                            </TabPanel> */}
                                        </TabContext>
                                        <div className='d-flex'>
                                            <div className='submit-button-sub-page mb-4'>
                                                <button className={`PrimaryButton ${buttonloader ? 'button-loader' : ' '}`} type="submit" variant="contained" onClick={handleupdateData} fullWidth>
                                                    {!buttonloader ? 'Submit' : ''}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <Snackbar open={errorSnackbarOpen} autoHideDuration={3000} onClose={() => setErrorSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                                <Alert severity='error' sx={{ width: '100%' }}>
                                    {errorMsg}
                                </Alert>
                            </Snackbar>
                            <Snackbar open={successSnackbarOpen} autoHideDuration={3000} onClose={() => setSuccessSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                                <Alert severity='success' sx={{ width: '100%' }}>
                                    {successMsg}
                                </Alert>
                            </Snackbar>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
