import React, { useState, useEffect } from 'react';
import TopHeaderMain from '../../../Layout/TopHeader/TopHeaderMain'
import {
    TextField,
    Tabs,
    Tab
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Link, useParams } from 'react-router-dom';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import config from '../../../config';
import authorization from '../../../authorization';
import axiosConfig from '../../../axios';
import TopHeaderInternal from '../../../Layout/TopHeader/TopHeaderInternal';
import FullScreenLoader from '../../../Layout/FullScreenLoader/FullScreenLoader';

export default function TestimonyDetails() {
    const [userData, setUserData] = useState([])
    const [value, setValue] = React.useState('1');
    const [fullScreenLoader, setFullScreenLoader] = useState(true)

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    let { id } = useParams();
    var gotFromUrlId = id

    useEffect(() => {
        axiosConfig
            .get(`/admin/testimonial/${gotFromUrlId}`, {
                headers: {
                    'Authorization': authorization.authorizationUrl
                },
            })
            .then((response) => {
                setUserData(response.data.data);
                // console.log("testimony data", response.data.data);
                if (response.data.status) {
                    setTimeout(() => {
                        setFullScreenLoader(false)
                    }, 800);
                }
            })
            .catch((error) => console.log(error));
    }, []);


    const handleDeleteClick = (id) => {
        axiosConfig.delete(`/admin/testimonial/${id}`, {
            headers: {
                'Authorization': authorization.authorizationUrl
            }
        })
            .then(response => {
                console.log(response.data.data.data);
            })
            .catch(error => console.log(error));

    }
    function formatDate(dateString) {
        const date = new Date(dateString);

        // Extract the components of the date
        const day = date.getDate();
        const month = date.getMonth() + 1; // Months are zero-based
        const year = date.getFullYear();

        // Pad the day and month with leading zeros if necessary
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;

        // Return the formatted date as "dd-mm-yyyy"
        return `${formattedDay}-${formattedMonth}-${year}`;
    }


    return (
        <>
            <div className="container-fluid d-flex">
                <div className="content-wrapper">
                    <div className="content-components-wrapper">
                        {/* <TopHeaderMain title={`Testimony detail (${gotFromUrlId})`} /> */}
                        {fullScreenLoader ? <FullScreenLoader /> : ''}
                        <TopHeaderInternal title={`Testimony Details`} linkToBackBtn={'/testimony'} />
                        <div className="user-content sub-page-details-wrapper Testimony-Details mt-4">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-7 col-sm-12">
                                    {userData ? (
                                        <div>
                                            <TabContext value={value}>
                                                <Tabs value={value} onChange={handleTabChange} variant="scrollable" scrollButtons allowScrollButtonsMobile aria-label="nav tabs example">
                                                    <Tab label="English" value="1" />
                                                    <Tab label="Arabic" value="2" />
                                                    <Tab label="Italian" value="3" />
                                                    <Tab label="Japanese" value="4" />
                                                </Tabs>
                                                <TabPanel className='p-3' value="1">
                                                    <h5 className='sub-page-title'>Testimony Details In English</h5>
                                                    <div className='around-border'>
                                                        <div className="row english-details my-3">
                                                            <div className="col-4"><p className="input-title">Date :</p></div>
                                                            <div className="col-8"><TextField size='small' className='mb-3' multiline style={{ maxWidth: '100%' }} value={formatDate(userData.updated_at)} InputProps={{ readOnly: true }} /> </div>
                                                            <div className="col-4"><p className="input-title">Customer name :</p></div>
                                                            <div className="col-8"> <TextField size='small' className='mb-3' multiline style={{ maxWidth: '100%' }} value={userData.user_name} InputProps={{ readOnly: true }} /> </div>
                                                            <div className="col-4"><p className="input-title">Image :</p></div>
                                                            <div className="col-8"> <img src={config.imageBaseURL + userData.user_image} alt={config.imageBaseURL + userData.user_image} className='mb-3 testimony-img' /> </div>
                                                            <div className="col-4"><p className="input-title">Location :</p></div>
                                                            <div className="col-8"> <TextField size='small' className='mb-3' multiline style={{ maxWidth: '100%' }} value={userData.user_location} InputProps={{ readOnly: true }} /> </div>
                                                            <div className="col-4"><p className="input-title">Latitude :</p></div>
                                                            <div className="col-8"> <TextField size='small' className='mb-3' multiline style={{ maxWidth: '100%' }} value={userData.latitude} InputProps={{ readOnly: true }} /> </div>
                                                            <div className="col-4"><p className="input-title">longitude :</p></div>
                                                            <div className="col-8"> <TextField size='small' className='mb-3' multiline style={{ maxWidth: '100%' }} value={userData.longitude} InputProps={{ readOnly: true }} /> </div>
                                                            <div className="col-4"><p className='input-title detail-title-en'>Description :</p></div>
                                                            <div className="col-8"> <TextField size='small' className='mb-3' multiline style={{ maxWidth: '100%' }} value={userData.text_en} InputProps={{ readOnly: true }} /> </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel className='p-3' value="2">
                                                    <h5 className='sub-page-title'>Testimony Details In Arabic</h5>
                                                    <div className='around-border'>
                                                        <div className="row arabic-details my-3">
                                                            <div className="col-4"><p className='input-title detail-title-ar'>Customer name :</p></div>
                                                            <div className="col-8"> <TextField size='small' className='mb-3' multiline style={{ maxWidth: '100%' }} value={userData.user_name} InputProps={{ readOnly: true }} /> </div>
                                                            <div className="col-4"><p className='input-title detail-title-ar'>Location :</p></div>
                                                            <div className="col-8"> <TextField size='small' className='mb-3' multiline style={{ maxWidth: '100%' }} value={userData.user_location} InputProps={{ readOnly: true }} /> </div>
                                                            <div className="col-4"><p className='input-title detail-title-en'>Description :</p></div>
                                                            <div className="col-8"> <TextField size='small' className='mb-3' multiline style={{ maxWidth: '100%' }} value={userData.text_ar} InputProps={{ readOnly: true }} /> </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel className='p-3' value="3">
                                                    <h5 className='sub-page-title'>Testimony Details In Italian</h5>
                                                    <div className='around-border'>
                                                        <div className="row italian-details my-3">

                                                            <div className="col-4"><p className='input-title detail-title-it'>Customer name :</p></div>
                                                            <div className="col-8"> <TextField size='small' className='mb-3' multiline style={{ maxWidth: '100%' }} value={userData.user_name} InputProps={{ readOnly: true }} /> </div>
                                                            <div className="col-4"><p className='input-title detail-title-it'>Location :</p></div>
                                                            <div className="col-8"> <TextField size='small' className='mb-3' multiline style={{ maxWidth: '100%' }} value={userData.user_location} InputProps={{ readOnly: true }} /> </div>
                                                            <div className="col-4"><p className='input-title detail-title-en'>Description :</p></div>
                                                            <div className="col-8"> <TextField size='small' className='mb-3' multiline style={{ maxWidth: '100%' }} value={userData.text_it} InputProps={{ readOnly: true }} /> </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel className='p-3' value="4">
                                                    <h5 className='sub-page-title'>Testimony Details In Japanese</h5>
                                                    <div className='around-border'>
                                                        <div className="row japanese-details my-3">

                                                            <div className="col-4"><p className='input-title detail-title-jp'>Customer name :</p></div>
                                                            <div className="col-8"> <TextField size='small' className='mb-3' style={{ maxWidth: '100%' }} value={userData.user_name} InputProps={{ readOnly: true }} /> </div>
                                                            <div className="col-4"><p className='input-title detail-title-jp'>Location :</p></div>
                                                            <div className="col-8"> <TextField size='small' className='mb-3' style={{ maxWidth: '100%' }} value={userData.user_location} InputProps={{ readOnly: true }} /> </div>
                                                            <div className="col-4"><p className='input-title detail-title-en'>Description :</p></div>
                                                            <div className="col-8"> <TextField size='small' className='mb-3' multiline style={{ maxWidth: '100%' }} value={userData.text_ja} InputProps={{ readOnly: true }} /> </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>
                                            </TabContext>

                                        </div>
                                    ) : (
                                        <p>Loading user data...</p>
                                    )}

                                </div>
                            </div>

                            {/* <div className="row my-4 function-button-wrapper-payment-details">
                                <div className="col-8">
                                    <div className="row">
                                        <div className="col-3"><button className='DeleteButton' onClick={() => handleDeleteClick(userData.id)}><DeleteForeverOutlinedIcon /> Delete</button></div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
