import React, { useEffect, useState } from 'react';
import TopHeaderMain from '../../../Layout/TopHeader/TopHeaderMain'
import {
    TextField,
    Button,
    IconButton,
    InputAdornment,
    Snackbar,
    Alert,
    createTheme,
    ThemeProvider,
    Input,
    Autocomplete
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axiosConfig from '../../../axios';
import authorization from '../../../authorization';
import TopHeaderInternal from '../../../Layout/TopHeader/TopHeaderInternal';
import { countries } from '../../../Layout/country';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import FullScreenLoader from '../../../Layout/FullScreenLoader/FullScreenLoader';

const theme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInput-underline:before': {
                        borderBottomColor: '#5C0F87',
                    },
                    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                        borderBottomColor: '#5C0F87',
                    },
                    '& .MuiInput-underline:after': {
                        borderBottomColor: '#5C0F87',
                    },
                },
            },
        },
    },
});



export default function AddUser() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [country, setCountry] = useState()
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [test, setTest] = useState()

    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [countryError, setCountryError] = useState(false);

    const [nameErrorMsg, setNameErrorMsg] = useState();
    const [emailErrorMsg, setEmailErrorMsg] = useState();
    const [phoneErrorMsg, setPhoneErrorMsg] = useState();
    const [passwordErrorMsg, setPasswordErrorMsg] = useState();
    const [countryErrorMsg, setCountryErrorMsg] = useState();

    const [buttonloader, setButtonLoader] = useState(false)
    const [fullScreenLoader, setFullScreenLoader] = useState(true)

    let history = useNavigate()


    useEffect(() => {
        setTimeout(() => {
            setFullScreenLoader(false)
        }, 800);

    }, [])
    const handleSubmit = (e) => {
        e.preventDefault();
        // Check for errors

        let hasError = false;

        // Validate Name
        if (name.trim() === '') {
            setNameError(true);
            setNameErrorMsg('Name is required');
            hasError = true;
        } else {
            setNameError(false);
            setNameErrorMsg('');
        }

        // Validate Email
        if (email.trim() === '') {
            setEmailError(true);
            setEmailErrorMsg('Email is required');
            hasError = true;
        } else if (!/^\S+@\S+\.\S+$/.test(email)) {
            setEmailError(true);
            setEmailErrorMsg('Invalid email address');
            hasError = true;
        } else {
            setEmailError(false);
            setEmailErrorMsg('');
        }

        // Validate Phone Number
        if (mobile.trim() === '') {
            setPhoneError(true);
            setPhoneErrorMsg('Phone number is required');
            hasError = true;
        } else if (!isValidPhoneNumber(mobile)) {
            setPhoneError(true);
            setPhoneErrorMsg('Invalid phone number');
            hasError = true;
        } else {
            setPhoneError(false);
            setPhoneErrorMsg('');
        }

        // Validate Country
        if (!country) {
            setCountryError(true);
            setCountryErrorMsg('Country is required');
            hasError = true;
        } else {
            setCountryError(false);
            setCountryErrorMsg('');
        }

        // Validate Password
        if (password.trim() === '') {
            setPasswordError(true);
            setPasswordErrorMsg('Password is required');
            hasError = true;
        } else if (password.length < 8) {
            setPasswordError(true);
            setPasswordErrorMsg('Password must be at least 8 characters long');
            hasError = true;
        } else {
            setPasswordError(false);
            setPasswordErrorMsg('');
        }


        setTest(hasError)

        if (!hasError) {
            // Form data is valid
            const userData = {
                country,
                name,
                email,
                mobile,
                password
            };
            // console.log(JSON.stringify(userData));
            setButtonLoader(true)
            handleAPiCall(userData)

        }
        
    };
    const handleAPiCall = (userData) => {

        axiosConfig
            .post(`/admin/user`, userData, {
                headers: {
                    Authorization: authorization.authorizationUrl
                },
            })
            .then((response) => {
                // console.log("add user post response", response.data, response.data.message.email);

                setEmailError(true);
                setEmailErrorMsg(response.data.message.email);


                if (response.data.status === true) {
                    // Show success message
                    setOpenSnackbar(true);

                    // Reset form values
                    setName('');
                    setEmail('');
                    setMobile('');
                    setCountry(' ')
                    setPassword('');
                    setConfirmPassword('');
                    setEmailError(false)
                    setEmailErrorMsg('');
                    setTimeout(() => {
                        setButtonLoader(false)
                        history('/user')
                    }, 700);
                }

            })
            .catch(error => {
                console.log(error);
            });
    }
    return (
        <ThemeProvider theme={theme}>
            <>
                <div className="container-fluid d-flex">
                    <div className="content-wrapper">
                        <div className="content-components-wrapper">
                            {/* <TopHeaderMain title='Add New User' icons={<PersonAddIcon fontSize="large" />} /> */}
                            <TopHeaderInternal title={`Create New User `} linkToBackBtn={'/user'} />
                            {fullScreenLoader ? <FullScreenLoader /> : ''}
                            <div className="user-content sub-page-details-wrapper mt-4">
                                <div className="row">
                                    <div className="col-xl-5 col-lg-6 col-md-7 col-sm-12">
                                        <h5 className='sub-page-title'>Personal Information</h5>
                                        <form className='d-flex flex-column my-3'>
                                            <div className='around-border'>

                                                <div className="row">
                                                    <div className="col-4"><p className='input-title'>Name:</p></div>
                                                    <div className="col-8 mb-3">
                                                        <Input
                                                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', fontFamily: 'Kufam' }}
                                                            size='small'
                                                            disableUnderline
                                                            className='table-inputs-content'
                                                            placeholder='Enter Name'
                                                            value={name}
                                                            onChange={(event) => setName(event.target.value)}
                                                        // error={nameErrorMsg}
                                                        // helperText={nameErrorMsg ? nameErrorMsg : ''}
                                                        />
                                                        <span className='error-msg'>{nameErrorMsg ? nameErrorMsg : ''}</span>
                                                    </div>
                                                    <div className="col-4"><p className='input-title'>Email:</p></div>
                                                    <div className="col-8 mb-3">
                                                        <Input
                                                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%' }}
                                                            size='small'
                                                            disableUnderline
                                                            className='table-inputs-content'
                                                            placeholder='Enter Email'
                                                            type="email"
                                                            value={email}
                                                            onChange={(event) => setEmail(event.target.value)}
                                                        // error={emailErrorMsg}
                                                        // helperText={emailErrorMsg ? emailErrorMsg : ''}
                                                        />
                                                        <span className='error-msg'>{emailErrorMsg ? emailErrorMsg : ''}</span>
                                                    </div>
                                                    <div className="col-4"><p className='input-title'>Phone Number:</p></div>
                                                    <div className="col-8 mb-3">


                                                        <PhoneInput
                                                            international
                                                            countryCallingCodeEditable={false}
                                                            defaultCountry="SA"
                                                            value={mobile}
                                                            onChange={setMobile}
                                                        />

                                                        <span className='error-msg'>{phoneErrorMsg ? phoneErrorMsg : ''}</span>
                                                    </div>
                                                    <div className='col-4'><p className='input-title'>Country: </p></div>
                                                    <div className='col-8 mb-3'>
                                                        <Autocomplete
                                                            id="country"
                                                            size="small"
                                                            options={countries.map(country => country.name)}
                                                            value={country}
                                                            onChange={(event, newValue) => setCountry(newValue || '')}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    placeholder="Select Country"
                                                                    variant="outlined"
                                                                    style={{ maxWidth: "100%" }}
                                                                />
                                                            )}
                                                        />
                                                        <span className='error-msg'>{countryErrorMsg ? countryErrorMsg : ''}</span>
                                                    </div>
                                                    <div className="col-4"><p className='input-title'>Password:</p></div>
                                                    <div className="col-8 mb-3">
                                                        <Input
                                                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%' }}
                                                            size='small'
                                                            disableUnderline
                                                            className='table-inputs-content'
                                                            placeholder="Enter Password"
                                                            type={showPassword ? 'text' : 'password'}
                                                            value={password}
                                                            onChange={(event) => setPassword(event.target.value)}
                                                            // error={passwordErrorMsg}
                                                            // helperText={passwordErrorMsg ? passwordErrorMsg : ''}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={() => setShowPassword(!showPassword)}
                                                                        onMouseDown={(event) => event.preventDefault()}
                                                                    >
                                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                        <span className='error-msg'>{passwordErrorMsg ? passwordErrorMsg : ''}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='submit-button-sub-page'>
                                                <button className={`PrimaryButton ${buttonloader ? 'button-loader' : ' '}`} type="submit" variant="contained" onClick={handleSubmit}>
                                                    {buttonloader ? '' : 'Submit'}
                                                </button>
                                            </div>
                                        </form>
                                        <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={() => setOpenSnackbar(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                                            <Alert severity="success" sx={{ width: '100%' }}>
                                                User added successfully!
                                            </Alert>
                                        </Snackbar>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </ThemeProvider>
    )
}
