import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    FormControl,
    Select,
    MenuItem,
    Menu,
    InputLabel,
    TableSortLabel,
    TableFooter,
    Checkbox,
    IconButton,
    Pagination,
    TablePagination,
    Snackbar,
    Alert,
    Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import PeopleIcon from "@mui/icons-material/People";
import AddIcon from "@mui/icons-material/Add";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CommentIcon from "@mui/icons-material/Comment";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import DeleteIcon from "@mui/icons-material/Delete";
import TopHeaderMain from "../../Layout/TopHeader/TopHeaderMain";
import PaymentIcon from "@mui/icons-material/Payment";
import axiosConfig from "../../axios";
import config from "../../config";
import authorization from "../../authorization";
import DeleteModal from "../../Layout/Modal/Modal";

import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import FullScreenLoader from "../../Layout/FullScreenLoader/FullScreenLoader";

const StyledTextField = styled(TextField)({
    width: "100%",
    marginBottom: "1rem",
});

const StyledFormControl = styled(FormControl)({
    width: "100%",
    marginBottom: "1rem",
});

function FaqList() {
    const [testimonials, setTestimonials] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [sortColumn, setSortColumn] = useState("id");
    const [sortOrder, setSortOrder] = useState("asc");
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);

    const [testimonialId, setTestimonialId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    const [fullScreenLoader, setFullScreenLoader] = useState(true)

    const [faqCategories, setFaqCategories] = useState([])

    useEffect(() => {
        axiosConfig
            .get(`/admin/faqslist?page=${page}`, {
                headers: {
                    Authorization: authorization.authorizationUrl,
                },
            })
            .then((response) => {
                // console.log("faq list", response.data.data.data)
                setTestimonials(response.data.data.data);
                // setTotalPages(response.data.data.last_page);
                setTimeout(() => {
                    setFullScreenLoader(false)
                }, 800);
            })
            .catch((error) => console.log(error));


        getFaqCategory()
    }, [page, rowsPerPage]);

    const getFaqCategory = () => {
        axiosConfig
            .get('/admin/faqs/category', {
                headers: {
                    Authorization: authorization.authorizationUrl,
                },
            })
            .then((response) => {
                let data = response.data.data;
                // console.log("faq category data", data)
                setFaqCategories(data)
            })
            .catch((error) => console.log(error));
    }

    const getmeCorrectCateName = (thatId) => {
        const categoryName = faqCategories.find(category => category.id === thatId);
        if (categoryName) {
            return categoryName.title_en;
        } else {
            return "Unknown Category";
        }
    }

    const close = () => {
        setShowModal(false);
    };

    const deleteTestimonial = () => {
        axiosConfig
            .delete(`/admin/deletefaq/${testimonialId}`, {
                headers: {
                    Authorization: authorization.authorizationUrl,
                },
            })
            .then((response) => {
                // Fetch the updated list of users
                axiosConfig
                    .get(`/admin/faqslist?page=${page}`, {
                        headers: {
                            Authorization: authorization.authorizationUrl,
                        },
                    })
                    .then((response) => {
                        setTestimonials(response.data.data.data);
                        setTotalPages(response.data.data.last_page);
                    })
                    .catch((error) => console.log(error));
            })
            .catch((error) => console.log(error));
        setShowModal(false);
    };

    const handleDeleteClick = (id) => {
        setTestimonialId(id);
        setShowModal(true);
    };

    const history = useNavigate();
    const handleEditClick = (id) => {
        // logic to handle edit click
        history(`/faq/edit-faq/${id}`);
        // console.log(`Edit clicked for faq with id ${id}`);
    };

    const handleDetailClick = (user) => {
        // history(`/testimony/testimony-details/${user.id}`);
    };

    const handleSortClick = (column) => {
        if (sortColumn === column) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortColumn(column);
            setSortOrder("asc");
        }
    };
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };



    return (
        <>
            <div className="container-fluid d-flex">
                <div className="content-wrapper">
                    <div className="content-components-wrapper">
                        {fullScreenLoader ? <FullScreenLoader /> : ''}
                        <TopHeaderMain
                            title="FAQ's"
                            icons={<QuizOutlinedIcon fontSize="large" />}
                        />
                        <div className="user-content mt-4">
                            <div className="row mb-4">
                                <div className="col-6 col-xl-6 col-sm-12">
                                    {/* <StyledTextField
                                        size="small"
                                        placeholder="Search Here"
                                        variant="outlined"
                                        value={searchQuery}
                                        onChange={(event) => setSearchQuery(event.target.value)}
                                    /> */}
                                </div>
                                <div className="col-6 col-xl-6 col-sm-12 text-end">
                                    <Link to="/faq/create-new" className="nounderline">
                                        <button className="PrimaryButton">
                                            <AddIcon /> Create FAQs
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div className="user-table-content">
                                <TableContainer
                                    component={Paper}
                                    sx={{ boxShadow: "none", border: "1px solid #E4E4E4" }}
                                >
                                    <Table>
                                        <TableHead style={{ backgroundColor: "#F2F2F2" }}>
                                            <TableRow>
                                                <TableCell>
                                                    No.
                                                </TableCell>
                                                <TableCell>
                                                    Category Name
                                                </TableCell>
                                                <TableCell>Details</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {testimonials.length > 0 ? (
                                            <TableBody className="table-body-wrapper tours-table-body-wrapper">
                                                {testimonials.map((user, index) => (
                                                    <TableRow key={user.id}>
                                                        {/* <TableCell>{user.id}</TableCell> */}
                                                        <TableCell className="table-data table-data-bold">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell className="table-data">

                                                            {user && getmeCorrectCateName(user.category_id)}


                                                        </TableCell>
                                                        <TableCell className="table-data">

                                                            {user.title_en}

                                                        </TableCell>
                                                        <TableCell className="table-data">
                                                            {/* {user.user_location} */}
                                                        </TableCell>
                                                        <TableCell className="table-data">
                                                            {/* {user.latitude} */}
                                                        </TableCell>
                                                        <TableCell className="table-data">
                                                            {/* {user.longitude} */}
                                                        </TableCell>
                                                        <TableCell className="action-dropdown-btn">
                                                            <Dropdown>
                                                                <Dropdown.Toggle
                                                                    variant="secondary"
                                                                    id="dropdown-basic"
                                                                    size="sm"
                                                                    style={{
                                                                        color: "#000000",
                                                                        border: "none",
                                                                        background: "transparent",
                                                                    }}
                                                                >
                                                                    <MoreVertIcon />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>

                                                                    <Dropdown.Item className="px-0">

                                                                        <Button
                                                                            color="primary"
                                                                            aria-label="Delete"
                                                                            onClick={() => handleEditClick(user.id)}
                                                                            style={{ fontSize: "14px", width: "100%", justifyContent: "start", paddingLeft: "18px" }}
                                                                            startIcon={<EditIcon style={{ fontSize: 16 }} />}
                                                                        >
                                                                            Edit
                                                                        </Button>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className="px-0">

                                                                        <Button
                                                                            color="secondary"
                                                                            aria-label="Delete"
                                                                            onClick={() => handleDeleteClick(user.id)}
                                                                            style={{ fontSize: "14px", width: "100%", justifyContent: "start", paddingLeft: "18px" }}
                                                                            startIcon={<DeleteIcon style={{ fontSize: 16 }} />}
                                                                        >
                                                                            Delete
                                                                        </Button>
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        ) : (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell colSpan={6} align="center">
                                                        No Data found.
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )}
                                    </Table>
                                </TableContainer>
                                <div className="pagination-wrapper">
                                    {totalPages > 1 ? (
                                        <Pagination
                                            className="custom-pagination"
                                            count={totalPages}
                                            variant="outlined"
                                            shape="rounded"
                                            page={page}
                                            onChange={(event, newPage) => setPage(newPage)}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <DeleteModal
                                showModal={showModal}
                                onClose={close}
                                onSubmit={deleteTestimonial}
                            />
                            <Snackbar
                                open={openSnackbar}
                                autoHideDuration={7000}
                                onClose={() => setOpenSnackbar(false)}
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            >
                                <Alert
                                    severity="success"
                                    sx={{ width: "100%" }}
                                >
                                    FAQ Deleted successfully!
                                </Alert>
                            </Snackbar>
                            <Snackbar
                                open={openErrorSnackbar}
                                autoHideDuration={7000}
                                onClose={() => setOpenErrorSnackbar(false)}
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            >
                                <Alert
                                    severity="error"
                                    sx={{ width: "100%" }}
                                >
                                    Delete failed!
                                </Alert>
                            </Snackbar>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FaqList;
