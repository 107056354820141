import React from 'react'


const FullScreenLoader = () => {
    return (
        <div className='full-screen-loader-wrapper'>
            <div class="full-screen-loader">
                <div class="loader-square"></div>
                <div class="loader-square"></div>
                <div class="loader-square"></div>
                <div class="loader-square"></div>
                <div class="loader-square"></div>
                <div class="loader-square"></div>
                <div class="loader-square"></div>
            </div>
        </div>
    )
}

export default FullScreenLoader