import styled from "@emotion/styled";
import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import PhonenumberInputBox from "./PhonenumberInputBox";

import PurpleLogo from "../../assets/images/logoPurple.svg";
import ArrowIcon from "../../assets/images/arrow_back.png";
import { useStore } from "../../Store/auth";
import axiosConfig from "../../axios";
import { Alert, Snackbar } from "@mui/material";
import Turnstile from "react-turnstile";

const LoginBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 200px 0% auto 0%;
  font-family: Kufam, sans-serif;

  .registration-form, .login-form, .forgot-password-form {
    width: 350px;
  }

  h2 {
    padding: 25px 0;
  }

  .popup-input-groups {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    position: relative;
  }

  .login-label {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    padding-bottom: 8px;
  }

  .popup-input-groups .popup-input {
    background: #fff;
    border: 0.694006px solid #c2c8cc;
    border-radius: 2.77603px;
    box-shadow: 0 0.694006px 2.77603px rgba(16, 24, 40, 0.05);
    color: #646464;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    padding: 11px;
  }

  .login-desc {
    padding-bottom: 15px;
  }

  .login-desc label {
    color: #6b6b6b;
    font-size: 12px;
    font-weight: 600;
    padding-left: 6px;
  }

  .login-desc input#remember {
    border: 1px solid #777;
    border-radius: 2px;
    height: 15px;
    width: 12px;
  }

  .login-desc a {
    color: #6b6b6b;
    font-size: 12px;
    font-weight: 600;
    line-height: 17px;
  }

  .SecondaryButton {
    align-items: center;
    background: #5c0f87;
    /* background: var(--color-primary); */
    border: transparent;
    border-radius: 4px;
    color: #fff;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    gap: 10px;
    height: 40px;
    justify-content: center;
    line-height: 22px;
    padding: 8px 24px;
    text-transform: capitalize;
    width: 100%;
  }

  .login-desc-account {
    padding: 17px 0 0;
    text-align: center;
  }

  .login-desc-account p {
    color: #6b6b6b;
    font-size: 12px;
    font-weight: 400;
    line-height: 143.02%;
    margin: 0;
  }

  .login-desc-account p a {
    font-weight: 600;
  }

  .popup-input-groups {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    position: relative;
  }

  .popup-input-groups .popup-input:focus-visible,
  .popup-input-groups .phonenumber-popup-input .PhoneInputInput:focus-visible {
    outline-color: var(--color-primary);
  }

  .popup-input-groups .EyeIcon {
    position: absolute;
    right: 0.5rem;
    bottom: 0.3rem;
  }

  .popup-input-groups .EyeIcon i {
    color: #6b6b6b;
  }

  .popup-input-groups label.login-label {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #0d0d0d;
    padding-bottom: 8px;
  }

  .popup-input-groups .popup-input,
  .popup-input-groups .phonenumber-popup-input .PhoneInputInput {
    background: #ffffff;
    border: 1px solid #c2c8cc !important;
    box-shadow: 0px 0.694006px 2.77603px rgba(16, 24, 40, 0.05);
    border-radius: 2.77603px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 139% */
    color: #646464;
    padding: 11px;
  }

  .PhoneInputCountry {
    width: 50px;
    margin-right: 0 !important;
    background: #ffffff;
    border: 1px solid #c2c8cc;
    border-radius: 4px 0 0 4px;
  }

  .PhoneInputCountryIcon.PhoneInputCountryIcon--border {
    box-shadow: none;
    background: transparent;
    margin-left: 0.5rem;
  }

  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
    box-shadow: none !important;
  }

  .PhoneInputCountrySelect:focus
    + .PhoneInputCountryIcon
    + .PhoneInputCountrySelectArrow {
    color: initial !important;
  }

  .terms-info p {
    font-size: 10px;
    line-height: 20px;
    text-align: center;
    color: #787878;
    margin: 0;
    padding-top: 25px;
  }

  .nounderline {
    text-decoration: none;
    color: inherit;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .nounderline:hover {
    color: inherit;
  }

  /* error message */
  .error-msg {
    color: red;
    font-size: 12px;
    text-transform: capitalize;
  }


.button-loader {
  --color: #ffffff;
  --size: 24px;
  position: relative;
  width: var(--size);
  height: var(--size);
}


.button-loader::after {
  content: '';
  width: 25px;
  height: 25px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  border: 3px solid var(--color);
  border-bottom: 3px solid transparent;
  border-radius: 100%;
  animation: keyframes-rotate .8s infinite linear;
}


@keyframes keyframes-rotate {
  0% {
    transform: rotate(360deg);
  }
}


`;

export default function Login() {
  const emailRef = useRef("email");
  const passwordRef = useRef("password");
  const [passwordShown, setPasswordShown] = useState(false);
  const [profileStats, setProfileStats] = useState({
    errorMsg: "",
    responseDataEmail: "",
    responseDataPassword: "",
    token: "",
    status: "",
  });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [buttonloader, setButtonLoader] = useState(false)
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [rememberMe, setRememberMe] = useState(true)

  const [ipAddress, setIpAddress] = useState('');
  var selectedLanguageCode = localStorage.getItem('language_code')
  const [secondToken, setSecondToken] = useState();
  const [captchaValidation, setCaptchaValidation] = useState();

  useEffect(() => {
    fetch('https://api.ipify.org/?format=json')
      .then((response) => response.json())
      .then((data) => setIpAddress(data.ip))
      .catch((error) => {
        // Handle the error.
      });
  }, []);

  useEffect(() => {

    // console.log("secondToken",secondToken)

    if (secondToken && ipAddress) {
      // console.log("got the token gotted", secondToken, ipAddress)

      axiosConfig
        .post(`/auth/get-verify?language_code=${selectedLanguageCode}&device_id=deviceid&user_agent=useragent&token=${secondToken}&user_ip=${ipAddress}`)
        .then((response) => {
          // console.log('API Response get-verify:', response.data);
          if (response.data.status) {
            setCaptchaValidation(true); // Send the value to the parent component
          }
        })
        .catch((error) => {
          console.error('API Error:', error);
        });

    }

  }, [secondToken, ipAddress])

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const login = useStore((state) => state.login);
  const navigate = useNavigate();

  useEffect(() => {
    // console.log("gg:", profileStats);
  }, [profileStats]);

  useEffect(() => {
    if (localStorage.getItem("status") === "true") navigate("/user");
  }, [navigate]);

  const validateEmail = () => {
    if (!email) {
      setEmailError("Email is required");
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }
  };

  const validatePassword = () => {
    if (!password) {
      setPasswordError("Password is required");
    } else if (password.length < 8) {
      setPasswordError("Password must be at least 8 characters long");
    } else {
      setPasswordError("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    validateEmail();
    validatePassword();
    if (!secondToken) {
      // alert("returnd")
      // console.log("captchaValidation", captchaValidation)
      return;
    }

    if (!emailError && !passwordError) {
      // Call your login logic here
      setButtonLoader(true)
      emailRef.current = email;
      passwordRef.current = password;
      handleLogin();
    }
  };

  const handleLogin = () => {
    const selectedLanguageCode = "en";
    const deviceId = "kjkd";
    const userAgent = "kjkj";
    const role = "admin";

    axiosConfig
      .post(`/auth/login?role=${role}`, {
        language_code: selectedLanguageCode,
        device_id: deviceId,
        user_agent: userAgent,
        email: emailRef.current,
        password: passwordRef.current,
        role: role,
      })
      .then((response) => {
        // console.log("response from API call login", response);
        setProfileStats({
          errorMsg: response.data.message,
          responseDataEmail: response.data.message.email,
          responseDataPassword: response.data.message.password,
          token: response.data.data.token,
          status: response.data.status,
        });
        var LoginToken = response.data.data.token;

        if (response.data.status_code === 200) {
          // Store data in localStorage
          localStorage.setItem("Token-for-login", LoginToken);
          localStorage.setItem("status", response.data.status);

          // Store data in sessionStorage
          sessionStorage.setItem("Token-for-login", LoginToken);
          sessionStorage.setItem("status", response.data.status);


          useStore.setState({ LoginStatus: response.data.status })
          useStore.setState({ LoginToken });
          setSuccessMsg("Login successful!")
          setSuccessSnackbarOpen(true)

          setTimeout(() => {
            login();
            setButtonLoader(false)
            navigate("/user");
          }, 1000);
        } else {
          setButtonLoader(false)
          setErrorSnackbarOpen(true)
          setErrorMsg(response.data.message)
        }
        response.data.data.token
          ? this.props.callbackFromParent(response.data.status_code)
          : console.log("null token");
      })
      .catch((error) => {
        setButtonLoader(false)
        console.error(error);

      });
  };

  const RenderLogoImg = () => (
    <>
      <img src={PurpleLogo} alt="" />
    </>
  );

  return (
    <>
      <div className="container-fluid d-flex">
        <div className="content-wrapper" style={{ marginLeft: "0px" }}>
          <div
            className="content-components-wrapper"
            style={{ height: "75vh" }}
          >
            <LoginBox>
              <RenderLogoImg />
              <div>
                <h2>Welcome Back Admin</h2>
              </div>
              <div className="login-box-wrapper">
                <form className="login-form" onSubmit={handleSubmit}>
                  <span className="error-msg text-center d-block">
                    {!profileStats.status ? profileStats.errorMsg : ""}
                  </span>
                  <div className="popup-input-groups">
                    <label htmlFor="email" className="login-label">
                      Email
                    </label>
                    <input
                      type="text"
                      id="email"
                      className={`popup-input ${emailError ? "error" : ""}`}
                      placeholder="Enter your email or phone number"
                      value={email}
                      onBlur={validateEmail}
                      onChange={(event) => setEmail(event.target.value)}

                    />
                    <span className="error-msg">{emailError}</span>
                  </div>
                  <div className="popup-input-groups">
                    <label htmlFor="password" className="login-label">
                      Password
                    </label>
                    <div className="password-box-wrap position-relative">
                      <input
                        type={passwordShown ? "text" : "password"}
                        id="txtPassword current-password"
                        className="popup-input w-100"
                        name="login_password"
                        placeholder="Enter your password"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}

                      />
                      <div className="EyeIcon">
                        <i
                          id="toggle_pwd"
                          onClick={togglePassword}
                          className={
                            passwordShown
                              ? "fa-regular fa-eye-slash"
                              : "fa-regular fa-eye"
                          }
                        ></i>
                      </div>
                      <span className="error-msg">{passwordError}</span>
                    </div>
                  </div>
                  {/* <div className="login-desc row align-items-center">
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        name="remember"
                        id="remember"
                        checked={rememberMe}
                        onChange={handleRememberMeChange}
                      />
                      <label htmlFor="remember">Remember Me</label>
                    </div> 

                  </div>*/}
                  <Turnstile
                    onVerify={setSecondToken}
                    sitekey={`0x4AAAAAAAMIBRH2dKxA4RfZ`}
                    theme="light"
                    language={selectedLanguageCode}
                  />
                  <div className="login-submit-btn pt-2">
                    <button className={`SecondaryButton`} type="submit" disabled={buttonloader}>
                      {buttonloader ? <span className="button-loader"></span> : "Log in"}
                    </button>
                  </div>
                </form>
              </div>
            </LoginBox>
          </div>
        </div>
        <Snackbar open={errorSnackbarOpen} autoHideDuration={3000} onClose={() => setErrorSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert severity='error' sx={{ width: '100%' }}>
            {errorMsg}
          </Alert>
        </Snackbar>
        <Snackbar open={successSnackbarOpen} autoHideDuration={3000} onClose={() => setSuccessSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert severity='success' sx={{ width: '100%' }}>
            {successMsg}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}