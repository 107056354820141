import React, { useState, useEffect } from 'react';
import TopHeaderMain from '../../../Layout/TopHeader/TopHeaderMain'
import {
  TextField,
  Input
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import { Link, useParams } from 'react-router-dom';
import TopHeaderInternal from '../../../Layout/TopHeader/TopHeaderInternal';
import axiosConfig from '../../../axios';
import authorization from '../../../authorization';

export default function UserDetails() {
  const [userData, setUserData] = useState([])

  let { id } = useParams();
  var gotFromUrlId = id
  useEffect(() => {
    axiosConfig
      .get(`/admin/user/${gotFromUrlId}`, {
        headers: {
          Authorization: authorization.authorizationUrl
        },
      })
      .then((response) => {
        let allData = response.data.data
        console.log("view user get response", response.data.data);
        setUserData(allData)

      })
      .catch(error => {
        console.log(error);
      });
  }, []);



  return (
    <>
      <div className="container-fluid d-flex">
        <div className="content-wrapper">
          <div className="content-components-wrapper">
            {/* <TopHeaderMain title='Users Details' icons={<FeedOutlinedIcon fontSize="large" />} /> */}
            <TopHeaderInternal title={`Users Details `} linkToBackBtn={'/user'} />
            <div className="user-content mt-4">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-7 col-sm-12">
                  <div className="d-flex align-items-baseline justify-content-between">
                    <h5 className='m-0 details-title-left'> <FeedOutlinedIcon /> Personal Information</h5>
                    <Link to={`/user/edit-user/${gotFromUrlId}`} className='nounderline'>
                      <button className='SmallButton'><EditIcon fontSize='small' /> Edit </button>
                    </Link>
                  </div>

                  {userData ? (
                    <div>
                      <div className="row">
                        <div className="col-4"><p className='input-title'>Name:</p></div>
                        <div className="col-8">

                          <Input
                            sx={{ border: '0', borderRadius: 1, padding: '5px 0', width: '275px', fontFamily: 'Kufam', fontWeight: 500 }}
                            size='small'
                            disableUnderline
                            className='table-inputs-content mb-3'
                            placeholder='No Data Available'
                            value={userData.name ? userData.name : userData.first_name ? userData.first_name : 'No Info Available'}
                            readOnly
                          />

                        </div>
                        <div className="col-4"><p className='input-title'>Email:</p></div>
                        <div className="col-8">
                          <Input
                            sx={{ border: '0', borderRadius: 1, padding: '5px 0', width: '275px', fontFamily: 'Kufam', fontWeight: 500 }}
                            size='small'
                            disableUnderline
                            className='table-inputs-content mb-3'
                            placeholder='No Data Available'
                            value={userData.email ? userData.email : 'No Info Available'}
                            readOnly />

                        </div>
                        <div className="col-4"><p className='input-title'>Phone:</p></div>
                        <div className="col-8">
                          <Input
                            sx={{ border: '0', borderRadius: 1, padding: '5px 0', width: '275px', fontFamily: 'Kufam', fontWeight: 500 }}
                            size='small'
                            disableUnderline
                            className='table-inputs-content mb-3'
                            placeholder='No Data Available'
                            value={userData.mobile ? userData.mobile : 'No Info Available'}
                            readOnly />

                        </div>
                        <div className="col-4"><p className='input-title'>Nationality:</p></div>
                        <div className="col-8">
                          <Input
                            sx={{ border: '0', borderRadius: 1, padding: '5px 0', width: '275px', fontFamily: 'Kufam', fontWeight: 500 }}
                            size='small'
                            disableUnderline
                            className='table-inputs-content mb-3'
                            placeholder='No Data Available'
                            value={userData.country ? userData.country : 'No Info Available'}
                            readOnly />
                        </div>
                        <div className="col-4"><p className='input-title'>Status:</p></div>
                        <div className="col-8 mb-3"><span className={userData.is_active == "1" ? "thisActive" : "thisInactive"}>{userData.is_active == "1" ? "Active" : "Blocked"}</span> </div>
                        <div className="col-4"><p className='input-title'>Email Verified:</p></div>
                        <div className="col-8 mb-3"><span className={userData.email_verified_at !== null ? "thisActive" : "thisInactive"}>{userData.email_verified_at !== null ? "Verified" : "Unverified"}</span> </div>
                      </div>

                    </div>
                  ) : (
                    <p>Loading user data...</p>
                  )}

                </div>
                {/* <div className="col-xl-6 col-lg-6 col-md-5 col-sm-12">
                  <h5 className='m-0 details-title-right'><FeedOutlinedIcon />  Travel History</h5>
                  <div className="user-history-wrapper mb-3">
                    <div className="travel-history-box">
                      <div className="row">
                        <div className="col-8">
                          <div className="travel-history-heading-amount">
                            <h6 className='pb-2'>Morning City Tour of Riyadh </h6>
                            <h6 className='lite-color'>300 SAR</h6>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="travel-history-info-date">
                            <h6 className='completed pending inprogress pb-2'>Completed</h6>
                            <h6 className='lite-color'>04 / 05/ 2022</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
