import React, { useEffect, useState } from 'react';
import {
    Snackbar,
    Alert,
    Tabs,
    Tab,
    Input
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import axiosConfig from '../../axios';
import authorization from '../../authorization';

const MetaInfo = (props) => {
    const [valueMeta, setValueMeta] = useState('1');
    const [inputValuesMetaTitle, setInputValuesMetaTitle] = useState({
        en: '',
        ar: '',
    });
    const [inputValuesMetaKeyword, setInputValuesMetaKeyword] = useState({
        en: '',
        ar: '',
    });
    const [inputValuesMetaDesc, setInputValuesMetaDesc] = useState({
        en: '',
        ar: '',
    });
    const [metaPageId, setMetaPageId] = useState()

    const handleTabChangeMeta = (event, newValue) => {
        setValueMeta(newValue);
    };
    const [fullScreenLoader, setFullScreenLoader] = useState(true)
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState()
    const [buttonloader, setButtonLoader] = useState(false)
    const [editMode, setEditMode] = useState(false);

    const handleEdit = () => {

        setEditMode(true);
    };
    const handleCancel = () => {

        setEditMode(false);
    };

    const HandleMetaSubmit = (e) => {
        e.preventDefault()
        console.log("submited", metaPageId,);
        setButtonLoader(true)
        axiosConfig
            .post(`/admin/page/${metaPageId}`, {
                title_en: inputValuesMetaTitle.en,
                title_ar: inputValuesMetaTitle.ar,
                title_it: inputValuesMetaTitle.en,
                title_ja: inputValuesMetaTitle.en,
                meta_keyword_en: inputValuesMetaKeyword.en,
                meta_keyword_ar: inputValuesMetaKeyword.ar,
                meta_keyword_it: inputValuesMetaKeyword.en,
                meta_keyword_ja: inputValuesMetaKeyword.en,
                meta_description_en: inputValuesMetaDesc.en,
                meta_description_ar: inputValuesMetaDesc.ar,
                meta_description_it: inputValuesMetaDesc.en,
                meta_description_ja: inputValuesMetaDesc.en,
            }, {
                headers: {
                    Authorization: authorization.authorizationUrl,
                    'Content-Type': 'multipart/form-data'
                },
            })
            .then((response) => {
                console.log("onclick of update value, HandleMetaSubmit", response.data);
                if (response.data.status) {

                    setSuccessSnackbarOpen(true);
                    setButtonLoader(false)
                    setEditMode(false);
                    getPageMetaInfo();
                } else {
                    setErrorSnackbarOpen(true)
                    setButtonLoader(false)
                    setEditMode(false);
                    getPageMetaInfo();
                }
            })
            .catch(error => {
                console.log(error);
                setSuccessSnackbarOpen(false);
            });
    };

    const getPageMetaInfo = () => {
        axiosConfig
            .get(`/admin/page/${props.slug}`, {
                headers: {
                    'Authorization': authorization.authorizationUrl,
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                let theData = response.data.data[0]
                console.log("getPageMetaInfo", response.data.data[0]);

                setInputValuesMetaTitle({
                    en: theData.title_en || '',
                    ar: theData.title_ar || '',
                    // it: theData.meta_title_it || '',
                    // ja: theData.meta_title_ja || '',
                })
                setInputValuesMetaKeyword({
                    en: theData.meta_keyword_en || '',
                    ar: theData.meta_keyword_ar || '',
                    // it: theData.meta_keyword_it || '',
                    // ja: theData.meta_keyword_ja || '',
                })
                setInputValuesMetaDesc({
                    en: theData.meta_description_en || '',
                    ar: theData.meta_description_ar || '',
                    // it: theData.meta_description_it || '',
                    // ja: theData.meta_description_ja || '',
                })
                setMetaPageId(theData.id)

                if (response.data.status) {
                    setTimeout(() => {
                        setFullScreenLoader(false)
                    }, 800);
                }
            })
            .catch((error) => console.log(error));
    }

    useEffect(() => {
        console.log("props.slug", props.slug)
        getPageMetaInfo()
    }, [props.slug])


    return (
        <>
            {metaPageId ?
                <div className="meta-title-description-wrapper">
                    <h5 className='sub-page-title'>Meta Title & Description</h5>
                    <form id="form-file-upload-meta-tags" onSubmit={HandleMetaSubmit}>
                        <TabContext value={valueMeta}>
                            <Tabs value={valueMeta} onChange={handleTabChangeMeta} variant="scrollable" scrollButtons allowScrollButtonsMobile aria-label="nav tabs example">
                                <Tab label="English" value="1" className="error-msg" />
                                <Tab label="Arabic" value="2" className="error-msg" />
                            </Tabs>

                            <div className='around-border pt-2'>
                                <div className='edit-button-wrapper text-end position-relative'>
                                    {!editMode ? (
                                        <button
                                            className='SmallButton'
                                            onClick={handleEdit}
                                            type='button'
                                        >
                                            Edit
                                        </button>
                                    ) : <button
                                        className='SmallButton'
                                        onClick={handleCancel}
                                        type='button'
                                    >
                                        Cancel
                                    </button>}
                                </div>
                                <TabPanel className='p-3' value="1">
                                    <div className='row'>
                                        <div className="col-4"><p className='input-title'>Meta Title :</p></div>
                                        <div className="col-8 mb-3">
                                            <Input
                                                disableUnderline
                                                sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                className='table-inputs-content'
                                                size='small'
                                                fullWidth
                                                multiline
                                                maxRows={3}
                                                value={inputValuesMetaTitle.en}
                                                onChange={(e) => setInputValuesMetaTitle({ ...inputValuesMetaTitle, en: e.target.value })}
                                                placeholder={`Enter Meta Title in English`}
                                                required
                                                disabled={!editMode}
                                            />
                                        </div>
                                        <div className="col-4"><p className='input-title'>Meta Keywords :</p></div>
                                        <div className="col-8 mb-3">
                                            <Input
                                                disableUnderline
                                                sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                className='table-inputs-content'
                                                size='small'
                                                fullWidth
                                                multiline
                                                maxRows={3}
                                                value={inputValuesMetaKeyword.en}
                                                onChange={(e) => setInputValuesMetaKeyword({ ...inputValuesMetaKeyword, en: e.target.value })}
                                                placeholder={`Enter Meta Keyword in English`}
                                                required
                                                disabled={!editMode}
                                            />
                                        </div>
                                        <div className="col-4"><p className='input-title'>Meta Description :</p></div>
                                        <div className="col-8 mb-3">
                                            <Input
                                                disableUnderline
                                                sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                className='table-inputs-content'
                                                size='small'
                                                fullWidth
                                                multiline
                                                maxRows={6}
                                                value={inputValuesMetaDesc.en}
                                                onChange={(e) => setInputValuesMetaDesc({ ...inputValuesMetaDesc, en: e.target.value })}
                                                placeholder={`Enter Meta Description in English`}
                                                required
                                                disabled={!editMode}
                                            />
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel className='p-3' value="2">
                                    <div className='row'>
                                        <div className="col-4"><p className='input-title'>Meta Title :</p></div>
                                        <div className="col-8 mb-3">
                                            <Input
                                                disableUnderline
                                                sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                className='table-inputs-content'
                                                size='small'
                                                fullWidth
                                                multiline
                                                maxRows={3}
                                                value={inputValuesMetaTitle.ar}
                                                onChange={(e) => setInputValuesMetaTitle({ ...inputValuesMetaTitle, ar: e.target.value })}
                                                placeholder={`Enter Meta Title in Arabic`}
                                                required
                                                disabled={!editMode}
                                            />
                                        </div>
                                        <div className="col-4"><p className='input-title'>Meta Keywords :</p></div>
                                        <div className="col-8 mb-3">
                                            <Input
                                                disableUnderline
                                                sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                className='table-inputs-content'
                                                size='small'
                                                fullWidth
                                                multiline
                                                maxRows={3}
                                                value={inputValuesMetaKeyword.ar}
                                                onChange={(e) => setInputValuesMetaKeyword({ ...inputValuesMetaKeyword, ar: e.target.value })}
                                                placeholder={`Enter Meta Keyword in Arabic`}
                                                required
                                                disabled={!editMode}
                                            />
                                        </div>
                                        <div className="col-4"><p className='input-title'>Meta Description :</p></div>
                                        <div className="col-8 mb-3">
                                            <Input
                                                disableUnderline
                                                sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                className='table-inputs-content'
                                                size='small'
                                                fullWidth
                                                multiline
                                                maxRows={6}
                                                value={inputValuesMetaDesc.ar}
                                                onChange={(e) => setInputValuesMetaDesc({ ...inputValuesMetaDesc, ar: e.target.value })}
                                                placeholder={`Enter Meta Description in Arabic`}
                                                required
                                                disabled={!editMode}
                                            />
                                        </div>
                                    </div>
                                </TabPanel>
                                {editMode ? ( // Show Submit button only in edit mode
                                    <div className='submit-button-sub-page mb-4'>
                                        <button className={`PrimaryButton ${buttonloader ? 'button-loader' : ' '}`} type="submit" variant="contained" fullWidth>
                                            {!buttonloader ? 'Submit' : ''}
                                        </button>
                                    </div>
                                ) : null}
                            </div>
                        </TabContext>
                    </form>
                    <Snackbar open={errorSnackbarOpen} autoHideDuration={3000} onClose={() => setErrorSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Alert severity="error" sx={{ width: '100%' }}>
                            {errorMsg}
                        </Alert>
                    </Snackbar>
                    <Snackbar open={successSnackbarOpen} autoHideDuration={3000} onClose={() => setSuccessSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Alert severity="success" sx={{ width: '100%' }}>
                            Meta Info Updated successfully!
                        </Alert>
                    </Snackbar>
                </div> : ''}
        </>

    );
}

export default MetaInfo;
