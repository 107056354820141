import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Input,
  IconButton,
  Pagination,
  Snackbar,
  Alert,
  Button,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import TopHeaderMain from '../../../Layout/TopHeader/TopHeaderMain';
import axiosConfig from '../../../axios';
import authorization from '../../../authorization';
import ClearIcon from '@mui/icons-material/Clear'; // Import the ClearIcon from Material-UI icons
import DeleteModal from '../../../Layout/Modal/Modal';
import FullScreenLoader from '../../../Layout/FullScreenLoader/FullScreenLoader';

function ContactUsList() {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [searchbarData, setSearchbarData] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [fullScreenLoader, setFullScreenLoader] = useState(true)

  useEffect(() => {
    handleContactUsList();
  }, [page, searchbarData]);

  const handleContactUsList = () => {
    axiosConfig
      .get(`/admin/contact/query?page=${page}${searchbarData ? `&search=${searchbarData}` : ''}&sortby=ASC`, {
        headers: {
          'Authorization': authorization.authorizationUrl
        }
      })
      .then(response => {
        // console.log("contactuslist api data", response.data.data);
        setUsers(response.data.data.data);
        setTotalPages(response.data.data.last_page);
        setTimeout(() => {
          setFullScreenLoader(false)
        }, 800);
      })
      .catch(error => console.log(error));
  };

  const handleSearchBar = (e) => {
    const newValue = e.target.value;
    setSearchbarData(newValue);
    setPage(1);
    // setTimeout(() => {
    // //   console.log("searchbar value", newValue);
    //   handleContactUsList();
    // }, 400);
  };

  const handleClearSearch = () => {
      setPage(1);
      setSearchbarData('');
      handleContactUsList();
    
  };

  const handleDelete = (id) => {
    setSelectedId(id);
    setShowModal(true);
  };

  const handleDeleteClick = () => {
    // console.log("delete clicked id", selectedId);

    axiosConfig.delete(`/admin/contact/query/delete/${selectedId}`, {
      headers: {
        'Authorization': authorization.authorizationUrl
      }
    })
      .then(response => {
        if (response.data.status) {
          setShowModal(false);
          setOpenSnackbar(true);
          handleContactUsList();
        } else {
          setOpenErrorSnackbar(true);
        }
      })
      .catch(error => {
        setOpenErrorSnackbar(true);
        console.log(error);
      });
  };

  const close = () => {
    setShowModal(false);
  };

  const history = useNavigate();
  const handleDetailClick = (user) => {
    history(`/inquiry/contact-us/details/${user.id}`);
  };

  function formatDate(dateStr) {
    const dateObj = new Date(dateStr);

    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const formattedDate = dateObj.toLocaleDateString('en-GB', options);

    return formattedDate;
}

  return (
    <>
      <div className="container-fluid d-flex">
        <div className="content-wrapper">
          <div className="content-components-wrapper">
          {fullScreenLoader ? <FullScreenLoader /> : ''}
            <TopHeaderMain title='Contact Us' />
            <div className="user-content mt-4">
              <div className="row">
                <div className="col-3">
                  <div className="position-relative">
                    <Input
                      disableUnderline
                      sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                      className='table-inputs-content mb-4'
                      size='small'
                      placeholder='Search'
                      required
                      fullWidth
                      value={searchbarData}
                      onChange={handleSearchBar}
                    />
                    {searchbarData && (
                      <IconButton onClick={handleClearSearch}
                        sx={{ position: 'absolute', top: '30%', right: '0px', transform: 'translateY(-50%)' }}
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                  </div>
                </div>
              </div>
              <div className="user-table-content">
                <TableContainer component={Paper} sx={{ boxShadow: 'none', border: '1px solid #E4E4E4' }}>
                  <Table>
                    <TableHead style={{ backgroundColor: '#F2F2F2' }}>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Phone no</TableCell>
                        <TableCell>Country</TableCell>
                        <TableCell>Message</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    {users.length > 0 ? (
                      <TableBody className='table-body-wrapper tours-table-body-wrapper'>
                        {users.map((user) => (
                          <TableRow key={user.id}>
                            <TableCell>{formatDate(user.created_at)}</TableCell>
                            <TableCell><span style={{ cursor: 'pointer' }} onClick={() => handleDetailClick(user)}>{user.name}</span></TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user.phone_number}</TableCell>
                            <TableCell>{user.country}</TableCell>
                            <TableCell><span className='message-written'>{user.message}</span></TableCell>
                            <TableCell className='action-dropdown-btn'>
                              <Dropdown>
                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" size="sm" style={{ color: '#000000', border: 'none', background: 'transparent' }}>
                                  <MoreVertIcon />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item className='px-0'>
                                  <Button
                                      color="primary"
                                      aria-label="View"
                                      onClick={() => handleDetailClick(user)}
                                      style={{ fontSize: "14px", width: "100%", justifyContent: "start", paddingLeft: "18px" }} 
                                      startIcon={<InfoIcon style={{ fontSize: 16 }} />}
                                    >
                                      View
                                    </Button>
                                    
                                  </Dropdown.Item>
                                  <Dropdown.Item className='px-0'>
                                  <Button
                                      color="secondary"
                                      aria-label="Delete"
                                      onClick={() => handleDelete(user.id)}
                                      style={{ fontSize: "14px", width: "100%", justifyContent: "start", paddingLeft: "18px" }} 
                                      startIcon={<DeleteIcon style={{ fontSize: 16 }} />}
                                    >
                                      Delete
                                    </Button>
                                    {/* <IconButton
                                      color="secondary"
                                      aria-label="Delete"
                                      onClick={() => handleDelete(user.id)}
                                      style={{ fontSize: '14px' }}
                                    >
                                      <DeleteIcon style={{ fontSize: 16 }} className='me-1' /> Delete
                                    </IconButton> */}
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={6} align="center">
                            No Tours found.
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
                <div className="pagination-wrapper">
                  {totalPages > 1 && <Pagination className="custom-pagination" count={totalPages} variant="outlined" shape="rounded" page={page} onChange={(event, newPage) => setPage(newPage)} />}
                </div>
                <Snackbar
                  open={openSnackbar}
                  autoHideDuration={5000}
                  onClose={() => setOpenSnackbar(false)}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                  <Alert
                    severity="success"
                    sx={{ width: "100%" }}
                  >
                    Contact Inquiry Deleted successfully!
                  </Alert>
                </Snackbar>
                <Snackbar
                  open={openErrorSnackbar}
                  autoHideDuration={5000}
                  onClose={() => setOpenErrorSnackbar(false)}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                  <Alert
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    Delete failed!
                  </Alert>
                </Snackbar>
                <DeleteModal
                  showModal={showModal}
                  onClose={close}
                  onSubmit={handleDeleteClick}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUsList;
