import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Menu,
  InputLabel,
  TableSortLabel,
  TableFooter,
  Checkbox,
  IconButton,
  Pagination,
  TablePagination,
  Snackbar,
  Alert,
  Input,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import PeopleIcon from "@mui/icons-material/People";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import DeleteModal from "../../Layout/Modal/Modal";
import DeleteIcon from "@mui/icons-material/Delete";
import TopHeaderMain from "../../Layout/TopHeader/TopHeaderMain";
import axiosConfig from "../../axios";
import authorization from "../../authorization";
import ClearIcon from '@mui/icons-material/Clear'; // Import the ClearIcon from Material-UI icons
import { useStore } from '../../Store/auth';
import FullScreenLoader from "../../Layout/FullScreenLoader/FullScreenLoader";

const StyledTextField = styled(TextField)({
  width: "100%",
  marginBottom: "1rem",
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  marginBottom: "1rem",
});

function UsersTable() {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

  const [searchbarData, setSearchbarData] = useState('')
  const [loading, setLoading] = useState(true);
  const [fullScreenLoader, setFullScreenLoader] = useState(true)

  const isAuth = useStore(((state) => state.isAuth))
  const token = useStore(((token) => token.token))

  useEffect(() => {

    setTimeout(() => {
      // console.log("token from state", authorization.authorizationUrl)
      // if(authorization.authorizationUrl === 'Bearer null') {
      //   window.location.reload();
      // }
      if (isAuth) {
        userDataList()
      }

    }, 400);
    // console.log("theToken", theToken)

    setTimeout(() => {
      setFullScreenLoader(false)
    }, 800);

  }, [authorization, page, searchbarData]);

  let searchTimeout;

  const userDataList = async () => {
    try {
      setLoading(true); // Start loading

      const response = await axiosConfig.get(`/admin/user?page=${page}${searchbarData ? `&search=${searchbarData}` : ''}`, {
        headers: {
          Authorization: authorization.authorizationUrl,
        },
      });
      console.log(response.data.data)

      setUsers(response.data.data.data);
      setTotalPages(response.data.data.last_page);
    } catch (error) {
      console.log(error);

    } finally {
      setLoading(false); // End loading
    }
  };

  const handleSearchBar = (e) => {
    const newValue = e.target.value;
    setSearchbarData(newValue);
    console.log(newValue)
    setPage(1);


    // setTimeout(() => {
    //   userDataList();
    // }, 800);
  };

  const handleClearSearch = () => {
    setSearchbarData('');
    setPage(1);
    clearTimeout(searchTimeout); // Clear any pending timeouts
    userDataList();
  };


  const handleDeleteClick = (id) => {
    setSelectedId(id);
    setShowModal(true);
  };

  const close = () => {
    setShowModal(false);
  };

  const deleteHandler = () => {
    axiosConfig
      .delete(`/admin/user/${selectedId}`, {
        headers: {
          Authorization: authorization.authorizationUrl,
        },
      })
      .then((response) => {
        // Fetch the updated list of users
        // console.log("Deleted user", response.data.status);
        if (response.data.status) {
          setOpenSnackbar(true);
        }
        axiosConfig
          .get(`/admin/user?page=${page}`, {
            headers: {
              Authorization: authorization.authorizationUrl,
            },
          })
          .then((response) => {
            setUsers(response.data.data.data);
            setTotalPages(response.data.data.last_page);
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => {
        setOpenErrorSnackbar(true);
        console.log(error);
      });
    setShowModal(false);
  };

  const history = useNavigate();
  const handleEditClick = (id) => {
    // logic to handle edit click
    history(`/user/edit-user/${id}`);
    // console.log(`Edit clicked for user with id ${id}`);
  };

  const handleDetailClick = (user) => {
    history(`/user/user-details/${user.id}`);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  return (
    <>
      <div className="container-fluid d-flex">
        <div className="content-wrapper">
          <div className="content-components-wrapper">
          {fullScreenLoader ? <FullScreenLoader /> : ''}
            <TopHeaderMain
              title="Users"
              icons={<PeopleIcon fontSize="large" />}
            />
            <div className="user-content mt-4">
              <div className="row">
                <div className="col-3">
                  <div className="position-relative">
                    <Input
                      disableUnderline
                      sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                      className='table-inputs-content mb-4'
                      size='small'
                      placeholder='Search'
                      required
                      fullWidth
                      value={searchbarData}
                      onChange={handleSearchBar}
                    />
                    {searchbarData && ( // Render the ClearIcon only if there's a search term
                      <IconButton onClick={handleClearSearch}
                        sx={{ position: 'absolute', top: '30%', right: '0px', transform: 'translateY(-50%)' }}
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                  </div>
                </div>
                <div className="col-5"></div>
                <div className="col-4 text-end">
                  <Link to="/user/add-new-user">
                    <button className="PrimaryButton">
                      <AddIcon /> Add New User
                    </button>
                  </Link>
                </div>
              </div>
              <div className="user-table-content mt-3">
                <TableContainer
                  component={Paper}
                  sx={{ boxShadow: "none", border: "1px solid #E4E4E4" }}
                >
                  <Table sx={{ borderRadius: 1 }}>
                    <TableHead style={{ backgroundColor: "#F2F2F2" }}>
                      <TableRow>
                        <TableCell>
                          Name
                        </TableCell>
                        <TableCell>
                          Email
                        </TableCell>
                        <TableCell>
                          Country
                        </TableCell>
                        <TableCell>
                          Mobile Number
                        </TableCell>
                        {/* <TableCell className="table-title-bold">
                          Verification
                        </TableCell> */}
                        <TableCell>
                          Status
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    {loading ? (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={6} align="left">
                            Loading...
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : (
                      <>
                        {users ? (
                          <TableBody>
                            {users.map((user) => (
                              <TableRow key={user.id}>
                                {/* <TableCell>{user.id}</TableCell> */}
                                <TableCell>
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleDetailClick(user)}
                                  >
                                    {user.name
                                      ? user.name
                                      : user.first_name
                                        ? user.first_name
                                        : user.last_name
                                          ? user.last_name
                                          : "-"}
                                  </span>
                                </TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.country}</TableCell>
                                <TableCell>{user.mobile}</TableCell>
                                {/* <TableCell>
                                  {user.email_verified_at
                                    ? "verified"
                                    : "Not verified"}
                                </TableCell> */}
                                <TableCell>
                                  <span
                                    className={
                                      user.is_active == "1"
                                        ? "thisActive"
                                        : "thisInactive"
                                    }
                                  >
                                    {user.is_active == "1" ? "Active" : "Inactive"}
                                  </span>
                                </TableCell>
                                <TableCell className="action-dropdown-btn">
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      variant="secondary"
                                      id="dropdown-basic"
                                      size="sm"
                                      style={{
                                        color: "#000000",
                                        border: "none",
                                        background: "transparent",
                                      }}
                                    >
                                      <MoreVertIcon />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item className="px-0">
                                        <Button
                                          color="primary"
                                          aria-label="View"
                                          onClick={() => handleDetailClick(user)}
                                          style={{ fontSize: "14px", width: "100%", justifyContent: "start", paddingLeft: "18px" }}
                                          startIcon={<InfoIcon style={{ fontSize: 16 }} />}
                                        >
                                          View
                                        </Button>
                                      </Dropdown.Item>
                                      <Dropdown.Item className="px-0">
                                        <Button
                                          color="primary"
                                          aria-label="Edit"
                                          onClick={() => handleEditClick(user.id)}
                                          style={{ fontSize: "14px", width: "100%", justifyContent: "start", paddingLeft: "18px" }}
                                          startIcon={<EditIcon style={{ fontSize: 16 }} />}
                                        >
                                          Edit
                                        </Button>
                                      </Dropdown.Item>
                                      <Dropdown.Item className="px-0">
                                        <Button
                                          color="secondary"
                                          aria-label="Delete"
                                          onClick={() => handleDeleteClick(user.id)}
                                          style={{ fontSize: "14px", width: "100%", justifyContent: "start", paddingLeft: "18px" }}
                                          startIcon={<DeleteIcon style={{ fontSize: 16 }} />}
                                        >
                                          Delete
                                        </Button>
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        ) : (
                          <TableBody>
                            <TableRow>
                              <TableCell colSpan={6} align="center">
                                No users found.
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                      </>
                    )}
                  </Table>
                </TableContainer>
                <div className="pagination-wrapper">
                  {totalPages > 1 ?
                    <Pagination
                      className="custom-pagination"
                      count={totalPages}
                      variant="outlined"
                      shape="rounded"
                      page={page}
                      onChange={handlePageChange}
                    /> : ' '}

                </div>
              </div>
              <Snackbar
                open={openSnackbar}
                autoHideDuration={7000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                maxSnack={3}
              >
                <Alert
                  // onClose={() => setOpenSnackbar(false)}
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  User Deleted successfully!
                </Alert>
              </Snackbar>
              <Snackbar
                open={openErrorSnackbar}
                autoHideDuration={7000}
                onClose={() => setOpenErrorSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                maxSnack={3}
              >
                <Alert
                  // onClose={() => setOpenErrorSnackbar(false)}
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  Delete failed!
                </Alert>
              </Snackbar>
              <DeleteModal
                showModal={showModal}
                onClose={close}
                onSubmit={deleteHandler}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UsersTable;
