import React, { useEffect, useState } from "react";
import TopHeaderMain from "../../../Layout/TopHeader/TopHeaderMain";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TableContainer,
  Paper,
  Snackbar,
  TableSortLabel,
  IconButton,
  Alert,
  Pagination,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import authorization from "../../../authorization";
import config from "../../../config";
import axiosConfig from "../../../axios";
import DeleteModal from "../../../Layout/Modal/Modal";
import FullScreenLoader from "../../../Layout/FullScreenLoader/FullScreenLoader";

const StyledTextField = styled(TextField)({
  width: "100%",
  marginBottom: "1rem",
});

export default function Locations() {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [locations, setLocations] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(8);

  const [locationId, setLocationId] = React.useState(null);
  const [showModal, setShowModal] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [fullScreenLoader, setFullScreenLoader] = useState(true)

  useEffect(() => {
    axiosConfig
      .get(`/admin/tour/location?page=${page}`, {
        headers: {
          Authorization: authorization.authorizationUrl,
        },
      })
      .then((response) => {

        if (response.data.status) {
          setLocations(response.data.data.data);
          setTotalPages(response.data.data.last_page);
          console.log("location", response.data)
          setTimeout(() => {
            setFullScreenLoader(false)
          }, 800);
        } else {
          setTimeout(() => {
            setFullScreenLoader(false)
          }, 800);
        }

      })
      .catch((error) => console.log(error));
  }, [page, rowsPerPage]);


  const history = useNavigate();

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const close = () => {
    setShowModal(false);
  };

  const deleteLocation = () => {
    axiosConfig
      .delete(`/admin/tour/location/${locationId}`, {
        headers: {
          Authorization: authorization.authorizationUrl,
        },
      })
      .then((response) => {
        // Fetch the updated list of users
        console.log("Deleted user", response.data.status);
        if (response.data.status) {
          // setOpenSnackbar(true)
        }
        axiosConfig
          .get(`/admin/tour/location?page=${page}`, {
            headers: {
              Authorization: authorization.authorizationUrl,
            },
          })
          .then((response) => {
            setLocations(response.data.data.data);
            setTotalPages(response.data.data.last_page);
            console.log("new data after delete", response.data.data.data);
            setOpenSnackbar(true);
          })
          .catch((error) => {
            console.log(error);
            setOpenErrorSnackbar(true);
          });
      })
      .catch((error) => console.log(error));
    setShowModal(false);
  };

  const handleDeleteClick = (id) => {
    setLocationId(id);
    setShowModal(true);
  };

  const handleEditClick = (user) => {
    // logic to handle edit click
    history(`/tours/locations/edit-location/${user.id}`);
  };
  const handleDetailClick = (user) => {
    history(`/tours/locations/location-details/${user.id}`);
  };

  return (
    <>
      <div className="container-fluid d-flex">
        <div className="content-wrapper">
          <div className="content-components-wrapper">
            <TopHeaderMain title={`Locations `} />
            <div className="tour-wrapper mt-4">
              <div className="row mb-4">
                <div className="col-4">
                  {/* <StyledTextField
                    label="Search by Name"
                    variant="outlined"
                    size="small"
                    
                  /> */}
                </div>
                <div className="col-4">

                </div>
                <div className="col-4 text-end">
                  <Link to="/tours/locations/create-new-location">
                    <button className="PrimaryButton">
                      <AddIcon />
                      Create new Location
                    </button>
                  </Link>
                </div>
              </div>
              <div className="user-table-content">
                <TableContainer
                  component={Paper}
                  sx={{ boxShadow: "none", border: "1px solid #E4E4E4" }}
                >
                  <Table>
                    <TableHead style={{ backgroundColor: "#F2F2F2" }}>
                      <TableRow>

                        <TableCell sx={{ width: 200 }}>Cover</TableCell>
                        <TableCell>
                          <TableSortLabel>Locations</TableSortLabel>
                        </TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    {fullScreenLoader ? <FullScreenLoader /> : ''}
                    {locations.length > 0 ? (
                      <TableBody className="table-body-wrapper tours-table-body-wrapper">
                        {locations.map((user, index) => (
                          <TableRow key={user.id}>
                            {/* <TableCell >{index + 1}</TableCell> */}
                            <TableCell className="table-data">
                              <img
                                src={config.imageBaseURL + user.image}
                                alt={user.image}
                                className="border rounded"
                                style={{
                                  width: "80px",
                                  height: "80px",
                                  objectFit: "cover",
                                }}
                              />
                            </TableCell>
                            <TableCell className="table-data">
                              {user.location_en}
                            </TableCell>
                            <TableCell
                              className="action-dropdown-btn"
                              align="center"
                            >
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="secondary"
                                  id="dropdown-basic"
                                  size="sm"
                                  style={{
                                    color: "#000000",
                                    border: "none",
                                    background: "transparent",
                                  }}
                                >
                                  <MoreVertIcon />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item className="px-0">
                                    <Button
                                      color="primary"
                                      aria-label="Edit"
                                      onClick={() => handleEditClick(user)}
                                      style={{ fontSize: "14px", width: "100%", justifyContent: "start", paddingLeft: "18px" }}
                                      startIcon={<EditIcon style={{ fontSize: 16 }} />}
                                    >
                                      Edit
                                    </Button>

                                  </Dropdown.Item>
                                  <Dropdown.Item className="px-0">
                                    <Button
                                      color="secondary"
                                      aria-label="Delete"
                                      onClick={() => handleDeleteClick(user.id)}
                                      style={{ fontSize: "14px", width: "100%", justifyContent: "start", paddingLeft: "18px" }}
                                      startIcon={<DeleteIcon style={{ fontSize: 16 }} />}
                                    >
                                      Delete
                                    </Button>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={6} align="center">
                            No Tours found.
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
                <div className="pagination-wrapper">
                  {totalPages > 1 ? (
                    <Pagination
                      className="custom-pagination"
                      count={totalPages}
                      variant="outlined"
                      shape="rounded"
                      page={page}
                      onChange={(event, newPage) => setPage(newPage)}
                    />
                  ) : (
                    ""
                  )}
                </div>

                <DeleteModal
                  showModal={showModal}
                  onClose={close}
                  onSubmit={deleteLocation}
                />
                <Snackbar
                  open={openSnackbar}
                  autoHideDuration={5000}
                  onClose={() => setOpenSnackbar(false)}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                  <Alert
                    severity="success"
                    sx={{ width: "100%" }}
                  >
                    Location Deleted successfully!
                  </Alert>
                </Snackbar>
                <Snackbar
                  open={openErrorSnackbar}
                  autoHideDuration={5000}
                  onClose={() => setOpenErrorSnackbar(false)}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                  <Alert
                    severity="error"
                    sx={{ width: "100%" }}
                  >
                    Delete failed!
                  </Alert>
                </Snackbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
