import React, { useState, useEffect } from 'react'
import TopHeaderMain from '../TopHeader/TopHeaderMain'
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import { Alert, Input, Snackbar } from '@mui/material';
import ReactQuill from 'react-quill';
import axiosConfig from '../../axios';
import EditIcon from '@mui/icons-material/Edit';
import authorization from '../../authorization';
import FullScreenLoader from '../FullScreenLoader/FullScreenLoader';
import MetaInfo from '../MetaInfo/MetaInfo';

const CompanyDetails = () => {
    const [allData, setAllData] = useState()

    const [addressEn, setAddressEn] = useState()
    const [addressAr, setAddressAr] = useState()
    const [addressIt, setAddressIt] = useState()
    const [addressJa, setAddressJa] = useState()
    const [addressLink, setAddressLink] = useState()
    const [addressIframe, setAddressIframe] = useState()
    const [phoneNumber, setPhoneNumber] = useState()
    const [mobileNumber, setMobileNumber] = useState()
    const [emailAddress, setEmailAddress] = useState()
    const [socialLinkedin, setSocialLinkedin] = useState()
    const [socialInstagram, setSocialInstagram] = useState()
    const [socialYoutube, setSocialYoutube] = useState()
    const [socialTwitter, setSocialTwitter] = useState()
    const [socialFacebook, setSocialFacebook] = useState()
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [buttonloader, setButtonLoader] = useState(false)
    const [fullScreenLoader, setFullScreenLoader] = useState(true)

    const [addressEnError, setAddressEnError] = useState(null);
    const [addressArError, setAddressArError] = useState(null);
    const [addressItError, setAddressItError] = useState(null);
    const [addressJaError, setAddressJaError] = useState(null);
    const [addressLinkError, setAddressLinkError] = useState(null);
    const [addressIframeError, setAddressIframeError] = useState(null);
    const [phoneNumberError, setPhoneNumberError] = useState(null);
    const [mobileNumberError, setMobileNumberError] = useState(null);
    const [editMode, setEditMode] = useState(false);

    const handleEdit = () => {

        setEditMode(true);
    };
    const handleCancel = () => {

        setEditMode(false);
    };

    useEffect(() => {
        getContactData()
    }, [])

    const getContactData = () => {

        axiosConfig
            .get(`/admin/get-general-settings`, {
                headers: {
                    Authorization: authorization.authorizationUrl
                },
            })
            .then((response) => {
                let allData = response.data.data[0]
                // console.log("view getContactData get response", response.data.status);
                setAllData(allData)
                if (response.data.status) {
                    setAddressEn(allData.office_address_en)
                    setAddressAr(allData.office_address_ar)
                    setAddressIt(allData.office_address_it)
                    setAddressJa(allData.office_address_ja)
                    setAddressLink(allData.map_link)
                    setAddressIframe(allData.map_iframe)

                    setMobileNumber(allData.mobile_number)
                    setPhoneNumber(allData.phone_number)
                    setEmailAddress(allData.email_address)

                    setSocialLinkedin(allData.linked_link)
                    setSocialInstagram(allData.insta_link)
                    setSocialYoutube(allData.youtube_link)
                    setSocialTwitter(allData.x_link)
                    setSocialFacebook(allData.fb_link)

                    setTimeout(() => {
                        setFullScreenLoader(false)
                    }, 800);

                } else {
                    setFullScreenLoader(false)
                    setErrorSnackbarOpen(true)
                }


            })
            .catch(error => {
                console.log(error);
            });

    };

    const handleSubmit = (e) => {
        e.preventDefault()
        setButtonLoader(true)
        // Reset previous errors
        setAddressEnError(null);
        setAddressArError(null);
        setAddressItError(null);
        setAddressJaError(null);
        setAddressLinkError(null);
        setAddressIframeError(null);
        setPhoneNumberError(null);
        setMobileNumberError(null);

        // Validation checks
        let isValid = true;

        if (!addressEn || addressEn.trim() === '') {
            setAddressEnError('Address English is required');
            isValid = false;
        }

        if (!addressAr || addressAr.trim() === '') {
            setAddressArError('Address Arabic is required');
            isValid = false;
        }

        // if (!addressIt || addressIt.trim() === '') {
        //     setAddressItError('Address Italian is required');
        //     isValid = false;
        // }

        // if (!addressJa || addressJa.trim() === '') {
        //     setAddressJaError('Address Japanese is required');
        //     isValid = false;
        // }

        if (!addressLink || addressLink.trim() === '') {
            setAddressLinkError('Map Link is required');
            isValid = false;
        }

        if (!addressIframe || addressIframe.trim() === '') {
            setAddressIframeError('Embedded Map Link is required');
            isValid = false;
        }

        if (!phoneNumber || phoneNumber.trim() === '') {
            setPhoneNumberError('Phone number is required');
            isValid = false;
        }

        if (!mobileNumber || mobileNumber.trim() === '') {
            setMobileNumberError('Mobile Number is required');
            isValid = false;
        }


        if (!isValid) {
            // Log error messages without stopping form submission
            // console.log('Validation Errors:', {
            //     addressEnError,
            //     addressArError,
            //     addressItError,
            //     addressJaError,
            //     addressLinkError,
            //     addressIframeError,
            //     phoneNumberError,
            //     mobileNumberError,
            // });
            setButtonLoader(false)
        } else {

            apiCall()
            // Continue with form submission...
        }

    }

    const apiCall = () => {
        axiosConfig
            .post(`/admin/add-general-settings`, {
                office_address_en: addressEn,
                office_address_ar: addressAr,
                office_address_it: addressIt,
                office_address_ja: addressJa,
                map_link: addressLink,
                map_iframe: addressIframe,
                mobile_number: mobileNumber,
                phone_number: phoneNumber,
                email_address: emailAddress,
                linked_link: socialLinkedin,
                insta_link: socialInstagram,
                youtube_link: socialYoutube,
            }, {
                headers: {
                    Authorization: authorization.authorizationUrl
                },
            })
            .then((response) => {
                // console.log("Edit user response", response.data);
                if (response.data.status === true) {
                    // Show success message
                    setButtonLoader(false);
                    setOpenSnackbar(true);
                    setEditMode(false);
                    getContactData();
                }

            })
            .catch(error => {
                console.log(error);
            });
    }


    const modules = {
        toolbar: false,
        clipboard: {
            matchVisual: false,
        },
    };
    return (
        <div className="container-fluid d-flex">
            <div className="content-wrapper">
                <div className="content-components-wrapper">
                    {fullScreenLoader ? <FullScreenLoader /> : ''}
                    <TopHeaderMain title='Contact Us ' icons={<FeedOutlinedIcon fontSize="large" />} />
                    <div className='user-content sub-page-details-wrapper mt-4'>
                        <div className='row'>
                            <div className='col-xl-6 col-lg-6 col-md-8 col-sm-12'>
                                <MetaInfo slug="contact-us" />
                                <h5 className='sub-page-title'>Contact Info</h5>
                                <div className='around-border pt-2'>
                                    <div className='edit-button-wrapper text-end position-relative'>
                                        {!editMode ? (
                                            <button
                                                className='SmallButton'
                                                onClick={handleEdit}
                                                type='button'
                                            >
                                                Edit
                                            </button>
                                        ) : <button
                                            className='SmallButton'
                                            onClick={handleCancel}
                                            type='button'
                                        >
                                            Cancel
                                        </button>}
                                    </div>
                                    <div className="additional-information-wrapper sub-page-details-wrapper mt-4">
                                        {/* <h5>Location</h5> */}
                                        <div className="row">
                                            <div className="col-4"><p className='input-title'>Address English:</p></div>
                                            <div className="col-8 mb-3">
                                                <ReactQuill
                                                    className='company-details-address '
                                                    value={addressEn}
                                                    onChange={(newValue) =>
                                                        setAddressEn(newValue)
                                                    }
                                                    modules={modules}
                                                    readOnly={!editMode}
                                                />
                                                <span className='error-msg'>{addressEnError ? addressEnError : ''}</span>
                                            </div>
                                            <div className="col-4"><p className='input-title'>Address Arabic:</p></div>
                                            <div className="col-8 mb-3">
                                                <ReactQuill
                                                    className='company-details-address '
                                                    value={addressAr}
                                                    onChange={(newValue) =>
                                                        setAddressAr(newValue)
                                                    }
                                                    modules={modules}
                                                    readOnly={!editMode}
                                                />
                                                <span className='error-msg'>{addressArError ? addressArError : ''}</span>
                                            </div>

                                            <div className="col-4"><p className='input-title'>Location Map:</p></div>
                                            <div className="col-8 mb-3">
                                                <Input
                                                    sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%' }}
                                                    size='small'
                                                    disableUnderline
                                                    className='table-inputs-content'
                                                    placeholder='Enter Google Map Link'
                                                    type="text"
                                                    value={addressLink}
                                                    onChange={(event) => setAddressLink(event.target.value)}
                                                    multiline
                                                    minRows={1}
                                                    maxRows={6}
                                                    disabled={!editMode}

                                                />
                                                <span className='error-msg'>{addressLinkError ? addressLinkError : ''}</span>
                                            </div>
                                            <div className="col-4"><p className='input-title'>Embedded Map:</p></div>
                                            <div className="col-8 mb-3">
                                                <Input
                                                    sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%' }}
                                                    size='small'
                                                    disableUnderline
                                                    className='table-inputs-content'
                                                    placeholder='Enter Iframe Map Link'
                                                    type="text"
                                                    value={addressIframe}
                                                    onChange={(event) => setAddressIframe(event.target.value)}
                                                    multiline
                                                    minRows={1}
                                                    maxRows={6}
                                                    disabled={!editMode}
                                                />
                                                <span className='error-msg'>{addressIframeError ? addressIframeError : ''}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="additional-information-wrapper sub-page-details-wrapper mt-4">
                                        {/* <h5>Contact</h5> */}
                                        <div className='row'>
                                            <div className="col-4"><p className='input-title'>Mobile:</p></div>
                                            <div className="col-8 mb-3">
                                                <Input
                                                    sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%' }}
                                                    size='small'
                                                    disableUnderline
                                                    className='table-inputs-content'
                                                    placeholder='Enter Mobile Number'
                                                    type="text"
                                                    value={mobileNumber}
                                                    onChange={(event) => setMobileNumber(event.target.value)}
                                                    disabled={!editMode}
                                                />
                                                <span className='error-msg'>{mobileNumberError ? mobileNumberError : ''}</span>
                                            </div>
                                            <div className="col-4"><p className='input-title'>Phone:</p></div>
                                            <div className="col-8 mb-3">
                                                <Input
                                                    sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%' }}
                                                    size='small'
                                                    disableUnderline
                                                    className='table-inputs-content'
                                                    placeholder='Enter Phone Number'
                                                    type="text"
                                                    value={phoneNumber}
                                                    onChange={(event) => setPhoneNumber(event.target.value)}
                                                    disabled={!editMode}
                                                />
                                                <span className='error-msg'>{phoneNumberError ? phoneNumberError : ''}</span>
                                            </div>
                                            <div className="col-4"><p className='input-title'>Email:</p></div>
                                            <div className="col-8 mb-3">
                                                <Input
                                                    sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%' }}
                                                    size='small'
                                                    disableUnderline
                                                    className='table-inputs-content'
                                                    placeholder='Enter Email Address'
                                                    type="email"
                                                    value={emailAddress}
                                                    onChange={(event) => setEmailAddress(event.target.value)}
                                                    disabled={!editMode}
                                                />
                                                {/* <span className='error-msg'>{phoneNumberError ? phoneNumberError : ''}</span> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="additional-information-wrapper sub-page-details-wrapper mt-4">
                                        {/* <h5>Social Media</h5> */}
                                        <div className='row'>
                                            <div className="col-4"><p className='input-title'>LinkedIn:</p></div>
                                            <div className="col-8 mb-3">
                                                <Input
                                                    sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%' }}
                                                    size='small'
                                                    disableUnderline
                                                    multiline
                                                    maxRows={3}
                                                    className='table-inputs-content'
                                                    placeholder='Enter LinkedIn Link'
                                                    type="text"
                                                    value={socialLinkedin}
                                                    onChange={(event) => setSocialLinkedin(event.target.value)}
                                                    disabled={!editMode}
                                                />
                                            </div>
                                            <div className="col-4"><p className='input-title'>Instagram:</p></div>
                                            <div className="col-8 mb-3">
                                                <Input
                                                    sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%' }}
                                                    size='small'
                                                    disableUnderline
                                                    multiline
                                                    maxRows={3}
                                                    className='table-inputs-content'
                                                    placeholder='Enter Instagram Link'
                                                    type="text"
                                                    value={socialInstagram}
                                                    onChange={(event) => setSocialInstagram(event.target.value)}
                                                    disabled={!editMode}
                                                />
                                            </div>
                                            <div className="col-4"><p className='input-title'>Youtube:</p></div>
                                            <div className="col-8 mb-3">
                                                <Input
                                                    sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%' }}
                                                    size='small'
                                                    disableUnderline
                                                    multiline
                                                    maxRows={3}
                                                    className='table-inputs-content'
                                                    placeholder='Enter Youtube Link'
                                                    type="text"
                                                    value={socialYoutube}
                                                    onChange={(event) => setSocialYoutube(event.target.value)}
                                                    disabled={!editMode}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {editMode ? (
                                    <div className='submit-button-sub-page mb-4'>
                                        <button className={`PrimaryButton ${buttonloader ? 'button-loader' : ' '}`}
                                            type="submit" onClick={handleSubmit} variant="contained">
                                            {buttonloader ? '' : 'Update'}
                                        </button>
                                    </div>) : null}
                                <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={() => setOpenSnackbar(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                                    <Alert severity="success" sx={{ width: '100%' }}>
                                        User Updated successfully!
                                    </Alert>
                                </Snackbar>
                                <Snackbar
                                    open={errorSnackbarOpen}
                                    autoHideDuration={5000}
                                    onClose={() => setErrorSnackbarOpen(false)}
                                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                >
                                    <Alert
                                        severity="error"
                                        sx={{ width: "100%" }}
                                    >
                                        Error in uploading Data!
                                    </Alert>
                                </Snackbar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default CompanyDetails