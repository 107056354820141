import React, { useEffect } from "react";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import { Link, useNavigate } from "react-router-dom";
import { useStore } from '../../Store/auth';
import authorization from "../../authorization";

export default function TopHeaderInternal(props) {
  let token = localStorage.getItem("Token-for-login")
  const isAuth = useStore(((state) => state.isAuth))
  let navigate = useNavigate()
  useEffect(() => {
    if (!isAuth) {
      navigate("/");
    } else if (authorization.authorizationUrl === 'Bearer null') {
      window.location.reload();
    }
  }, [token])


  return (
    <div className="topheader-wrapper">

      <div className="back-btn-wrapper">
        <Link to={props.linkToBackBtn} className="nounderline">
          <KeyboardBackspaceRoundedIcon fontSize="large" />
        </Link>
      </div>
      <h3>
        {props.icons} {props.title}
      </h3>

    </div>
  );
}
