import React, { useState } from 'react'
import { FormControl, MenuItem, Select, styled, TextField, Autocomplete, Input, InputAdornment, IconButton } from '@mui/material'
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { countries } from '../../../Layout/country'
import TopHeaderMain from '../../../Layout/TopHeader/TopHeaderMain'

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import TopHeaderInternal from '../../../Layout/TopHeader/TopHeaderInternal';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';



const StyledFormControl = styled(FormControl)({
    // width: '100%',
    marginBottom: '1rem',
});


export default function CreateBooking() {
    const [adultCount, setAdultCount] = useState(1);
    const [childCount, setChildCount] = useState(0);
    const [phone, setPhone] = useState();
    const [totalTravelers, setTotalTravelers] = useState();

    const [selectedDate, setSelectedDate] = useState();

    const [country, setCountry] = useState(null);

    const [adultDetails, setAdultDetails] = useState([]);
    const [bookingFormData, setBookingFormData] = useState({
        tourName: '',
        date: '',
        pricePerPerson: 0,
        totalPrice: 0,
        status: '',
        totalTraveller: 0,
        adultDetails: [],
        childDetails: [], // add childDetails array to state
    });

    const handleAdultDetailsChange = (event, index) => {
        const { name, value } = event.target;
        const updatedDetails = [...adultDetails];
        updatedDetails[index] = {
            ...updatedDetails[index],
            [name]: value
        };
        setAdultDetails(updatedDetails);

        setBookingFormData(prevFormData => ({
            ...prevFormData,
            adultDetails: updatedDetails
        }));
    };

    const renderAdultDetails = () => {
        return [...Array(adultCount)].map((_, index) => {
            const adult = adultDetails[index] || {};
            return (
                <div key={index} className='adult-details-wrapper-count details-wrapper-adult-child' id={index + 1}>
                    <h5>Adult-{index + 1} Details:</h5>
                    <div className="row align-items-baseline">
                        <div className="col-5"><p className='input-title'>Name</p></div>
                        <div className="col-7">
                            <Input
                                disableUnderline
                                sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '275px' }}
                                className='table-inputs-content mb-3'
                                size='small'
                                name="name"
                                value={adult.name || ''}
                                onChange={(e) => handleAdultDetailsChange(e, index)}
                            />
                        </div>
                        <div className="col-5"><p className='input-title'>Email</p></div>
                        <div className="col-7">
                            <Input
                                disableUnderline
                                sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '275px' }}
                                className='table-inputs-content mb-3'
                                type='email'
                                size='small'
                                name="email"
                                value={adult.email || ''}
                                onChange={(e) => handleAdultDetailsChange(e, index)}
                            />
                        </div>
                        <div className="col-5"><p className='input-title'>Phone</p></div>
                        <div className="col-7">
                            <PhoneInput
                                international
                                countryCallingCodeEditable={true}
                                defaultCountry="SA"
                                value={adult.phone || ''}
                                onChange={(value) => handleAdultDetailsChange({ target: { name: 'phone', value } }, index)}
                                className="booking-phone-input"
                                style={{
                                    maxWidth: '275px',
                                    marginBottom: '1rem',
                                    paddingTop: '2px',
                                    paddingBottom: '2px'
                                }}
                            />
                        </div>
                        <div className="col-5"><p className='input-title'>Country</p></div>
                        <div className="col-7">
                            <Autocomplete
                                sx={{
                                    display: 'inline-block',
                                    '& input': {
                                        width: 200,
                                        bgcolor: 'background.paper',
                                        color: (theme) =>
                                            theme.palette.getContrastText(theme.palette.background.paper),
                                    },
                                }}
                                name="country"
                                className='mb-3'
                                onChange={(e, value) => handleAdultDetailsChange({ target: { name: 'country', value } }, index)}
                                value={adult.country || null}
                                options={countries}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <div ref={params.InputProps.ref}>
                                        <Input type="text" disableUnderline
                                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '275px' }}
                                            className='table-inputs-content mb-3' {...params.inputProps} />
                                    </div>
                                )}
                            />

                            {/* <Autocomplete
                                size='small'
                                name="country"
                                value={adult.country || null}
                                onChange={(e, value) => handleAdultDetailsChange({ target: { name: 'country', value } }, index)}
                                options={countries}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Select Your Country"
                                        variant="outlined"
                                    />
                                )}
                            /> */}
                        </div>
                    </div>
                </div>
            );
        });
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setBookingFormData({
            ...bookingFormData,
            [name]: value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(bookingFormData); // prints the updated form data to the console
    };

    const resetForm = () => {
        setBookingFormData({
            tourName: '',
            date: '',
            pricePerPerson: 0,
            totalPrice: 0,
            status: '',
            totalTraveller: 0,
            adultDetails: [],
            childDetails: [],
        });
        setAdultDetails({})
    }

    const options = [
        "Saudi International",
        "Saudi International Qualifier",
        "Saudi Arabian Golf Championship",
        "Saudi Ladies Team International",
        "Saudi Ladies International",
        "Saudi Ladies International Qualifier",
        "Saudi International Powerlifting Championship",
        "Saudi International Squash Championship",
        "Saudi International Tennis Tournament",
        "Saudi International Bowling Championship",
        "Saudi International Chess Championship",
        "Saudi International Swimming Championship",
        "Saudi International Cycling Race",
        "Saudi International Marathon",
        "Saudi International Triathlon",
        "Saudi International Rally"
    ];

    return (
        <>
            <div className="container-fluid d-flex">
                <div className="content-wrapper">
                    <div className="content-components-wrapper">
                        <TopHeaderInternal title={`Create Booking`} linkToBackBtn={`/bookings`} />
                        <div className="bookings-wrapper sub-page-details-wrapper mt-4">
                            <div className="row">
                                <div className="col-xl-5 col-lg-7 col-md-10 col-sm-12" >
                                    <h5 className='sub-page-title'>Tour Details</h5>
                                    <form className='booking-form-wrapper' onSubmit={handleSubmit}>
                                        <div className="booking-form around-border">
                                            <div className="row align-items-baseline">
                                                <div className="col-5"><p className='input-title'>Tour Name</p></div>
                                                <div className="col-7">
                                                    <Autocomplete
                                                        sx={{
                                                            display: 'inline-block',
                                                            '& input': {
                                                                width: 200,
                                                                bgcolor: 'background.paper',
                                                                color: (theme) =>
                                                                    theme.palette.getContrastText(theme.palette.background.paper),
                                                            },
                                                        }}
                                                        id="custom-input-demo"
                                                        className='mb-3'
                                                        onChange={(event, value) =>
                                                            setBookingFormData({ ...bookingFormData, tourName: value })
                                                        }
                                                        options={options}
                                                        renderInput={(params) => (
                                                            <div ref={params.InputProps.ref}>
                                                                <Input type="text" disableUnderline
                                                                    sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '275px' }}
                                                                    className='table-inputs-content mb-3' {...params.inputProps} />
                                                            </div>
                                                        )}
                                                    />


                                                    {/* <Autocomplete
                                                        className='mb-3'
                                                        name="tourName"
                                                        options={['Tour 1', 'Tour 2', 'Tour 3']}
                                                        getOptionLabel={(option) => option}
                                                        renderInput={(params) => <TextField {...params} size="small" className='table-inputs-content' />}
                                                        onChange={(event, value) =>
                                                            setBookingFormData({ ...bookingFormData, tourName: value })
                                                        }
                                                    /> */}
                                                </div>
                                                <div className="col-5"><p className='input-title'>Select Date </p></div>
                                                <div className="col-7">
                                                    {/* <Input
                                                        sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '275px' }}
                                                        size='small'
                                                        disableUnderline
                                                        className='table-inputs-content mb-3'
                                                        name="date"
                                                        onChange={handleInputChange}
                                                    /> */}
                                                    <DatePicker
                                                        selected={selectedDate}
                                                        onChange={setSelectedDate}
                                                        dateFormat="dd/MM/yyyy"
                                                        className="table-inputs-content table-input-date-selector mb-3"
                                                    />
                                                </div>
                                                <div className="col-5"><p className='input-title table-inputs-with-sub-text'>Price <span className='lite-color'>(Per Person)</span></p></div>
                                                <div className="col-7">
                                                    <Input
                                                        disableUnderline
                                                        sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '275px' }}
                                                        className='table-inputs-content mb-3'
                                                        name="pricePerPerson"
                                                        size='small'
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className="col-5"><p className='input-title'>Total Price</p></div>
                                                <div className="col-7">
                                                    <Input
                                                        disableUnderline
                                                        sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '275px' }}
                                                        className='table-inputs-content mb-3'
                                                        name="totalPrice"
                                                        size='small'
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className="col-5"><p className='input-title'>Status</p></div>
                                                <div className="col-7">
                                                    {/* <StyledFormControl variant="outlined"> */}
                                                    <FormControl variant="standard">
                                                        <Select
                                                            className='table-inputs-content'
                                                            size="small"
                                                            id="status-select"
                                                            defaultValue="confirmed"
                                                            name="status"
                                                            disableUnderline
                                                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '275px' }}
                                                            onChange={handleInputChange}
                                                        >
                                                            <MenuItem value="confirmed">Confirmed</MenuItem>
                                                            <MenuItem value="pending">Pending</MenuItem>
                                                            <MenuItem value="cancelled">Cancelled</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    {/* </StyledFormControl> */}
                                                </div>
                                            </div>
                                        </div>

                                        <h5 className='sub-page-title'>Traveler Details</h5>

                                        <div className='form-part-two around-border'>
                                            <div className="traveler-details-wrapper">
                                                <div className="row align-center">
                                                    <div className="col-5"><p className='input-title'>Adult traveler</p></div>
                                                    <div className="col-7">
                                                        <Input
                                                            disableUnderline
                                                            readOnly
                                                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '275px' }}
                                                            className='table-inputs-content mb-3'
                                                            type="number"
                                                            size="small"
                                                            value={adultCount}
                                                            endAdornment={
                                                                <InputAdornment className='create-tour-icon-wrapper'>
                                                                    <IconButton
                                                                        aria-label="decrease"
                                                                        onClick={() => setAdultCount(adultCount > 0 ? adultCount - 1 : 0)}
                                                                        edge="end"
                                                                        disabled={adultCount <= 1}
                                                                    >
                                                                        <RemoveIcon />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        aria-label="increase"
                                                                        onClick={() => setAdultCount(adultCount + 1)}
                                                                        edge="end"
                                                                    >
                                                                        <AddIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={(e) => setAdultCount(parseInt(e.target.value))}
                                                        />
                                                    </div>
                                                    <div className="col-5"><p className='input-title table-inputs-with-sub-text'>Child traveler <span className='lite-color'>(0-14) years</span></p></div>
                                                    <div className="col-7">
                                                        <Input
                                                            disableUnderline
                                                            readOnly
                                                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '275px' }}
                                                            className='table-inputs-content mb-3'
                                                            type='number'
                                                            size='small'
                                                            value={childCount}
                                                            endAdornment={
                                                                <InputAdornment className='create-tour-icon-wrapper'>
                                                                    <IconButton
                                                                        aria-label="decrease"
                                                                        onClick={() => setChildCount(childCount > 0 ? childCount - 1 : 0)}
                                                                        edge="end"
                                                                        disabled={childCount <= 0}
                                                                    >
                                                                        <RemoveIcon />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        aria-label="increase"
                                                                        onClick={() => setChildCount(childCount + 1)}
                                                                        edge="end"
                                                                    >
                                                                        <AddIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={(e) => setChildCount(parseInt(e.target.value))}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="total-traveler-count">
                                                    <h5>Total Traveler: {adultCount + childCount} </h5>
                                                </div>
                                            </div>
                                            {adultCount > 0 && (
                                                <div className="travelers-adult-get-info">
                                                    {renderAdultDetails()}
                                                </div>
                                            )}
                                            {childCount > 0 &&
                                                <div className="travelers-child-get-info ">
                                                    {[...Array(childCount)].map((_, index) =>
                                                        <div key={index} className='details-wrapper-adult-child'>
                                                            <h5>Child-{index + 1} Details</h5>
                                                            <div className="row align-items-center">
                                                                <div className="col-5"><p className='input-title'>Name</p></div>
                                                                <div className="col-7">
                                                                    <Input
                                                                        disableUnderline
                                                                        sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '275px' }}
                                                                        className='table-inputs-content mb-3'
                                                                        // label="Name"
                                                                        size='small'
                                                                        onChange={(e) => {
                                                                            const childName = e.target.value;
                                                                            setBookingFormData(prevState => {
                                                                                const childDetails = [...prevState.childDetails];
                                                                                childDetails[index] = { ...childDetails[index], name: childName };
                                                                                return { ...prevState, childDetails };
                                                                            });
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                        </div>
                                        <div className="submit-reset-button">
                                            <button type="reset" className='SecondaryButton' onClick={resetForm}>Reset</button>
                                            <button type="submit" className="mr-3 PrimaryButton">Submit Booking</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
