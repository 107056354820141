import React, { useState } from 'react';
import TopHeaderMain from '../../../Layout/TopHeader/TopHeaderMain'
import {
  TextField,
  Button,
  IconButton,
  Input,
  InputAdornment,
  Snackbar,
  Switch,
  Alert,
  Autocomplete
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import TopHeaderInternal from '../../../Layout/TopHeader/TopHeaderInternal';
import { useEffect } from 'react';
import authorization from '../../../authorization';
import axiosConfig from '../../../axios';
import { useNavigate, useParams } from 'react-router-dom';
import { countries } from '../../../Layout/country'
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import FullScreenLoader from '../../../Layout/FullScreenLoader/FullScreenLoader';

export default function EditUsers() {
  const [allData, setAllData] = useState([])
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('')
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [countryError, setCountryError] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [nameErrorMsg, setNameErrorMsg] = useState();
  const [emailErrorMsg, setEmailErrorMsg] = useState();
  const [phoneErrorMsg, setPhoneErrorMsg] = useState();
  const [countryErrorMsg, setCountryErrorMsg] = useState('');

  const [passwordErrorMsg, setPasswordErrorMsg] = useState();

  const [isActive, setIsActive] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);

  const [buttonloader, setButtonLoader] = useState(false)
  const [fullScreenLoader, setFullScreenLoader] = useState(true)

  let { id } = useParams();
  var gotFromUrlId = id
  let history = useNavigate()
  useEffect(() => {
    handleUserData()
  }, [])

  const handleUserData = () => {
    axiosConfig
      .get(`/admin/user/${gotFromUrlId}`, {
        headers: {
          Authorization: authorization.authorizationUrl
        },
      })
      .then((response) => {
        let allData = response.data.data
        if (response.data.status) {
          setTimeout(() => {
            setFullScreenLoader(false)
          }, 800);
        }
        // console.log("Edit user get response", response.data.data);
        setName(allData.name ? allData.name : allData.first_name);
        setEmail(allData.email);
        setPhone(allData.mobile);
        setCountry(allData.country)
        setAllData(allData)
        setIsActive(allData.is_active == "1" ? true : false)
        setIsEmailVerified(allData.email_verified_at !== null ? true : false)
        setIsPhoneVerified(allData.phone_verified_at !== null ? true : false)
        // setPassword(allData.password);
        // setConfirmPassword('');

      })
      .catch(error => {
        console.log(error);
      });
  }


  const handleSubmit = (event) => {
    event.preventDefault();
    setButtonLoader(true)
    // Check for errors
    let hasError = false;
    // Validate Name
    if (!name?.trim()) {
      setNameError(true);
      setNameErrorMsg('Name is required');
      hasError = true;
    } else {
      setNameError(false);
      setNameErrorMsg('');
    }

    // Validate Email
    if (!email.trim() === '') {
      setEmailError(true);
      setEmailErrorMsg('Email is required');
      hasError = true;
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      setEmailError(true);
      setEmailErrorMsg('Invalid email address');
      hasError = true;
    } else {
      setEmailError(false);
      setEmailErrorMsg('');
    }

    // Validate Phone Number
    if (phone.trim() === '') {
      setPhoneError(true);
      setPhoneErrorMsg('Phone number is required');
      hasError = true;
    } else if (!isValidPhoneNumber(phone)) {
      setPhoneError(true);
      setPhoneErrorMsg('Invalid phone number');
      hasError = true;
    } else {
      setPhoneError(false);
      setPhoneErrorMsg('');
    }

    if (!country) {
      setCountryError(true);
      setCountryErrorMsg('Country is required');
      hasError = true;
    } else {
      setCountryError(false);
      setCountryErrorMsg('');
    }

    if (!hasError) {
      // Form data is valid
      const userData = {
        name,
        ...(email !== allData.email ? { email } : {}), // Include email if it's different
        ...(phone !== allData.mobile ? { mobile: phone } : {}), // Include mobile if it's different
        country,
        isactive: isActive ? 1 : 0,
        email_verified_at: isEmailVerified ? 1 : 0,
        phone_verified_at: isPhoneVerified ? 1 : 0,
      };
      console.log(JSON.stringify(userData));

      // Show success message
      // setOpenSnackbar(true);

      //call API for data update
      handleUpdateUserData(userData)

      // Reset form values
      // setName('');
      // setEmail('');
      // setPhone('');
      // setPassword('');
      // setConfirmPassword('');
    } else {
      setButtonLoader(false)
      return;
    }

  };

  const handleUpdateUserData = (userData) => {
    // console.log("the clicked data", userData)
    axiosConfig
      .put(`/admin/user/${gotFromUrlId}`, userData, {
        headers: {
          Authorization: authorization.authorizationUrl
        },
      })
      .then((response) => {
        // console.log("Edit user response", response.data);
        if (response.data.status === true) {
          // Show success message
          setButtonLoader(false)
          setOpenSnackbar(true);
          setTimeout(() => {
            history('/user')
          }, 800);
        }

      })
      .catch(error => {
        console.log(error);
      });
  }

  const handleActiveClick = () => {
    setIsActive(!isActive);
  };
  const handleEmailVerified = () => {
    setIsEmailVerified(!isEmailVerified);
  };
  const handlePhoneVerified = () => {
    setIsPhoneVerified(!isPhoneVerified);
  };


  return (
    <>
      <div className="container-fluid d-flex">
        <div className="content-wrapper">
          <div className="content-components-wrapper">
            {fullScreenLoader ? <FullScreenLoader /> : ''}
            {/* <TopHeaderMain title='Edit Users Details' icons={<EditIcon fontSize="large" />} /> */}
            <TopHeaderInternal title={`Edit Users Details `} linkToBackBtn={'/user'} />
            <div className="user-content sub-page-details-wrapper mt-4">
              <div className="row">
                <div className="col-xl-5 col-lg-6 col-md-7 col-sm-12">
                  <form className='d-flex flex-column my-3'>
                    <h5 className='sub-page-title'> Edit Personal Information</h5>
                    <div className='around-border'>
                      <div className="row">
                        <div className="col-4"><p className='input-title'>Name:</p></div>
                        <div className="col-8 mb-3">
                          <Input
                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', fontFamily: 'Kufam' }}
                            size='small'
                            disableUnderline
                            className='table-inputs-content'
                            placeholder='Enter Name'
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                          // error={nameErrorMsg}
                          // helperText={nameErrorMsg ? nameErrorMsg : ''}
                          />
                          <span className='error-msg'>{nameErrorMsg ? nameErrorMsg : ''}</span>
                        </div>
                        <div className="col-4"><p className='input-title'>Email:</p></div>
                        <div className="col-8 mb-3">
                          <Input
                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%' }}
                            size='small'
                            disableUnderline
                            className='table-inputs-content'
                            placeholder='Enter Email'
                            type="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                          // error={emailErrorMsg}
                          // helperText={emailErrorMsg ? emailErrorMsg : ''}
                          />
                          <span className='error-msg'>{emailErrorMsg ? emailErrorMsg : ''}</span>
                        </div>
                        <div className="col-4"><p className='input-title'>Phone Number:</p></div>
                        <div className="col-8 mb-3">
                          <PhoneInput
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry="SA"
                            value={phone}
                            onChange={setPhone}
                          />
                          <span className='error-msg'>{phoneErrorMsg ? phoneErrorMsg : ''}</span>
                        </div>
                        <div className='col-4'><p className='input-title'>Country: </p></div>
                        <div className='col-8 mb-3'>
                          <Autocomplete
                            id="country"
                            size="small"
                            options={countries.map(country => country.name)}
                            value={country}
                            onChange={(event, newValue) => setCountry(newValue || '')}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select Country"
                                variant="outlined"
                                style={{ maxWidth: "100%" }}
                              />
                            )}
                          />

                        </div>
                        <div className='col-4'><p className='input-title'>Active Status: </p></div>
                        <div className='col-8 mb-2'>
                          <Switch
                            checked={isActive}
                            onChange={handleActiveClick}
                            className={isActive ? "thisActiveUser" : "thisInactiveUser"}
                            color="default"
                          />
                        </div>
                        <div className='col-4'><p className='input-title'>Email Verified: </p></div>
                        <div className='col-8 mb-2'>
                          <Switch
                            checked={isEmailVerified}
                            onChange={handleEmailVerified}
                            className={isEmailVerified ? "thisActiveUser" : "thisInactiveUser"}
                            color="default"
                          />
                        </div>
                        <div className='col-4'><p className='input-title'>Phone Verified: </p></div>
                        <div className='col-8 mb-2'>
                          <Switch
                            checked={isPhoneVerified}
                            onChange={handlePhoneVerified}
                            className={isPhoneVerified ? "thisActiveUser" : "thisInactiveUser"}
                            color="default"
                          />
                        </div>
                      </div>
                    </div>

                    <div className='submit-button-sub-page'>
                      <button className={`PrimaryButton ${buttonloader ? 'button-loader' : ' '}`}
                        type="submit" onClick={handleSubmit} variant="contained">
                        {buttonloader ? '' : 'Update'}
                      </button>
                    </div>

                  </form>
                  <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={() => setOpenSnackbar(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert severity="success" sx={{ width: '100%' }}>
                      User Updated successfully!
                    </Alert>
                  </Snackbar>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
