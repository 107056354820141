import React, { useEffect, useState } from 'react'
import TopHeaderMain from '../../../Layout/TopHeader/TopHeaderMain'
import { styled } from '@mui/material/styles';
import {
    Table, TableBody, TableCell, TableHead, Paper, TableRow, TextField, FormControl, TableSortLabel, IconButton, Pagination, TableContainer,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import DeleteIcon from '@mui/icons-material/Delete';
import axiosConfig from '../../../axios';
import config from '../../../config';
import authorization from '../../../authorization';

const StyledTextField = styled(TextField)({
    width: '100%',
    marginBottom: '1rem',
});

export default function PopularTour() {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('all');
    const [sortBy, setSortBy] = useState(null);
    const [sortColumn, setSortColumn] = useState('id');
    const [sortOrder, setSortOrder] = useState('asc');
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(8);
    const [totalPages, setTotalPages] = useState()
    const [openSnackbar, setOpenSnackbar] = useState(false);


    useEffect(() => {
        axiosConfig.get(`/admin/tour/category?page=${page}`, {
            headers: {
                'Authorization': authorization.authorizationUrl
            }
        })
            .then(response => {
                setUsers(response.data.data);
                setTotalPages(response.data.data.last_page);
            })
            .catch(error => console.log(error));
    }, [page, rowsPerPage]);



    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const history = useNavigate();


    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };



    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    const handleDeleteClick = (user) => {
        const updatedUsers = users.filter((user) => user.id !== user.id);
        setUsers(updatedUsers);
    };

    const handleEditClick = (user) => {
        // logic to handle edit click
        history(`/tours/popular-tour/edit-popular-tour/${user.id}`);
    };
    const handleDetailClick = (user) => {
        history(`/tours/popular-tour/popular-tour-details/${user.id}`);
    }
    
    return (
        <>
            <div className="container-fluid d-flex">
                <div className="content-wrapper">
                    <div className="content-components-wrapper">
                        <TopHeaderMain title={`Popular Tour`} />
                        <div className="tour-wrapper mt-4">

                            <div className="row">
                                <div className="col-4">
                                    <StyledTextField
                                        label="Search by Name"
                                        variant="outlined"
                                        size="small"
                                        onChange={handleSearchInputChange}
                                    />
                                </div>
                                <div className="col-4">
                                    {/* <StyledFormControl variant="outlined">
                                        <InputLabel id="status-select-label">Filter by status</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="status-select-label"
                                            id="status-select"
                                            value={selectedStatus}
                                            onChange={handleStatusSelectChange}
                                            label="Filter by status"
                                        >
                                            <MenuItem value="all">All</MenuItem>
                                            <MenuItem value="Confirmed">Confirmed</MenuItem>
                                            <MenuItem value="Cancelled">Cancelled</MenuItem>
                                        </Select>
                                    </StyledFormControl> */}
                                </div>
                                {/* <div className="col-4 text-end">  ******removed ******
                                    <Link to='/tours/popular-tour/create-new-popular-tour'>
                                        <button className='PrimaryButton'><AddIcon />Create new Populer Tour</button>
                                    </Link>
                                </div> */}
                            </div>
                            <div className="user-table-content">
                                <TableContainer component={Paper} sx={{ boxShadow: 'none', border: '1px solid #E4E4E4' }}>
                                    <Table sx={{ boxShadow: 1 }}>
                                        <TableHead style={{ backgroundColor: '#e9ecef' }}>
                                            <TableRow >
                                                {/* <TableCell sx={{ width: 200 }}>
                                                <TableSortLabel
                                                >
                                                    ID
                                                </TableSortLabel>
                                            </TableCell> */}
                                                <TableCell sx={{ width: 200 }}>
                                                    Cover
                                                </TableCell>
                                                <TableCell>
                                                    <TableSortLabel>
                                                        Populer Tour
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell align="center"></TableCell>
                                            </TableRow>

                                        </TableHead>
                                        {users.length > 0 ? (
                                            <TableBody className='table-body-wrapper tours-table-body-wrapper'>
                                                {users.map((user) => (
                                                    <TableRow key={user.id}>
                                                        {/* <TableCell >{user.id}</TableCell> */}
                                                        <TableCell className='table-data'><img src={config.imageBaseURL + user.image} alt={user.image} className="border rounded" style={{ width: '80px', height: '80px', objectFit: 'cover' }} /></TableCell>
                                                        <TableCell className='table-data'>{user.title_en}</TableCell>
                                                        <TableCell className='action-dropdown-btn' align="center">
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" size="sm" style={{ color: '#000000', border: 'none', background: 'transparent' }}>
                                                                    <MoreVertIcon />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    {/* <Dropdown.Item>
                                                                        <IconButton
                                                                            color="primary"
                                                                            aria-label="Edit"
                                                                            style={{ fontSize: '14px' }}
                                                                            onClick={() => handleEditClick(user)}
                                                                        >
                                                                            <EditIcon style={{ fontSize: 16 }} className='me-1' /> Edit
                                                                        </IconButton>
                                                                    </Dropdown.Item> */}
                                                                    <Dropdown.Item>
                                                                        <IconButton
                                                                            color="secondary"
                                                                            aria-label="Delete"
                                                                            style={{ fontSize: '14px' }}
                                                                            onClick={() => handleDeleteClick(user.id)}
                                                                        >
                                                                            <DeleteIcon style={{ fontSize: 16 }} className='me-1' /> Delete
                                                                        </IconButton>
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        ) : (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell colSpan={6} align="center">
                                                        No Tours found.
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )}
                                    </Table>
                                </TableContainer>
                                <div className="pagination-wrapper">
                                    {totalPages > 1 ? <Pagination className="custom-pagination" count={totalPages} variant="outlined" shape="rounded" page={page} onChange={(event, newPage) => setPage(newPage + 1)} /> : ""}

                                    {/* <TablePagination
                                        component="div"
                                        count={totalPages * rowsPerPage}
                                        page={page - 1}
                                        onPageChange={(event, newPage) => setPage(newPage + 1)}
                                        rowsPerPage={rowsPerPage}
                                        rowsPerPageOptions={[]}
                                    /> */}
                                </div>
                                {/* <Pagination
                                    className='my-3 user-table user-table-pagination'
                                    // count={Math.ceil(filteredUsers.length / rowsPerPage)}
                                    page={page}
                                    onChange={handlePageChange}
                                    shape="rounded"
                                    color="secondary"
                                    rowsPerPage={rowsPerPage}
                                    rowsPerPageOptions={[8]}
                                    onPageChange={handlePageChange}
                                    onRowsPerPageChange={handleRowsPerPageChange}
                                    boundaryCount={1}
                                    siblingCount={1}
                                /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
