import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Menu,
  InputLabel,
  TableSortLabel,
  TableFooter,
  Checkbox,
  IconButton,
  Pagination,
  TablePagination,
  Snackbar,
  Alert,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import PeopleIcon from "@mui/icons-material/People";
import AddIcon from "@mui/icons-material/Add";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CommentIcon from "@mui/icons-material/Comment";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import DeleteIcon from "@mui/icons-material/Delete";
import TopHeaderMain from "../../Layout/TopHeader/TopHeaderMain";
import PaymentIcon from "@mui/icons-material/Payment";
import axiosConfig from "../../axios";
import config from "../../config";
import authorization from "../../authorization";
import DeleteModal from "../../Layout/Modal/Modal";
import FullScreenLoader from "../../Layout/FullScreenLoader/FullScreenLoader";

const StyledTextField = styled(TextField)({
  width: "100%",
  marginBottom: "1rem",
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  marginBottom: "1rem",
});

function Testimony() {
  const [testimonials, setTestimonials] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortColumn, setSortColumn] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const [testimonialId, setTestimonialId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);


  const [fullScreenLoader, setFullScreenLoader] = useState(true)

  useEffect(() => {
    getTestimonyData()

  }, [page, rowsPerPage]);

  const getTestimonyData = () => {
    axiosConfig
      .get(`/admin/testimonial?page=${page}&sortby=DESC`, {
        headers: {
          Authorization: authorization.authorizationUrl,
        },
      })
      .then((response) => {
        // console.log("response.data", response.data)
        setTestimonials(response.data.data.data);
        setTotalPages(response.data.data.last_page);
        if (response.data.status) {
          setTimeout(() => {
            setFullScreenLoader(false)
          }, 800);
        }

      })
      .catch((error) => console.log(error));
  }

  const close = () => {
    setShowModal(false);
  };

  const deleteTestimonial = () => {
    axiosConfig
      .delete(`/admin/testimonial/${testimonialId}`, {
        headers: {
          Authorization: authorization.authorizationUrl,
        },
      })
      .then((response) => {
        // console.log("deleteTestimonial", response.data)
        let status = response.data.status
        if (status) {
          setShowModal(false);
          getTestimonyData()
        } else {
          setOpenErrorSnackbar(true)
        }
      })
      .catch((error) => console.log(error));
  };

  const handleDeleteClick = (id) => {
    setTestimonialId(id);
    setShowModal(true);
  };

  const history = useNavigate();
  const handleEditClick = (id) => {
    // logic to handle edit click
    history(`/testimony/edit-testimony/${id}`);
    // console.log(`Edit clicked for user with id ${id}`);
  };

  const handleDetailClick = (user) => {
    history(`/testimony/testimony-details/${user.id}`);
  };

  const handleSortClick = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // const filteredUsers = users.filter((user) =>
  //     user.name.toLowerCase().includes(searchQuery.toLowerCase())
  // );


  function formatDate(dateStr) {
    const dateObj = new Date(dateStr);

    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const formattedDate = dateObj.toLocaleDateString("en-GB", options);

    return formattedDate;
  }

  return (
    <>
      <div className="container-fluid d-flex">
        <div className="content-wrapper">
          <div className="content-components-wrapper">
            {fullScreenLoader ? <FullScreenLoader /> : ''}
            <TopHeaderMain
              title=" Testimonials"
              icons={<CommentIcon fontSize="large" />}
            />
            <div className="user-content mt-4">
              <div className="row mb-4">
                <div className="col-6 col-xl-6 col-sm-12">

                </div>
                <div className="col-6 col-xl-6 col-sm-12 text-end">
                  <Link to="/testimony/create-new" className="nounderline">
                    <button className="PrimaryButton">
                      <AddIcon /> Create Testimony
                    </button>
                  </Link>
                </div>
              </div>
              <div className="user-table-content">
                <TableContainer
                  component={Paper}
                  sx={{ boxShadow: "none", border: "1px solid #E4E4E4" }}
                >
                  <Table>
                    <TableHead style={{ backgroundColor: "#F2F2F2" }}>
                      <TableRow>

                        <TableCell>Date</TableCell>
                        <TableCell>Customer Name</TableCell>
                        <TableCell>Image</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Latitude</TableCell>
                        <TableCell>longitude</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    {testimonials.length > 0 ? (
                      <TableBody className="table-body-wrapper tours-table-body-wrapper">
                        {testimonials.map((user) => (
                          <TableRow key={user.id}>
                            {/* <TableCell>{user.id}</TableCell> */}
                            <TableCell className="table-data table-data-bold">
                              {formatDate(user.created_at) + ' ' + user.id}
                            </TableCell>
                            <TableCell className="table-data">
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => handleDetailClick(user)}
                              >
                                {user.user_name}
                              </span>
                            </TableCell>
                            <TableCell className="table-data">
                              <img
                                src={config.imageBaseURL + user.user_image}
                                className="testimony-img"
                              />{" "}
                            </TableCell>
                            <TableCell className="table-data">
                              {user.user_location}
                            </TableCell>
                            <TableCell className="table-data">
                              {user.latitude}
                            </TableCell>
                            <TableCell className="table-data">
                              {user.longitude}
                            </TableCell>
                            <TableCell className="action-dropdown-btn">
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="secondary"
                                  id="dropdown-basic"
                                  size="sm"
                                  style={{
                                    color: "#000000",
                                    border: "none",
                                    background: "transparent",
                                  }}
                                >
                                  <MoreVertIcon />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item className="px-0">
                                    <Button
                                      color="primary"
                                      aria-label="View"
                                      onClick={() => handleDetailClick(user)}
                                      style={{ fontSize: "14px", width: "100%", justifyContent: "start", paddingLeft: "18px" }}
                                      startIcon={<InfoIcon style={{ fontSize: 16 }} />}
                                    >
                                      View
                                    </Button>

                                  </Dropdown.Item>
                                  <Dropdown.Item className="px-0">
                                    <Button
                                      color="primary"
                                      aria-label="View"
                                      onClick={() => handleEditClick(user.id)}
                                      style={{ fontSize: "14px", width: "100%", justifyContent: "start", paddingLeft: "18px" }}
                                      startIcon={<EditIcon style={{ fontSize: 16 }} />}
                                    >
                                      Edit
                                    </Button>

                                  </Dropdown.Item>
                                  <Dropdown.Item className="px-0">
                                    <Button
                                      color="secondary"
                                      aria-label="Delete"
                                      onClick={() => handleDeleteClick(user.id)}
                                      style={{ fontSize: "14px", width: "100%", justifyContent: "start", paddingLeft: "18px" }}
                                      startIcon={<DeleteIcon style={{ fontSize: 16 }} />}
                                    >
                                      Delete
                                    </Button>

                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={6} align="center">
                            No Testimonials found.
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
                <div className="pagination-wrapper">
                  {totalPages > 1 ? (
                    <Pagination
                      className="custom-pagination"
                      count={totalPages}
                      variant="outlined"
                      shape="rounded"
                      page={page}
                      onChange={(event, newPage) => setPage(newPage)}
                    />
                  ) : (
                    ""
                  )}

                </div>
              </div>
              <DeleteModal
                showModal={showModal}
                onClose={close}
                onSubmit={deleteTestimonial}
              />
              <Snackbar
                open={openSnackbar}
                autoHideDuration={7000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <Alert
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  Testimonial Deleted successfully!
                </Alert>
              </Snackbar>
              <Snackbar
                open={openErrorSnackbar}
                autoHideDuration={5000}
                onClose={() => setOpenErrorSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <Alert
                  severity="error"
                  sx={{ width: "100%" }}
                >
                  Delete failed!
                </Alert>
              </Snackbar>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Testimony;
