import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    FormControl,
    TableSortLabel,
    TableFooter,
    Checkbox,
    IconButton,
    Pagination,
    Snackbar,
    Alert,
    Input,
    Button,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import PeopleIcon from '@mui/icons-material/People';
import AddIcon from '@mui/icons-material/Add';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

import DeleteIcon from '@mui/icons-material/Delete';
import TopHeaderMain from '../../../Layout/TopHeader/TopHeaderMain';
import axiosConfig from '../../../axios';
import DeleteModal from '../../../Layout/Modal/Modal';
import ClearIcon from '@mui/icons-material/Clear'; // Import the ClearIcon from Material-UI
import FullScreenLoader from '../../../Layout/FullScreenLoader/FullScreenLoader';

const StyledTextField = styled(TextField)({
    width: '100%',
    marginBottom: '1rem',
});

const StyledFormControl = styled(FormControl)({
    width: '100%',
    marginBottom: '1rem',
});

function MakeTourList() {
    const [inquiries, setInquiries] = useState([]);
    const [sortColumn, setSortColumn] = useState('id');
    const [sortOrder, setSortOrder] = useState('asc');
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [deleteFlag, setDeleteFlag] = useState(false);

    const [searchbarData, setSearchbarData] = useState()

    const [selectedId, setSelectedId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    const [fullScreenLoader, setFullScreenLoader] = useState(true)

    const token = localStorage.getItem("Token-for-login")

    const handleDeleteClick = (id) => {
        setSelectedId(id);
        setShowModal(true);
    };


    const handleDelete = () => {
        axiosConfig.delete(`/admin/tour/query/delete/${selectedId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                // console.log("delete inquiry", response.data)

                if (response.data.status) {
                    setShowModal(false);
                    setDeleteFlag(true)
                    listMakeaTour()
                    setOpenSnackbar(true)
                }
            })
            .catch(error => console.log(error));

        // const updatedUsers = inquiries.filter((user) => user.id !== id);
        // setInquiries(updatedUsers);

    };

    const close = () => {
        setShowModal(false);
    };

    useEffect(() => {
        listMakeaTour()
    }, [searchbarData, page]);

    const listMakeaTour = () => {
        axiosConfig.get(`/admin/tour/queries?page=${page}${searchbarData ? `&search=${searchbarData}` : ''}&sortby=ASC`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                // console.log("make tour", response.data.data.data)
                setInquiries(response.data.data.data);
                setTotalPages(response.data.data.last_page);
                setTimeout(() => {
                    setFullScreenLoader(false)
                }, 800);
                if (deleteFlag) setDeleteFlag(false)
            })
            .catch(error => console.log(error));
    }

    const history = useNavigate();
    const handleEditClick = (id) => {
        // logic to handle edit click
        history(`/inquiry/make-a-tour/edit/${id}`);
        // console.log(`Edit clicked for user with id ${id}`);
    };

    const handleDetailClick = (user) => {
        history(`/inquiry/make-a-tour/details/${user.id}`);
    }

    const handleSearchBar = (e) => {
        const newValue = e.target.value;
        setSearchbarData(newValue); // Update the state with the new search value
        setPage(1)
        // setTimeout(() => {
        //     // console.log("searchbar value", newValue); // Log the new search value
        //     listMakeaTour(); // Call the tour list function after a short delay with the updated search value
        // }, 400);
    };
    const handleClearSearch = () => {
        setSearchbarData(''); // Clear the search bar
        setPage(1)
        listMakeaTour(); // Call the tour list function with empty search term
    };



    return (
        <>
            <div className="container-fluid d-flex">
                <div className="content-wrapper">
                    <div className="content-components-wrapper">
                        {fullScreenLoader ? <FullScreenLoader /> : ''}
                        <TopHeaderMain title='Make A Tour' icons={<ContactPhoneIcon fontSize="large" />} />
                        <div className="user-content mt-4">
                            <div className="row">
                                <div className="col-3">
                                    <div className="position-relative">
                                        <Input
                                            disableUnderline
                                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                            className='table-inputs-content mb-4'
                                            size='small'
                                            placeholder='Search'
                                            required
                                            fullWidth
                                            value={searchbarData}
                                            onChange={handleSearchBar}
                                        />
                                        {searchbarData && ( // Render the ClearIcon only if there's a search term
                                            <IconButton onClick={handleClearSearch}
                                                sx={{ position: 'absolute', top: '30%', right: '0px', transform: 'translateY(-50%)' }}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="user-table-content">
                                <TableContainer component={Paper} sx={{ boxShadow: 'none', border: '1px solid #E4E4E4' }}>
                                    <Table >
                                        <TableHead style={{ backgroundColor: '#F2F2F2' }}>
                                            <TableRow >
                                                <TableCell>Id</TableCell>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Email</TableCell>
                                                <TableCell>Phone no</TableCell>
                                                <TableCell>Country</TableCell>
                                                {/* <TableCell>Destination</TableCell> */}
                                                <TableCell>Travel Date</TableCell>
                                                <TableCell>No. of Days</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {inquiries.length > 0 ? (
                                            <TableBody>
                                                {inquiries.map((user) => (
                                                    <TableRow key={user.id}>
                                                        <TableCell>{user.id}</TableCell>
                                                        <TableCell><span style={{ cursor: 'pointer' }} onClick={() => handleDetailClick(user)}>{user.name}</span></TableCell>
                                                        <TableCell>{user.email}</TableCell>
                                                        <TableCell>{user.phone_number}</TableCell>
                                                        <TableCell>{user.country}</TableCell>
                                                        {/* <TableCell><span className='destination-details'>{user.location_id}</span></TableCell> */}
                                                        <TableCell>{user.travel_date}</TableCell>
                                                        <TableCell>{user.no_of_days}</TableCell>
                                                        <TableCell className='action-dropdown-btn'>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" size="sm" style={{ color: '#000000', border: 'none', background: 'transparent' }}>
                                                                    <MoreVertIcon />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item className='px-0'>
                                                                        <Button
                                                                            color="primary"
                                                                            aria-label="View"
                                                                            onClick={() => handleDetailClick(user)}
                                                                            style={{ fontSize: "14px", width: "100%", justifyContent: "start", paddingLeft: "18px" }}
                                                                            startIcon={<InfoIcon style={{ fontSize: 16 }} />}
                                                                        >
                                                                            View
                                                                        </Button>
                                                                    </Dropdown.Item>

                                                                    <Dropdown.Item className='px-0'>
                                                                        <Button
                                                                            color="secondary"
                                                                            aria-label="Delete"
                                                                            onClick={() => handleDeleteClick(user.id)}
                                                                            style={{ fontSize: "14px", width: "100%", justifyContent: "start", paddingLeft: "18px" }}
                                                                            startIcon={<DeleteIcon style={{ fontSize: 16 }} />}
                                                                        >
                                                                            Delete
                                                                        </Button>

                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        ) : (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell colSpan={6} align="center">
                                                        No Data found.
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )}

                                    </Table>
                                </TableContainer>
                                <div className="pagination-wrapper">
                                    {totalPages > 1 ? <Pagination className="custom-pagination" count={totalPages} variant="outlined" shape="rounded" page={page} onChange={(event, newPage) => setPage(newPage)} /> : ""}
                                </div>
                                <DeleteModal
                                    showModal={showModal}
                                    onClose={close}
                                    onSubmit={handleDelete}
                                />
                                <Snackbar
                                    open={openSnackbar}
                                    autoHideDuration={5000}
                                    onClose={() => setOpenSnackbar(false)}
                                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                >
                                    <Alert
                                        severity="success"
                                        sx={{ width: "100%" }}
                                    >
                                        Deleted successfully!
                                    </Alert>
                                </Snackbar>
                                <Snackbar
                                    open={openErrorSnackbar}
                                    autoHideDuration={5000}
                                    onClose={() => setOpenErrorSnackbar(false)}
                                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                >
                                    <Alert
                                        severity="error"
                                        sx={{ width: "100%" }}
                                    >
                                        Delete failed!
                                    </Alert>
                                </Snackbar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MakeTourList;