import React, { Component, useCallback, useEffect, useState } from "react";
import {
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Input,
  Snackbar,
  Alert,
  Autocomplete,
  Box,
  Switch,
  Tab,
  Tabs,
} from "@mui/material";
import config from "../../../config";
import { TabContext, TabPanel } from "@mui/lab";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useRef } from "react";
import { useParams } from "react-router-dom";
import axiosConfig from "../../../axios";
import { dropRight, concat, findIndex, words, forEach, isEmpty } from "lodash";
import ImageUploader from "./ImageUploader";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import TopHeaderInternal from "../../../Layout/TopHeader/TopHeaderInternal";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import ImageField from "../../../Layout/ImageField/ImageField";
import FullScreenLoader from "../../../Layout/FullScreenLoader/FullScreenLoader";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    console.error(error);
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default function TourForm() {
  const history = useNavigate();
  const { id: tourId } = useParams();
  const token = localStorage.getItem("Token-for-login");

  const [tourData, setTourData] = useState({
    title_en: "",
    days: 1,
    canBooked: false,
    is_featured: false,
    isPublished: false,
    category: null,
    location: null,
    category_id: null,
    location_id: null,
    payment_policy_en: "",
    meta_title_en: '',
    meta_description_en: '',
  });
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [itinerary, setItinerary] = useState([
    {
      no_of_day: "1",
      title_en: "",
      title_ar: "",
      title_ja: "",
      title_it: "",
      description_en: "",
      description_ar: "",
      description_ja: "",
      description_it: "",
      image: null,
    },
  ]);

  const [additionalInfo, setAdditionalInfo] = useState({
    inclusionArr: [],
    exclusionArr: [],
  });

  const [gallerySelectedFiles, setGallerySelectedFiles] = useState([]);
  const [galleryDeletedFiles, setGalleryDeletedFiles] = useState([]);
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);
  const itineraryImageRef = useRef(null);

  const [titleError, setTitleError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [errorFileUploader, setErrorFileUploader] = useState(false);
  const [errorFileUploaderLoc, setErrorFileUploaderLoc] = useState(false);
  const [tourErrors, setTourErrors] = useState({});
  const [itineraryErrors, setItineraryErrors] = useState([]);

  const [value, setValue] = React.useState("1");
  const [validationSnackbar, setValidationSnackbar] = useState(false);
  const [validationMessage, setValidationMessage] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrDelLocSnackbar, setOpenErrDelLocSnackbar] = useState(false)
  const [successMsg, setSuccessMsg] = useState()

  const [buttonloader, setButtonLoader] = useState(false)

  const [fullScreenLoader, setFullScreenLoader] = useState(true)

  const [showHideGoTopBtn, setShowHideGoTopBtn] = useState(false)

  // const [tourPolicy, setTourPolicy] = useState('')

  const toggleInclusions = (num) => {
    let tempInclusion = additionalInfo?.inclusionArr;
    tempInclusion[num].selected = !tempInclusion[num].selected;
    setAdditionalInfo((prevData) => ({
      ...prevData,
      inclusionArr: tempInclusion,
    }));
  };

  const toggleExclusions = (num) => {
    let tempExclusion = additionalInfo?.exclusionArr;
    tempExclusion[num].selected = !tempExclusion[num].selected;
    // console.log(tempExclusion[num], num);
    setAdditionalInfo((prevData) => ({
      ...prevData,
      exclusionArr: tempExclusion,
    }));
  };

  const getInclusion = useCallback(() => {
    axiosConfig
      .get(`/admin/inclusion?page=1`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        let theData = response.data.data;
        forEach(theData, (inclusion, index) => {
          theData[index].selected = false;
        });
        if (response.data.status) {
          setTimeout(() => {
            setFullScreenLoader(false)
          }, 800);
        }
        // console.log("getInclusion value", theData);
        // Update the inclutionArr in the data state
        setAdditionalInfo((prevData) => ({
          ...prevData,
          inclusionArr: theData,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token]);

  const getExclusion = useCallback(() => {
    axiosConfig
      .get(`/admin/exclusion?page=1`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        let theData = response.data.data;
        forEach(theData, (exclusion, index) => {
          theData[index].selected = false;
        });
        // console.log("getExclusion value", theData);
        if (response.data.status) {
          setTimeout(() => {
            setFullScreenLoader(false)
          }, 800);
        }
        // Update the exclutionArr in the data state
        setAdditionalInfo((prevData) => ({
          ...prevData,
          exclusionArr: theData,
        }));


      })
      .catch((error) => {
        console.log(error);
      });
  }, [token]);

  useEffect(() => {
    // Function to handle the scroll event
    const handleScroll = () => {
      // Get the current scroll position
      const scrollPosition = window.scrollY;

      // Check if the scroll position is more than 75px
      if (scrollPosition > 75) {
        setShowHideGoTopBtn(true);
      } else {
        setShowHideGoTopBtn(false);
      }
    };

    // Add the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!!tourId) {
      axiosConfig
        .get(`/admin/tours/${tourId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          // console.log("values for edit tour", response.data.data);
          // *title_en gets replaced by category.title_en in the backend, fix that
          setTourData({
            ...response.data.data,
            title_en: response.data.data.toure_title_en,
            canBooked: response.data.data.canBooked === "true" ? true : false,
            is_featured:
              response.data.data.is_featured === "true" ? true : false,
            isPublished: response.data.data.isPublished === "true" ? true : false,
          });

          axiosConfig
            .get(`/admin/inclusion?page=1`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((inclusions) => {
              let theData = inclusions.data.data;
              forEach(theData, (inclusion, index) => {
                theData[index].selected = false;
              });
              // console.log("getInclusion value", theData);

              const inclusionsAsString = words(response.data.data.inclusions);
              inclusionsAsString.forEach((itemStr) => {
                const id = findIndex(
                  theData,
                  (x) => x.id === parseInt(itemStr)
                );
                if (id !== -1) theData[id].selected = !theData[id].selected;
              });

              setAdditionalInfo((prevData) => ({
                ...prevData,
                inclusionArr: theData,
              }));
            })
            .catch((error) => {
              console.log(error);
            });

          axiosConfig
            .get(`/admin/exclusion?page=1`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((exclusions) => {
              let theData = exclusions.data.data;
              forEach(theData, (exclusion, index) => {
                theData[index].selected = false;
              });
              // console.log("getExclusion value", theData);

              const exclusionsAsString = words(response.data.data.exclusions);
              exclusionsAsString.forEach((itemStr) => {
                const id = findIndex(
                  theData,
                  (x) => x.id === parseInt(itemStr)
                );
                if (id !== -1) theData[id].selected = !theData[id].selected;
              });

              // Update the exclutionArr in the data state
              setAdditionalInfo((prevData) => ({
                ...prevData,
                exclusionArr: theData,
              }));
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => console.log(error));
      // setTimeout(() => {
      axiosConfig
        .get(`/admin/tour/itinerary?tour_id=${tourId}&page=-1`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          // console.log("Itinerary for edit", response.data.data);
          setItinerary(response.data.data);
        })
        .catch((error) => console.log(error));
      // }, 1500);

      axiosConfig
        .get(`/admin/tour/images/${tourId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          // console.log("Gallery for edit", response.data.data);
          setGallerySelectedFiles(response.data.data);
        })
        .catch((error) => console.log(error));

      setTimeout(() => {
        setFullScreenLoader(false)
      }, 1500);
    } else {
      getInclusion();
      getExclusion();
    }
  }, [token]);

  const handleIncrement = () => {
    setTourData({ ...tourData, days: tourData?.days + 1 });
    setItinerary(
      concat(itinerary, {
        no_of_day: tourData?.days + 1,
        title_en: "",
        title_ar: "",
        title_ja: "",
        title_it: "",
        description_en: "",
        description_ar: "",
        description_ja: "",
        description_it: "",
      })
    );
  };

  const handleDecrement = () => {
    setTourData({ ...tourData, days: tourData?.days - 1 });
    setItinerary(dropRight(itinerary, 1));
  };

  const createStringInputfromArray = (array) => {
    let selectedAdditionals = "";
    array.forEach((item) => {
      if (item.selected)
        selectedAdditionals = selectedAdditionals + item.id + ",";
    });
    console.debug(selectedAdditionals);
    return selectedAdditionals.slice(0, -1);
  };

  const handleToggle = () => {
    setTourData({
      ...tourData,
      isPublished: !tourData.isPublished,
    });
  };
  const handleTogglePopulerTour = () => {
    setTourData({
      ...tourData,
      is_featured: !tourData.is_featured,
    });
  };

  const createItineraryCalls = () => {
    let itineraryCall = {};
    itinerary.forEach((item, index) => {
      itineraryCall[`itinerarydays[${index}][no_of_day]`] = item.no_of_day;
      itineraryCall[`itinerarydays[${index}][title_en]`] = item.title_en;
      itineraryCall[`itinerarydays[${index}][title_ar]`] = item.title_ar || "";
      itineraryCall[`itinerarydays[${index}][title_ja]`] = item.title_en || "";
      itineraryCall[`itinerarydays[${index}][title_it]`] = item.title_en || "";
      itineraryCall[`itinerarydays[${index}][description_en]`] =
        item.description_en;
      itineraryCall[`itinerarydays[${index}][description_ar]`] =
        item.description_ar || "";
      itineraryCall[`itinerarydays[${index}][description_ja]`] = "Japanese Data";
      itineraryCall[`itinerarydays[${index}][description_it]`] = "Italian Data";
      itineraryCall[`itinerarydays[${index}][latitude]`] = initialValueLat;
      itineraryCall[`itinerarydays[${index}][longitude]`] = initialValueLog;
      // Check if item.image is an object (not a string) and is not null
      if (typeof item.image === "object" && item.image !== null) {
        itineraryCall[`itinerarydays[${index}][image]`] = item.image;
        // console.log("typeof item.image === ", typeof item.image === "object" && item.image !== null, item.image)
      }
    });
    return itineraryCall;
  };

  const createItineraryForEditCalls = () => {
    let itineraryCall = {};
    itinerary.forEach((item, index) => {
      item.title_ar = item.title_ar ?? "";
      item.title_ja = "Japanese_Data";
      item.title_it = "Italian_Data";
      item.description_ar = item.description_ar ?? "";
      item.description_ja = "Japanese_Data";
      item.description_it = "Italian_Data";
      itineraryCall[`days[${index}]`] = item;
    });
    // console.log(itineraryCall);
    return itineraryCall;
  };
  let initialValueLat = "24.774265"
  let initialValueLog = "46.738586"
  const handleSubmit = (event) => {
    event.preventDefault();

    let errors = {};
    if (!tourData?.title_en) {
      errors["title_en"] = "title field required";
    }
    if (!tourData?.description_en) {
      errors["description_en"] = "description field required";
    }
    if (!tourData?.location) {
      errors["location"] = "location field required";
    }
    if (!tourData?.category) {
      errors["category"] = "category field required";
    }
    // if (!tourData?.price) {
    //   errors["price"] = "price field required";
    // }
    setTourErrors(errors);

    if (!isEmpty(errors)) {
      setValidationSnackbar(true);
      setValidationMessage("Some fields are missing.");
      return;
    }

    // gallery validation
    // if (gallerySelectedFiles.length < 10) {
    //   setValidationSnackbar(true);
    //   setValidationMessage("Minimum 10 gallery images required.");
    //   return;
    // }

    const image_name = [];
    gallerySelectedFiles.forEach((file) => {
      if (!file.id) image_name.push(file);
    });

    if (!!tourId) {
      if (!tourData?.title_ar) {
        errors["title_ar"] = "title_ar field required";
      }
      // if (!tourData?.title_it) {
      //   errors["title_it"] = "title_it field required";
      // }
      // if (!tourData?.title_ja) {
      //   errors["title_ja"] = "title_ja field required";
      // }
      if (!tourData?.description_ar) {
        errors["description_ar"] = "description_ar field required";
      }
      // if (!tourData?.description_it) {
      //   errors["description_it"] = "description_it field required";
      // }
      // if (!tourData?.description_ja) {
      //   errors["description_ja"] = "description_ja field required";
      // }
      setTourErrors(errors);

      if (!isEmpty(errors)) {
        setValidationSnackbar(true);
        setValidationMessage("Some fields are missing.");
        return;
      }

      // edit itinerary validation
      let itenErrors = {};
      itinerary.forEach((item, index) => {
        if (!item.title_en)
          itenErrors[`${index}`] = {
            ...itenErrors[`${index}`],
            title_en: "title field required",
          };
        if (!item.title_ar)
          itenErrors[`${index}`] = {
            ...itenErrors[`${index}`],
            title_ar: "title field required",
          };
        // if (!item.title_ja)
        //   itenErrors[`${index}`] = {
        //     ...itenErrors[`${index}`],
        //     title_ja: "title field required",
        //   };
        // if (!item.title_it)
        //   itenErrors[`${index}`] = {
        //     ...itenErrors[`${index}`],
        //     title_it: "title field required",
        //   };
        if (!item.description_en)
          itenErrors[`${index}`] = {
            ...itenErrors[`${index}`],
            description_en: "description field required",
          };
        if (!item.description_ar)
          itenErrors[`${index}`] = {
            ...itenErrors[`${index}`],
            description_ar: "description field required",
          };
        // if (!item.description_ja)
        //   itenErrors[`${index}`] = {
        //     ...itenErrors[`${index}`],
        //     description_ja: "description field required",
        //   };
        // if (!item.description_it)
        //   itenErrors[`${index}`] = {
        //     ...itenErrors[`${index}`],
        //     description_it: "description field required",
        //   };
        // if (!item.latitude)
        //   handleItineraryUpdate(initialValueLat, "latitude", index);
        // // itenErrors[`${index}`] = {
        // //   ...itenErrors[`${index}`],
        // //   latitude: "latitude field required",
        // // };
        // if (!item.latitude)
        //   handleItineraryUpdate(initialValueLog, "longitude", index);
        // // itenErrors[`${index}`] = {
        // //   ...itenErrors[`${index}`],
        // //   longitude: "longitude field required",
        // // };

      });
      setItineraryErrors(itenErrors);

      if (!isEmpty(itenErrors)) {
        setValidationSnackbar(true);
        setValidationMessage("Itinerery fields are missing");

        return;
      }

      // console.log("gallerySelectedFiles", gallerySelectedFiles, image_name);
      const itineraryCalls = createItineraryCalls();
      let updatedData = {
        ...tourData,
        is_featured: tourData.is_featured ? "true" : "false",
        canBooked: tourData.canBooked ? "true" : "false",
        detail_id: "",
        feature_id: "",
        itinerie_id: "",
        discount: "0",
        isPublished: tourData.isPublished ? "true" : "false",
        ...itineraryCalls,
        inclusions: createStringInputfromArray(additionalInfo.inclusionArr),
        exclusions: createStringInputfromArray(additionalInfo.exclusionArr),
        title_it: tourData.title_en,
        title_ja: tourData.title_en,
        description_it: tourData.description_en,
        description_ja: tourData.description_en,
        meta_title_it: tourData.meta_title_en,
        meta_description_it: tourData.meta_description_en,
        meta_title_ja: tourData.meta_title_en,
        meta_description_ja: tourData.meta_description_en,
      };

      if (
        typeof updatedData.image === "string" ||
        updatedData.image instanceof String
      )
        delete updatedData.image;

      axiosConfig
        .put(`/admin/tours/${tourId}`, updatedData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          // console.log("tour response:", response.data.data);
          if (!response.data.status) {
            // console.log("gg", response.data.message?.title_en?.[0]);

            setTitleError({
              value: response.data.message?.title_en?.[0],
              status: response.data.message?.title_en,
            });
            setDescriptionError({
              value: response.data.message?.description_en?.[0],
              status: response.data.message?.description_en,
            });
            setCategoryError({
              value: response.data.message?.category?.[0],
              status: response.data.message?.category,
            });
            setCountryError({
              value: response.data.message?.category?.[0],
              status: response.data.message?.category,
            });
            setErrorFileUploader({
              value: response.data.message?.image?.[0],
              status: response.data.message?.image,
            });
            setErrorSnackbarOpen(true);

          }

          let editImagesFlag = true;
          let deleteImagesFlag = true;
          if (image_name.length !== 0) {
            let images = {};
            image_name.forEach((image, index) => {
              images = {
                ...images,
                "image_name[0]": image,
              };
            });
            axiosConfig
              .post(
                `/admin/tour/image/store`,
                {
                  tour_id: tourId,
                  "image_name[]": image_name,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then((response) => {
                // console.log("response:", response.data);
                if (!response.data.status) {
                  editImagesFlag = false;
                  // console.log("response:", editImagesFlag);
                  setErrorSnackbarOpen(true);

                }
              })
              .catch((error) => {
                setErrorSnackbarOpen(true);

                editImagesFlag = false;
                console.log(error);
              });
          }

          if (galleryDeletedFiles.length > 0) {
            // console.log("galleryDeletedFiles:", galleryDeletedFiles);
            const tempDelFiles = galleryDeletedFiles;
            for (let index = 0; index < galleryDeletedFiles.length; index++) {
              ((flag) => {
                axiosConfig
                  .delete(
                    `/admin/tour/image/delete/${galleryDeletedFiles[index]}`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((response) => {
                    // console.log("delete response:", response.data.data.id);
                    if (!response.data.status) {
                      flag = false;
                    } else {
                      tempDelFiles.splice(index, 1);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    setErrorSnackbarOpen(true);

                    flag = false;
                  });
              })(deleteImagesFlag);
            }
            if (deleteImagesFlag) setGalleryDeletedFiles(tempDelFiles);
          }

          return { editImagesFlag, deleteImagesFlag };
        })
        .then(({ editImagesFlag, deleteImagesFlag }) => {
          itinerary.forEach((item, index) => {
            if (typeof item.image === "string" || item.image instanceof String)
              delete itinerary[index].image;
          });
          let itineraryFlag = true;
          let itineraryEditCall = createItineraryForEditCalls(itinerary);
          axiosConfig
            .post(
              `/admin/tour/itinerary/update/${tourId}`,
              {
                tour_id: tourId,
                ...itineraryEditCall,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              // console.log(
              //   "itinerary response",
              //   response.data.data,
              //   editImagesFlag,
              //   deleteImagesFlag
              // );

              itineraryFlag = response.data.status;

              // if (response.status && editImagesFlag && deleteImagesFlag) {
              //   setOpenSnackbar(true);
              //   setTimeout(() => history("/tours/all-tours"), 2000);
              // }
            })
            .catch((error) => {
              setErrorSnackbarOpen(true);

              console.log(error);
            });

          console.debug(itineraryFlag);
          return { editImagesFlag, deleteImagesFlag, itineraryFlag };
        })
        .then(({ editImagesFlag, deleteImagesFlag, itineraryFlag }) => {
          if (itineraryFlag && editImagesFlag && deleteImagesFlag) {
            setOpenSnackbar(true);
            setSuccessMsg("Tour Updated Successfully!")
            setTimeout(() => history("/tours/all-tours"), 2000);
          }
        })
        .catch((error) => {
          setErrorSnackbarOpen(true);

          console.log(error);
        });
    } else {
      // edit itinerary validation
      let itenErrors = [];
      itinerary.forEach((item, index) => {
        if (!item.title_en)
          itenErrors[`${index}`] = {
            ...itenErrors[`${index}`],
            title_en: "title_en field required",
          };
        if (!item.description_en)
          itenErrors[`${index}`] = {
            ...itenErrors[`${index}`],
            description_en: "description_en field required",
          };
        // if (!item.image)
        //   itenErrors[`${index}`] = {
        //     ...itenErrors[`${index}`],
        //     image: "image field required",
        //   };
        // if (!item.latitude)
        //   handleItineraryUpdate(initialValueLat, "latitude", index);
        // // itenErrors[`${index}`] = {
        // //   ...itenErrors[`${index}`],
        // //   latitude: "latitude field required",
        // // };
        // if (!item.longitude)
        //   handleItineraryUpdate(initialValueLog, "longitude", index);
        // // itenErrors[`${index}`] = {
        // //   ...itenErrors[`${index}`],
        // //   longitude: "longitude field required",
        // // };
      });
      setItineraryErrors(itenErrors);

      if (!isEmpty(itenErrors)) {
        setValidationSnackbar(true);
        return;
      }

      const itineraryCalls = createItineraryCalls();
      // console.log("itineraryCalls itineraryCalls", itineraryCalls);
      setButtonLoader(true)
      axiosConfig
        .post(
          `/admin/tours`,
          {
            ...tourData,
            is_featured: tourData.is_featured ? "true" : "false",
            canBooked: tourData.canBooked ? "true" : "false",
            detail_id: "",
            feature_id: "",
            itinerie_id: "",
            discount: "0",
            isPublished: tourData.isPublished ? "true" : "false",
            image_name,
            ...itineraryCalls,
            inclusions: createStringInputfromArray(additionalInfo.inclusionArr),
            exclusions: createStringInputfromArray(additionalInfo.exclusionArr),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((tourResponse) => {
          // console.log("tour response:", tourResponse.data);
          // console.log("itineraryCalls tourData", itineraryCalls, tourData);
          if (!tourResponse.data.status) {
            // console.log("gg", tourResponse.data.message?.title_en?.[0]);
            setButtonLoader(false)
            setTitleError({
              value: tourResponse.data.message?.title_en?.[0],
              status: tourResponse.data.message?.title_en,
            });
            setDescriptionError({
              value: tourResponse.data.message?.description_en?.[0],
              status: tourResponse.data.message?.description_en,
            });
            setCategoryError({
              value: tourResponse.data.message?.category?.[0],
              status: tourResponse.data.message?.category,
            });
            setCountryError({
              value: tourResponse.data.message?.category?.[0],
              status: tourResponse.data.message?.category,
            });
            setErrorFileUploader({
              value: tourResponse.data.message?.image?.[0],
              status: tourResponse.data.message?.image,
            });
            setDateError({
              value: tourResponse.data.message?.date?.[0],
              status: tourResponse.data.message?.date,
            });
            setErrorSnackbarOpen(true);

          } else {

            setSuccessMsg("Tour Created successfully!")
            setOpenSnackbar(true);
            setButtonLoader(false)
            setTimeout(() => history("/tours/all-tours"), 1500);
          }
        })
        .catch((error) => {
          setErrorSnackbarOpen(true);

          console.log(error);
        });
    }
  };
  useEffect(() => {
    axiosConfig
      .get(`/admin/tour/location?page=-1`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log("location value", response.data.data);
        setLocations(response.data.data);
      })
      .then(() => {
        axiosConfig
          .get(`/admin/tour/category?page=-1`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            // console.log("category value", response.data.data);
            setCategories(response.data.data);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token]);

  const handleItineraryUpdate = (value, property, index) => {
    let newItinerary = structuredClone(itinerary);
    newItinerary.forEach((item, i) =>
      index === i ? (item[property] = value) : null
    );
    setItinerary(newItinerary);
    // console.log("value, property, index", value, property, index)
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (...arg) => {
    arg[0].preventDefault();
    arg[0].stopPropagation();
    setDragActive(false);
    if (arg[0].dataTransfer.files && arg[0].dataTransfer.files[0]) {
      const droppedFile = arg[0].dataTransfer.files[0];
      if (validateFile(droppedFile)) {
        arg[1]
          ? handleItineraryUpdate(droppedFile, arg[1], arg[2])
          : setTourData({ ...tourData, image: droppedFile });
        console.log("droped log", droppedFile);
        setErrorFileUploaderLoc(false);
      } else {
        setErrorFileUploaderLoc(true);
      }
    }
  };

  const handleChange = (...arg) => {
    const event = arg[0];
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      if (validateFile(selectedFile)) {
        if (arg[1]) {
          // Itinerary update
          const updatedItinerary = [...itinerary];
          updatedItinerary[arg[2]][arg[1]] = selectedFile;
          handleItineraryUpdate(updatedItinerary);
        } else {
          // General update to tourData
          setTourData({ ...tourData, image: selectedFile });
        }
        setErrorFileUploaderLoc(false);

        // Clear the input field value to allow the same file to be uploaded again
        event.target.value = '';
      } else {
        setErrorFileUploaderLoc(true);
      }
    }
  };


  const handleDropCoverImg = (...arg) => {
    arg[0].preventDefault();
    arg[0].stopPropagation();
    setDragActive(false);
    if (arg[0].dataTransfer.files && arg[0].dataTransfer.files[0]) {
      const droppedFile = arg[0].dataTransfer.files[0];
      if (validateFile(droppedFile)) {
        arg[1]
          ? handleItineraryUpdate(droppedFile, arg[1], arg[2])
          : setTourData({ ...tourData, image: droppedFile });
        // console.log("droped log", droppedFile);
        setErrorFileUploader(false);
      } else {
        setErrorFileUploader(true);
      }
    }
  };

  const handleChangeCoverImg = (...args) => {
    args[0].preventDefault();

    if (args[0].target.files && args[0].target.files[0]) {
      const selectedFile = args[0].target.files[0];

      if (validateFile(selectedFile)) {
        if (args[1]) {
          // If arg[1] is provided, call handleItineraryUpdate
          handleItineraryUpdate(selectedFile, args[1], args[2]);
          console.log("if");
        } else {
          // Check if tourData.image is an empty object
          if (typeof tourData.image === 'string') {
            // Set it to null or update it as needed
            // For example, set it to null:
            setTourData({ ...tourData, image: null });
            console.log("got null the IMG")
          }

          // Now update tourData.image with the selectedFile (file)
          setTourData({ ...tourData, image: selectedFile });
          console.log("else");
        }

        setErrorFileUploader(false);
        console.log("Selected file:", selectedFile);

        // You can add any additional logic or processing here

        setTimeout(() => {
          console.log("Updated tourData.image:", tourData.image);
        }, 2500);
      } else {
        // console.log("Invalid file format:", selectedFile);
        setErrorFileUploader(true);
      }
    }
  };



  const getMap = () => {
    if (!itineraryImageRef.current) {
      itineraryImageRef.current = new Map();
    }
    return itineraryImageRef.current;
  };

  const onButtonClick = (...arg) => {
    const map = getMap();
    if (typeof arg[1] === "number") {
      const node = map.get(arg[1]);
      node.click();
    } else {
      inputRef.current.click();
    }
  };

  const validateFile = (file) => {
    const allowedTypes = [
      "image/png",
      "image/jpeg",
      "image/gif",
      "image/bmp",
      "image/svg+xml",
      "image/webp",
      "image/x-icon",
    ];
    const maxFileSize = 5 * 1024 * 1024; // 5MB

    if (!allowedTypes.includes(file.type)) {
      return false;
    }

    if (file.size > maxFileSize) {
      return false;
    }

    return true;
  };

  // const fromGalleryFiles = (files) => {
  //   setGallerySelectedFiles(files);
  //   console.log("filesfilesfilesfilesfiles", files);
  // };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const modules = {
    toolbar: [
      // [{ header: [1, 2, 3, 4, 5, 6] }],
      // [{ size: ['small', false, 'large', 'huge'] }], // Add font size selector
      ['bold', 'italic'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      // ['link', 'image', 'video'],
      // ['clean'],

    ],
    clipboard: {
      matchVisual: false,
    },
  };



  const handleGoToTop = () => {
    window.scroll(0, 0)
  }

  const handleRemoveImage = (event, index, itnaryId) => {
    event.preventDefault();
    // Create a copy of the current itinerary
    const updatedItinerary = [...itinerary];
    // Check if the image data at the specified index is a string
    if (typeof updatedItinerary[index].image === "string") {
      // Remove the image data by setting it to null
      updatedItinerary[index].image = null;
      // Update the itinerary state with the modified data
      handleItineraryUpdate(updatedItinerary);
      handleImageRemoveApi(itnaryId)
    } else {
      // Remove the image data by setting it to null
      updatedItinerary[index].image = null;
      // Update the itinerary state with the modified data
      handleItineraryUpdate(updatedItinerary);

      setSuccessMsg("Location Image Removed")
      setOpenSnackbar(true)
      setTimeout(() => {
        setSuccessMsg("")
        setOpenSnackbar(false)
      }, 1000);
    }
  };

  const handleImageRemoveApi = (itnaryId) => {
    // console.log("handleImageRemoveApi", itnaryId)
    axiosConfig
      .delete(`/admin/tour/itineraryimage/delete/${itnaryId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log("image delete response", response.data.status)
        if (response.data.status) {
          setSuccessMsg("Location Image Removed")
          setOpenSnackbar(true)
          setTimeout(() => {
            setSuccessMsg("")
            setOpenSnackbar(false)
          }, 1000);
        } else {
          setOpenErrDelLocSnackbar(true)
        }
      })
      .catch((error) => console.log(error));
  }
  return (
    <>
      <div className="container-fluid d-flex">
        <div className="content-wrapper">
          <div className="content-components-wrapper">
            <TopHeaderInternal
              title={!!tourId ? "Edit Tour" : `Create New Tour`}
              icons={<PersonAddIcon fontSize="large" />}
              linkToBackBtn={"/tours/all-tours"}
            />
            {fullScreenLoader ? <FullScreenLoader /> :
              <div className="user-content sub-page-details-wrapper mt-4">
                <div className="row">
                  <div className="col-xl-6 col-lg-7 col-md-9 col-sm-12">
                    {!tourId ?
                      <>
                        <h5 className="sub-page-title">
                          Tour Details In English
                        </h5>
                        <form
                          className="d-flex flex-column my-3"
                        >
                          <div className="create-tour-form around-border">
                            <div className="row">
                              <div className="col-4">
                                <p className="input-title">Title :</p>
                              </div>
                              <div className="col-8 mb-3">
                                <Input
                                  disableUnderline
                                  sx={{
                                    border: "1px solid #C2C8CC;",
                                    borderRadius: 1,
                                    padding: "8px 15px",
                                    width: "100%",
                                    maxWidth: "100%",
                                  }}
                                  className="table-inputs-content"
                                  size="small"
                                  placeholder="Enter Title"
                                  value={tourData?.title_en}
                                  onChange={(event) =>
                                    setTourData({
                                      ...tourData,
                                      title_en: event.target.value,
                                    })
                                  }
                                  error={titleError.status}
                                  helperText={titleError?.value}
                                />
                                <span
                                  className={
                                    !!tourErrors.title_en
                                      ? "d-block error-msg py-1"
                                      : `d-none`
                                  }
                                >
                                  {tourErrors.title_en}
                                </span>
                              </div>
                              <div className="col-4">
                                <p className="input-title">Description :</p>
                              </div>
                              <div className="col-8 mb-3">
                                <Input
                                  disableUnderline
                                  sx={{
                                    border: "1px solid #C2C8CC;",
                                    borderRadius: 1,
                                    padding: "8px 15px",
                                    width: "100%",
                                    maxWidth: "100%",
                                  }}
                                  className="table-inputs-content"
                                  id="description-textfield"
                                  placeholder="Description"
                                  multiline
                                  minRows={4}
                                  maxRows={6}
                                  variant="outlined"
                                  value={tourData?.description_en}
                                  onChange={(event) =>
                                    setTourData({
                                      ...tourData,
                                      description_en: event.target.value,
                                    })
                                  }
                                />
                                <span
                                  className={
                                    !!tourErrors.description_en
                                      ? "d-block error-msg py-1"
                                      : `d-none`
                                  }
                                >
                                  {tourErrors.description_en}
                                </span>
                              </div>
                              <div className="col-4">
                                <p className="input-title">Cover Image :</p>
                              </div>
                              <ImageField
                                image={tourData?.image}
                                handleChange={handleChangeCoverImg}
                                handleDrop={handleDropCoverImg}
                                errorFileUploader={errorFileUploader}
                                // submitError={errors.image?.[0]}
                                dragActive={dragActive}
                                setDragActive={setDragActive}
                              />

                              <div className="col-4">
                                <p className="input-title">Price (SAR) :</p>
                              </div>
                              <div className="col-8 mb-3">
                                <Input
                                  disableUnderline
                                  sx={{
                                    border: "1px solid #C2C8CC;",
                                    borderRadius: 1,
                                    padding: "8px 15px",
                                    width: "100%",
                                    maxWidth: "100%",
                                  }}
                                  className="table-inputs-content"
                                  size="small"
                                  placeholder="Enter Price"
                                  value={tourData?.price}
                                  onChange={(event) =>
                                    setTourData({
                                      ...tourData,
                                      price: event.target.value,
                                    })
                                  }
                                />
                                <span className="below-note text-start">If Price is 0 then it will show "Price on request"</span>
                                <span
                                  className={
                                    !!tourErrors.price
                                      ? "d-block error-msg py-1"
                                      : `d-none`
                                  }
                                >
                                  {tourErrors.price}
                                </span>
                              </div>
                              <div className="col-4">
                                <p className="input-title">
                                  Location :
                                </p>
                              </div>
                              <div className="col-8 mb-3">
                                <Autocomplete
                                  id="location"
                                  size="small"
                                  name="location"
                                  value={tourData?.location}
                                  onChange={(event, newValue) => {
                                    setTourData({
                                      ...tourData,
                                      location: newValue.location_en,
                                      location_id: newValue.id,
                                    });
                                    // console.log("while selecting the location", [{location: newValue.location_en,
                                    //   location_id: newValue.id,}] )
                                  }}
                                  options={locations}
                                  getOptionLabel={(option) =>
                                    option.location_en || option
                                  }
                                  isOptionEqualToValue={(option, value) =>
                                    option.location_en === value
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="Select Location of Departure"
                                      variant="outlined"
                                      style={{ maxWidth: "100%" }}
                                    />
                                  )}
                                />
                                {/* enable to handle multiple location selection <Autocomplete
                                  multiple
                                  limitTags={2}
                                  id="location"
                                  size="small"
                                  name="location"
                                  value={Array.isArray(tourData.location) ? tourData.location : []}
                                  onChange={(event, newValue) => {
                                    if (newValue) {
                                      const selectedIds = newValue.map((option) => option.id);
                                      const selectedLoc = newValue.map((option) => option.location_en)
                                      const handelLocationId = selectedIds.join(',');
                                      const handleLocationName = selectedLoc.join(',');

                                      setTourData({
                                        ...tourData,
                                        location: handleLocationName,
                                        location_id: handelLocationId,
                                      });

                                      console.log("while selecting the location", [{
                                        location: handleLocationName,
                                        location_id: handelLocationId,
                                      }]);
                                    } else {
                                      
                                    }
                                  }}
                                  options={locations}
                                  getOptionLabel={(option) => option.location_en}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="Select Location of Departure"
                                      InputLabelProps={{ shrink: true, }}
                                      variant="outlined"
                                      style={{ maxWidth: "100%" }}
                                    />
                                  )}
                                /> */}
                                <span
                                  className={
                                    !!tourErrors.location
                                      ? "d-block error-msg py-1"
                                      : `d-none`
                                  }
                                >
                                  {tourErrors.location}
                                </span>
                              </div>
                              <div className="col-4">
                                <p className="input-title">Category :</p>
                              </div>
                              <div className="col-8 mb-3">
                                <Autocomplete
                                  id="country"
                                  size="small"
                                  name="country"
                                  value={tourData?.category}
                                  onChange={(event, newValue) => {
                                    setTourData({
                                      ...tourData,
                                      category: newValue.title_en,
                                      category_id: newValue.id,
                                    });
                                  }}
                                  options={categories}
                                  getOptionLabel={(option) =>
                                    option.title_en || option
                                  }
                                  isOptionEqualToValue={(option, value) =>
                                    option.title_en === value
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="Select Tour Category"
                                      variant="outlined"
                                      style={{ maxWidth: "100%" }}
                                    />
                                  )}
                                />
                                <span
                                  className={
                                    !!tourErrors.category
                                      ? "d-block error-msg py-1"
                                      : `d-none`
                                  }
                                >
                                  {tourErrors.category}
                                </span>
                              </div>
                              <div className="col-4">
                                <p className="input-title">Select Date :</p>
                              </div>
                              <div className="col-8 mb-3">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DatePicker
                                    className="maxwidth-full"
                                    size="small"
                                    value={dayjs(tourData?.date || null)}
                                    onChange={(newValue) => {
                                      setTourData({
                                        ...tourData,
                                        date: newValue
                                          ? `${newValue.$M + 1}/${newValue.$D}/${newValue.$y
                                          }`
                                          : "",
                                      });
                                    }}
                                    slotProps={{
                                      textField: {
                                        size: "small",
                                      },
                                    }}
                                    minDate={dayjs().startOf('day')}
                                  />
                                </LocalizationProvider>
                                <span className="below-note text-start">If Date is not selected then it will show "Dates May vary"</span>
                                <span
                                  className={
                                    dateError.status
                                      ? "d-block error-msg py-1"
                                      : `d-none`
                                  }
                                >
                                  {dateError.value}
                                </span>
                              </div>
                              <div className="col-4">
                                <p className="input-title">Meta Title :</p>
                              </div>
                              <div className="col-8 mb-3">
                                <Input
                                  disableUnderline
                                  sx={{
                                    border: "1px solid #C2C8CC;",
                                    borderRadius: 1,
                                    padding: "8px 15px",
                                    width: "100%",
                                    maxWidth: "100%",
                                  }}
                                  className="table-inputs-content"
                                  size="small"
                                  placeholder="Enter Meta Title"
                                  value={tourData?.meta_title_en}
                                  onChange={(event) =>
                                    setTourData({
                                      ...tourData,
                                      meta_title_en: event.target.value,
                                    })
                                  }

                                />
                              </div>
                              <div className="col-4">
                                <p className="input-title">Meta Description :</p>
                              </div>
                              <div className="col-8 mb-3">
                                <Input
                                  disableUnderline
                                  sx={{
                                    border: "1px solid #C2C8CC;",
                                    borderRadius: 1,
                                    padding: "8px 15px",
                                    width: "100%",
                                    maxWidth: "100%",
                                  }}
                                  multiline
                                  maxRows={6}
                                  className="table-inputs-content"
                                  size="small"
                                  placeholder="Enter Meta Description"
                                  value={tourData?.meta_description_en}
                                  onChange={(event) =>
                                    setTourData({
                                      ...tourData,
                                      meta_description_en: event.target.value,
                                    })
                                  }

                                />
                              </div>
                              <div className="col-4">
                                <p className="input-title">Days :</p>
                              </div>
                              <div className="col-8">
                                <Input
                                  disableUnderline
                                  readOnly
                                  sx={{
                                    border: "1px solid #C2C8CC;",
                                    borderRadius: 1,
                                    padding: "8px 15px",
                                    width: "130px",
                                  }}
                                  className="table-inputs-content mb-3"
                                  type="number"
                                  size="small"
                                  value={tourData?.days}
                                  endAdornment={
                                    <InputAdornment className="create-tour-icon-wrapper">
                                      <IconButton
                                        aria-label="decrease"
                                        onClick={handleDecrement}
                                        edge="end"
                                        disabled={tourData?.days <= 1}
                                      >
                                        <RemoveIcon />
                                      </IconButton>
                                      <IconButton
                                        aria-label="increase"
                                        onClick={handleIncrement}
                                        edge="end"
                                      >
                                        <AddIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                  inputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={(e) =>
                                    setTourData({
                                      ...tourData,
                                      day: e.target.value,
                                    })
                                  }
                                />
                              </div>
                              <div className="col-4">
                                <p className="input-title">Is Active :</p>
                              </div>
                              <div className="col-8">
                                <Switch
                                  checked={tourData?.isPublished}
                                  onChange={handleToggle}
                                  className={`mb-3 ${tourData?.isPublished ? "thisActiveUser" : "thisInactiveUser"}`}
                                  // defaultChecked
                                  color="default"
                                />
                              </div>

                              {/* <div className="col-4">   
                              <p className="input-title">Online Booking :</p>
                            </div>
                            <div className="col-8">
                              <Switch
                                checked={tourData?.isPublished}
                                onChange={handleToggle}
                                className={`mb-3 ${tourData?.isPublished ? "thisActiveUser" : "thisInactiveUser"}`}
                                // defaultChecked
                                color="default"
                              />
                            </div> */}
                              <div className="col-4">
                                <p className="input-title">
                                  Add To Popular Tours :
                                </p>
                              </div>
                              <div className="col-8">
                                <Switch
                                  checked={tourData?.is_featured}
                                  onChange={handleTogglePopulerTour}
                                  className={`mb-3 ${tourData?.is_featured ? "thisActiveUser" : "thisInactiveUser"}`}
                                  // defaultChecked
                                  color="default"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="gallery-wrapper">
                            <h5 className="sub-page-title">Gallery</h5>
                            <div className="create-tour-form around-border">
                              <div className="row">
                                {/* <div className="col-4">
                                <p className="input-title">Gallery Image :</p>
                              </div> */}
                                {/* <div className="col-12"> */}

                                <ErrorBoundary>
                                  <ImageUploader
                                    files={gallerySelectedFiles}
                                    onFilesSelected={setGallerySelectedFiles}
                                    setGalleryDeletedFiles={
                                      setGalleryDeletedFiles
                                    }
                                    galleryDeletedFiles={galleryDeletedFiles}
                                  />
                                </ErrorBoundary>

                                {/* </div> */}
                              </div>
                            </div>
                          </div>

                          {Array.from(
                            { length: tourData?.days },
                            (_, index) => index
                          ).map((index) => (
                            <div key={index}>
                              <h5 key={index} className="sub-page-title">
                                Day {index + 1}
                              </h5>
                              <div className="create-tour-form around-border">
                                <div className="days-wrapper">
                                  <div className="row">
                                    <div className="col-4">
                                      <p className="input-title">Title :</p>
                                    </div>
                                    <div className="col-8 mb-3">
                                      <Input
                                        disableUnderline
                                        sx={{
                                          border: "1px solid #C2C8CC;",
                                          borderRadius: 1,
                                          padding: "8px 15px",
                                          width: "100%",
                                          maxWidth: "100%",
                                        }}
                                        className="table-inputs-content"
                                        size="small"
                                        placeholder="Enter Title"
                                        value={itinerary[index]?.title_en}
                                        onChange={(event) =>
                                          handleItineraryUpdate(
                                            event.target.value,
                                            "title_en",
                                            index
                                          )
                                        }
                                        error={!!itineraryErrors[index]?.title_en}
                                        helperText={
                                          !!itineraryErrors[index]?.title_en
                                            ? itineraryErrors[index].title_en
                                            : ""
                                        }
                                      />
                                      <span
                                        className={
                                          !!itineraryErrors[index]?.title_en
                                            ? "d-block error-msg py-1"
                                            : `d-none`
                                        }
                                      >
                                        {itineraryErrors[index]?.title_en}
                                      </span>
                                    </div>
                                    <div className="col-4">
                                      <p className="input-title">Description :</p>
                                    </div>
                                    <div className="col-8 mb-3">

                                      <CKEditor
                                        editor={ClassicEditor}
                                        data={itinerary[index]?.description_en}
                                        onReady={editor => {
                                          // You can store the "editor" and use when it is needed.
                                          // console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => {
                                          const data = editor.getData();
                                          handleItineraryUpdate(
                                            data,
                                            "description_en",
                                            index
                                          )

                                        }}
                                        config={{
                                          toolbar: {
                                            items: ['bold', 'italic', '|', 'numberedList', 'bulletedList'],
                                          },
                                        }}
                                      />
                                      <span
                                        className={
                                          !!itineraryErrors[index]?.description_en
                                            ? "d-block error-msg py-1"
                                            : `d-none`
                                        }
                                      >
                                        {itineraryErrors[index]?.description_en}
                                      </span>
                                    </div>
                                    <div className="col-4">
                                      <p className="input-title">
                                        Image :
                                      </p>
                                    </div>
                                    <div className="col-8 mb-3">
                                      <div className="location-img-wrap">
                                        {itinerary[index]?.image ? <button className="remove-button-location-img" onClick={(event) => handleRemoveImage(event, index, itinerary[index].id)}>Remove</button> : ''}
                                        <input
                                          ref={(node) => {
                                            const map = getMap();
                                            if (node) {
                                              map.set(index, node);
                                            } else {
                                              map.delete(index);
                                            }
                                          }}
                                          type="file"
                                          accept="image/png, image/jpeg, image/gif"
                                          onChange={(e) =>
                                            handleChange(e, "image", index)
                                          }
                                          style={{ display: "none" }}
                                        />
                                        <label
                                          htmlFor="input-file-upload"
                                          className={
                                            dragActive
                                              ? "drag-active label-file-upload "
                                              : "label-file-upload"
                                          }
                                          onDragEnter={handleDrag}
                                          onDragLeave={handleDrag}
                                          onDragOver={handleDrag}
                                          onDrop={(e) =>
                                            handleDrop((e, "image", index))
                                          }
                                        >
                                          {itinerary[index]?.image &&
                                            typeof itinerary[index]?.image ===
                                            "object" ? (
                                            <Box sx={{ my: 2 }}>
                                              <img
                                                src={URL.createObjectURL(
                                                  itinerary[index].image
                                                )}
                                                alt="Uploaded file"
                                                style={{ maxWidth: "390px" }}
                                              />
                                              <p className="pt-2">
                                                Selected file:
                                                {itinerary[index].image.name}
                                              </p>
                                            </Box>
                                          ) : itinerary[index]?.image ? (
                                            <Box sx={{ my: 2 }}>
                                              <img
                                                src={
                                                  config.imageBaseURL +
                                                  itinerary[index].image
                                                }
                                                alt="Uploaded file"
                                                style={{ maxWidth: "450px" }}
                                              />
                                              <p>
                                                Selected file:{" "}
                                                {itinerary[index].image.name}
                                              </p>
                                            </Box>
                                          ) : (
                                            <></>
                                          )}
                                          <div className="text-center">
                                            <span className="m-0 below-note">
                                              Drag and drop your image here or
                                            </span>
                                            <Button
                                              className="upload-button"
                                              variant="text"
                                              component="span"
                                              color="secondary"
                                              onClick={(e) =>
                                                onButtonClick(e, index)
                                              }
                                            >
                                              Click here To Upload a Image
                                            </Button>
                                          </div>
                                          <span className="below-note">Maximun Image size is 5MB*</span>
                                        </label>
                                        {dragActive && (
                                          <div
                                            id="drag-file-element"
                                            onDragEnter={handleDrag}
                                            onDragLeave={handleDrag}
                                            onDragOver={handleDrag}
                                            onDrop={(e) =>
                                              handleDrop((e, "image", index))
                                            }
                                          ></div>
                                        )}
                                        {errorFileUploaderLoc && (
                                          <span className="error-msg">Invalid file type or size</span>
                                        )}
                                      </div>

                                      <span
                                        className={
                                          !!itineraryErrors.image
                                            ? "d-block error-msg py-1"
                                            : `d-none`
                                        }
                                      >
                                        {itineraryErrors.image}
                                      </span>
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                          <div className="inclusion-exclusion-wrapper">
                            <h5 className="sub-page-title">
                              Additional Information{" "}
                            </h5>
                            <div className="create-tour-form around-border">
                              <div className="row">
                                <div className="col-4">
                                  <p className="input-title">Inclusions :</p>
                                </div>
                                <div className="col-8">
                                  <ul className="inclusion-exclusion-list">
                                    {additionalInfo?.inclusionArr?.map(
                                      (inclusion, index) => (
                                        <li>
                                          <input
                                            className="inclusion-exclusion-checkbox"
                                            type="checkbox"
                                            checked={
                                              additionalInfo.inclusionArr[index]
                                                .selected
                                            }
                                            onChange={() =>
                                              toggleInclusions(index)
                                            }
                                          />
                                          <span className="inclusion-exclution-text">
                                            {inclusion.name_en}
                                          </span>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-4">
                                  <p className="input-title">Exclusion :</p>
                                </div>
                                <div className="col-8">
                                  <ul className="inclusion-exclusion-list">
                                    {additionalInfo?.exclusionArr?.map(
                                      (exclusion, index) => (
                                        <li>
                                          <input
                                            className="inclusion-exclusion-checkbox"
                                            id={index}
                                            type="checkbox"
                                            // value={exclusions.selected}
                                            checked={exclusion.selected}
                                            onChange={() =>
                                              toggleExclusions(index)
                                            }
                                          />
                                          <span className="inclusion-exclution-text">
                                            {exclusion.name_en}
                                          </span>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="privacy-policy-wrapper">
                            <h5 className="sub-page-title">
                              Policies{" "}
                            </h5>
                            <div className="create-tour-form around-border">
                              <div className="row">
                                <div className="col-12">
                                  <ReactQuill
                                    className="create-blog-description-wrapper"
                                    value={tourData.payment_policy_en}
                                    onChange={(value) =>
                                      setTourData({
                                        ...tourData,
                                        payment_policy_en: value,
                                      })
                                    }
                                    modules={modules}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </>
                      : ''}

                    {tourId ? (
                      <TabContext value={value}>
                        <Tabs
                          value={value}
                          onChange={handleTabChange}
                          variant="scrollable"
                          scrollButtons
                          allowScrollButtonsMobile
                          aria-label="nav tabs example"
                        >
                          <Tab label="English" value="1" />
                          <Tab label="Arabic" value="2" />
                          {/* <Tab label="Italian" value="3" /> */}
                          {/* <Tab label="Japanese" value="4" /> */}
                        </Tabs>

                        <TabPanel className="p-3" value="1">
                          <h5 className="sub-page-title">
                            Tour Details In English
                          </h5>
                          <form
                            className="d-flex flex-column my-3"
                          >
                            <div className="create-tour-form around-border">
                              <div className="row">
                                <div className="col-4">
                                  <p className="input-title">Title :</p>
                                </div>
                                <div className="col-8 mb-3">
                                  <Input
                                    disableUnderline
                                    sx={{
                                      border: "1px solid #C2C8CC;",
                                      borderRadius: 1,
                                      padding: "8px 15px",
                                      width: "100%",
                                      maxWidth: "100%",
                                    }}
                                    className="table-inputs-content"
                                    size="small"
                                    placeholder="Enter Title"
                                    value={tourData?.title_en}
                                    onChange={(event) =>
                                      setTourData({
                                        ...tourData,
                                        title_en: event.target.value,
                                      })
                                    }
                                    error={titleError.status}
                                    helperText={titleError?.value}
                                  />
                                  <span
                                    className={
                                      !!tourErrors.title_en
                                        ? "d-block error-msg py-1"
                                        : `d-none`
                                    }
                                  >
                                    {tourErrors.title_en}
                                  </span>
                                </div>
                                <div className="col-4">
                                  <p className="input-title">Description :</p>
                                </div>
                                <div className="col-8 mb-3">
                                  <Input
                                    disableUnderline
                                    sx={{
                                      border: "1px solid #C2C8CC;",
                                      borderRadius: 1,
                                      padding: "8px 15px",
                                      width: "100%",
                                      maxWidth: "100%",
                                    }}
                                    className="table-inputs-content"
                                    id="description-textfield"
                                    placeholder="Description"
                                    multiline
                                    minRows={4}
                                    maxRows={6}
                                    variant="outlined"
                                    value={tourData?.description_en}
                                    onChange={(event) =>
                                      setTourData({
                                        ...tourData,
                                        description_en: event.target.value,
                                      })
                                    }
                                  />
                                  <span
                                    className={
                                      !!tourErrors.description_en
                                        ? "d-block error-msg py-1"
                                        : `d-none`
                                    }
                                  >
                                    {tourErrors.description_en}
                                  </span>
                                </div>
                                <div className="col-4">
                                  <p className="input-title">Cover Image :</p>
                                </div>
                                <ImageField
                                  image={tourData?.image}
                                  handleChange={handleChangeCoverImg}
                                  handleDrop={handleDropCoverImg}
                                  errorFileUploader={errorFileUploader}
                                  // submitError={errors.image?.[0]}
                                  dragActive={dragActive}
                                  setDragActive={setDragActive}
                                />

                                <div className="col-4">
                                  <p className="input-title">Price (SAR) :</p>
                                </div>
                                <div className="col-8 mb-3">
                                  <Input
                                    disableUnderline
                                    sx={{
                                      border: "1px solid #C2C8CC;",
                                      borderRadius: 1,
                                      padding: "8px 15px",
                                      width: "100%",
                                      maxWidth: "100%",
                                    }}
                                    className="table-inputs-content"
                                    size="small"
                                    placeholder="Enter Price"
                                    value={tourData?.price}
                                    onChange={(event) =>
                                      setTourData({
                                        ...tourData,
                                        price: event.target.value,
                                      })
                                    }
                                  />
                                  <span className="below-note text-start">If Price is 0 then it will show "Price on request"</span>
                                  <span
                                    className={
                                      !!tourErrors.price
                                        ? "d-block error-msg py-1"
                                        : `d-none`
                                    }
                                  >
                                    {tourErrors.price}
                                  </span>
                                </div>
                                <div className="col-4">
                                  <p className="input-title">
                                    Location :
                                  </p>
                                </div>
                                <div className="col-8 mb-3">
                                  <Autocomplete
                                    id="location"
                                    size="small"
                                    name="location"
                                    value={tourData?.location}
                                    onChange={(event, newValue) => {
                                      setTourData({
                                        ...tourData,
                                        location: newValue.location_en,
                                        location_id: newValue.id,
                                      });
                                    }}
                                    options={locations}
                                    getOptionLabel={(option) =>
                                      option.location_en || option
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                      option.location_en === value
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Select Location of Departure"
                                        variant="outlined"
                                        style={{ maxWidth: "100%" }}
                                      />
                                    )}
                                  />
                                  <span
                                    className={
                                      !!tourErrors.location
                                        ? "d-block error-msg py-1"
                                        : `d-none`
                                    }
                                  >
                                    {tourErrors.location}
                                  </span>
                                </div>
                                <div className="col-4">
                                  <p className="input-title">Category :</p>
                                </div>
                                <div className="col-8 mb-3">
                                  <Autocomplete
                                    id="country"
                                    size="small"
                                    name="country"
                                    value={tourData?.category}
                                    onChange={(event, newValue) => {
                                      setTourData({
                                        ...tourData,
                                        category: newValue.title_en,
                                        category_id: newValue.id,
                                      });
                                    }}
                                    options={categories}
                                    getOptionLabel={(option) =>
                                      option.title_en || option
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                      option.title_en === value
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Select Tour Category"
                                        variant="outlined"
                                        style={{ maxWidth: "100%" }}
                                      />
                                    )}
                                  />
                                  <span
                                    className={
                                      !!tourErrors.category
                                        ? "d-block error-msg py-1"
                                        : `d-none`
                                    }
                                  >
                                    {tourErrors.category}
                                  </span>
                                </div>
                                <div className="col-4">
                                  <p className="input-title">Select Date :</p>
                                </div>
                                <div className="col-8 mb-3">
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                      className="maxwidth-full"
                                      size="small"
                                      value={dayjs(tourData?.date || null)}
                                      onChange={(newValue) => {
                                        setTourData({
                                          ...tourData,
                                          date: newValue
                                            ? `${newValue.$M + 1}/${newValue.$D}/${newValue.$y
                                            }`
                                            : "",
                                        });
                                      }}
                                      slotProps={{
                                        textField: {
                                          size: "small",
                                        },
                                      }}
                                      minDate={dayjs().startOf('day')}
                                    />
                                  </LocalizationProvider>
                                  <span className="below-note text-start">If Date is not selected then it will show "Dates May vary"</span>
                                  <span
                                    className={
                                      dateError.status
                                        ? "d-block error-msg py-1"
                                        : `d-none`
                                    }
                                  >
                                    {dateError.value}
                                  </span>
                                </div>
                                <div className="col-4">
                                  <p className="input-title">Meta Title :</p>
                                </div>
                                <div className="col-8 mb-3">
                                  <Input
                                    disableUnderline
                                    sx={{
                                      border: "1px solid #C2C8CC;",
                                      borderRadius: 1,
                                      padding: "8px 15px",
                                      width: "100%",
                                      maxWidth: "100%",
                                    }}
                                    className="table-inputs-content"
                                    size="small"
                                    placeholder="Enter Meta Title"
                                    value={tourData?.meta_title_en}
                                    onChange={(event) =>
                                      setTourData({
                                        ...tourData,
                                        meta_title_en: event.target.value,
                                      })
                                    }

                                  />
                                </div>
                                <div className="col-4">
                                  <p className="input-title">Meta Description :</p>
                                </div>
                                <div className="col-8 mb-3">
                                  <Input
                                    disableUnderline
                                    sx={{
                                      border: "1px solid #C2C8CC;",
                                      borderRadius: 1,
                                      padding: "8px 15px",
                                      width: "100%",
                                      maxWidth: "100%",
                                    }}
                                    multiline
                                    maxRows={6}
                                    className="table-inputs-content"
                                    size="small"
                                    placeholder="Enter Meta Description"
                                    value={tourData?.meta_description_en}
                                    onChange={(event) =>
                                      setTourData({
                                        ...tourData,
                                        meta_description_en: event.target.value,
                                      })
                                    }

                                  />
                                </div>
                                <div className="col-4">
                                  <p className="input-title">Days :</p>
                                </div>
                                <div className="col-8">
                                  <Input
                                    disableUnderline
                                    readOnly
                                    sx={{
                                      border: "1px solid #C2C8CC;",
                                      borderRadius: 1,
                                      padding: "8px 15px",
                                      width: "130px",
                                    }}
                                    className="table-inputs-content mb-3"
                                    type="number"
                                    size="small"
                                    value={tourData?.days}
                                    endAdornment={
                                      <InputAdornment className="create-tour-icon-wrapper">
                                        <IconButton
                                          aria-label="decrease"
                                          onClick={handleDecrement}
                                          edge="end"
                                          disabled={tourData?.days === tourData?.days || tourData?.days <= 1}
                                        >
                                          <RemoveIcon />
                                        </IconButton>
                                        <IconButton
                                          aria-label="increase"
                                          onClick={handleIncrement}
                                          edge="end"
                                          disabled={tourData?.days === tourData?.days}
                                        >
                                          <AddIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    }
                                    inputlabelprops={{
                                      shrink: true,
                                    }}
                                    onChange={(e) =>
                                      setTourData({
                                        ...tourData,
                                        day: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                                <div className="col-4">
                                  <p className="input-title">Is Active :</p>
                                </div>
                                <div className="col-8">
                                  <Switch
                                    checked={tourData?.isPublished}
                                    onChange={handleToggle}
                                    className={`mb-3 ${tourData?.isPublished ? "thisActiveUser" : "thisInactiveUser"}`}
                                    // defaultChecked
                                    color="default"
                                  />
                                </div>

                                <div className="col-4">
                                  <p className="input-title">
                                    Add To Popular Tours :
                                  </p>
                                </div>
                                <div className="col-8">
                                  <Switch
                                    checked={tourData?.is_featured}
                                    onChange={handleTogglePopulerTour}
                                    className={`mb-3 ${tourData?.is_featured ? "thisActiveUser" : "thisInactiveUser"}`}
                                    // defaultChecked
                                    color="default"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="gallery-wrapper">
                              <h5 className="sub-page-title">Gallery</h5>
                              <div className="create-tour-form around-border">
                                <div className="row">


                                  <ErrorBoundary>
                                    <ImageUploader
                                      files={gallerySelectedFiles}
                                      onFilesSelected={setGallerySelectedFiles}
                                      setGalleryDeletedFiles={
                                        setGalleryDeletedFiles
                                      }
                                      galleryDeletedFiles={galleryDeletedFiles}
                                    />
                                  </ErrorBoundary>


                                </div>
                              </div>
                            </div>

                            {itinerary.length > 0 ? <>
                              {Array.from(
                                { length: tourData?.days },
                                (_, index) => index
                              ).map((index) => (

                                <div key={index}>
                                  <h5 key={index} className="sub-page-title">
                                    Day {index + 1}
                                  </h5>
                                  <div className="create-tour-form around-border">
                                    <div className="days-wrapper">
                                      <div className="row">
                                        <div className="col-4">
                                          <p className="input-title">Title :</p>
                                        </div>
                                        <div className="col-8 mb-3">
                                          <Input
                                            disableUnderline
                                            sx={{
                                              border: "1px solid #C2C8CC;",
                                              borderRadius: 1,
                                              padding: "8px 15px",
                                              width: "100%",
                                              maxWidth: "100%",
                                            }}
                                            className="table-inputs-content"
                                            size="small"
                                            placeholder="Enter Title"
                                            value={itinerary[index]?.title_en}
                                            onChange={(event) => {
                                              handleItineraryUpdate(
                                                event.target.value,
                                                "title_en",
                                                index
                                              )
                                              // console.log("handle the title_en", event.target.value, index, itinerary[index]?.title_en)
                                            }
                                            }

                                          />
                                          <span
                                            className={
                                              !!itineraryErrors[index]?.title_en
                                                ? "d-block error-msg py-1"
                                                : `d-none`
                                            }
                                          >
                                            {itineraryErrors[index]?.title_en}
                                          </span>
                                        </div>
                                        <div className="col-4">
                                          <p className="input-title">Description :</p>
                                        </div>
                                        <div className="col-8 mb-3">

                                          {/* <Input
                                            disableUnderline
                                            sx={{
                                              border: "1px solid #C2C8CC;",
                                              borderRadius: 1,
                                              padding: "8px 15px",
                                              width: "100%",
                                              maxWidth: "100%",
                                            }}
                                            className="create-blog-description-wrapper"
                                            // id="description-textfield"
                                            placeholder="Description"
                                            multiline
                                            minRows={4}
                                            maxRows={6}
                                            variant="outlined"
                                            value={itinerary[index]?.description_en}
                                            onChange={(event) => {
                                              handleItineraryUpdate(
                                                event.target.value,
                                                "description_en",
                                                index
                                              )
                                              console.log("description_en", index, event.target.value )
                                            }
                                            }
                                          /> */}

                                          <CKEditor
                                            editor={ClassicEditor}
                                            data={itinerary[index]?.description_en}
                                            onReady={editor => {
                                              // You can store the "editor" and use when it is needed.
                                              // console.log('Editor is ready to use!', editor);
                                            }}
                                            onChange={(event, editor) => {
                                              const data = editor.getData();
                                              handleItineraryUpdate(
                                                data,
                                                "description_en",
                                                index
                                              )

                                            }}
                                            config={{
                                              toolbar: {
                                                items: ['bold', 'italic', '|', 'numberedList', 'bulletedList'],
                                              },
                                            }}
                                          />

                                          <span
                                            className={
                                              !!itineraryErrors[index]?.description_en
                                                ? "d-block error-msg py-1"
                                                : `d-none`
                                            }
                                          >
                                            {itineraryErrors[index]?.description_en}
                                          </span>
                                        </div>
                                        <div className="col-4">
                                          <p className="input-title">
                                            Image :
                                          </p>
                                        </div>
                                        <div className="col-8 mb-3">
                                          <div className="location-img-wrap">

                                            <input
                                              ref={(node) => {
                                                const map = getMap();
                                                if (node) {
                                                  map.set(index, node);
                                                } else {
                                                  map.delete(index);
                                                }
                                              }}
                                              type="file"
                                              accept="image/png, image/jpeg, image/gif"
                                              onChange={(e) =>
                                                handleChange(e, "image", index)
                                              }
                                              style={{ display: "none" }}
                                            />
                                            {itinerary[index]?.image ? <button className="remove-button-location-img" onClick={(event) => handleRemoveImage(event, index, itinerary[index].id)}>Remove</button> : ''}
                                            <label
                                              htmlFor="input-file-upload"
                                              className={
                                                dragActive
                                                  ? "drag-active label-file-upload "
                                                  : "label-file-upload"
                                              }
                                              onDragEnter={handleDrag}
                                              onDragLeave={handleDrag}
                                              onDragOver={handleDrag}
                                              onDrop={(e) =>
                                                handleDrop((e, "image", index))
                                              }
                                            >

                                              {itinerary[index]?.image &&
                                                typeof itinerary[index]?.image ===
                                                "object" ? (
                                                <Box sx={{ my: 2 }}>
                                                  <img
                                                    src={URL.createObjectURL(
                                                      itinerary[index].image
                                                    )}
                                                    alt="Uploaded file"
                                                    style={{ maxWidth: "390px" }}
                                                  />
                                                  <p className="pt-2">
                                                    Selected file:
                                                    {itinerary[index].image.name}
                                                  </p>
                                                </Box>
                                              ) : itinerary[index]?.image ? (
                                                <Box sx={{ my: 2 }}>
                                                  <img
                                                    src={
                                                      config.imageBaseURL +
                                                      itinerary[index].image
                                                    }
                                                    alt="Uploaded file"
                                                    style={{ maxWidth: "450px" }}
                                                  />
                                                  <p>
                                                    Selected file:{" "}
                                                    {itinerary[index].image.name}
                                                  </p>
                                                </Box>
                                              ) : (
                                                <></>
                                              )}
                                              <div className="text-center">
                                                <span className="m-0 below-note">
                                                  Drag and drop your image here or
                                                </span>
                                                <Button
                                                  className="upload-button"
                                                  variant="text"
                                                  component="span"
                                                  color="secondary"
                                                  onClick={(e) =>
                                                    onButtonClick(e, index)
                                                  }
                                                >
                                                  Click here To Upload a Image
                                                </Button>
                                              </div>
                                              <span className="below-note">Maximun Image size is 5MB*</span>
                                            </label>
                                            {dragActive && (
                                              <div
                                                id="drag-file-element"
                                                onDragEnter={handleDrag}
                                                onDragLeave={handleDrag}
                                                onDragOver={handleDrag}
                                                onDrop={(e) =>
                                                  handleDrop((e, "image", index))
                                                }
                                              ></div>
                                            )}
                                            {errorFileUploaderLoc && (
                                              <span className="error-msg">Invalid file type or size</span>
                                            )}
                                          </div>

                                          <span
                                            className={
                                              !!itineraryErrors.image
                                                ? "d-block error-msg py-1"
                                                : `d-none`
                                            }
                                          >
                                            {itineraryErrors.image}
                                          </span>
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </> : ''}

                            <div className="inclusion-exclusion-wrapper">
                              <h5 className="sub-page-title">
                                Additional Information{" "}
                              </h5>
                              <div className="create-tour-form around-border">
                                <div className="row">
                                  <div className="col-4">
                                    <p className="input-title">Inclusions :</p>
                                  </div>
                                  <div className="col-8">
                                    <ul className="inclusion-exclusion-list">
                                      {additionalInfo?.inclusionArr?.map(
                                        (inclusion, index) => (
                                          <li>
                                            <input
                                              className="inclusion-exclusion-checkbox"
                                              type="checkbox"
                                              checked={
                                                additionalInfo.inclusionArr[index]
                                                  .selected
                                              }
                                              onChange={() =>
                                                toggleInclusions(index)
                                              }
                                            />
                                            <span className="inclusion-exclution-text">
                                              {inclusion.name_en}
                                            </span>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-4">
                                    <p className="input-title">Exclusion :</p>
                                  </div>
                                  <div className="col-8">
                                    <ul className="inclusion-exclusion-list">
                                      {additionalInfo?.exclusionArr?.map(
                                        (exclusion, index) => (
                                          <li>
                                            <input
                                              className="inclusion-exclusion-checkbox"
                                              id={index}
                                              type="checkbox"
                                              // value={exclusions.selected}
                                              checked={exclusion.selected}
                                              onChange={() =>
                                                toggleExclusions(index)
                                              }
                                            />
                                            <span className="inclusion-exclution-text">
                                              {exclusion.name_en}
                                            </span>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="privacy-policy-wrapper">
                              <h5 className="sub-page-title">
                                Policies{" "}
                              </h5>
                              <div className="create-tour-form around-border">
                                <div className="row">
                                  <div className="col-12">
                                    <ReactQuill
                                      className="create-blog-description-wrapper"
                                      value={tourData.payment_policy_en}
                                      onChange={(value) =>
                                        setTourData({
                                          ...tourData,
                                          payment_policy_en: value,
                                        })
                                      }
                                      modules={modules}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </TabPanel>

                        <TabPanel className="p-3" value="2">
                          <h5 className="sub-page-title">Tour Details In Arabic</h5>
                          <div className="create-tour-form arabic-data around-border">
                            <div className="row">
                              <div className="col-4">
                                <p className="input-title">Title:</p>
                              </div>
                              <div className="col-8 mb-3">
                                <Input
                                  disableUnderline
                                  sx={{
                                    border: "1px solid #C2C8CC;",
                                    borderRadius: 1,
                                    padding: "8px 15px",
                                    width: "100%",
                                    maxWidth: "100%",
                                  }}
                                  className="table-inputs-content"
                                  size="small"
                                  placeholder="Tour Title In Arabic"
                                  value={tourData?.title_ar}
                                  onChange={(event) =>
                                    setTourData({
                                      ...tourData,
                                      title_ar: event.target.value,
                                    })
                                  }
                                  error={titleError.status}
                                  helperText={titleError?.value}
                                />
                                <span
                                  className={
                                    !!tourErrors?.title_ar
                                      ? "d-block error-msg py-1"
                                      : `d-none`
                                  }
                                >
                                  {tourErrors?.title_ar}
                                </span>
                              </div>
                              <div className="col-4">
                                <p className="input-title">Description:</p>
                              </div>
                              <div className="col-8 mb-3">
                                <Input
                                  disableUnderline
                                  sx={{
                                    border: "1px solid #C2C8CC;",
                                    borderRadius: 1,
                                    padding: "8px 15px",
                                    width: "100%",
                                    maxWidth: "100%",
                                  }}
                                  className="table-inputs-content"
                                  id="description-textfield"
                                  placeholder="Tour Description In Arabic"
                                  multiline
                                  minRows={4}
                                  maxRows={6}
                                  variant="outlined"
                                  value={tourData?.description_ar}
                                  onChange={(event) =>
                                    setTourData({
                                      ...tourData,
                                      description_ar: event.target.value,
                                    })
                                  }
                                />
                                <span
                                  className={
                                    !!tourErrors?.description_ar
                                      ? "d-block error-msg py-1"
                                      : `d-none`
                                  }
                                >
                                  {tourErrors?.description_ar}
                                </span>
                              </div>
                              <div className="col-4">
                                <p className="input-title">Meta Title :</p>
                              </div>
                              <div className="col-8 mb-3">
                                <Input
                                  disableUnderline
                                  sx={{
                                    border: "1px solid #C2C8CC;",
                                    borderRadius: 1,
                                    padding: "8px 15px",
                                    width: "100%",
                                    maxWidth: "100%",
                                  }}
                                  className="table-inputs-content"
                                  size="small"
                                  placeholder="Enter Meta Title"
                                  value={tourData?.meta_title_ar}
                                  onChange={(event) =>
                                    setTourData({
                                      ...tourData,
                                      meta_title_ar: event.target.value,
                                    })
                                  }

                                />
                              </div>
                              <div className="col-4">
                                <p className="input-title">Meta Description :</p>
                              </div>
                              <div className="col-8 mb-3">
                                <Input
                                  disableUnderline
                                  sx={{
                                    border: "1px solid #C2C8CC;",
                                    borderRadius: 1,
                                    padding: "8px 15px",
                                    width: "100%",
                                    maxWidth: "100%",
                                  }}
                                  multiline
                                  maxRows={6}
                                  className="table-inputs-content"
                                  size="small"
                                  placeholder="Enter Meta Description"
                                  value={tourData?.meta_description_ar}
                                  onChange={(event) =>
                                    setTourData({
                                      ...tourData,
                                      meta_description_ar: event.target.value,
                                    })
                                  }

                                />
                              </div>
                            </div>
                          </div>

                          <div className="daily-data-wrapper">
                            <div className="row arabic-details">
                              {itinerary ? (
                                itinerary?.map((dataDays, index) => (
                                  <div key={index}>
                                    <h5 key={index} className="sub-page-title">
                                      Day {index + 1}
                                    </h5>
                                    <div className="create-tour-form around-border">
                                      <div className="days-wrapper">
                                        <div className="row">
                                          <div className="col-4">
                                            <p className="input-title">Title:</p>
                                          </div>
                                          <div className="col-8 mb-3">
                                            <Input
                                              disableUnderline
                                              sx={{
                                                border: "1px solid #C2C8CC;",
                                                borderRadius: 1,
                                                padding: "8px 15px",
                                                width: "100%",
                                                maxWidth: "100%",
                                              }}
                                              className="table-inputs-content"
                                              size="small"
                                              placeholder="Enter Title"
                                              value={itinerary[index]?.title_ar}
                                              onChange={(event) =>
                                                handleItineraryUpdate(
                                                  event.target.value,
                                                  "title_ar",
                                                  index
                                                )
                                              }
                                              error={titleError}
                                              helperText={
                                                titleError
                                                  ? "Title is required"
                                                  : ""
                                              }
                                            />
                                            <span
                                              className={
                                                !!itineraryErrors[index]?.title_ar
                                                  ? "d-block error-msg py-1"
                                                  : `d-none`
                                              }
                                            >
                                              {itineraryErrors[index]?.title_ar}
                                            </span>
                                          </div>
                                          <div className="col-4">
                                            <p className="input-title">
                                              Description :
                                            </p>
                                          </div>
                                          <div className="col-8 mb-3">
                                            <CKEditor
                                              editor={ClassicEditor}
                                              data={itinerary[index]?.description_ar}
                                              onReady={editor => {
                                                // You can store the "editor" and use when it is needed.
                                                // console.log('Editor is ready to use! AR', index);
                                              }}
                                              onChange={(event, editor) => {
                                                const data = editor.getData();
                                                handleItineraryUpdate(
                                                  data,
                                                  "description_ar",
                                                  index
                                                )

                                              }}

                                              config={{
                                                toolbar: {
                                                  items: ['bold', 'italic', '|', 'numberedList', 'bulletedList'],
                                                },
                                              }}
                                            />


                                            <span
                                              className={
                                                !!itineraryErrors[index]
                                                  ?.description_ar
                                                  ? "d-block error-msg py-1"
                                                  : `d-none`
                                              }
                                            >
                                              {
                                                itineraryErrors[index]
                                                  ?.description_ar
                                              }
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <p>Arabic Daily Data Loading</p>
                              )}
                            </div>
                          </div>
                          <div className="privacy-policy-wrapper">
                            <h5 className="sub-page-title">
                              Policies{" "}
                            </h5>
                            <div className="create-tour-form around-border">
                              <div className="row">
                                <div className="col-12">
                                  <ReactQuill
                                    className="create-blog-description-wrapper"
                                    value={tourData.payment_policy_ar}
                                    onChange={(value) =>
                                      setTourData({
                                        ...tourData,
                                        payment_policy_ar: value,
                                      })
                                    }
                                    modules={modules}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPanel>


                      </TabContext>
                    ) : (
                      <></>
                    )}
                    <div className="col-12 d-flex justify-content-end mb-4">
                      {/* <button
                          type="reset"
                          variant="contained"
                          className="SecondaryButton me-3"
                          onClick={handleReset}
                        >
                          Reset Form
                        </button> */}
                      <button
                        type="submit"
                        variant="contained"
                        className={`PrimaryButton ${buttonloader ? 'button-loader' : ' '}`}
                        onClick={handleSubmit}
                      >
                        {!buttonloader ? !tourId ? "Create Tour" : "Update Tour" : ''}
                      </button>
                    </div>

                    <Snackbar
                      open={openSnackbar}
                      autoHideDuration={5000}
                      onClose={() => setOpenSnackbar(false)}
                      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                      <Alert

                        severity="success"
                        sx={{ width: "100%" }}
                      >
                        {successMsg}

                      </Alert>
                    </Snackbar>
                    <Snackbar
                      open={errorSnackbarOpen}
                      autoHideDuration={5000}
                      onClose={() => setErrorSnackbarOpen(false)}
                      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                      <Alert

                        severity="error"
                        sx={{ width: "100%" }}
                      >
                        Created/Edit Tour Failed!
                      </Alert>
                    </Snackbar>
                    <Snackbar
                      open={validationSnackbar}
                      autoHideDuration={5000}
                      onClose={() => setValidationSnackbar(false)}
                      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                      <Alert

                        severity="error"
                        sx={{ width: "100%" }}
                      >
                        {validationMessage || "Itinerary fields missing!"}
                      </Alert>
                    </Snackbar>

                    <Snackbar
                      open={openErrDelLocSnackbar}
                      autoHideDuration={5000}
                      onClose={() => setOpenErrDelLocSnackbar(false)}
                      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                      <Alert

                        severity="error"
                        sx={{ width: "100%" }}
                      >
                        Error Deleting Location Image

                      </Alert>
                    </Snackbar>

                  </div>
                  {showHideGoTopBtn ? <div className="back-to-top-button-wrapper fixed-bottom pe-5 pb-5" style={{ width: "fit-content", marginLeft: "auto" }}>
                    <button className="back-to-top-button" onClick={handleGoToTop}><ArrowCircleUpIcon /></button>
                  </div>
                    : ''}
                </div>
              </div>
            }
          </div>

        </div>
      </div>
    </>
  );
}
