import React, { Component } from 'react'
import Sidebar from '../Layout/Sidebar/Sidebar'
import TopHeaderMain from '../Layout/TopHeader/TopHeaderMain'
import UsersTable from './Users/UsersTable'
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import EditUser from './Users/components/EditUsers';


export default class MainPages extends Component {
    render() {
        return (
            <>
                <div className="container-fluid d-flex">
                 
                    <div className="content-wrapper">
                        <div className="content-components-wrapper">
                            <UsersTable />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}   
