import React, { useState, useEffect } from "react";
import {
  TextField,
  Tabs,
  Tab,
  Input,
  Switch,
  Autocomplete,
  Snackbar,
  Alert,
} from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { useParams, useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import axiosConfig from "../../../axios";
import TopHeaderInternal from "../../../Layout/TopHeader/TopHeaderInternal";
import ImageField from "../../../Layout/ImageField/ImageField";

import "react-datepicker/dist/react-datepicker.css";
import FullScreenLoader from "../../../Layout/FullScreenLoader/FullScreenLoader";

export default function EditBanner() {
  const history = useNavigate();

  const [bannerData, setBannerData] = useState({ section: "" });
  const [dragActive, setDragActive] = useState(false);

  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorFileUploader, setErrorFileUploader] = useState(false);
  const [errors, setErrors] = useState({});
  const [value, setValue] = React.useState("1");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [buttonloader, setButtonLoader] = useState(false)
  const [fullScreenLoader, setFullScreenLoader] = useState(true)

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  let { id } = useParams();
  var gotFromUrlId = id;
  const token = localStorage.getItem("Token-for-login");

  useEffect(() => {
    axiosConfig
      .get(`/admin/banner/${gotFromUrlId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log("banner data", response.data.data);
        setBannerData(response.data.data);
        if (response.data.status) {
          setTimeout(() => {
            setFullScreenLoader(false)
          }, 800);
        }
        // setFile(response.data.data.image);
      })
      .catch((error) => console.log(error));
  }, [gotFromUrlId, token]);

  const handleUpdate = () => {
    const updatedData = {
      _method: "Put",
      title_en: bannerData.title_en,
      title_ar: bannerData.title_ar,
      title_ja: bannerData.title_en,
      title_it: bannerData.title_en,
      content_en: bannerData.content_en,
      content_ar: bannerData.content_ar,
      content_ja: bannerData.content_en,
      content_it: bannerData.content_en,
      image: bannerData.image,
      link_text_en: bannerData.link_text_en,
      link_text_ar: bannerData.link_text_ar,
      link_text_it: bannerData.link_text_en,
      link_text_ja: bannerData.link_text_en,
      link: bannerData.link,
      section: bannerData.section,
      status: bannerData.status,
    };

    if (
      typeof updatedData.image === "string" ||
      updatedData.image instanceof String
    )
      delete updatedData.image;
    setButtonLoader(true)
    // console.log("updatedData", updatedData)
    axiosConfig
      .post(`/admin/banner/${gotFromUrlId}`, updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // console.log("updated value", response.data.data);
        if (response.data.status) {
          setTimeout(() => {
            setOpenSnackbar(true);
          }, 600);
          setTimeout(() => {
            setButtonLoader(false)
            history("/tours/banners")
          }, 1000);
          // setTimeout(() => history("/tours/banners"), 2000);
        } else {
          setErrorSnackbarOpen(true);
          setErrors(response.data.message);
          setButtonLoader(false)
        }
      })
      .catch((error) => console.log(error));
  };

  const validateFile = (file) => {
    const allowedTypes = [
      "image/png",
      "image/jpeg",
      "image/gif",
      "image/bmp",
      "image/svg+xml",
      "image/webp",
      "image/x-icon",
    ];
    const maxFileSize = 5 * 1024 * 1024; // 5MB

    if (!allowedTypes.includes(file.type)) {
      return false;
    }

    if (file.size > maxFileSize) {
      return false;
    }

    return true;
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const droppedFile = e.dataTransfer.files[0];
      if (validateFile(droppedFile)) {
        setBannerData({ ...bannerData, image: droppedFile });
        // console.log("droped log", droppedFile);
        setErrorFileUploader(false);
      } else {
        setErrorFileUploader(true);
      }
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      if (validateFile(selectedFile)) {
        setBannerData({ ...bannerData, image: selectedFile });
        // console.log(selectedFile);
        setErrorFileUploader(false);
      } else {
        setErrorFileUploader(true);
      }
    }
  };

  return (
    <>
      <div className="container-fluid d-flex">
        <div className="content-wrapper">
          <div className="content-components-wrapper">
            {fullScreenLoader ? <FullScreenLoader /> : ''}
            <TopHeaderInternal
              title={`Edit Banner`}
              linkToBackBtn={"/tours/banners"}
            />
            <div className="user-content sub-page-details-wrapper mt-4">
              <div className="row">
                <div className="col-xl-6 col-lg-7 col-md-9 col-sm-12">
                  <TabContext value={value}>
                    <Tabs
                      value={value}
                      onChange={handleTabChange}
                      variant="scrollable"
                      scrollButtons
                      allowScrollButtonsMobile
                      aria-label="nav tabs example"
                    >
                      <Tab label="English" value="1" />
                      <Tab label="Arabic" value="2" />
                      {/* <Tab label="Italian" value="3" /> */}
                      {/* <Tab label="Japanese" value="4" /> */}
                    </Tabs>
                    <TabPanel className="p-3" value="1">
                      <div>
                        <h5 className="sub-page-title">
                          Edit Banner In English
                        </h5>
                      </div>
                      <div className="english-data around-border">
                        <div className="row english-details add-border my-3">
                          <div className="col-4">
                            <p className="input-title">Title :</p>
                          </div>
                          <div className="col-8 mb-3">
                            <Input
                              disableUnderline
                              sx={{
                                border: "1px solid #C2C8CC;",
                                borderRadius: 1,
                                padding: "8px 15px",
                                width: "100%",
                                maxWidth: "100%",
                              }}
                              className="table-inputs-content "
                              size="small"
                              placeholder="Enter Title"
                              value={bannerData.title_en}
                              onChange={(e) =>
                                setBannerData({
                                  ...bannerData,
                                  title_en: e.target.value,
                                })
                              }
                            />
                            <span
                              className={
                                !!errors.title_en?.[0]
                                  ? "d-block error-msg py-1"
                                  : `d-none`
                              }
                            >
                              {errors.title_en?.[0]}
                            </span>
                          </div>
                          <div className="col-4">
                            <p className="input-title">Content :</p>
                          </div>
                          <div className="col-8 mb-3">
                            <Input
                              disableUnderline
                              sx={{
                                border: "1px solid #C2C8CC;",
                                borderRadius: 1,
                                padding: "8px 15px",
                                width: "100%",
                                maxWidth: "100%",
                              }}
                              className="table-inputs-content "
                              id="description-textfield"
                              placeholder="Enter Content"
                              multiline
                              minRows={4}
                              maxRows={6}
                              variant="outlined"
                              value={bannerData.content_en}
                              onChange={(e) =>
                                setBannerData({
                                  ...bannerData,
                                  ...{ content_en: e.target.value },
                                })
                              }
                              style={{ maxWidth: "100%" }}
                            />
                            <span
                              className={
                                !!errors.content_en?.[0]
                                  ? "d-block error-msg py-1"
                                  : `d-none`
                              }
                            >
                              {errors.content_en?.[0]}
                            </span>
                          </div>
                          <div className="col-4">
                            <p className="input-title">Cover Image :</p>
                          </div>
                          <ImageField
                            image={bannerData.image}
                            handleChange={handleChange}
                            handleDrop={handleDrop}
                            errorFileUploader={errorFileUploader}
                            submitError={errors.image?.[0]}
                            dragActive={dragActive}
                            setDragActive={setDragActive}
                          />

                          <div className="col-4">
                            <p className="input-title">Link :</p>
                          </div>
                          <div className="col-8 mb-3">
                            <Input
                              disableUnderline
                              sx={{
                                border: "1px solid #C2C8CC;",
                                borderRadius: 1,
                                padding: "8px 15px",
                                width: "100%",
                                maxWidth: "100%",
                              }}
                              className="table-inputs-content"
                              size="small"
                              placeholder="Enter Link"
                              value={bannerData.link}
                              onChange={(e) =>
                                setBannerData({
                                  ...bannerData,
                                  link: e.target.value,
                                })
                              }
                            />
                            <span
                              className={
                                !!errors.link?.[0]
                                  ? "d-block error-msg py-1"
                                  : `d-none`
                              }
                            >
                              {errors.link?.[0]}
                            </span>
                          </div>
                          <div className="col-4">
                            <p className="input-title">Link Text :</p>
                          </div>
                          <div className="col-8 mb-3">
                            <Input
                              disableUnderline
                              sx={{
                                border: "1px solid #C2C8CC;",
                                borderRadius: 1,
                                padding: "8px 15px",
                                width: "100%",
                                maxWidth: "100%",
                              }}
                              className="table-inputs-content"
                              size="small"
                              placeholder="Enter Link text"
                              value={bannerData.link_text_en}
                              onChange={
                                (e) =>
                                  setBannerData({
                                    ...bannerData,
                                    link_text_en: e.target.value,
                                  })
                              }
                            />
                            <span
                              className={
                                !!errors.link_text_en?.[0]
                                  ? "d-block error-msg py-1"
                                  : `d-none`
                              }
                            >
                              {errors.link_text_en?.[0]}
                            </span>
                          </div>

                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel className="p-3" value="2">
                      <div>
                        <h5 className="sub-page-title">
                          Edit Banner In Arabic
                        </h5>
                      </div>
                      <div className="arabic-data around-border">
                        <div className="row arabic-details add-border my-3">
                          <div className="col-4">
                            <p className="input-title">Title :</p>
                          </div>
                          <div className="col-8">
                            {" "}
                            <TextField
                              size="small"
                              multiline
                              className="mb-3"
                              placeholder="Enter Title"
                              value={bannerData.title_ar}
                              onChange={(e) =>
                                setBannerData({
                                  ...bannerData,
                                  title_ar: e.target.value,
                                })
                              }
                              style={{ maxWidth: "100%" }}
                            />{" "}
                          </div>

                          <div className="col-4">
                            <p className="input-title">Content :</p>
                          </div>
                          <div className="col-8">
                            <TextField
                              size="small"
                              multiline
                              className="mb-3"
                              placeholder="Enter Content"
                              value={bannerData.content_ar}
                              onChange={(e) =>
                                setBannerData({
                                  ...bannerData,
                                  content_ar: e.target.value,
                                })
                              }
                              style={{ maxWidth: "100%" }}
                            />
                          </div>
                          <div className="col-4">
                            <p className="input-title">Link Text :</p>
                          </div>
                          <div className="col-8 mb-3">
                            <Input
                              disableUnderline
                              sx={{
                                border: "1px solid #C2C8CC;",
                                borderRadius: 1,
                                padding: "8px 15px",
                                width: "100%",
                                maxWidth: "100%",
                              }}
                              className="table-inputs-content"
                              size="small"
                              placeholder="Enter Link text"
                              value={bannerData.link_text_ar}
                              onChange={
                                (e) =>
                                  setBannerData({
                                    ...bannerData,
                                    link_text_ar: e.target.value,
                                  })
                              }
                            />

                          </div>
                        </div>
                      </div>
                    </TabPanel>

                    {/* <TabPanel className="p-3" value="3">
                      <div>
                        <h5 className="sub-page-title">
                          Edit Banner In Italian
                        </h5>
                      </div>
                      <div className="italian-data around-border">
                        <div className="row italian-details add-border my-3">
                          <div className="col-4">
                            <p className="input-title">Title :</p>
                          </div>
                          <div className="col-8">
                            {" "}
                            <TextField
                              size="small"
                              multiline
                              className="mb-3"
                              placeholder="Enter Title"
                              value={bannerData.title_it}
                              onChange={(e) =>
                                setBannerData({
                                  ...bannerData,
                                  title_it: e.target.value,
                                })
                              }
                              style={{ maxWidth: "100%" }}
                            />{" "}
                          </div>
                         
                          <div className="col-4">
                            <p className="input-title">Content :</p>
                          </div>
                          <div className="col-8">
                            <TextField
                              size="small"
                              multiline
                              className="mb-3"
                              placeholder="Enter Content"
                              value={bannerData.content_it}
                              onChange={(e) =>
                                setBannerData({
                                  ...bannerData,
                                  content_it: e.target.value,
                                })
                              }
                              style={{ maxWidth: "100%" }}
                            />
                          </div>
                          <div className="col-4">
                            <p className="input-title">Link Text :</p>
                          </div>
                          <div className="col-8 mb-3">
                            <Input
                              disableUnderline
                              sx={{
                                border: "1px solid #C2C8CC;",
                                borderRadius: 1,
                                padding: "8px 15px",
                                width: "100%",
                                maxWidth: "100%",
                              }}
                              className="table-inputs-content"
                              size="small"
                              placeholder="Enter Link text"
                              value={bannerData.link_text_it}
                              onChange={
                                (e) =>
                                  setBannerData({
                                    ...bannerData,
                                    link_text_it: e.target.value,
                                  })
                              }
                            />

                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel className="p-3" value="4">

                      <div>
                        <h5 className="sub-page-title">
                          Edit Banner In Japaneese
                        </h5>
                      </div>
                      <div className="japaneese-data around-border">
                        <div className="row japaneese-details add-border my-3">
                          <div className="col-4">
                            <p className="input-title">Title :</p>
                          </div>
                          <div className="col-8">
                            {" "}
                            <TextField
                              size="small"
                              multiline
                              className="mb-3"
                              placeholder="Enter Title"
                              value={bannerData.title_ja}
                              onChange={(e) =>
                                setBannerData({
                                  ...bannerData,
                                  title_ja: e.target.value,
                                })
                              }
                              style={{ maxWidth: "100%" }}
                            />{" "}
                          </div>
                          
                          <div className="col-4">
                            <p className="input-title">Content :</p>
                          </div>
                          <div className="col-8">
                            <TextField
                              size="small"
                              multiline
                              className="mb-3"
                              placeholder="Enter Content"
                              value={bannerData.content_ja}
                              onChange={(e) =>
                                setBannerData({
                                  ...bannerData,
                                  content_ja: e.target.value,
                                })
                              }
                              style={{ maxWidth: "100%" }}
                            />
                          </div>
                          <div className="col-4">
                            <p className="input-title">Link Text :</p>
                          </div>
                          <div className="col-8 mb-3">
                            <Input
                              disableUnderline
                              sx={{
                                border: "1px solid #C2C8CC;",
                                borderRadius: 1,
                                padding: "8px 15px",
                                width: "100%",
                                maxWidth: "100%",
                              }}
                              className="table-inputs-content"
                              size="small"
                              placeholder="Enter Link text"
                              value={bannerData.link_text_ja}
                              onChange={
                                (e) =>
                                  setBannerData({
                                    ...bannerData,
                                    link_text_ja: e.target.value,
                                  })
                              }
                            />

                          </div>
                        </div>
                      </div>
                    </TabPanel> */}

                  </TabContext>
                  <div className="submit-button-sub-page">
                    <button className={`PrimaryButton ${buttonloader ? 'button-loader' : ' '}`} onClick={handleUpdate}>
                      {" "}
                      {buttonloader ? '' : 'Submit'}
                    </button>
                  </div>
                </div>

              </div>

              <div className="row my-4 function-button-wrapper-payment-details">
                <div className="col-8">
                  <div className="row">
                    <div className="col-3"></div>

                  </div>
                </div>
              </div>
            </div>
            <Snackbar
              open={errorSnackbarOpen}
              autoHideDuration={7000}
              onClose={() => setErrorSnackbarOpen(false)}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Alert

                severity="error"
                sx={{ width: "100%" }}
              >
                Banner update Failed!
              </Alert>
            </Snackbar>
            <Snackbar
              open={openSnackbar}
              autoHideDuration={7000}
              onClose={() => setOpenSnackbar(false)}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Alert

                severity="success"
                sx={{ width: "100%" }}
              >
                Banner updated successfully!
              </Alert>
            </Snackbar>
          </div>
        </div>
      </div>
    </>
  );
}
