import React, { useState, useEffect } from 'react';
import { Input, Snackbar, Alert, Tabs, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import DeleteModal from '../../../../Layout/Modal/Modal';
import axiosConfig from '../../../../axios';
import FullScreenLoader from '../../../../Layout/FullScreenLoader/FullScreenLoader';

const AdditionalInformation = () => {
    const [value, setValue] = useState('en'); // Set the default language to 'en' (English)

    const [languages, setLanguages] = useState([
        { id: 'en', label: 'English' },
        { id: 'ar', label: 'Arabic' },
        // { id: 'it', label: 'Italian' },
        // { id: 'ja', label: 'Japanese' },
    ]);

    const [data, setData] = useState({
        inclutionArr: [],
        exclutionArr: [],
    });

    const [inputValues, setInputValues] = useState({
        en: '',
        ar: '',
        // it: '',
        // ja: '',
    });

    const [inputValuesExc, setInputValuesExc] = useState({
        en: '',
        ar: '',
        // it: '',
        // ja: '',
    });

    const [errInclution, setErrInclution] = useState({
        en: '',
        ar: '',
        // it: '',
        // ja: '',
    })

    const [errExclution, setErrExclution] = useState({
        en: '',
        ar: '',
        // it: '',
        // ja: '',
    })

    const [changeInc, setChangeInc] = useState(false);
    const [changeExc, setChangeExc] = useState(false);

    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showModalExc, setShowModalExc] = useState(false);
    const [deleteId, setDeleteId] = useState(null); // Track the id of the item to be deleted
    const [deleteIdExc, setDeleteIdExc] = useState(null);
    const [showTabErr, setShowTabErr] = useState(false);
    const [buttonloader, setButtonLoader] = useState(false)
    const [fullScreenLoader, setFullScreenLoader] = useState(true)
    const [buttonloaderExc, setButtonLoaderExc] = useState(false)
    const token = localStorage.getItem('Token-for-login');

    const [inclData, setInclData] = useState(false)
    const [exclData, setExclData] = useState(false)

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeInc = () => {
        setChangeInc(true);
    };
    const handleChangeExc = () => {
        setChangeExc(true);
    };

    useEffect(() => {
        getInclusion();
        getExclusion();

        if (inclData && exclData) {
            setTimeout(() => {
                setFullScreenLoader(false)
            }, 800);
        }
        


    }, [inclData, exclData]);

    const getInclusion = () => {
        axiosConfig
            .get(`/admin/inclusion`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                let theData = response.data.data;
                // console.log('getInclusion value', response.data.data);
                // Update the inclutionArr in the data state
                setData((prevData) => ({
                    ...prevData,
                    inclutionArr: theData,
                }));
                if (response.data.status) {
                    setInclData(true)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getExclusion = () => {
        axiosConfig
            .get(`/admin/exclusion`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                let theData = response.data.data;
                // console.log('getExclusion value', response.data.data);

                // Update the exclutionArr in the data state
                setData((prevData) => ({
                    ...prevData,
                    exclutionArr: theData,
                }));
                if (response.data.status) {
                    setExclData(true)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };



    const handleDeleteClickInc = (id) => {
        let theId = id
        // console.log("handleDeleteClickInc", id)
        setShowModal(true);
        setDeleteId(theId);
    };

    const handleDeleteClickExc = (id) => {
        setShowModalExc(true);
        setDeleteIdExc(id);
    };

    const handleDelete = () => {
        axiosConfig
            .delete(`/admin/inclusion/${deleteId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                let theData = response.data.status;
                // console.log('handleDelete response api call value', response.data, theData);

                if (theData) {
                    setShowModal(false);
                    setSuccessMsg(`Inclution Deleted successfully!`);
                    setSuccessSnackbarOpen(true);
                    getInclusion()
                } else {
                    setErrorMsg(response.data.message)
                    setErrorSnackbarOpen(true)
                }
            })
            .catch((error) => {
                console.log(error);
            });

    };
    const handleDeleteExc = () => {
        axiosConfig
            .delete(`/admin/exclusion/${deleteIdExc}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                let theData = response.data.status;
                // console.log('handleDeleteExc response api call value', response.data, theData);

                if (theData) {
                    setShowModalExc(false);
                    setSuccessMsg('Deleted exc successfully!');
                    setSuccessSnackbarOpen(true);
                    getExclusion()
                } else {
                    setErrorMsg(response.data.message)
                    setErrorSnackbarOpen(true)
                }
            })
            .catch((error) => {
                console.log(error);
            });



    };

    const close = () => {
        setShowModal(false);
        setShowModalExc(false);
    };

    const handleApiSubmiteInc = () => {
        // Check if any of the input values are empty
        setButtonLoader(true)
        const invalidLanguages = languages.filter(lang => {
            const inputValue = inputValues[lang.id];
            return !inputValue || inputValue.trim() === '' || inputValue.length < 3;
        });

        const newErrInclution = {};

        invalidLanguages.forEach(lang => {
            const inputValue = inputValues[lang.id];
            let errorMessage = '';

            if (!inputValue) {
                errorMessage = `${lang.label} value is empty`;
            } else if (inputValue.trim() === '') {
                errorMessage = `${lang.label} value contains only whitespace`;
            } else if (inputValue.length < 3) {
                errorMessage = `${lang.label} value should be at least 3 characters`;
            }

            newErrInclution[lang.id] = errorMessage;
        });

        const hasErrors = Object.values(newErrInclution).some(errorMsg => errorMsg !== '');

        if (hasErrors) {
            setErrInclution(newErrInclution);
            setErrorMsg('Fields have errors in Inclutions');
            setErrorSnackbarOpen(true);
            setButtonLoader(false)
            // console.log(errInclution, newErrInclution)
        } else {
            // All input values are filled, proceed with API submission
            // console.log("inputValues:", inputValues);
            // Call your API submission function here

            const requestData = {
                name_en: inputValues.en,
                name_ar: inputValues.ar,
                name_it: inputValues.it,
                name_ja: inputValues.ja,
            };
            axiosConfig
                .post(`/admin/inclusion`, requestData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((response) => {
                    let theData = response.data;
                    // console.log('updateApiInclusion create value', response.data);
                    if (theData.status) {
                        setSuccessMsg('Inclusions added successfully!');
                        getInclusion()
                        setSuccessSnackbarOpen(true);
                        setButtonLoader(false)
                        setInputValues({
                            en: '',
                            ar: '',
                            it: '',
                            ja: '',
                        })
                        setErrInclution({
                            en: '',
                            ar: '',
                            it: '',
                            ja: '',
                        })
                    } else {
                        setButtonLoader(false)
                        setErrorMsg('Error in Submitting Data');
                        setErrorSnackbarOpen(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const handleApiSubmiteExc = () => {
        // Check if any of the input values are empty
        setButtonLoaderExc(true)
        const invalidLanguages = languages.filter(lang => {
            const inputValue = inputValuesExc[lang.id];
            return !inputValue || inputValue.trim() === '' || inputValue.length < 3;
        });

        const newErrExclution = {};

        invalidLanguages.forEach(lang => {
            const inputValue = inputValues[lang.id];
            let errorMessage = '';

            if (!inputValue) {
                errorMessage = `${lang.label} value is empty`;
            } else if (inputValue.trim() === '') {
                errorMessage = `${lang.label} value contains only whitespace`;
            } else if (inputValue.length < 3) {
                errorMessage = `${lang.label} value should be at least 3 characters`;
            }

            newErrExclution[lang.id] = errorMessage;
        });

        const hasErrors = Object.values(newErrExclution).some(errorMsg => errorMsg !== '');

        if (hasErrors) {
            setErrExclution(newErrExclution);
            setErrorMsg('Fields have errors in Exclusions');
            setErrorSnackbarOpen(true);
            setButtonLoaderExc(false)
        } else {
            // All input values are filled, proceed with API submission
            // console.log("inputValuesExc:", inputValuesExc);
            // Call your API submission function here

            const requestData = {
                name_en: inputValuesExc.en,
                name_ar: inputValuesExc.ar,
                name_it: inputValuesExc.it,
                name_ja: inputValuesExc.ja,
            };

            axiosConfig
                .post(`/admin/exclusion`, requestData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((response) => {
                    let theData = response.data;
                    // console.log('updateApiexclusion create value', response.data);
                    if (theData.status) {
                        setSuccessMsg('Exclusions added successfully!');
                        getExclusion()
                        setSuccessSnackbarOpen(true);
                        setInputValuesExc({
                            en: '',
                            ar: '',
                            it: '',
                            ja: '',
                        })
                        setErrExclution({
                            en: '',
                            ar: '',
                            it: '',
                            ja: '',
                        })
                        setButtonLoaderExc(false)
                    } else {
                        setButtonLoaderExc(false)
                        setErrorMsg('Exclution Error');
                        setErrorSnackbarOpen(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });



        }
    }

    const InclutionContent = (language) => {
        const nextTabIndex = (languages.findIndex(lang => lang.id === language) + 1) % languages.length;

        return (
            <>
                <div className='inclution-items-wrapper'>
                    {fullScreenLoader ? <FullScreenLoader /> : ''}
                    <ul className='menu-items-additional-info'>
                        {data.inclutionArr.map((dataItem) => (
                            <li className='menu-items-li' key={dataItem.id}>
                                <span className='items-additional-info pe-4'>{dataItem[`name_${language}`]}</span>
                                <span>
                                    <button className='DeleteButton' onClick={() => handleDeleteClickInc(dataItem.id)}>Delete</button>
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
                {changeInc ?
                    <>
                        <div className='add-new-items-input d-flex'>
                            <Input
                                disableUnderline
                                sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                className='table-inputs-content mb-3 me-4'
                                size='small'
                                placeholder='Enter category name'
                                required
                                fullWidth
                                value={inputValues[value]}
                                onChange={(e) =>
                                    setInputValues((prevInputValues) => ({
                                        ...prevInputValues,
                                        [value]: e.target.value,
                                    }))
                                }
                            />
                            {value === 'ar' ?
                                <button className={`PrimaryButton ${buttonloader ? 'button-loader' : ' '}`} onClick={handleApiSubmiteInc}>{!buttonloader ? 'Submit' : ''}</button>
                                :
                                <button
                                    className='PrimaryButton'
                                    type="button"
                                    variant="contained"
                                    onClick={() => setValue(languages[nextTabIndex].id)}
                                // style={{max-width: 107px;width: 100%;}}
                                >
                                    Next
                                </button>
                            }
                            {/* <button className='PrimaryButton' onClick={handleAddingInclusion}>Add</button> */}
                        </div>
                        {errInclution[value] && <span className="error-msg">{errInclution[value]}</span>}
                    </>
                    :
                    <div className='add-new-items-wrapper'>
                        <button className='SecondaryButton' onClick={handleChangeInc}>Add New Item</button>
                    </div>
                }
            </>
        );
    };

    const ExclutionContent = (language) => {
        const nextTabIndex = (languages.findIndex(lang => lang.id === language) + 1) % languages.length;
        return (
            <>
                <div className='exclution-items-wrapper'>
                    <ul className='menu-items-additional-info'>
                        {data.exclutionArr.map((dataItem) => (
                            <li className='menu-items-li' key={dataItem.id}>
                                <span className='items-additional-info pe-4'>{dataItem[`name_${language}`]}</span>
                                <span>
                                    <button className='DeleteButton' onClick={() => handleDeleteClickExc(dataItem.id)}>Delete</button>
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
                {changeExc ?
                    <>
                        <div className='add-new-items-input d-flex'>
                            <Input
                                disableUnderline
                                sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                className='table-inputs-content mb-3 me-4'
                                size='small'
                                placeholder='Enter category name'
                                required
                                fullWidth
                                value={inputValuesExc[value]}
                                onChange={(e) =>
                                    setInputValuesExc((prevInputValues) => ({
                                        ...prevInputValues,
                                        [value]: e.target.value,
                                    }))
                                }
                            />
                            {value === 'ar' ?
                                <button className={`PrimaryButton ${buttonloaderExc ? 'button-loader' : ' '}`} onClick={handleApiSubmiteExc}>{!buttonloaderExc ? 'Submit' : ''}</button>
                                :
                                <button
                                    className='PrimaryButton'
                                    type="button"
                                    variant="contained"
                                    onClick={() => setValue(languages[nextTabIndex].id)}
                                // style={{max-width: 107px;width: 100%;}}
                                >
                                    Next
                                </button>
                            }
                            {/* <button className='PrimaryButton' onClick={handleAddingExclusion}>Add</button> */}
                        </div>
                        {errExclution[value] && <span className="error-msg">{errExclution[value]}</span>}
                    </>
                    :
                    <div className='add-new-items-wrapper'>
                        <button className='SecondaryButton' onClick={handleChangeExc}>Add New Item</button>
                    </div>
                }
            </>
        )

    }

    return (
        <div className="additional-information-wrapper sub-page-details-wrapper mt-4">
            
            <TabContext value={value}>
                <TabList onChange={handleTabChange} aria-label="nav tabs example">
                    {languages.map((language) => (
                        <Tab key={language.id} label={language.label} value={language.id} className={`${errInclution[language.id] ? 'error-msg-dot' : ''} ${errExclution[language.id] ? 'error-msg-dot' : ''}`} />
                    ))}
                </TabList>
                {languages.map((language) => (
                    <TabPanel key={language.id} value={language.id}>
                        <>
                            <div className='inclution-wrapper'>
                                <h5 className='sub-page-title'>Inclusions</h5>
                                <div className="additional-information-content-wrapper inclution-content-wrapper around-border">
                                    {InclutionContent(language.id)}
                                    {/* {changeInc ?
                                        <button onClick={handleAddingInclusion}>add click</button>
                                        : ''
                                    } */}
                                </div>
                            </div>
                            <div className='exclution-wrapper'>
                                <h5 className='sub-page-title'>Exclusions</h5>
                                <div className="additional-information-content-wrapper exclution-content-wrapper around-border">
                                    {ExclutionContent(language.id)}
                                    {/* {changeExc ?
                                        <button onClick={handleAddingExclusion}>add click</button>
                                        : ''
                                    } */}
                                </div>
                            </div>
                        </>
                    </TabPanel>
                ))}
            </TabContext>
            <Snackbar open={errorSnackbarOpen} autoHideDuration={3000} onClose={() => setErrorSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity='error' sx={{ width: '100%' }}>
                    {errorMsg}
                </Alert>
            </Snackbar>
            <Snackbar open={successSnackbarOpen} autoHideDuration={3000} onClose={() => setSuccessSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity='success' sx={{ width: '100%' }}>
                    {successMsg}
                </Alert>
            </Snackbar>
            <DeleteModal
                showModal={showModal}
                onClose={close}
                onSubmit={handleDelete}
            />
            <DeleteModal
                showModal={showModalExc}
                onClose={close}
                onSubmit={handleDeleteExc}
            />
        </div>
    );
};

export default AdditionalInformation;
