import React from 'react'
import { useNavigate } from 'react-router-dom'

const PageNotFound = () => {
    let history = useNavigate()
    const handleHomeClicked = () => {
        history('/')
    }
    return (
        <div className='page-not-found-wrapper'>
            <div className="content-wrapper">
                <h1>404</h1>
                <h4>Page Not Found</h4>
                <p>The page you are looking for does not seem to exist</p>
                <button className='PrimaryButton' onClick={handleHomeClicked}>Home</button>
            </div>
        </div>
    )
}

export default PageNotFound