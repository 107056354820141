import { useState, useEffect } from "react";
import { IconButton, Link } from "@mui/material";
import { remove, reverse } from "lodash";
import { useNavigate } from "react-router-dom";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import TopHeaderMain from "../../../Layout/TopHeader/TopHeaderMain";
import axiosConfig from "../../../axios";
import config from "../../../config";
import EditIcon from "@mui/icons-material/Edit";
import styled from "@emotion/styled";
import FullScreenLoader from "../../../Layout/FullScreenLoader/FullScreenLoader";

const BannerSmall = styled.div`

  .backgroundcolor-wrapper {
    margin-bottom: 30px;
  }

  .editButton {
    top: 0;
    right: 0;
    position: absolute;
    z-index: 100;
  }
  .editButton .edit-button-icon {
    background: #e9ecef;
  }

  .lite-purple-color {
    background: #7042a2;
  }
  .BannerSmHorizontal {
    height: 198px;
  }
  .backgroundcolor-wrapper {
    background: #8a0ad1;
    border-radius: 24px;
    max-width: 900px;
    width: 100%;
    // height: 409px;
    overflow: hidden;
    width: 100%;
    position: relative;
  }
  .banner-text-wrapper {
    width: 50%;
    height: 100%;
    padding: 24px;
    background: linear-gradient(
      341deg,
      #7042a2 40.57%,
      #7042a2 75.69%,
      #976ec4
    );
    color: #fff;
  }
  .banner-text-wrapper::before {
    background-image: url(/static/media/lite-purple-logo.9eb3198….png);
    background-size: cover;
    content: "";
    z-index: 1;
    bottom: 0;
    left: 150px;
    right: 0;
    top: 0;
    width: 90%;
  }
  .backgroundcolor-wrapper .banner-img-wrapper {
    width: 50%;
    position: relative;
  }

  .banner-content p:first-child {
    font-size: 10px;
    margin-bottom: 5px;
    font-weight: 700;
    line-height: 138%;
    color: #e0e0e0;
    letter-spacing: .32em;
    text-transform: uppercase;
}
  .banner-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
}
.backgroundcolor-wrapper .banner-img-wrapper:before {
  background: linear-gradient(90deg,#7042a2,rgba(112,66,162,.12) 85.42%);
  border-radius: 0 24px 24px 0;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.banner-content h3 {
  font-size: 24px;
  font-weight: 700;
    line-height: 138%;
    margin-bottom: 0;

}
.banner-content a {
  font-weight: 600;
  font-size: 12px;
  line-height: 138%;
  text-decoration-line: underline;
  color: #faea58;
}
`;

const BannerBigVertical = styled.div`
max-width: 635px;

height: 100%;
margin-right: 0px;

  .vertical-banner-text {
    padding: 68px 48px 48px;
    position: relative;
    z-index: 1;
    color: white;
    height: 225px;
  }
  .big-banner-vertical.Pink-color {
    background: #7042a2;
}
:nth-child(2) .big-banner-vertical.Pink-color:nth-child(odd) {
  background: #cd6ac8;
}

  .editButton {
    top: 0;
    right: 0;
    position: absolute;
    z-index: 100;
  }
  .editButton .edit-button-icon {
    background: #e9ecef;
  }

  .big-banner-vertical {
    border-radius: 24px;
    background: #5c0f87;
    position: relative;
  }

  .vertical-banner-text::before {
    content: "";
    background-image: url("/public/assets/images/vercical-banner-logo.png");
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }
  .banner-content > * {
    margin-bottom: 12px;
  }
  .banner-content p:first-child {
    font-size: 10px;
    margin-bottom: 12px;
    font-weight: 700;
    line-height: 138%;
    color: #e0e0e0;
    letter-spacing: .32em;
    text-transform: uppercase;
}

.banner-content h3{
  font-weight: 700;
  line-height: 138%;
  margin-bottom: 12px;
  color: #fff;
  font-size: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.banner-content a {
  color: #faea58;
  font-size: 12px;
  font-weight: 600;
  line-height: 138%;
  text-decoration-line: underline;
}

  .vertical-banner-img img {
    border-radius: 0 0 24px 24px;
    height: 200px;
    object-fit: cover;
    object-position: center;
  }

  :nth-child(2) .vertical-banner-img::before {
background: linear-gradient(180deg,#cd6ac8,rgba(205,106,200,.4) 59.12%,rgba(177,36,255,0))
  }

  .vertical-banner-img::before {
    content: "";
    background: linear-gradient(180deg,#7042a2 3.51%,rgba(150,48,217,.38) 60.55%,rgba(177,36,255,0));
    position: absolute;
    left: 0;
    top: -1px;
    right: 0;
    bottom: 0;
    border-radius: 0 0 24px 24px;
  }
  .vertical-banner-img {
    position: relative;
    /* background: linear-gradient(180deg, #5C0F87 0%, rgba(92, 15, 135, 0.39) 59.12%, rgba(92, 15, 135, 0.24) 100%); */
  }
`;

const BannerBigHorizontal = styled.div`
max-width: 1440px;
width: 100%;
.backgroundcolor-wrapper {
    background: #8a0ad1;
    border-radius: 24px;
    height: 409px;
    overflow: hidden;
    width: 100%;
    position: relative;
  }

  .editButton {
    top: 0;
    right: 0;
    position: absolute;
    z-index: 100;
  }

  .editButton .edit-button-icon {
    background: #e9ecef;
  }

  .banner-text-wrapper {
    align-items: center;
    background: linear-gradient(
      314deg,
      #610097 40.57%,
      #641093 75.69%,
      #9b0ce9
    );
    border-radius: 24px 0 0 24px;
    color: #fff;
    display: flex;
    flex: 0 0 50%;
    height: 100%;
    padding-left: 48px;
    padding-right: 92px;
    position: relative;
    width: 100%;
  }

  .banner-text-wrapper::before {
    content: "";
    background-image: url("/public/assets/images/purple-logo.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 100%;
    position: absolute;
    left: 140px;
    right: 0;
    top: 15px;
    bottom: 15px;
    z-index: 1;
  }

  .banner-content {
    z-index: 1;
  }

  .backgroundcolor-wrapper .banner-img-wrapper {
    flex: 0 0 50%;
    height: 100%;
    margin-left: auto;
    position: relative;
  }

  .banner-img-wrapper::before {
    content: "";
    background: linear-gradient(90deg, #620695 0%, rgba(98, 6, 149, 0.12) 100%);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0 24px 24px 0;
  }

  img {
    height: 100%;
    border-radius: 0 24px 24px 0;
  }

  .banner-content p:first-child {
    font-weight: 700;
    font-size: 16px;
    line-height: 138%;
    /* identical to box height, or 22px */
    letter-spacing: 0.32em;
    text-transform: uppercase;
    color: #e0e0e0;
    margin-bottom: 12px !important;
  }

  .banner-content h3 {
    font-weight: 700;
    font-size: 32px;
    line-height: 138%;
    /* or 44px */
    color: #ffffff;
    margin-bottom: 12px !important;
  }

  .banner-content p:last-child {
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
    /* or 24px */
    color: #e8e8e8;
  }

  .banner-content a {
    font-weight: 600;
    font-size: 16px;
    line-height: 138%;
    /* identical to box height, or 22px */
    text-decoration-line: underline;
    color: #faea58;
  }

  .banner-content a:hover {
    color: #faea58;
  }

  .banner-content p {
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
    /* or 24px */
    color: #e8e8e8;
    margin-bottom: 12px !important;
  }

`;

export default function Banners() {
  const history = useNavigate();

  const [fullWidthBanner, setFullWidthBanner] = useState({});
  const [halfWidthBanner1, setHalfWidthBanner1] = useState({});
  const [halfWidthBanner2, setHalfWidthBanner2] = useState({});
  const [halfWidthBanner3, setHalfWidthBanner3] = useState({});

  const [tourPageBanner, setTourPageBanners] = useState([]);

  const [fullScreenLoader, setFullScreenLoader] = useState(true)

  useEffect(() => {
    const token = localStorage.getItem("Token-for-login");
    axiosConfig
      .get(`/admin/banner?language_code=en&device_id=dsdsa&user_agent=asdsa`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log(response.data);
        if (response.data.status) {
          setTimeout(() => {
            setFullScreenLoader(false)
          }, 800);
        }
        const banners = structuredClone(response.data.data);
        setFullWidthBanner(
          remove(banners, (banner) => banner.id === 1)[0] ?? {}
        );
        setHalfWidthBanner1(
          remove(banners, (banner) => banner.id === 2)[0] ?? {}
        );
        setHalfWidthBanner2(
          remove(banners, (banner) => banner.id === 3)[0] ?? {}
        );
        setHalfWidthBanner3(
          remove(banners, (banner) => banner.id === 4)[0] ?? {}
        );
        setTourPageBanners(reverse(banners) ?? []);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleEditClick = (id) => {
    // logic to handle edit click
    history(`/booking/edit-booking/${id}`);
  };

  return (
    <>
      <div className="container-fluid d-flex">
        <div className="content-wrapper">
          <div className="content-components-wrapper">
            {fullScreenLoader ? <FullScreenLoader /> : ''}
            <TopHeaderMain
              title="Banners"

            />
            <div className="bookings-wrapper mt-4">
              <div className="row">
                <div className="user-content mb-3">
                  <h5>Home Page</h5>
                  <p className="small-title">Full Width Banner</p>
                </div>
                <BannerBigHorizontal>
                  <div className="backgroundcolor-wrapper purple-color d-flex align-items-center">
                    <div className="editButton pt-2 px-2">
                      <Link href="/tours/banners/1">
                        <IconButton className="edit-button-icon">
                          <EditIcon titleAccess="edit" />
                        </IconButton>
                      </Link>
                    </div>
                    <div className="banner-text-wrapper">
                      <div className="banner-content">
                        <p>experience Saudi</p>
                        <h3>
                          {fullWidthBanner.title
                            ? fullWidthBanner.title
                            : "Weekend Tours in Riyadh"}
                        </h3>
                        <p className="hideonmobile">
                          {fullWidthBanner.content
                            ? fullWidthBanner.content
                            : `In the ever-growing and flourishing city of Riyadh,
                          you will discover the birthplace of the Kingdom of
                          Saudi Arabia, along with its historical treasures
                          hidden in the old palaces that witnessed the founding
                          of the kingdom.`}
                        </p>
                        <a to="">
                          {fullWidthBanner.link_text ?? "Explore Tours"}
                        </a>
                      </div>
                    </div>
                    <div className="banner-img-wrapper">
                      <img
                        src={config.imageBaseURL +
                          fullWidthBanner.image ??
                          "/assets/images/banner-img.png"
                        }
                        alt={fullWidthBanner.image_alt ?? "banner-img.png"}
                        className="w-100"
                      />
                    </div>
                  </div>
                </BannerBigHorizontal>

              </div>
                <div className="mt-3 mb-3">
                  <p className="small-title">Half Width Banners</p>
                </div>
              <div className="row justify-content-center">

                <BannerBigVertical className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-3">
                  <div className="big-banner-vertical Pink-color">
                    <div className="editButton pt-2 px-2">
                      <Link href="/tours/banners/2">
                        <IconButton className="edit-button-icon">
                          <EditIcon titleAccess="edit" />
                        </IconButton>
                      </Link>
                    </div>
                    <div className="vertical-banner-text">
                      <div className="vertical-banner-content banner-content">
                        <p className="vertical-banner-sub-title">
                          experience Saudi
                        </p>
                        <h3 className="vertical-banner-main-title">
                          {halfWidthBanner1.title ??
                            "Weekend Tours in Riyadh"}
                        </h3>
                        <a to="/" className="vertical-banner-link">
                          {halfWidthBanner1.link_text ?? "Explore Tours"}
                        </a>
                      </div>
                    </div>
                    <div className="vertical-banner-img">
                      <img
                        src={config.imageBaseURL +
                          halfWidthBanner1.image ??
                          "/assets/images/banner-img-pink.png w-100"
                        }
                        alt={halfWidthBanner1.image_alt ?? "tourimg.png"}
                        className="w-100"
                      />
                    </div>
                  </div>
                </BannerBigVertical>
                <BannerBigVertical className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                  <div className="big-banner-vertical Pink-color">
                    <div className="editButton pt-2 px-2">
                      <Link href="/tours/banners/3">
                        <IconButton className="edit-button-icon">
                          <EditIcon titleAccess="edit" />
                        </IconButton>
                      </Link>
                    </div>
                    <div className="vertical-banner-text">
                      <div className="vertical-banner-content banner-content">
                        <p className="vertical-banner-sub-title">
                          experience Saudi
                        </p>
                        <h3 className="vertical-banner-main-title">
                          {halfWidthBanner2.title ??
                            "Weekend Tours in Riyadh"}
                        </h3>
                        <a to="/" className="vertical-banner-link">
                          {halfWidthBanner2.link_text ?? "Explore Tours"}
                        </a>
                      </div>
                    </div>
                    <div className="vertical-banner-img">
                      <img
                        src={config.imageBaseURL +
                          halfWidthBanner2.image ??
                          "/assets/images/banner-img-pink.png"
                        }
                        alt={halfWidthBanner2.image_alt ?? "tourimg.png"}
                        className="w-100"
                      />
                    </div>
                  </div>
                </BannerBigVertical>
                <BannerBigVertical className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                  <div className="big-banner-vertical Pink-color">
                    <div className="editButton pt-2 px-2">
                      <Link href="/tours/banners/4">
                        <IconButton className="edit-button-icon">
                          <EditIcon titleAccess="edit" />
                        </IconButton>
                      </Link>
                    </div>
                    <div className="vertical-banner-text">
                      <div className="vertical-banner-content banner-content">
                        <p className="vertical-banner-sub-title">
                          experience Saudi
                        </p>
                        <h3 className="vertical-banner-main-title">
                          {halfWidthBanner3.title ??
                            "Weekend Tours in Riyadh"}
                        </h3>
                        <a to="/" className="vertical-banner-link">
                          {halfWidthBanner3.link_text ?? "Explore Tours"}
                        </a>
                      </div>
                    </div>
                    <div className="vertical-banner-img">
                      <img
                        src={config.imageBaseURL +
                          halfWidthBanner3.image ??
                          "/assets/images/banner-img-pink.png"
                        }
                        alt={halfWidthBanner3.image_alt ?? "tourimg.png"}
                        className="w-100"
                      />
                    </div>
                  </div>
                </BannerBigVertical>
              </div>

              <div className="row">
                <div className="user-content mt-3">
                  <h5>Tour Page Banners</h5>
                </div>


                {tourPageBanner.map((banner) => (
                  <BannerSmall className="col-12">

                    <div className="backgroundcolor-wrapper BannerSmHorizontal lite-purple-color d-flex align-items-center">
                      <div className="editButton pt-2 px-2">
                        <Link href={"/tours/banners/" + (banner.id)} >
                          <IconButton className="edit-button-icon">
                            <EditIcon titleAccess="edit" />
                          </IconButton>
                        </Link>
                      </div>
                      <div className="banner-text-wrapper">
                        <div className="banner-content">
                          <p>experience Saudi</p>
                          <h3>{banner.title ?? "Weekend Tours in Riyadh"}</h3>
                          <a to=""> {banner.link_text ?? "Explore Tours"}</a>
                        </div>
                      </div>
                      <div className="banner-img-wrapper">
                        <img
                          src={config.imageBaseURL +
                            banner.image ?? "/assets/images/banner-img.png"
                          }
                          alt="banner-img.png"
                          className="w-100"
                        />
                      </div>
                    </div>
                  </BannerSmall>
                ))}
              </div>
              <div className="user-table-content mt-3"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
