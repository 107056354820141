import React, { useState, useEffect } from "react";
import TopHeaderInternal from "../../../../Layout/TopHeader/TopHeaderInternal";
import { TextField, Input, Snackbar, Alert } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import { useParams, useNavigate } from "react-router-dom";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import axiosConfig from "../../../../axios";
import authorization from "../../../../authorization";
import DeleteModal from "../../../../Layout/Modal/Modal";

export default function MakeTourDetails() {
  const [userData, setUserData] = useState([]);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [locationData, setLocationData] = useState([])

  const history = useNavigate();
  let { id } = useParams();
  var gotFromUrlId = id;
  const token = localStorage.getItem("Token-for-login");


  useEffect(() => {
    listMakeaTour()
  }, []);

  const listMakeaTour = () => {
    axiosConfig
      .get(`/admin/tour/query/detail/${gotFromUrlId}`, {
        headers: {
          Authorization: authorization.authorizationUrl,
        },
      })
      .then((response) => {
        let allData = response.data.data
        // console.log("make tour", allData);
        setUserData(allData);
        if (allData.location_id) {
          getLocationNames(allData.location_id)
        }
      })

      .catch((error) => console.log(error));
  }

  const getLocationNames = (location) => {
    const inclusionsString = location
    const locationIds = inclusionsString.split(',');

    // console.log("inclusionsString & locationIds", locationIds,)

    const fetchInclusions = async (locationIds) => {
      const inclusionResponses = [];

      for (const id of locationIds) {
        const url = `/admin/tour/location/details/${id}`;
        // console.log("const id of locationIds", id)
        try {
          const response = await axiosConfig.get(url, {
            headers: {
              'Authorization': authorization.authorizationUrl,
            },
          });
          inclusionResponses.push(response.data); // Assuming the response structure is similar to your inclusion data
        } catch (error) {
          console.error(`Error fetching inclusion with ID ${id}:`, error);
        }
      }

      return inclusionResponses;
    };

    // Usage


    fetchInclusions(locationIds)
      .then(inclusionResponses => {
        const locationText = inclusionResponses.map(data => data.data.location_en).join(', ');
        setLocationData(locationText)
      })
      .catch(error => {
        console.error('Error fetching inclusions:', error);
      });
  }

  const handleDeleteData = (id) => {
    // console.log("selectedId")
    setSelectedId(id);
    setShowModal(true);
  };

  const close = () => {
    setShowModal(false);
  };

  const handleDelete = () => {
    // console.log("selectedId", selectedId)
    axiosConfig.delete(`/admin/tour/query/delete/${selectedId}`, {
      headers: {
        'Authorization': authorization.authorizationUrl,
      }
    })
      .then(response => {
        // console.log("delete inquiry", response.data)
        if (response.data.status) {
          setShowModal(false);
          setOpenSnackbar(true)
          setTimeout(() => {
            history('/inquiry/make-a-tour')
          }, 800);
        } else {
          setOpenErrorSnackbar(true)
        }
      })
      .catch(error => console.log(error));
  };


  return (
    <>
      <div className="container-fluid d-flex">
        <div className="content-wrapper">
          <div className="content-components-wrapper">
            <TopHeaderInternal
              title={`Make a Tour Details`}
              linkToBackBtn={"/inquiry/make-a-tour"}
            />
            <div className="user-content sub-page-details-wrapper mt-4">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-7 col-sm-12">
                  <h5 className='sub-page-title'>Form Details</h5>
                  <div className='around-border'>
                    {userData ? (
                      <div className="row">
                        <div className="col-4">
                          <p className='input-title'>Name:</p>
                        </div>
                        <div className="col-8">
                          {" "}
                          <Input
                            disableUnderline
                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                            size='small'
                            className='table-inputs-content mb-3'
                            value={userData.name}
                            readOnly
                          />{" "}
                        </div>
                        <div className="col-4">
                          <p className='input-title'>Email:</p>
                        </div>
                        <div className="col-8">
                          {" "}
                          <Input
                            disableUnderline
                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                            size='small'
                            className='table-inputs-content mb-3'
                            value={userData.email}
                            readOnly
                          />{" "}
                        </div>
                        <div className="col-4">
                          <p className='input-title'>Phone:</p>
                        </div>
                        <div className="col-8">
                          {" "}
                          <Input
                            disableUnderline
                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                            size='small'
                            className='table-inputs-content mb-3'
                            value={userData.phone_number}
                            readOnly
                          />{" "}
                        </div>
                        <div className="col-4">
                          <p className='input-title'>Country:</p>
                        </div>
                        <div className="col-8">
                          {" "}
                          <Input
                            disableUnderline
                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                            size='small'
                            className='table-inputs-content mb-3'
                            value={userData.country}
                            readOnly
                          />{" "}
                        </div>
                        <div className="col-4">
                          <p className='input-title'>Destination:</p>
                        </div>
                        <div className="col-8">
                          {" "}
                          <Input
                            disableUnderline
                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                            size='small'
                            className='table-inputs-content mb-3'
                            value={locationData}
                            readOnly
                            multiline
                          />{" "}
                        </div>
                        <div className="col-4">
                          <p className='input-title'>Travel Date:</p>
                        </div>
                        <div className="col-8">
                          {" "}
                          <Input
                            disableUnderline
                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                            size='small'
                            className='table-inputs-content mb-3'
                            value={userData.travel_date}
                            readOnly
                          />{" "}
                        </div>
                        <div className="col-4">
                          <p className='input-title'>No. of Days:</p>
                        </div>
                        <div className="col-8">
                          {" "}
                          <Input
                            disableUnderline
                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                            size='small'
                            className='table-inputs-content mb-3'
                            value={userData.no_of_days}
                            readOnly
                          />{" "}
                        </div>

                        <div className="col-4">
                          {userData.id ?
                            <button
                              className="DeleteButton"
                              onClick={() => handleDeleteData(userData.id)}
                            >
                              <DeleteForeverOutlinedIcon /> Delete
                            </button>
                            : ''}
                        </div>
                      </div>
                    ) : (
                      <p className='input-title'>Loading user data...</p>
                    )}
                  </div>
                  <DeleteModal
                    showModal={showModal}
                    onClose={close}
                    onSubmit={handleDelete}
                  />
                  <Snackbar
                    open={openSnackbar}
                    autoHideDuration={7000}
                    onClose={() => setOpenSnackbar(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  >
                    <Alert
                      
                      severity="success"
                      sx={{ width: "100%" }}
                    >
                      Deleted successfully!
                    </Alert>
                  </Snackbar>
                  <Snackbar
                    open={openErrorSnackbar}
                    autoHideDuration={7000}
                    onClose={() => setOpenErrorSnackbar(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  >
                    <Alert
                      
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      Delete failed!
                    </Alert>
                  </Snackbar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
