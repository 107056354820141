import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles';

import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    FormControl,
    Select,
    MenuItem,
    Menu,
    InputLabel,
    TableSortLabel,
    TableFooter,
    Checkbox,
    IconButton,
    Pagination,
    TablePagination,

} from '@mui/material';
import TopHeaderMain from '../../Layout/TopHeader/TopHeaderMain';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import PeopleIcon from '@mui/icons-material/People';
import AddIcon from '@mui/icons-material/Add';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

import DeleteIcon from '@mui/icons-material/Delete';
import axiosConfig from '../../axios';

const StyledTextField = styled(TextField)({
    width: '100%',
    marginBottom: '1rem',
});

const StyledFormControl = styled(FormControl)({
    width: '100%',
    marginBottom: '1rem',
});

export default function Report() {
    const [category, setCategory] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortColumn, setSortColumn] = useState('id');
    const [sortOrder, setSortOrder] = useState('asc');
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        axiosConfig.get(`/admin/contact/query?page=${page}`, {
            headers: {
                'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYzMzMDhmNzBhOTg4MThlMTlhNGYxOTllOTAwMTlkNWFjNzA1ODkzZDQzMjY3NTI1NTBiOTYwMjQ5NDgzZjA5NGExNDdmNzU0YjZkNjg2YTYiLCJpYXQiOjE2NjQ0NDI4ODAuNjg0ODQ2LCJuYmYiOjE2NjQ0NDI4ODAuNjg0ODQ4LCJleHAiOjE2OTU5Nzg4ODAuNjc3NzM1LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.JjlS3pvv8k7K0ftzW_cR0c8Rs5OtgciDKWPLdvCKfwG-AZCTHeWS6PGkGUsSeV_5NkTCz5Z0GTvj-Akmu5Gs8Z2oPGGMfnx4HC9ABf2v8d-MnzhOIxdaMQKBgXXeL0ELOaUP1Vo2jMwgoQunEpxoWozEhtmOMl3hLtcjLx3hHZAFeL4V1IRxgZKJIebprKQOwWOSJ68AqmzmSL1e0P1Y0KCfxlxg7hmQaB2-hmovzfzUxWUPBTHukduXTQS-B1H02HdJ5kJXVxoD57PIIyRj7V4juROOY8D74W-tlrF9I73gTVPKDDRwYdfbrhVWbcEMyUaeO0j5F9jZtzXLrwpBCX_3lqKX7Wr722HZDFCX2YWedkdD0ZcRPdHjaaRpgh_jb5rCbZxOEK67qWTd-m43XSKeyLN4mX_dwyT_BdQktnDChuzKMfYXSPxg0aLFe_KIFchDRgQVTyXKav7-lsa8FjuewNq16NPqRXNPC9VaFmZoZ7eH8TMpyrF4v9WyrRqITRnxNpFcJJRdCIvJEl0dxJlAjfDiOgy-qJo2E5Jmb6jrYVBniI2k4M_QHxs_Wf5bD0bx3rVpdz6ey2tYFPt2w7hHnJjMxyIcU5MlKqemn5MmYjhQgHXyJDhnV_Fbp-rND8IotwFsOdjM7LPIXd1meYKu6-DR6f-4C0B7i7chYCo'
            }
        })
            .then(response => {
                setUsers(response.data.data.data);
                setTotalPages(response.data.data.last_page);
            })
            .catch(error => console.log(error));
    }, [page, rowsPerPage]);


    const handleDeleteClick = (id) => {


        // fetch(`https://phpstack-876914-3037838.cloudwaysapps.com/api/admin/contact/query`, {
        //     method: 'GET',
        //     headers: {
        //         'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYzMzMDhmNzBhOTg4MThlMTlhNGYxOTllOTAwMTlkNWFjNzA1ODkzZDQzMjY3NTI1NTBiOTYwMjQ5NDgzZjA5NGExNDdmNzU0YjZkNjg2YTYiLCJpYXQiOjE2NjQ0NDI4ODAuNjg0ODQ2LCJuYmYiOjE2NjQ0NDI4ODAuNjg0ODQ4LCJleHAiOjE2OTU5Nzg4ODAuNjc3NzM1LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.JjlS3pvv8k7K0ftzW_cR0c8Rs5OtgciDKWPLdvCKfwG-AZCTHeWS6PGkGUsSeV_5NkTCz5Z0GTvj-Akmu5Gs8Z2oPGGMfnx4HC9ABf2v8d-MnzhOIxdaMQKBgXXeL0ELOaUP1Vo2jMwgoQunEpxoWozEhtmOMl3hLtcjLx3hHZAFeL4V1IRxgZKJIebprKQOwWOSJ68AqmzmSL1e0P1Y0KCfxlxg7hmQaB2-hmovzfzUxWUPBTHukduXTQS-B1H02HdJ5kJXVxoD57PIIyRj7V4juROOY8D74W-tlrF9I73gTVPKDDRwYdfbrhVWbcEMyUaeO0j5F9jZtzXLrwpBCX_3lqKX7Wr722HZDFCX2YWedkdD0ZcRPdHjaaRpgh_jb5rCbZxOEK67qWTd-m43XSKeyLN4mX_dwyT_BdQktnDChuzKMfYXSPxg0aLFe_KIFchDRgQVTyXKav7-lsa8FjuewNq16NPqRXNPC9VaFmZoZ7eH8TMpyrF4v9WyrRqITRnxNpFcJJRdCIvJEl0dxJlAjfDiOgy-qJo2E5Jmb6jrYVBniI2k4M_QHxs_Wf5bD0bx3rVpdz6ey2tYFPt2w7hHnJjMxyIcU5MlKqemn5MmYjhQgHXyJDhnV_Fbp-rND8IotwFsOdjM7LPIXd1meYKu6-DR6f-4C0B7i7chYCo'
        //     }
        // })
        //     .then(response => response.json())
        //     .then(data => console.log(data))
        //     .catch(error => console.log(error));


        const updatedUsers = users.filter((user) => user.id !== id);
        setUsers(updatedUsers);

    };

    const history = useNavigate();
    const handleEditClick = (id) => {
        // logic to handle edit click
        history(`/blog/edit-blog${id}`);
        // console.log(`Edit clicked for user with id ${id}`);
    };

    const handleDetailClick = (user) => {
        history(`/blof/blog-details${user.id}`);
    }

    const handleSortClick = (column) => {
        if (sortColumn === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortOrder('asc');
        }
    };
    const sortedUsers = users.sort((a, b) => {
        if (sortOrder === 'asc') {
            return a[sortColumn] > b[sortColumn] ? 1 : -1;
        } else {
            return a[sortColumn] < b[sortColumn] ? 1 : -1;
        }
    });
    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
    };

    const handleFromDateChange = (event) => {
        setFromDate(event.target.value);
    };

    const handleToDateChange = (event) => {
        setToDate(event.target.value);
    };

    const handleGenerateClick = () => {
        // TODO: Implement report generation logic
    };

    return (
        <>
            <div className="container-fluid d-flex">
                <div className="content-wrapper">
                    <div className="content-components-wrapper">
                        <TopHeaderMain title='Reports' icons={<AssessmentIcon fontSize="large" />} />
                        <div className="user-content mt-4">

                            <div className="report-generate-inputs d-flex align-items-center flex-wrap mb-5">
                                <div className="input-title-wrapper">
                                    <h6>Category</h6>
                                    <FormControl sx={{ minWidth: 120 }}>
                                        <Select
                                            size='small'
                                            placeholder='Select Category'
                                            id="category"
                                            value={category || 'default'}
                                            onChange={handleCategoryChange}
                                        >
                                            <MenuItem value="default">Select Category</MenuItem>
                                            <MenuItem value="category1">Category 1</MenuItem>
                                            <MenuItem value="category2">Category 2</MenuItem>
                                            <MenuItem value="category3">Category 3</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="input-title-wrapper">
                                    <h6>From</h6>
                                    <TextField
                                        size='small'
                                        id="from-date"
                                        placeholder="From"
                                        type="date"

                                        value={fromDate}
                                        onChange={handleFromDateChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                <div className="input-title-wrapper">
                                    <h6>To</h6>
                                    <TextField
                                        id="to-date"
                                        placeholder="To"
                                        type="date"
                                        size='small'
                                        value={toDate}
                                        onChange={handleToDateChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                <button className='SecondaryButton' onClick={handleGenerateClick}>
                                    Generate
                                </button>
                            </div>

                            <div className="report-wrapper-title pt-4 mb-3">
                                <div className="row">
                                    <div className="col-6">
                                        <h5>Payment Report  <span className='gray-color'>(47 entries)</span></h5>
                                    </div>
                                    <div className="col-6 text-end">
                                        <button className='PrimaryButton'>
                                            <FileDownloadOutlinedIcon /> Download
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="table-wrapper">
                                <div className="user-table-content">
                                    <Table >
                                        <TableHead style={{ backgroundColor: '#e9ecef' }}>
                                            <TableRow >
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={sortColumn === 'id'}
                                                        direction={sortColumn === 'id' ? sortOrder : 'asc'}
                                                        onClick={() => handleSortClick('id')}
                                                    >
                                                        ID
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={sortColumn === 'name'}
                                                        direction={sortColumn === 'name' ? sortOrder : 'asc'}
                                                        onClick={() => handleSortClick('name')}
                                                    >
                                                        Name
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell>date</TableCell>
                                                <TableCell>category</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>
                                                    <TableSortLabel
                                                        active={sortColumn === 'date'}
                                                        direction={sortColumn === 'date' ? sortOrder : 'asc'}
                                                        onClick={() => handleSortClick('date')}
                                                    >
                                                        Date
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sortedUsers.map((user) => (
                                                <TableRow key={user.id}>
                                                    <TableCell>{user.id}</TableCell>
                                                    <TableCell><span style={{ cursor: 'pointer' }} onClick={() => handleDetailClick(user)}>{user.name}</span></TableCell>
                                                    <TableCell>{user.email}</TableCell>
                                                    <TableCell>{user.phone_number}</TableCell>
                                                    <TableCell>{user.country}</TableCell>
                                                    <TableCell><span className='message-written'>{user.message}</span></TableCell>
                                                    <TableCell></TableCell>
                                                    <TableCell className='action-dropdown-btn'>
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="secondary" id="dropdown-basic" size="sm" style={{ color: '#000000', border: 'none', background: 'transparent' }}>
                                                                <MoreVertIcon />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item>
                                                                    <IconButton
                                                                        color="primary"
                                                                        aria-label="View"
                                                                        onClick={() => handleDetailClick(user)}
                                                                        style={{ fontSize: '14px' }}
                                                                    >
                                                                        <InfoIcon style={{ fontSize: 16 }} className='me-1' /> View
                                                                    </IconButton>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item>
                                                                    <IconButton
                                                                        color="primary"
                                                                        aria-label="Edit"
                                                                        onClick={() => handleEditClick(user.id)}
                                                                        style={{ fontSize: '14px' }}
                                                                    >
                                                                        <EditIcon style={{ fontSize: 16 }} className='me-1' /> Edit
                                                                    </IconButton>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item>
                                                                    <IconButton
                                                                        color="secondary"
                                                                        aria-label="Delete"
                                                                        onClick={() => handleDeleteClick(user.id)}
                                                                        style={{ fontSize: '14px' }}
                                                                    >
                                                                        <DeleteIcon style={{ fontSize: 16 }} className='me-1' /> Delete
                                                                    </IconButton>
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <div className="pagination-wrapper">
                                        <TablePagination
                                            component="div"
                                            count={totalPages * rowsPerPage}
                                            page={page - 1}
                                            onPageChange={(event, newPage) => setPage(newPage + 1)}
                                            rowsPerPage={rowsPerPage}
                                            rowsPerPageOptions={[]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
