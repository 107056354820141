import React, { useState, useEffect } from 'react';
import TopHeaderMain from '../../../Layout/TopHeader/TopHeaderMain'
import {
    Autocomplete,
    MenuItem,
    FormControl,
    Select,
    TextField,
    styled
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import { Link, useParams } from 'react-router-dom';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

const StyledFormControl = styled(FormControl)({
    // width: '100%',
    marginBottom: '1rem',
});

export default function CreatePayment() {
    const [userData, setUserData] = useState([])

    let { id } = useParams();
    var gotFromUrlId = id
    useEffect(() => {

        fetch(`https://phpstack-876914-3037838.cloudwaysapps.com/api/admin/user/${gotFromUrlId}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYzMzMDhmNzBhOTg4MThlMTlhNGYxOTllOTAwMTlkNWFjNzA1ODkzZDQzMjY3NTI1NTBiOTYwMjQ5NDgzZjA5NGExNDdmNzU0YjZkNjg2YTYiLCJpYXQiOjE2NjQ0NDI4ODAuNjg0ODQ2LCJuYmYiOjE2NjQ0NDI4ODAuNjg0ODQ4LCJleHAiOjE2OTU5Nzg4ODAuNjc3NzM1LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.JjlS3pvv8k7K0ftzW_cR0c8Rs5OtgciDKWPLdvCKfwG-AZCTHeWS6PGkGUsSeV_5NkTCz5Z0GTvj-Akmu5Gs8Z2oPGGMfnx4HC9ABf2v8d-MnzhOIxdaMQKBgXXeL0ELOaUP1Vo2jMwgoQunEpxoWozEhtmOMl3hLtcjLx3hHZAFeL4V1IRxgZKJIebprKQOwWOSJ68AqmzmSL1e0P1Y0KCfxlxg7hmQaB2-hmovzfzUxWUPBTHukduXTQS-B1H02HdJ5kJXVxoD57PIIyRj7V4juROOY8D74W-tlrF9I73gTVPKDDRwYdfbrhVWbcEMyUaeO0j5F9jZtzXLrwpBCX_3lqKX7Wr722HZDFCX2YWedkdD0ZcRPdHjaaRpgh_jb5rCbZxOEK67qWTd-m43XSKeyLN4mX_dwyT_BdQktnDChuzKMfYXSPxg0aLFe_KIFchDRgQVTyXKav7-lsa8FjuewNq16NPqRXNPC9VaFmZoZ7eH8TMpyrF4v9WyrRqITRnxNpFcJJRdCIvJEl0dxJlAjfDiOgy-qJo2E5Jmb6jrYVBniI2k4M_QHxs_Wf5bD0bx3rVpdz6ey2tYFPt2w7hHnJjMxyIcU5MlKqemn5MmYjhQgHXyJDhnV_Fbp-rND8IotwFsOdjM7LPIXd1meYKu6-DR6f-4C0B7i7chYCo'
            }
        })
            .then(response => response.json())
            .then(data => setUserData(data.data))
            .catch(error => console.log(error));
    }, []);

    return (
        <>
            <div className="container-fluid d-flex">
                <div className="content-wrapper">
                    <div className="content-components-wrapper">
                        <TopHeaderMain title='Create Payment' />
                        <div className="user-content mt-4">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-7 col-sm-12">


                                    <div>
                                        <div className="row">
                                            <div className="col-4"><p>Payment ID:</p></div>
                                            <div className="col-8"> <TextField size='small' className='mb-3' value={userData.email} /> </div>
                                            <div className="col-4"><p>Date :</p></div>
                                            <div className="col-8"> <TextField size='small' className='mb-3' value={userData.mobile} /> </div>
                                            <div className="col-4"><p>Customer name :</p></div>
                                            <div className="col-8"> <TextField size='small' className='mb-3' value={userData.is_active} /> </div>
                                            <div className="col-4"><p>Customer email :</p></div>
                                            <div className="col-8"> <TextField size='small' className='mb-3' value={userData.is_active} /> </div>
                                            <div className="col-4"><p>Travelers:</p></div>
                                            <div className="col-8"> <TextField size='small' className='mb-3' value={userData.is_active} /> </div>
                                            <div className="col-4"><p>Amount:</p></div>
                                            <div className="col-8"> <TextField size='small' className='mb-3' value={userData.is_active} /> </div>
                                            <div className="col-4"><p>Tour name:</p></div>
                                            <div className="col-8">
                                                <Autocomplete
                                                    className='mb-3'
                                                    name="tourName"
                                                    options={['Tour 1', 'Tour 2', 'Tour 3']}
                                                    getOptionLabel={(option) => option}
                                                    renderInput={(params) => <TextField {...params} size="small" />}
                                                />
                                            </div>
                                            <div className="col-4"><p>Mode of payment:</p></div>
                                            <div className="col-8">
                                                <StyledFormControl variant="outlined">
                                                    <Select
                                                        size="small"
                                                        id="payment-mode"
                                                        defaultValue="offline"
                                                        name="payment-mode"
                                                    >
                                                        <MenuItem value="offline">Offline</MenuItem>
                                                        <MenuItem value="online">Online</MenuItem>

                                                    </Select>
                                                </StyledFormControl>
                                            </div>
                                        </div>

                                    </div>


                                </div>
                            </div>

                            <div className="row my-4 function-button-wrapper-payment-details">
                                <div className="col-8">
                                    <div className="row">
                                        <div className="col-3"></div>
                                        <div className="col-9"><div className='d-flex'><button className='SecondaryButton me-3'>Reset</button> <button className='PrimaryButton ms-3'>Create Payment</button> </div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
