import { useRef, useState } from "react";
import config from "../../config";
import { Box, Button } from "@mui/material";
import { useEffect } from "react";

export default function ImageField({
  image,
  handleChange,
  handleDrop,
  errorFileUploader,
  submitError,
  dragActive,
  setDragActive,
}) {
  const inputRef = useRef(null);

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  useEffect(() => {
    console.log("got the image in ", image)
  }, [image])
  

  return (
    <div className="col-8 mb-3">
      <div className="cover-image-wrapper ">
        <input
          ref={inputRef}
          type="file"
          accept="image/png, image/jpeg, image/gif"
          onChange={handleChange}
          style={{ display: "none" }}
        />
        <label
          htmlFor="input-file-upload"
          className={
            dragActive ? "drag-active label-file-upload" : "label-file-upload"
          }
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        >
          {image && typeof image === "object" ? (
            <Box sx={{ my: 2, width: "100%" }}>
              <img
                src={URL.createObjectURL(image)}
                alt="Uploaded file"
                style={{ width: "100%" }}
              />
              <p>Selected file: {image.name}</p>
            </Box>
          ) : image ? (
            <Box sx={{ my: 2 }}>
              <img
                src={config.imageBaseURL + image}
                alt="Uploaded file"
                style={{ width: "100%" }}
              />
              <p
                className="mt-1"
                style={{ fontSize: "0.7em", textAlign: "center" }}
              >
                Selected file: {image}
              </p>
            </Box>
          ) : (
            <></>
          )}
          <div className="text-center">
            <span className="below-note">Drag and drop your image here or</span>
            <Button
              className="upload-button"
              variant="text"
              component="span"
              color="secondary"
              onClick={onButtonClick}
            >
              Click here To Upload a Image
            </Button>
          </div>
          <span className="below-note">Maximun Image size is 5MB*</span>
        </label>
        {dragActive && (
          <div
            id="drag-file-element"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
        {errorFileUploader && (
          <p className="d-block error-msg py-1">Invalid file type or size</p>
        )}
      </div>
      <span
        className={submitError?.status ? "d-block error-msg py-1" : `d-none`}
      >
        {submitError?.value}
      </span>
    </div>
  );
}
