import React, { useState, useRef, useEffect } from 'react';
import {
    TextField,
    Button,
    Box,
    IconButton,
    InputAdornment,
    Snackbar,
    Alert,
    Input,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import TopHeaderInternal from '../../../Layout/TopHeader/TopHeaderInternal';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axiosConfig from '../../../axios';
import authorization from '../../../authorization';
import FullScreenLoader from '../../../Layout/FullScreenLoader/FullScreenLoader';


export default function CreateTestimony() {
    const history = useNavigate();
    // const [userData, setUserData] = useState([])
    const [selectedDate, setSelectedDate] = useState(null);
    const [title, setTitle] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [file, setFile] = useState(null);
    const [location, setLocation] = useState('');
    const [description, setDescription] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [dragActive, setDragActive] = useState(false);
    const [errorFileUploader, setErrorFileUploader] = useState(false);

    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');

    const [buttonloader, setButtonLoader] = useState(false)
    const [fullScreenLoader, setFullScreenLoader] = useState(true)


    useEffect(() => {
        setTimeout(() => {
            setFullScreenLoader(false)
        }, 800);
    }, [])


    const inputRef = useRef(null);

    const userData = {
        text_en: description,
        user_name: customerName,
        user_image: file,
        user_location: location,
        latitude: latitude,
        longitude: longitude
    };

    const [errTab, setErrTab] = useState({
        errcustomerName: '',
        errlocation: '',
        errdescription: '',
        errlatitude: '',
        errlongitude: '',
        errImage: '',
    })


    const handleCreateTestimony = () => {
        // console.log("userData", userData, userData.user_name)
        setButtonLoader(true)
        let errcustomerName = '';
        let errlocation = '';
        let errdescription = '';
        let errlatitude = '';
        let errlongitude = '';
        let errImage = '';

        const fieldsToValidate = [
            { name: 'customerName', value: customerName, titles: 'Customer Name' },
            { name: 'location', value: location, titles: 'Location' },
            { name: 'description', value: description, titles: 'Description' },
            { name: 'latitude', value: latitude, titles: 'Latitude' },
            { name: 'longitude', value: longitude, titles: 'Longitude' },
        ];

        fieldsToValidate.forEach((field) => {
            if (!field.value || field.value.trim().length === 0) {
                if (field.name === 'customerName') {
                    errcustomerName = `${field.titles} is empty.`;
                } else if (field.name === 'location') {
                    errlocation = `${field.titles} is empty.`;
                } else if (field.name === 'description') {
                    errdescription = `${field.titles} is empty.`;
                }
            } else if (field.value.trim().length < 3) {
                if (field.name === 'customerName') {
                    errcustomerName = `${field.titles} has less than 3 characters.`;
                } else if (field.name === 'location') {
                    errlocation = `${field.titles} has less than 3 characters.`;
                } else if (field.name === 'description') {
                    errdescription = `${field.titles} has less than 3 characters.`;
                }
            }

            if (!file) {
                errImage = `Image is empty.`;
            }

            if (field.name === 'latitude' && (isNaN(parseFloat(field.value)) || Math.abs(parseFloat(field.value)) > 90)) {
                errlatitude = 'Invalid latitude value.';
            }

            if (field.name === 'longitude' && (isNaN(parseFloat(field.value)) || Math.abs(parseFloat(field.value)) > 180)) {
                errlongitude = 'Invalid longitude value.';
            }
        });

        // Set the error messages in state
        setErrTab({
            errcustomerName,
            errlocation,
            errdescription,
            errlatitude,
            errlongitude,
            errImage,
        });

        // Check if any validation failed
        const validationFailed =
            errcustomerName !== '' ||
            errlocation !== '' ||
            errdescription !== '' ||
            errlatitude !== '' ||
            errlongitude !== '' ||
            errImage !== '';

        if (validationFailed) {
            setErrorMsg("Fields are Empty")
            setErrorSnackbarOpen(true)
            setButtonLoader(false)
            return;
        }


        axiosConfig
            .post(`/admin/testimonial`, userData, {
                headers: {
                    Authorization: authorization.authorizationUrl,
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                // console.log("add testimonial post response", response.data);
                // console.log("img file", file)
                let status = response.data.status
                if (status) {
                    setCustomerName('')
                    setFile(null)
                    setLocation('')
                    setDescription('')
                    setLatitude('')
                    setLongitude('')

                    setSuccessMsg("Testimony Created Successfully!")
                    setSuccessSnackbarOpen(true)
                    setButtonLoader(false)
                    setTimeout(() => {
                        history('/testimony')
                    }, 1000);
                } else {
                    setErrorMsg(response.data.message)
                    setErrorSnackbarOpen(true)
                    setButtonLoader(false)
                }

            })
            .catch(error => {
                console.log(error);
            });

    }

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            const droppedFile = e.dataTransfer.files[0];
            if (validateFile(droppedFile)) {
                setFile(droppedFile);
                displayFile(droppedFile);
                // console.log("droped log", droppedFile);
                setErrorFileUploader(false)
            } else {
                setErrorFileUploader(true);
            }
        }
    };

    const handleChange = (e) => {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            const selectedFile = e.target.files[0];
            if (validateFile(selectedFile)) {
                setFile(selectedFile);
                displayFile(selectedFile);
                // console.log(selectedFile); // log the file object to console
                setErrorFileUploader(false);
            } else {
                setErrorFileUploader(true);
            }
        }
    };


    const onButtonClick = () => {
        inputRef.current.click();
    };

    const displayFile = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            const img = document.getElementById("uploaded-img");
            img.src = e.target.result;
        };
    };

    const validateFile = (file) => {
        const allowedTypes = ["image/png", "image/jpeg", "image/gif", "image/bmp", "image/svg+xml", "image/webp", "image/x-icon"];
        const maxFileSize = 5 * 1024 * 1024; // 5MB

        if (!allowedTypes.includes(file.type)) {
            return false;
        }

        if (file.size > maxFileSize) {
            return false;
        }

        return true;
    };

    return (
        <>
            <div className="container-fluid d-flex">
                <div className="content-wrapper">
                    <div className="content-components-wrapper sub-page-details-wrapper">
                        {/* <TopHeaderMain title='Create New Testimony' /> */}
                        {fullScreenLoader ? <FullScreenLoader /> : ''}
                        <TopHeaderInternal title={`Create New Testimony`} linkToBackBtn={'/testimony'} />
                        <div className="user-content mt-4 mb-5">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-7 col-sm-12">
                                    <h5 className='sub-page-title'>Testimony Details</h5>
                                    <div className='around-border'>
                                        <div className="row">

                                            <div className="col-4"><p className="input-title" >Customer name :</p></div>
                                            <div className="col-8 mb-3">
                                                <Input
                                                    disableUnderline
                                                    sx={{
                                                        border: "1px solid #C2C8CC;",
                                                        borderRadius: 1,
                                                        padding: "8px 15px",
                                                        width: "100%",
                                                        maxWidth: "100%",
                                                    }}
                                                    className="table-inputs-content"
                                                    size="small"
                                                    placeholder="Enter Customer name"
                                                    value={customerName}
                                                    onChange={(e) => setCustomerName(e.target.value)}
                                                />
                                                <span className='error-msg'>{errTab.errcustomerName}</span>
                                            </div>
                                            <div className="col-4"><p className="input-title" >Image :</p></div>
                                            <div className="col-8 mb-3">

                                                <div>
                                                    <input
                                                        ref={inputRef}
                                                        type="file"
                                                        accept="image/png, image/jpeg, image/gif"
                                                        onChange={handleChange}
                                                        style={{ display: 'none' }}
                                                    />
                                                    <label
                                                        htmlFor="input-file-upload"
                                                        className={dragActive ? "drag-active label-file-upload" : "label-file-upload"}
                                                        onDragEnter={handleDrag}
                                                        onDragLeave={handleDrag}
                                                        onDragOver={handleDrag}
                                                        onDrop={handleDrop}
                                                    >
                                                        {file ? (
                                                            <Box sx={{ my: 2, width: '100%' }}>
                                                                <img src={URL.createObjectURL(file)} alt="Uploaded file" style={{ maxWidth: '450px', width: '100%' }} />
                                                                <p className="mt-1"
                                                                    style={{ fontSize: "0.7em", textAlign: "center" }}
                                                                > {file.name}</p>
                                                            </Box>
                                                        ) : (
                                                            <div className="text-center">
                                                                <span className="below-note">Drag and drop your image here or</span>
                                                                <Button
                                                                    className="upload-button"
                                                                    variant="text"
                                                                    component="span"
                                                                    onClick={onButtonClick}
                                                                >
                                                                    Upload a file
                                                                </Button>
                                                                <span className="below-note">Maximun Image size is 5MB*</span>
                                                            </div>
                                                        )}
                                                    </label>
                                                    {dragActive && (
                                                        <div
                                                            id="drag-file-element"
                                                            onDragEnter={handleDrag}
                                                            onDragLeave={handleDrag}
                                                            onDragOver={handleDrag}
                                                            onDrop={handleDrop}
                                                        ></div>
                                                    )}
                                                    {errorFileUploader && <p>Invalid file type or size</p>}
                                                </div>
                                                <span className='error-msg'>{errTab.errImage}</span>
                                            </div>
                                            <div className="col-4"><p className="input-title" >Location :</p></div>
                                            <div className="col-8 mb-3">
                                                <Input disableUnderline
                                                    sx={{
                                                        border: "1px solid #C2C8CC;",
                                                        borderRadius: 1,
                                                        padding: "8px 15px",
                                                        width: "100%",
                                                        maxWidth: "100%",
                                                    }}
                                                    className="table-inputs-content"
                                                    size="small" placeholder='Enter Location' value={location} onChange={(e) => setLocation(e.target.value)} />
                                                <span className='error-msg'>{errTab.errlocation}</span>
                                            </div>
                                            <div className="col-4"><p className='input-title detail-title-en'>Description :</p></div>
                                            <div className="col-8 mb-3">
                                                <Input disableUnderline
                                                    sx={{
                                                        border: "1px solid #C2C8CC;",
                                                        borderRadius: 1,
                                                        padding: "8px 15px",
                                                        width: "100%",
                                                        maxWidth: "100%",
                                                    }}
                                                    multiline
                                                    minRows={6}
                                                    maxRows={8}
                                                    className="table-inputs-content"
                                                    size="small" placeholder='Enter description' value={description} onChange={(e) => setDescription(e.target.value)} />
                                                <span className='error-msg'>{errTab.errdescription}</span>
                                            </div>
                                            <div className="col-4"><p className="input-title" >Latitude :</p></div>
                                            <div className="col-8 mb-3">
                                                <Input disableUnderline
                                                    sx={{
                                                        border: "1px solid #C2C8CC;",
                                                        borderRadius: 1,
                                                        padding: "8px 15px",
                                                        width: "100%",
                                                        maxWidth: "100%",
                                                    }}
                                                    className="table-inputs-content"
                                                    size="small" placeholder='Enter Latitude' value={latitude} onChange={(e) => setLatitude(e.target.value)} />
                                                <span className='error-msg'>{errTab.errlatitude}</span>
                                            </div>
                                            <div className="col-4"><p className="input-title" >Longitude :</p></div>
                                            <div className="col-8 mb-3">
                                                <Input disableUnderline
                                                    sx={{
                                                        border: "1px solid #C2C8CC;",
                                                        borderRadius: 1,
                                                        padding: "8px 15px",
                                                        width: "100%",
                                                        maxWidth: "100%",
                                                    }}
                                                    className="table-inputs-content"
                                                    size="small" placeholder='Enter Longitude' value={longitude} onChange={(e) => setLongitude(e.target.value)} />
                                                <span className='error-msg'>{errTab.errlongitude}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='submit-button-sub-page mb-4'>
                                        <button className={`PrimaryButton ${buttonloader ? 'button-loader' : ' '}`} type="submit" variant="contained" onClick={handleCreateTestimony} fullWidth>
                                            {!buttonloader ? 'Submit' : ''}
                                        </button>
                                    </div>
                                    <Snackbar open={errorSnackbarOpen} autoHideDuration={3000} onClose={() => setErrorSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                                        <Alert severity='error' sx={{ width: '100%' }}>
                                            {errorMsg}
                                        </Alert>
                                    </Snackbar>
                                    <Snackbar open={successSnackbarOpen} autoHideDuration={3000} onClose={() => setSuccessSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                                        <Alert severity='success' sx={{ width: '100%' }}>
                                            {successMsg}
                                        </Alert>
                                    </Snackbar>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
