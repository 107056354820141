import React, { useState } from 'react'
import { FormControl, MenuItem, Select, styled, TextField, Autocomplete, InputAdornment, IconButton, DatePicker } from '@mui/material'
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { countries } from '../../../Layout/country'
import TopHeaderMain from '../../../Layout/TopHeader/TopHeaderMain'

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';



const StyledFormControl = styled(FormControl)({
    // width: '100%',
    marginBottom: '1rem',
});


export default function EditBooking() {
    const [adultCount, setAdultCount] = useState(1);
    const [childCount, setChildCount] = useState(0);
    const [phone, setPhone] = useState();
    const [totalTravelers, setTotalTravelers] = useState();

    const [country, setCountry] = useState(null);

    const [adultDetails, setAdultDetails] = useState([]);
    const [bookingFormData, setBookingFormData] = useState({
        tourName: '',
        date: '',
        pricePerPerson: 0,
        totalPrice: 0,
        status: '',
        totalTraveller: 0,
        adultDetails: [],
        childDetails: [], // add childDetails array to state
    });

    const handleAdultDetailsChange = (event, index) => {
        const { name, value } = event.target;
        const updatedDetails = [...adultDetails];
        updatedDetails[index] = {
            ...updatedDetails[index],
            [name]: value
        };
        setAdultDetails(updatedDetails);

        setBookingFormData(prevFormData => ({
            ...prevFormData,
            adultDetails: updatedDetails
        }));
    };

    const renderAdultDetails = () => {
        return [...Array(adultCount)].map((_, index) => {
            const adult = adultDetails[index] || {};
            return (
                <div key={index} className='adult-details-wrapper-count'>
                    <h5>Adult-{index + 1} Details</h5>
                    <div className="row align-items-center">
                        <div className="col-4"><p>Name</p></div>
                        <div className="col-8">
                            <TextField
                                className='mb-3'
                                size='small'
                                name="name"
                                value={adult.name || ''}
                                onChange={(e) => handleAdultDetailsChange(e, index)}
                            />
                        </div>
                        <div className="col-4"><p>Email</p></div>
                        <div className="col-8">
                            <TextField
                                type='email'
                                className='mb-3'
                                size='small'
                                name="email"
                                value={adult.email || ''}
                                onChange={(e) => handleAdultDetailsChange(e, index)}
                            />
                        </div>
                        <div className="col-4"><p>Phone</p></div>
                        <div className="col-8">
                            <PhoneInput
                                international
                                countryCallingCodeEditable={true}
                                defaultCountry="SA"
                                value={adult.phone || ''}
                                onChange={(value) => handleAdultDetailsChange({ target: { name: 'phone', value } }, index)}
                                className="booking-phone-input"
                                style={{
                                    maxWidth: '250px',
                                    marginBottom: '1rem'
                                }}
                            />
                        </div>
                        <div className="col-4"><p>Country</p></div>
                        <div className="col-8">
                            <Autocomplete
                                size='small'
                                name="country"
                                value={adult.country || null}
                                onChange={(e, value) => handleAdultDetailsChange({ target: { name: 'country', value } }, index)}
                                options={countries}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Select Your Country"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>
            );
        });
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setBookingFormData({
            ...bookingFormData,
            [name]: value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(bookingFormData); // prints the updated form data to the console
    };

    const resetForm = () => {
        setBookingFormData({
            tourName: '',
            date: '',
            pricePerPerson: 0,
            totalPrice: 0,
            status: '',
            totalTraveller: 0,
            adultDetails: [],
            childDetails: [],
        });
        setAdultDetails({})
    }

    return (
        <>
            <div className="container-fluid d-flex">
                <div className="content-wrapper">
                    <div className="content-components-wrapper">
                        <TopHeaderMain title={`Edit Booking `} />
                        <div className="bookings-wrapper mt-4">
                            <div className="row">
                                <div className="col-xl-7 col-lg-9 col-md-10 col-sm-12" >
                                    <h5>Tour Details</h5>
                                    <form className='booking-form-wrapper' onSubmit={handleSubmit}>
                                        <div className="booking-form">
                                            <div className="row align-items-center">
                                                <div className="col-4"><p>Tour Name</p></div>
                                                <div className="col-8">
                                                    <Autocomplete
                                                        className='mb-3'
                                                        name="tourName"
                                                        options={['Tour 1', 'Tour 2', 'Tour 3']}
                                                        getOptionLabel={(option) => option}
                                                        renderInput={(params) => <TextField {...params} size="small" />}
                                                        onChange={(event, value) =>
                                                            setBookingFormData({ ...bookingFormData, tourName: value })
                                                        }
                                                    />
                                                </div>
                                                <div className="col-4"><p>Select Date </p></div>
                                                <div className="col-8">
                                                    <TextField
                                                        className='mb-3'
                                                        name="date"
                                                        size='small'
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className="col-4"><p>Price <span className='lite-color'>(Per Person)</span></p></div>
                                                <div className="col-8">
                                                    <TextField
                                                        className='mb-3'
                                                        name="pricePerPerson"
                                                        size='small'
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className="col-4"><p>Total Price</p></div>
                                                <div className="col-8">
                                                    <TextField
                                                        className='mb-3'
                                                        name="totalPrice"
                                                        size='small'
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className="col-4"><p>Status</p></div>
                                                <div className="col-8">
                                                    <StyledFormControl variant="outlined">
                                                        <Select
                                                            size="small"
                                                            id="status-select"
                                                            defaultValue="confirmed"
                                                            name="status"
                                                            onChange={handleInputChange}
                                                        >
                                                            <MenuItem value="confirmed">Confirmed</MenuItem>
                                                            <MenuItem value="pending">Pending</MenuItem>
                                                            <MenuItem value="cancelled">Cancelled</MenuItem>
                                                        </Select>
                                                    </StyledFormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <h5>Traveler Details</h5>
                                        <div className="traveler-details-wrapper">
                                            <div className="row align-center">
                                                <div className="col-4"><p>Adult traveler</p></div>
                                                <div className="col-8">
                                                    <TextField
                                                        type="number"
                                                        className="mb-3"
                                                        size="small"
                                                        value={adultCount}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="decrease"
                                                                        onClick={() => setAdultCount(adultCount - 1)}
                                                                        edge="end"
                                                                    >
                                                                        <RemoveIcon />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        aria-label="increase"
                                                                        onClick={() => setAdultCount(adultCount + 1)}
                                                                        edge="end"
                                                                    >
                                                                        <AddIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onChange={(e) => setAdultCount(parseInt(e.target.value))}
                                                    />

                                                </div>
                                                <div className="col-4"><p>Child traveler</p></div>
                                                <div className="col-8">
                                                    <TextField
                                                        type='number'
                                                        className='mb-3'
                                                        size='small'
                                                        value={childCount}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="decrease"
                                                                        onClick={() => setChildCount(childCount - 1)}
                                                                        edge="end"
                                                                    >
                                                                        <RemoveIcon />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        aria-label="increase"
                                                                        onClick={() => setChildCount(childCount + 1)}
                                                                        edge="end"
                                                                    >
                                                                        <AddIcon />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        onChange={(e) => setChildCount(parseInt(e.target.value))}
                                                    />
                                                </div>
                                            </div>
                                            <div className="total-traveler-count">
                                                <h5>Total Traveler: {adultCount + childCount} </h5>
                                            </div>
                                        </div>
                                        {adultCount > 0 && (
                                            <div className="travelers-adult-get-info">
                                                {renderAdultDetails()}
                                            </div>
                                        )}
                                        {childCount > 0 &&
                                            <div className="travelers-child-get-info">
                                                {[...Array(childCount)].map((_, index) =>
                                                    <div key={index}>
                                                        <h5>Child-{index + 1} Details</h5>
                                                        <div className="row align-items-center">
                                                            <div className="col-4"><p>Name</p></div>
                                                            <div className="col-8">
                                                                <TextField
                                                                    className='mb-3'
                                                                    // label="Name"
                                                                    size='small'
                                                                    onChange={(e) => {
                                                                        const childName = e.target.value;
                                                                        setBookingFormData(prevState => {
                                                                            const childDetails = [...prevState.childDetails];
                                                                            childDetails[index] = { ...childDetails[index], name: childName };
                                                                            return { ...prevState, childDetails };
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        }
                                        <div className="submit-reset-button">
                                            <button type="reset" className='SecondaryButton' onClick={resetForm}>Reset</button>
                                            <button type="submit" className="mr-3 PrimaryButton">Submit Booking</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
