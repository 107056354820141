import React, { useState, useEffect } from 'react';
import TopHeaderInternal from '../../../../Layout/TopHeader/TopHeaderInternal'
import {
    Input,
    Snackbar,
    Alert,
} from '@mui/material';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import authorization from '../../../../authorization';
import axiosConfig from '../../../../axios';
import DeleteModal from '../../../../Layout/Modal/Modal';

export default function ContactUsDetails() {
    const [userData, setUserData] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);

    let { id } = useParams();
    const history = useNavigate();
    var gotFromUrlId = id;

    useEffect(() => {
        handleGetData();
    }, []);

    const handleGetData = () => {
        axiosConfig
            .get(`/admin/contact/query/details/${gotFromUrlId}`, {
                headers: {
                    'Authorization': authorization.authorizationUrl
                },
            })
            .then((response) => {
                // console.log("ContactUsDetails value", response.data.data);
                setUserData(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    function formatDate(dateStr) {
        const dateObj = new Date(dateStr);
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const formattedDate = dateObj.toLocaleDateString('en-GB', options);
        return formattedDate;
    }

    const handleDelete = (id) => {
        setSelectedId(id);
        setShowModal(true);
    };

    const handleDeleteClick = () => {
        axiosConfig.delete(`/admin/contact/query/delete/${selectedId}`, {
            headers: {
                'Authorization': authorization.authorizationUrl
            }
        })
            .then(response => {
                if (response.data.status) {
                    setShowModal(false);
                    setOpenSnackbar(true);
                    setTimeout(() => {
                        history('/inquiry/contact-us');
                    }, 500);
                } else {
                    setOpenErrorSnackbar(true);
                }
            })
            .catch(error => {
                setOpenErrorSnackbar(true);
                console.log(error);
            });
    };

    const close = () => {
        setShowModal(false);
    };

    return (
        <>
            <div className="container-fluid d-flex">
                <div className="content-wrapper">
                    <div className="content-components-wrapper">
                        <TopHeaderInternal title={`Contact Us Details`} linkToBackBtn={`/inquiry/contact-us`} />
                        <div className="user-content sub-page-details-wrapper mt-4">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-7 col-sm-12">
                                    <h5 className='sub-page-title'>Form Details</h5>
                                    <div className='around-border'>
                                        {userData ? (
                                            <div className="row">
                                                <div className="col-4">
                                                    <p className='input-title'>Date:</p>
                                                </div>
                                                <div className="col-8">
                                                    <Input
                                                        disableUnderline
                                                        sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                        size='small'
                                                        className='table-inputs-content mb-3'
                                                        value={formatDate(userData.created_at)}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <p className='input-title'>Name:</p>
                                                </div>
                                                <div className="col-8">
                                                    <Input
                                                        disableUnderline
                                                        sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                        size='small'
                                                        className='table-inputs-content mb-3'
                                                        value={userData.name}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <p className='input-title'>Email:</p>
                                                </div>
                                                <div className="col-8">
                                                    <Input
                                                        disableUnderline
                                                        sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                        size='small'
                                                        className='table-inputs-content mb-3'
                                                        value={userData.email}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <p className='input-title'>Phone:</p>
                                                </div>
                                                <div className="col-8">
                                                    <Input
                                                        disableUnderline
                                                        sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                        size='small'
                                                        className='table-inputs-content mb-3'
                                                        value={userData.phone_number}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <p className='input-title'>Country:</p>
                                                </div>
                                                <div className="col-8">
                                                    <Input
                                                        disableUnderline
                                                        sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                        size='small'
                                                        className='table-inputs-content mb-3'
                                                        value={userData.country}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <p className='input-title'>Message:</p>
                                                </div>
                                                <div className="col-8">
                                                    <Input
                                                        disableUnderline
                                                        sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                        size='small'
                                                        minRows={3}
                                                        maxRows={7}
                                                        className='table-inputs-content mb-3'
                                                        multiline="true"
                                                        value={userData.message}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <button className='DeleteButton' onClick={() => handleDelete(userData.id)}>
                                                        <DeleteForeverOutlinedIcon /> Delete
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            <p className='input-title'>Loading user data...</p>
                                        )}
                                    </div>
                                    <Snackbar
                                        open={openSnackbar}
                                        autoHideDuration={7000}
                                        onClose={() => setOpenSnackbar(false)}
                                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                    >
                                        <Alert
                                            
                                            severity="success"
                                            sx={{ width: "100%" }}
                                        >
                                            Contact Inquiry Deleted successfully!
                                        </Alert>
                                    </Snackbar>
                                    <Snackbar
                                        open={openErrorSnackbar}
                                        autoHideDuration={7000}
                                        onClose={() => setOpenErrorSnackbar(false)}
                                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                    >
                                        <Alert
                                            
                                            severity="error"
                                            sx={{ width: "100%" }}
                                        >
                                            Delete failed!
                                        </Alert>
                                    </Snackbar>
                                    <DeleteModal
                                        showModal={showModal}
                                        onClose={close}
                                        onSubmit={handleDeleteClick}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}