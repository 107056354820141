import React, { useState } from "react";
import TopHeaderMain from "../../Layout/TopHeader/TopHeaderMain";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  FormControl,
  TableSortLabel,
  TableFooter,
  Checkbox,
  IconButton,
  Pagination,
  Snackbar,
  Alert,
  Input,
  Button,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import PeopleIcon from "@mui/icons-material/People";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import DeleteModal from "../../Layout/Modal/Modal";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect } from "react";
import axiosConfig from "../../axios";
import authorization from "../../authorization";
import ClearIcon from '@mui/icons-material/Clear'; // Import the ClearIcon from Material-UI icons
import FullScreenLoader from "../../Layout/FullScreenLoader/FullScreenLoader";


const StyledTextField = styled(TextField)({
  width: "100%",
  marginBottom: "1rem",
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  marginBottom: "1rem",
});

export default function TourList() {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [sortBy, setSortBy] = useState(null);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortOrder, setSortOrder] = useState("asc");
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [totalPages, setTotalPages] = useState();

  const [selectedId, setSelectedId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [fullScreenLoader, setFullScreenLoader] = useState(true);

  const [searchbarData, setSearchbarData] = useState()

  useEffect(() => {
    tourListCall()
  }, [page, searchbarData]);

  const tourListCall = () => {
    axiosConfig
      .get(`/admin/tours?page=${page}${searchbarData ? `&search=${searchbarData}` : ''}&sort_to=created_at`, {
        headers: {
          Authorization: authorization.authorizationUrl,
        },
      })
      .then((response) => {
        setUsers(response.data.data.data);
        setTotalPages(response.data.data.last_page);
        setTimeout(() => {
          setFullScreenLoader(false)
        }, 800);
        console.log("response.data.data.data", response.data.data.data)
      })
      .catch((error) => console.log(error));
  }

  const handleSearchBar = (e) => {
    const newValue = e.target.value;
    setSearchbarData(newValue); // Update the state with the new search value
    setPage(1)
    // setTimeout(() => {
    //   // console.log("searchbar value", newValue); // Log the new search value
    //   tourListCall(); // Call the tour list function after a short delay with the updated search value
    // }, 400);
  };
  const handleClearSearch = () => {
    setSearchbarData(''); // Clear the search bar
    setPage(1)
    tourListCall(); // Call the tour list function with empty search term
  };

  const handleDeleteClick = (id) => {
    setSelectedId(id);
    setShowModal(true);
  };

  const handleDeleteTour = () => {
    axiosConfig
      .delete(`/admin/tours/${selectedId}`, {
        headers: {
          Authorization: authorization.authorizationUrl,
        },
      })
      .then((response) => {
        // Fetch the updated list of users
        axiosConfig
          .get(`/admin/tours?page=${page}&sortby=DESC`, {
            headers: {
              Authorization: authorization.authorizationUrl,
            },
          })
          .then((response) => {
            setUsers(response.data.data.data);
            setTotalPages(response.data.data.last_page);
            setOpenSnackbar(true);
          })
          .catch((error) => console.log(error));

      })
      .catch((error) => console.log(error));
    setShowModal(false);
  };

  const close = () => {
    setShowModal(false);
  };

  const history = useNavigate();
  const handleEditClick = (id) => {
    // logic to handle edit click
    history(`/tours/edit-tour/${id}`);
  };
  const handleDetailClick = (id) => {
    history(`/tours/tour-details/${id}`);
  };
  // const handleSortClick = (column) => {
  //   if (sortColumn === column) {
  //     setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  //   } else {
  //     setSortColumn(column);
  //     setSortOrder("asc");
  //   }
  // };
  // const sortedUsers = users.sort((a, b) => {
  //   if (sortOrder === "asc") {
  //     return a[sortColumn] > b[sortColumn] ? 1 : -1;
  //   } else {
  //     return a[sortColumn] < b[sortColumn] ? 1 : -1;
  //   }
  // });



  return (
    <>
      <div className="container-fluid d-flex">
        <div className="content-wrapper">
          <div className="content-components-wrapper">
            <TopHeaderMain title={`Tour List `} />

            <div className="tour-wrapper mt-4">
              <div className="row">
                <div className="col-3">
                  <div className="position-relative">
                    <Input
                      disableUnderline
                      sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                      className='table-inputs-content mb-4'
                      size='small'
                      placeholder='Search'
                      required
                      fullWidth
                      value={searchbarData}
                      onChange={handleSearchBar}
                    />
                    {searchbarData && ( // Render the ClearIcon only if there's a search term
                      <IconButton onClick={handleClearSearch}
                        sx={{ position: 'absolute', top: '30%', right: '0px', transform: 'translateY(-50%)' }}
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                  </div>
                </div>
                <div className="col-5">
                  {/* <StyledFormControl variant="outlined">
                                        <InputLabel id="status-select-label">Filter by status</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="status-select-label"
                                            id="status-select"
                                            value={selectedStatus}
                                            onChange={handleStatusSelectChange}
                                            label="Filter by status"
                                        >
                                            <MenuItem value="all">All</MenuItem>
                                            <MenuItem value="Confirmed">Confirmed</MenuItem>
                                            <MenuItem value="Cancelled">Cancelled</MenuItem>
                                        </Select>
                                    </StyledFormControl> */}
                </div>
                <div className="col-4 text-end">
                  <Link to="/tours/create-new-tour">
                    <button className="PrimaryButton">
                      <AddIcon />
                      Create Tour
                    </button>
                  </Link>
                </div>
              </div>
              <div className="user-table-content">
                <TableContainer
                  component={Paper}
                  sx={{ boxShadow: "none", border: "1px solid #E4E4E4" }}
                >
                  <Table>
                    <TableHead style={{ backgroundColor: "#F2F2F2" }}>
                      <TableRow>
                        {/* <TableCell>Sr. No</TableCell> */}
                        <TableCell>
                          {/* <TableSortLabel
                            active={sortBy === "id"}
                            direction={sortOrder}
                            // onClick={() => handleSortClick("id")}
                          > */}
                          Tour Id
                          {/* </TableSortLabel> */}
                        </TableCell>
                        <TableCell>Tour Name</TableCell>
                        <TableCell>
                          {/* <TableSortLabel
                            active={sortBy === "price"}
                            direction={sortOrder}
                            onClick={() => handleSortClick("price")}
                          > */}
                          Price
                          {/* </TableSortLabel> */}
                        </TableCell>

                        {/* <TableCell>Phone no</TableCell> */}
                        <TableCell>
                          {/* <TableSortLabel
                            active={sortBy === "days"}
                            direction={sortOrder}
                            onClick={() => handleSortClick("days")}
                          > */}
                          Days
                          {/* </TableSortLabel> */}
                        </TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    {fullScreenLoader ? <FullScreenLoader /> :
                      <>
                        {
                          users.length > 0 ? (
                            <TableBody className="table-body-wrapper tours-table-body-wrapper">
                              {users.map((user, index) => (
                                <TableRow key={user.id}>
                                  {/* <TableCell>{index + 1}</TableCell> */}
                                  <TableCell className="table-data tours-table-data-id">
                                    {user.id}
                                  </TableCell>
                                  <TableCell className="table-data">
                                    {user.title_en}
                                  </TableCell>
                                  <TableCell className="table-data">
                                    {user.price === null ? '0' : user.price} SAR
                                  </TableCell>

                                  <TableCell className="table-data">
                                    {user.days}
                                  </TableCell>
                                  <TableCell className="table-data">
                                    {user.location}
                                  </TableCell>
                                  <TableCell className="table-data">
                                    <span
                                    className={
                                      user.isPublished === "true"
                                        ? "thisActive"
                                        : "thisInactive"
                                    }
                                  >
                                    {user.isPublished === "true" ? "Active" : "Inactive"}
                                  </span>
                                  </TableCell>
                                  <TableCell className="action-dropdown-btn">
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        variant="secondary"
                                        id="dropdown-basic"
                                        size="sm"
                                        style={{
                                          color: "#000000",
                                          border: "none",
                                          background: "transparent",
                                        }}
                                      >
                                        <MoreVertIcon />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item className="px-0">
                                          <Button
                                            color="primary"
                                            aria-label="View"
                                            onClick={() => handleDetailClick(user.id)}
                                            style={{ fontSize: "14px", width: "100%", justifyContent: "start", paddingLeft: "18px" }}
                                            startIcon={<InfoIcon style={{ fontSize: 16 }} />}
                                          >
                                            View
                                          </Button>
                                        </Dropdown.Item>
                                        <Dropdown.Item className="px-0">
                                          <Button
                                            color="primary"
                                            aria-label="Edit"
                                            onClick={() => handleEditClick(user.id)}
                                            style={{ fontSize: "14px", width: "100%", justifyContent: "start", paddingLeft: "18px" }}
                                            startIcon={<EditIcon style={{ fontSize: 16 }} />}
                                          >
                                            Edit
                                          </Button>
                                        </Dropdown.Item>
                                        <Dropdown.Item className="px-0">
                                          <Button
                                            color="secondary"
                                            aria-label="Delete"
                                            onClick={() => handleDeleteClick(user.id)}
                                            style={{ fontSize: "14px", width: "100%", justifyContent: "start", paddingLeft: "18px" }}
                                            startIcon={<DeleteIcon style={{ fontSize: 16 }} />}
                                          >
                                            Delete
                                          </Button>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          ) : (
                            <TableBody>
                              <TableRow>
                                <TableCell colSpan={6} align="center">
                                  No Tours found.
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          )
                        }
                      </>
                    }
                  </Table>
                </TableContainer>

                <div className="pagination-wrapper">
                  {totalPages > 1 ? (
                    <Pagination
                      className="custom-pagination"
                      count={totalPages}
                      variant="outlined"
                      shape="rounded"
                      page={page}
                      onChange={(event, newPage) => setPage(newPage)}
                    />
                  ) : (
                    ""
                  )}

                </div>
              </div>
              <DeleteModal
                showModal={showModal}
                onClose={close}
                onSubmit={handleDeleteTour}
              />
              <Snackbar
                open={openSnackbar}
                autoHideDuration={7000}
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <Alert

                  severity="success"
                  sx={{ width: "100%" }}
                >
                  Tour Deleted successfully!
                </Alert>
              </Snackbar>
              <Snackbar
                open={openErrorSnackbar}
                autoHideDuration={7000}
                onClose={() => setOpenErrorSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <Alert

                  severity="error"
                  sx={{ width: "100%" }}
                >
                  Delete failed!
                </Alert>
              </Snackbar>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
