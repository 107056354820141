import React, { useState, useRef } from 'react';
import {
    TextField,
    Button,
    Box,
    IconButton,
    InputAdornment,
    Input,
    Snackbar,
    Alert,
} from '@mui/material';
import TopHeaderInternal from '../../../../Layout/TopHeader/TopHeaderInternal';
import axiosConfig from '../../../../axios';
import authorization from '../../../../authorization';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FullScreenLoader from '../../../../Layout/FullScreenLoader/FullScreenLoader';

export default function CreateCategory() {
    const [dragActive, setDragActive] = useState(false);
    const [file, setFile] = useState(null);
    const [createCategory, setCreateCategory] = useState('');
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [errorFileUploader, setErrorFileUploader] = useState(false);

    const [categoryNameError, setCategoryNameError] = useState('');
    const [fileError, setFileError] = useState('');
    const [buttonloader, setButtonLoader] = useState(false)
    const [fullScreenLoader, setFullScreenLoader] = useState(true)
    const inputRef = useRef(null);

    let history = useNavigate()

    useEffect(() => {
        setTimeout(() => {
            setFullScreenLoader(false)
        }, 800);

    }, [])


    const HandleSubmitData = (e) => {
        e.preventDefault()
        setButtonLoader(true)
        let title_english = createCategory
        // Reset error messages
        setCategoryNameError('');
        setFileError('');

        // Additional validations
        let HandleError = false
        if (!createCategory) {
            // console.log('Category name is required.')
            setCategoryNameError('Category name is required.');
            HandleError = true

        } else if (createCategory.length < 3) {
            // console.log('Category name must be at least 3 characters long.')
            setCategoryNameError('Category name must be at least 3 characters long.');
            HandleError = true
        }
        if (!file) {
            // console.log('Please select an image file.')
            setFileError('Please select an image file.');
            HandleError = true
        }

        if (HandleError) {
            setButtonLoader(false)
            return;
        }

        const theData = {
            title_en: createCategory,
            // title_ar: title_english,
            // title_ja: title_english,
            // title_it: title_english,
            image: file,
        }
        // console.log(theData)
        axiosConfig
            .post(`/admin/tour/category/store`, theData, {
                headers: {
                    'Authorization': authorization.authorizationUrl,
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((response) => {
                // console.log("create category", response.data);
                if (response.data.status) {
                    setSuccessSnackbarOpen(true)
                    setCategoryNameError('')
                    setFileError('')
                    setTimeout(() => {
                        setButtonLoader(false)
                        history('/tours/categories')
                    }, 800);
                } else {
                    setButtonLoader(false)
                    setErrorSnackbarOpen(true)
                }

            })
            .catch((error) => console.log(error));

    }




    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            const droppedFile = e.dataTransfer.files[0];
            if (validateFile(droppedFile)) {
                setFile(droppedFile);
                displayFile(droppedFile);
                // console.log("droped log", droppedFile);
                setErrorFileUploader(false)
            } else {
                setErrorFileUploader(true);
            }
        }
    };

    const handleChange = (e) => {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            const selectedFile = e.target.files[0];
            if (validateFile(selectedFile)) {
                setFile(selectedFile);
                displayFile(selectedFile);
                // console.log(selectedFile);
                setErrorFileUploader(false)
            } else {
                setErrorFileUploader(true);
            }
        }
    };

    const onButtonClick = () => {
        inputRef.current.click();
    };

    const displayFile = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            const img = document.getElementById("uploaded-img");
            img.src = e.target.result;
        };
    };

    const validateFile = (file) => {
        const allowedTypes = ["image/png", "image/jpeg", "image/gif", "image/bmp", "image/svg+xml", "image/webp", "image/x-icon"];
        const maxFileSize = 5 * 1024 * 1024; // 5MB

        if (!allowedTypes.includes(file.type)) {
            return false;
        }

        if (file.size > maxFileSize) {
            return false;
        }

        return true;
    };

    return (
        <>
            <div className="container-fluid d-flex">
                <div className="content-wrapper">
                    <div className="content-components-wrapper">
                        <TopHeaderInternal title={`Add New Categories `} linkToBackBtn={`/tours/categories`} />
                        <div className="tour-wrapper sub-page-details-wrapper mt-4">
                            {fullScreenLoader ? <FullScreenLoader /> : ''}
                            <div className="row">
                                <div className="col-xl-5 col-lg-6 col-md-8 col-sm-12">
                                    <form id="form-file-upload" onDragEnter={handleDrag}>
                                        <h5 className='sub-page-title'>Category Details In English</h5>
                                        <div className='create-category-inputs-wrapper around-border'>
                                            <div className="row">
                                                <div className="col-4"><p className='input-title'>Category Name:</p></div>
                                                <div className="col-8 mb-3">
                                                    <Input
                                                        disableUnderline
                                                        sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                        className='table-inputs-content'
                                                        size='small'
                                                        placeholder='Enter category name'
                                                        required
                                                        fullWidth
                                                        value={createCategory}
                                                        onChange={(e) => setCreateCategory(e.target.value)}
                                                    />
                                                    {categoryNameError ? <span className='error-msg'>{categoryNameError}</span> : ""}
                                                </div>
                                                <div className="col-4"><p className='input-title'>Cover Image:</p></div>
                                                <div className="col-8 mb-3">
                                                    <div>
                                                        <input
                                                            ref={inputRef}
                                                            type="file"
                                                            accept="image/png, image/jpeg, image/gif"
                                                            onChange={handleChange}
                                                            style={{ display: 'none' }}
                                                        />
                                                        <label
                                                            htmlFor="input-file-upload"
                                                            className={dragActive ? "drag-active label-file-upload" : "label-file-upload"}
                                                            onDragEnter={handleDrag}
                                                            onDragLeave={handleDrag}
                                                            onDragOver={handleDrag}
                                                            onDrop={handleDrop}
                                                        >
                                                            {file ? (
                                                                <Box sx={{ mt: 2, textAlign: 'center', px: 2 }}>
                                                                    <img src={URL.createObjectURL(file)} alt="Uploaded file" style={{ maxWidth: '320px' }} />
                                                                    <p className='pt-2'>Selected file: {file.name}</p>
                                                                </Box>
                                                            ) : (
                                                                <div className="text-center">
                                                                    <span className="below-note m-0">Drag and drop your file here or</span>
                                                                    <Button
                                                                        className="upload-button"
                                                                        variant="text"
                                                                        component="span"
                                                                        onClick={onButtonClick}
                                                                    >
                                                                        Upload a file
                                                                    </Button>
                                                                    <span className="below-note">Upload Image Maximun size is 5MB*</span>
                                                                </div>
                                                            )}
                                                        </label>
                                                        {dragActive && (
                                                            <div
                                                                id="drag-file-element"
                                                                onDragEnter={handleDrag}
                                                                onDragLeave={handleDrag}
                                                                onDragOver={handleDrag}
                                                                onDrop={handleDrop}
                                                            ></div>
                                                        )}
                                                        {errorFileUploader && <span className='error-msg'>Invalid file type or size</span>}
                                                    </div>
                                                    {/* <input type='file' onChange={setTest}/> */}
                                                    {fileError ? <span className='error-msg'>{fileError}</span> : ""}
                                                </div>
                                                <div className="col-4"></div>

                                            </div>
                                        </div>
                                        <div className='submit-button-sub-page'>
                                            <button className={`PrimaryButton ${buttonloader ? 'button-loader' : ' '}`} type="submit" variant="contained" onClick={HandleSubmitData} fullWidth disabled={buttonloader}>
                                                {buttonloader ? '' : 'Submit'}
                                            </button>
                                        </div>
                                    </form>
                                    <Snackbar open={errorSnackbarOpen} autoHideDuration={3000} onClose={() => setErrorSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                                        <Alert severity="error" sx={{ width: '100%' }}>
                                            error
                                        </Alert>
                                    </Snackbar>
                                    <Snackbar open={successSnackbarOpen} autoHideDuration={3000} onClose={() => setSuccessSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                                        <Alert severity="success" sx={{ width: '100%' }}>
                                            Category successfully created!
                                        </Alert>
                                    </Snackbar>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}