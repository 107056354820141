import React, { useState, useEffect } from "react";
import {
  TextField, Input, Snackbar, Alert
} from "@mui/material";
import "react-phone-number-input/style.css";
import TopHeaderInternal from "../../../../Layout/TopHeader/TopHeaderInternal";
import axiosConfig from "../../../../axios";
import { useNavigate, useParams } from "react-router-dom";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import authorization from "../../../../authorization";
import DeleteModal from "../../../../Layout/Modal/Modal";



export default function InquiryDetails() {
  const [userData, setUserData] = useState([]);
  const [tourName, setTourName] = useState()

  const [selectedId, setSelectedId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [locationData, setLocationData] = useState([])

  const history = useNavigate();
  let { id } = useParams();
  var gotFromUrlId = id;
  const token = localStorage.getItem("Token-for-login");


  useEffect(() => {
    tourInquiryDetails()
  }, [gotFromUrlId, token]);

  const tourInquiryDetails = () => {
    axiosConfig
      .get(`/admin/tour-inquiry/${gotFromUrlId}`, {
        headers: {
          Authorization: authorization.authorizationUrl,
        },
      })
      .then((response) => {
        let tourId = response.data.data.tour_id
        // console.log("tour inquiry", response.data.data);
        setUserData(response.data.data);
        getTourData(tourId)
        return response.data.data
      })
      .catch((error) => console.log(error));
  }
  const getTourData = (tourId) => {
    axiosConfig
      .get(`/admin/tours/${tourId}`, {
        headers: {
          Authorization: authorization.authorizationUrl,
        },
      })
      .then((response) => {
        console.log("tour details", response.data);
        setTourName(response.data.data.toure_title_en)


      })
      .catch((error) => console.log(error));
  }


  const handleDeleteData = (id) => {
    // console.log("selectedId")
    setSelectedId(id);
    setShowModal(true);
  };

  const close = () => {
    setShowModal(false);
  };

  const handleDelete = () => {
    // console.log("selectedId", selectedId)
    axiosConfig.delete(`/admin/tour-inquiry/${selectedId}`, {
      headers: {
        'Authorization': authorization.authorizationUrl,
      }
    })
      .then(response => {
        // console.log("delete inquiry", response.data)
        if (response.data.status) {
          setShowModal(false);
          setOpenSnackbar(true)
          setTimeout(() => {
            history('/inquiry/tour-inquiry')
          }, 800);
        } else {
          setOpenErrorSnackbar(true)
        }
      })
      .catch(error => console.log(error));
  };


  // function formatDate(dateStr) {
  //   const dateObj = new Date(dateStr);

  //   const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  //   const formattedDate = dateObj.toLocaleDateString('en-GB', options);

  //   return formattedDate;
  // }

  return (
    <>
      <div className="container-fluid d-flex">
        <div className="content-wrapper">
          <div className="content-components-wrapper">
            <TopHeaderInternal
              title={`Tour Inquiry Details`}
              linkToBackBtn={"/inquiry/tour-inquiry"}
            />
            <div className="user-content sub-page-details-wrapper mt-4">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-7 col-sm-12">
                  <h5 className='sub-page-title'>Form Details</h5>
                  <div className='around-border'>
                    {userData ? (

                      <div className="row">
                        <div className="col-4">
                          <p className='input-title'>Tour Name:</p>
                        </div>
                        <div className="col-8">
                          {" "}
                          <Input
                            disableUnderline
                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                            size='small'
                            className='table-inputs-content mb-3'
                            value={tourName}
                            multiline
                            minRows={1}
                            maxRows={3}
                            readOnly
                          />{" "}
                        </div>
                        <div className="col-4">
                          <p className='input-title'>Name:</p>
                        </div>
                        <div className="col-8">
                          {" "}
                          <Input
                            disableUnderline
                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                            size='small'
                            className='table-inputs-content mb-3'
                            value={userData.name}
                            readOnly
                          />{" "}
                        </div>
                        <div className="col-4">
                          <p className='input-title'>Email:</p>
                        </div>
                        <div className="col-8">
                          {" "}
                          <Input
                            disableUnderline
                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                            size="small"
                            className="table-inputs-content mb-3"
                            value={userData.email}
                            readOnly
                          />{" "}
                        </div>
                        <div className="col-4">
                          <p className='input-title'>Phone:</p>
                        </div>
                        <div className="col-8">
                          {" "}
                          <Input
                            disableUnderline
                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                            size='small'
                            className='table-inputs-content mb-3'
                            value={userData.phone_number}
                            readOnly
                          />{" "}
                        </div>
                        <div className="col-4">
                          <p className='input-title'>Country:</p>
                        </div>
                        <div className="col-8">
                          {" "}
                          <Input
                            disableUnderline
                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                            size='small'
                            className='table-inputs-content mb-3'
                            value={userData.nationality}
                            readOnly
                          />{" "}
                        </div>

                        <div className="col-4">
                          <p className='input-title'>Travel Date:</p>
                        </div>
                        <div className="col-8">
                          {" "}
                          <Input
                            disableUnderline
                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                            size='small'
                            className='table-inputs-content mb-3'
                            value={userData.tour_date}
                            readOnly
                          />{" "}
                        </div>
                        <div className="col-4">
                          <p className='input-title'>No. of Days:</p>
                        </div>
                        <div className="col-8">
                          {" "}
                          <Input
                            disableUnderline
                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                            size='small'
                            className='table-inputs-content mb-3'
                            value={userData.no_of_days}
                            readOnly
                          />{" "}
                        </div>

                        <div className="col-4">
                          <button className="DeleteButton" onClick={() => handleDeleteData(userData.id)}>
                            <DeleteForeverOutlinedIcon /> Delete
                          </button>
                        </div>
                      </div>

                    ) : (
                      <p>Loading data...</p>
                    )}
                  </div>
                  <DeleteModal
                    showModal={showModal}
                    onClose={close}
                    onSubmit={handleDelete}
                  />
                  <Snackbar
                    open={openSnackbar}
                    autoHideDuration={7000}
                    onClose={() => setOpenSnackbar(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  >
                    <Alert
                      
                      severity="success"
                      sx={{ width: "100%" }}
                    >
                      Deleted successfully!
                    </Alert>
                  </Snackbar>
                  <Snackbar
                    open={openErrorSnackbar}
                    autoHideDuration={7000}
                    onClose={() => setOpenErrorSnackbar(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  >
                    <Alert
                      
                      severity="error"
                      sx={{ width: "100%" }}
                    >
                      Delete failed!
                    </Alert>
                  </Snackbar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
