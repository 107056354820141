import React, { useState, useEffect } from 'react';
import TopHeaderMain from '../../../Layout/TopHeader/TopHeaderMain'
import {
  Autocomplete,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Box,
  Button,
  Snackbar,
  Alert,
  styled,
  Tabs,
  Tab,
  InputLabel,
  Input
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useRef } from 'react';
import axiosConfig from '../../../axios';
import authorization from '../../../authorization';
import config from '../../../config';
import TopHeaderInternal from '../../../Layout/TopHeader/TopHeaderInternal';
import ReactQuill from 'react-quill';
import ImageField from '../../../Layout/ImageField/ImageField';
import axios from 'axios';
import FullScreenLoader from '../../../Layout/FullScreenLoader/FullScreenLoader';

const StyledFormControl = styled(FormControl)({
  // width: '100%',
  marginBottom: '1rem',
});

export default function EditBlog() {
  const [userData, setUserData] = useState([])
  const [updatedData, setUpdatedData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [dragActive, setDragActive] = useState(false);
  const [file, setFile] = useState(null);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorFileUploader, setErrorFileUploader] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);


  const [inputValuesTitle, setInputValuesTitle] = useState({
    category_id: '',
    en: '',
    ar: '',
    // it: '',
    // ja: '',
  });
  const [inputValuesDesc, setInputValuesDesc] = useState({
    en: '',
    ar: '',
    // it: '',
    // ja: '',
  });

  const [inputValuesMetaTitle, setInputValuesMetaTitle] = useState({
    en: '',
    ar: '',
    // it: '',
    // ja: '',
  });
  const [inputValuesMetaDesc, setInputValuesMetaDesc] = useState({
    en: '',
    ar: '',
    // it: '',
    // ja: '',
  });

  const [inputsCategory, setInputsCategory] = useState({
    id: '',
    title_en: '',
  })


  const [inputErrorsTitleEn, setTnputErrorsTitleEn] = useState('')
  const [inputErrorsTitleAr, setTnputErrorsTitleAr] = useState('')
  const [inputErrorsTitleIt, setTnputErrorsTitleIt] = useState('')
  const [inputErrorsTitleJa, setTnputErrorsTitleJa] = useState('')

  const [inputErrorsDescEn, setTnputErrorsDescEn] = useState('')
  const [inputErrorsDescAr, setTnputErrorsDescAr] = useState('')
  const [inputErrorsDescIt, setTnputErrorsDescIt] = useState('')
  const [inputErrorsDescJa, setTnputErrorsDescJa] = useState('')

  const [inputErrorImgEn, setInputErrorImgEn] = useState('')


  const [valErrTab, setValErrTab] = useState({
    en: false,
    ar: false,
    it: false,
    ja: false,
  });

  const [categoryError, setCategoryError] = useState('')
  const [buttonloader, setButtonLoader] = useState(false)
  const [fullScreenLoader, setFullScreenLoader] = useState(true)

  const [value, setValue] = React.useState('1');
  const inputRef = useRef(null);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  let { id } = useParams();
  var gotFromUrlId = id
  let history = useNavigate()

  useEffect(() => {

    axiosConfig
      .get(`/admin/category?page=1`, {
        headers: {
          'Authorization': authorization.authorizationUrl
        },
      })
      .then((response) => {
        // console.log("category value", response.data);
        setCategoryList(response.data.data.data);
      })
      .catch((error) => console.log(error));


    setTimeout(() => {
      setFullScreenLoader(false)
    }, 800);

  }, []);




  const validateInputs = () => {
    const errors = {};

    // Validation for Category ID
    if (!inputsCategory.id) {
      errors.categoryId = 'Category ID is required';
    }
    setValErrTab({
      en: false,
      ar: false,
      it: false,
      ja: false,
    });

    if (!inputsCategory.title_en) {
      setCategoryError(`Category is required`)
      // console.log("categoryERR")
    } else {
      setCategoryError('')
    }

    if (!file) {
      setInputErrorImgEn(`Cover Image is required`)
    } else {
      setInputErrorImgEn(` `)
    }

    // Validation for Titles
    Object.keys(inputValuesTitle).forEach((lang) => {
      const title = inputValuesTitle[lang];

      if (!title || title.length <= 3) {
        setValErrTab(prevValErrTab => ({
          ...prevValErrTab,
          [lang]: true,
        }));
        // console.log(errors[`title_${lang}`] = title ? `Title must be at least 3 characters` : `Title is required`)
        errors[`title_${lang}`] = title ? `Title must be at least 3 characters` : `Title is required`;
      }
    });

    // Validation for Descriptions
    Object.keys(inputValuesDesc).forEach((lang) => {
      const desc = inputValuesDesc[lang];

      if (!desc || desc.length <= 3) {
        setValErrTab(prevValErrTab => ({
          ...prevValErrTab,
          [lang]: true,
        }));
        // console.log(errors[`desc_${lang}`] = desc ? `Description must be at least 3 characters` : `Description is required`)
        errors[`desc_${lang}`] = desc ? `Description must be at least 3 characters` : `Description is required`;
      }
    });

    return errors;
  };

  const handleUpdate = (e) => {
    e.preventDefault()
    setButtonLoader(true)
    const errors = validateInputs();
    if (
      errors.title_en ||
      errors.title_ar ||
      // errors.title_it ||
      // errors.title_ja ||
      errors.desc_en ||
      errors.desc_ar ||
      // errors.desc_it ||
      // errors.desc_ja ||
      categoryError
    ) {
      // Set error messages to state variables
      setTnputErrorsTitleEn(errors.title_en || '');
      setTnputErrorsTitleAr(errors.title_ar || '');
      // setTnputErrorsTitleIt(errors.title_it || '');
      // setTnputErrorsTitleJa(errors.title_ja || '');

      setTnputErrorsDescEn(errors.desc_en || '');
      setTnputErrorsDescAr(errors.desc_ar || '');
      // setTnputErrorsDescIt(errors.desc_it || '');
      // setTnputErrorsDescJa(errors.desc_ja || '');

      setButtonLoader(false)
      // There are validation errors, do not make API call
      return;
    }

    // console.log("updatedData", updatedData, typeof (file))

    axiosConfig
      .post(`/admin/blog`, {
        // _method: "Put",
        category_id: inputsCategory.id,
        title_en: inputValuesTitle.en,
        title_ar: inputValuesTitle.ar,
        title_it: inputValuesTitle.en,
        title_ja: inputValuesTitle.en,
        content_en: inputValuesDesc.en,
        content_ar: inputValuesDesc.ar,
        content_it: inputValuesDesc.en,
        content_ja: inputValuesDesc.en,
        meta_title_en: inputValuesMetaTitle.en,
        meta_title_ar: inputValuesMetaTitle.ar,
        meta_title_it: inputValuesMetaTitle.en,
        meta_title_ja: inputValuesMetaTitle.en,
        meta_description_en: inputValuesMetaDesc.en,
        meta_description_ar: inputValuesMetaDesc.ar,
        meta_description_it: inputValuesMetaDesc.en,
        meta_description_ja: inputValuesMetaDesc.en,
        image: file,
      }, {
        headers: {
          Authorization: authorization.authorizationUrl,
          'Content-Type': 'multipart/form-data'
        },
      })
      .then((response) => {
        // console.log("onclick of value, response", response.data);
        if (response.data.status) {
          setUserData(response.data.data);
          setOpenSnackbar(true);
          setButtonLoader(false)
          setTimeout(() => {
            history('/blogs/blog-list')
          }, 800);
        } else {
          setErrorSnackbarOpen(true)
          setButtonLoader(false)
        }

      })
      .catch(error => {
        console.log(error);
        setOpenSnackbar(false);
      });

  };



  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const droppedFile = e.dataTransfer.files[0];
      if (validateFile(droppedFile)) {
        setFile(droppedFile);
        displayFile(droppedFile);
        // console.log("droped log", droppedFile);
        setErrorFileUploader(false)
      } else {
        setErrorFileUploader(true);
      }
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      if (validateFile(selectedFile)) {
        setFile(selectedFile);
        displayFile(selectedFile);
        // console.log(selectedFile);
        setErrorFileUploader(false)
      } else {
        setErrorFileUploader(true);
      }
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const displayFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      const img = document.getElementById("uploaded-img");
      img.src = e.target.result;
    };
  };

  const validateFile = (file) => {
    const allowedTypes = ["image/png", "image/jpeg", "image/gif", "image/bmp", "image/svg+xml", "image/webp", "image/x-icon"];
    const maxFileSize = 5 * 1024 * 1024; // 5MB

    if (!allowedTypes.includes(file.type)) {
      return false;
    }

    if (file.size > maxFileSize) {
      return false;
    }

    return true;
  };

  const modules = {
    toolbar: [
      [{ header: [4, 5, 6] }],
      [{ size: ['small', false, 'large', 'huge'] }], // Add font size selector
      ['bold', 'italic', 'underline', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image', 'video'],
      ['clean'],

    ],
    clipboard: {
      matchVisual: false,
    },
  };

  // const handleBlogFile = (event) => {
  //   const file = event.target.files;
  //   setSelectedFile(file);
  // };

  return (
    <>
      <div className="container-fluid d-flex">
        <div className="content-wrapper">
          <div className="content-components-wrapper">
            {/* <TopHeaderMain title='Edit Blog' /> */}
            {fullScreenLoader ? <FullScreenLoader /> : ''}
            <TopHeaderInternal title={`Create New Blog`} linkToBackBtn={'/blogs/blog-list'} />
            <div className="user-content mt-4">
              <div className="row">
                <div className="col-xl-6 col-lg-7 col-md-9 col-sm-12">
                  <TabContext value={value}>
                    <Tabs value={value} onChange={handleTabChange} variant="scrollable" scrollButtons allowScrollButtonsMobile aria-label="nav tabs example">
                      <Tab label="English" value="1" className={`error-msg ${valErrTab.en ? 'error-msg-dot' : ''}`} />
                      <Tab label="Arabic" value="2" className={`error-msg ${valErrTab.ar ? 'error-msg-dot' : ''}`} />
                      {/* <Tab label="Italian" value="3" className={`error-msg ${valErrTab.it ? 'error-msg-dot' : ''}`} /> */}
                      {/* <Tab label="Japanese" value="4" className={`error-msg ${valErrTab.ja ? 'error-msg-dot' : ''}`} /> */}
                    </Tabs>
                    <TabPanel className='p-3' value="1">

                      <div className="row english-details around-border mb-3">
                        <div className="col-4"><p className='input-title'>Title :</p></div>
                        <div className="col-8 mb-3">
                          <Input
                            disableUnderline
                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                            className='table-inputs-content'
                            size='small'
                            fullWidth
                            value={inputValuesTitle.en}
                            onChange={(e) =>
                              setInputValuesTitle((prevInputValues) => ({
                                ...prevInputValues,
                                en: e.target.value,
                              }))
                            }
                            placeholder={`Enter Title in English`}
                            required
                          />
                          <span className='error-msg'>{inputErrorsTitleEn}</span>
                        </div>

                        <div className="col-4"><p className='input-title'>Category :</p></div>
                        <div className="col-8 mb-3">
                          <Autocomplete
                            id="category"
                            size="small"
                            name="category"
                            value={inputsCategory?.title_en}
                            onChange={(event, newValue) => {
                              setInputsCategory({ id: newValue.id, title_en: newValue.title_en });
                              // console.log("onchange", newValue)
                            }}
                            options={categoryList}
                            getOptionLabel={(option) =>
                              option.title_en || option
                            }
                            isOptionEqualToValue={(option, value) =>
                              option.id === (value ? value.id : null)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select Faq Category"
                                variant="outlined"
                                style={{ maxWidth: "100%" }}
                              />
                            )}
                          />
                          <span className='error-msg'>{categoryError}</span>

                        </div>
                        <div className="col-4"><p className='input-title'>Cover Image :</p></div>
                        <div className="col-8 mb-3">
                          {/* <input type="file" onChange={handleBlogFile} /> */}

                          <div>
                            <input
                              ref={inputRef}
                              type="file"
                              accept="image/png, image/jpeg, image/gif, image/bmp, image/svg+xml, image/webp, image/x-icon"
                              onChange={handleChange}
                              style={{ display: 'none' }}
                            />
                            <label
                              htmlFor="input-file-upload"
                              className={dragActive ? "drag-active label-file-upload" : "label-file-upload"}
                              onDragEnter={handleDrag}
                              onDragLeave={handleDrag}
                              onDragOver={handleDrag}
                              onDrop={handleDrop}
                            >

                              <div>
                                {file ? (
                                  <Box sx={{ my: 2 }}>
                                    <img src={URL.createObjectURL(file)} alt="Uploaded file" style={{ maxWidth: '450px' }} />
                                    <p>Selected file: {file.name}</p>
                                  </Box>
                                ) : (
                                  <>
                                    {userData.image ?
                                      <Box sx={{ my: 2 }}><img src={config.imageBaseURL + userData.image} alt="Uploaded file" style={{ maxWidth: '450px' }} /></Box> : ''}
                                    <div className="text-center">
                                      <p className="m-0">Drag and drop your file here or</p>
                                      <Button
                                        className="upload-button"
                                        variant="text"
                                        component="span"
                                        onClick={onButtonClick}
                                      >
                                        Upload a file
                                      </Button>
                                    </div>
                                  </>
                                )}
                              </div>
                            </label>
                            {dragActive && (
                              <div
                                id="drag-file-element"
                                onDragEnter={handleDrag}
                                onDragLeave={handleDrag}
                                onDragOver={handleDrag}
                                onDrop={handleDrop}
                              ></div>
                            )}
                            {errorFileUploader && <span className='error-msg'>Invalid file type or size</span>}
                          </div>
                          <span className='error-msg'>{inputErrorImgEn}</span>
                        </div>
                        <div className="col-4"><p className='input-title'>Blog Description :</p></div>
                        <div className="col-8 mb-3">
                          <ReactQuill
                            className='create-blog-description-wrapper '
                            value={inputValuesDesc.en}
                            onChange={(newValue) =>
                              setInputValuesDesc((prevInputValues) => ({
                                ...prevInputValues,
                                en: newValue, // Update the 'en' property with the new value
                              }))
                            }
                            modules={modules}
                          />

                          <span className='error-msg'>{inputErrorsDescEn}</span>
                        </div>
                        <div className="col-4"><p className='input-title'>Meta Title :</p></div>
                        <div className="col-8 mb-3">
                          <Input
                            disableUnderline
                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                            className='table-inputs-content'
                            size='small'
                            fullWidth
                            value={inputValuesMetaTitle.en}
                            onChange={(e) =>
                              setInputValuesMetaTitle((prevInputValues) => ({
                                ...prevInputValues,
                                en: e.target.value,
                              }))
                            }
                            placeholder={`Enter Meta Title in English`}
                            required
                          />
                          <span className='error-msg'>{inputErrorsTitleEn}</span>
                        </div>
                        <div className="col-4"><p className='input-title'>Meta Description :</p></div>
                        <div className="col-8 mb-3">
                          <Input
                            disableUnderline
                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                            className='table-inputs-content'
                            size='small'
                            fullWidth
                            multiline
                            maxRows={6}
                            value={inputValuesMetaDesc.en}
                            onChange={(e) =>
                              setInputValuesMetaDesc((prevInputValues) => ({
                                ...prevInputValues,
                                en: e.target.value,
                              }))
                            }
                            placeholder={`Enter Meta Description in English`}
                            required
                          />
                          <span className='error-msg'>{inputErrorsTitleEn}</span>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel className='p-3' value="2">
                      <div className="row arabic-details around-border mb-3">

                        <div className="col-4"><p className='input-title'>Title :</p></div>
                        <div className="col-8 mb-3 ">
                          <Input
                            disableUnderline
                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                            className='table-inputs-content'
                            size='small'
                            fullWidth
                            value={inputValuesTitle.ar}
                            onChange={(e) =>
                              setInputValuesTitle((prevInputValues) => ({
                                ...prevInputValues,
                                ar: e.target.value,
                              }))
                            }
                            placeholder={`Enter Title in Arabic`}
                            required
                          />
                          <span className='error-msg'>{inputErrorsTitleAr}</span>
                        </div>

                        <div className="col-4"><p className='input-title'>Blog Description  :</p></div>
                        <div className="col-8 mb-3">
                          <ReactQuill
                            className='create-blog-description-wrapper '
                            value={inputValuesDesc.ar}
                            onChange={(newValue) =>
                              setInputValuesDesc((prevInputValues) => ({
                                ...prevInputValues,
                                ar: newValue, // Update the 'en' property with the new value
                              }))
                            }
                            modules={modules}
                          />
                          <span className='error-msg'>{inputErrorsDescAr}</span>
                        </div>
                        <div className="col-4"><p className='input-title'>Meta Title :</p></div>
                        <div className="col-8 mb-3">
                          <Input
                            disableUnderline
                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                            className='table-inputs-content'
                            size='small'
                            fullWidth
                            value={inputValuesMetaTitle.ar}
                            onChange={(e) =>
                              setInputValuesMetaTitle((prevInputValues) => ({
                                ...prevInputValues,
                                ar: e.target.value,
                              }))
                            }
                            placeholder={`Enter Meta Title in Arabic`}
                            required
                          />
                          <span className='error-msg'>{inputErrorsTitleEn}</span>
                        </div>
                        <div className="col-4"><p className='input-title'>Meta Description :</p></div>
                        <div className="col-8 mb-3">
                          <Input
                            disableUnderline
                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                            className='table-inputs-content'
                            size='small'
                            fullWidth
                            multiline
                            maxRows={6}
                            value={inputValuesMetaDesc.ar}
                            onChange={(e) =>
                              setInputValuesMetaDesc((prevInputValues) => ({
                                ...prevInputValues,
                                ar: e.target.value,
                              }))
                            }
                            placeholder={`Enter Meta Description in Arabic`}
                            required
                          />
                          <span className='error-msg'>{inputErrorsTitleEn}</span>
                        </div>
                      </div>
                    </TabPanel>
                    {/* <TabPanel className='p-3' value="3">
                      <div className="row italian-details around-border mb-3">

                        <div className="col-4"><p className='input-title'>Title :</p></div>
                        <div className="col-8 mb-3">
                          <Input
                            disableUnderline
                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                            className='table-inputs-content'
                            size='small'
                            fullWidth
                            value={inputValuesTitle.it}
                            onChange={(e) =>
                              setInputValuesTitle((prevInputValues) => ({
                                ...prevInputValues,
                                it: e.target.value,
                              }))
                            }
                            placeholder={`Enter Title in Italian`}
                            required
                          />
                          <span className='error-msg'>{inputErrorsTitleIt}</span>
                        </div>

                        <div className="col-4"><p className='input-title'>Blog Description  :</p></div>
                        <div className="col-8 mb-3">
                          <ReactQuill
                            className='create-blog-description-wrapper '
                            value={inputValuesDesc.it}
                            onChange={(newValue) =>
                              setInputValuesDesc((prevInputValues) => ({
                                ...prevInputValues,
                                it: newValue, // Update the 'en' property with the new value
                              }))
                            }
                            modules={modules}
                          />
                          <span className='error-msg'>{inputErrorsDescIt}</span>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel className='p-3' value="4">
                      <div className="row japanese-details around-border mb-3">

                        <div className="col-4"><p className='input-title'>Title :</p></div>
                        <div className="col-8 mb-3">
                          <Input
                            disableUnderline
                            sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                            className='table-inputs-content'
                            size='small'
                            fullWidth
                            value={inputValuesTitle.ja}
                            onChange={(e) =>
                              setInputValuesTitle((prevInputValues) => ({
                                ...prevInputValues,
                                ja: e.target.value,
                              }))
                            }
                            placeholder={`Enter Title in Japanese`}
                            required
                          />
                          <span className='error-msg'>{inputErrorsTitleJa}</span>
                        </div>

                        <div className="col-4"><p className='input-title'>Blog Description  :</p></div>
                        <div className="col-8 mb-3">
                          <ReactQuill
                            className='create-blog-description-wrapper '
                            value={inputValuesDesc.ja}
                            onChange={(newValue) =>
                              setInputValuesDesc((prevInputValues) => ({
                                ...prevInputValues,
                                ja: newValue, // Update the 'en' property with the new value
                              }))
                            }
                            modules={modules}
                          />
                          <span className='error-msg'>{inputErrorsDescJa}</span>
                        </div>
                      </div>
                    </TabPanel> */}
                  </TabContext>
                </div>
              </div>

              <div className="row mb-4 function-button-wrapper-update-details">
                <div className="col-6">
                  <div className="row">
                    <div className="col-4"></div>
                    <div className="col-8"><div className='d-flex justify-content-end pe-3'>
                      <button onClick={handleUpdate} className={`PrimaryButton ${buttonloader ? 'button-loader' : ' '}`}>{!buttonloader ? 'Submit' : ''}</button>
                    </div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {openSnackbar && (
          <Snackbar
            open={openSnackbar}
            autoHideDuration={7000}
            onClose={() => setOpenSnackbar(false)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert severity="success" sx={{ width: '100%' }}>
              Blog Create successfully!
            </Alert>
          </Snackbar>
        )}
        <Snackbar
          open={errorSnackbarOpen}
          autoHideDuration={5000}
          onClose={() => setErrorSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            severity="error"
            sx={{ width: "100%" }}
          >
            Error in Creating Blog!
          </Alert>
        </Snackbar>

      </div>
    </>
  )
}
