import React, { useState, useEffect } from 'react';
import TopHeaderMain from '../../../Layout/TopHeader/TopHeaderMain'
import {
    Autocomplete,
    MenuItem,
    FormControl,
    Select,
    TextField,
    Box,
    Button,
    styled,
    Tabs,
    Tab
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import { Link, useParams } from 'react-router-dom';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useRef } from 'react';
import { Editor, EditorState } from 'draft-js';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import config from '../../../config';
import axiosConfig from '../../../axios';
import authorization from '../../../authorization';
import TopHeaderInternal from '../../../Layout/TopHeader/TopHeaderInternal';
import FullScreenLoader from '../../../Layout/FullScreenLoader/FullScreenLoader';

export default function TourDetails() {
    const [userData, setUserData] = useState([])
    const [dayData, setDayData] = useState([])
    const [galleryImg, setGalleryImg] = useState([]);

    const [inclutionVal, setInclutionVal] = useState([])
    const [exclutionVal, setExclutionVal] = useState([])
    const [fullScreenLoader, setFullScreenLoader] = useState(true)
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);

    const [value, setValue] = React.useState('1');

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };



    let { id } = useParams();
    var gotFromUrlId = id


    useEffect(() => {
        if (gotFromUrlId) {
            tourDetails()
            tourGalleryDetails()
            tourDayDetails()
        }

    }, []);

    const tourDetails = () => {
        axiosConfig
            .get(`/admin/tours/${gotFromUrlId}`, {
                headers: {
                    'Authorization': authorization.authorizationUrl
                },
            })
            .then((response) => {
                let responseData = response.data.data
                console.log("initial value", response.data.data);
                setUserData(response.data.data);
                if (responseData.inclusions) {
                    inclutionDatafetch(responseData.inclusions)
                }
                if (responseData.exclusions) {
                    exclutionDatafetch(responseData.exclusions)
                }
                setTimeout(() => {
                    setFullScreenLoader(false)
                }, 800);
            })
            .catch((error) => console.log(error));
    }
    const tourGalleryDetails = () => {
        axiosConfig
            .get(`/admin/tour/images/${gotFromUrlId}`, {
                headers: {
                    'Authorization': authorization.authorizationUrl
                },
            })
            .then((response) => {
                // console.log("images gallery value", response.data.data);
                setGalleryImg(response.data.data)

            })
            .catch((error) => console.log(error));
    }
    const tourDayDetails = () => {
        axiosConfig
            .get(`/admin/tour/itinerary?tour_id=${gotFromUrlId}`, {
                headers: {
                    'Authorization': authorization.authorizationUrl
                },
            })
            .then((response) => {
                console.log("day data value", response.data.data);
                setDayData(response.data.data);
            })
            .catch((error) => console.log(error));
    }

    const inclutionDatafetch = (inclution) => {
        const inclusionsString = inclution
        const inclusionIds = inclusionsString.split(',');

        // console.log("inclusionsString & inclusionIds", inclusionIds,)

        const fetchInclusions = async (inclusionIds) => {
            const inclusionResponses = [];

            for (const id of inclusionIds) {
                const url = `/admin/inclusion/${id}`;
                // console.log("const id of inclusionIds", id)
                try {
                    const response = await axiosConfig.get(url, {
                        headers: {
                            'Authorization': authorization.authorizationUrl,
                        },
                    });
                    inclusionResponses.push(response.data); // Assuming the response structure is similar to your inclusion data
                } catch (error) {
                    console.error(`Error fetching inclusion with ID ${id}:`, error);
                }
            }

            return inclusionResponses;
        };

        // Usage


        fetchInclusions(inclusionIds)
            .then(inclusionResponses => {
                // console.log('Inclusion Responses:', inclusionResponses);
                // Do something with inclusionResponses
                setInclutionVal(inclusionResponses)

                setTimeout(() => {
                    console.log(inclutionVal[0].data)
                }, 1000);
            })
            .catch(error => {
                console.error('Error fetching inclusions:', error);
            });

    }

    const exclutionDatafetch = (exclution) => {
        const exclutionsString = exclution
        const exclutionIds = exclutionsString.split(',');

        console.log("exclutionsString & exclutionIds", exclutionIds,)

        const fetchExclutions = async (exclutionIds) => {
            const exclutionResponses = [];

            for (const id of exclutionIds) {
                const url = `/admin/exclusion/${id}`;

                try {
                    const response = await axiosConfig.get(url, {
                        headers: {
                            'Authorization': authorization.authorizationUrl,
                        },
                    });
                    exclutionResponses.push(response.data); // Assuming the response structure is similar to your exclution data
                } catch (error) {
                    console.error(`Error fetching exclution with ID ${id}:`, error);
                }
            }

            return exclutionResponses;
        };

        // Usage


        fetchExclutions(exclutionIds)
            .then(exclutionResponses => {
                console.log('exclution Responses:', exclutionResponses);
                // Do something with exclutionResponses
                setExclutionVal(exclutionResponses)
            })
            .catch(error => {
                console.error('Error fetching exclutions:', error);
            });

    }



    return (
        <>
            <div className="container-fluid d-flex">
                <div className="content-wrapper">
                    <div className="content-components-wrapper view-tour-details-wrapper">
                        {fullScreenLoader ? <FullScreenLoader /> : ''}
                        <TopHeaderInternal title={`Tour Details`} linkToBackBtn={'/tours/all-tours'} />
                        <div className="user-content sub-page-details-wrapper mt-4">
                            <div className="row">
                                <div className="col-xl-6 col-lg-7 col-md-9 col-sm-12">
                                    <TabContext value={value}>
                                        <Tabs value={value} onChange={handleTabChange} variant="scrollable" scrollButtons allowScrollButtonsMobile aria-label="nav tabs example">
                                            <Tab label="English" value="1" />
                                            <Tab label="Arabic" value="2" />
                                            {/* <Tab label="Italian" value="3" /> */}
                                            {/* <Tab label="Japanese" value="4" /> */}
                                        </Tabs>
                                        <TabPanel className='p-3' value="1">
                                            <h5 className="sub-page-title">Tour Details</h5>
                                            <div className="around-border">
                                                <div className="row english-details add-border my-3">

                                                    <div className="col-4"><p className="input-title">Title :</p></div>
                                                    <div className="col-8"> <p className='viwe-page-data'>{userData.toure_title_en}</p> </div>
                                                    <div className="col-4"><p className="input-title">Tour Description  :</p></div>
                                                    <div className="col-8">
                                                        <p className='viwe-page-data'>{userData.description_en}</p>
                                                    </div>

                                                    <div className="col-4"><p className="input-title">Cover Image :</p></div>
                                                    <div className="col-8">
                                                        <img src={config.imageBaseURL + userData.image} alt={config.imageBaseURL + userData.image} style={{ maxWidth: '100%' }} className="mb-3" />
                                                    </div>
                                                    {/* <div className="col-4"><p>ID:</p></div>
                                                <div className="col-8"> <TextField size='small' className='mb-3' value={userData.id} style={{ maxWidth: '100%' }} InputProps={{ readOnly: true }} /> </div> */}

                                                    <div className="col-4"><p className="input-title">Date :</p></div>
                                                    <div className="col-8"> <p className='viwe-page-data'>{userData.date === null ? 'Dates May Very' : userData.date}</p> </div>
                                                    <div className="col-4"><p className="input-title">Location :</p></div>
                                                    <div className="col-8"> <p className='viwe-page-data'>{userData.location}</p> </div>
                                                    <div className="col-4"><p className="input-title">Category :</p></div>
                                                    <div className="col-8"> <p className='viwe-page-data'>{userData.category}</p> </div>
                                                    <div className="col-4"><p className="input-title">Days :</p></div>
                                                    <div className="col-8"> <p className='viwe-page-data'>{userData.days}</p> </div>
                                                    <div className="col-4"><p className="input-title">Price :</p></div>
                                                    <div className="col-8"> <p className='viwe-page-data'>{userData.price === null ? '0' : userData.price}</p> </div>

                                                    <div className="col-4"><p className="input-title">In Popular Tours :</p></div>
                                                    <div className="col-8"> <p className='viwe-page-data'>{userData.is_featured === "true" ? 'Yes' : 'No'}</p> </div>
                                                </div>
                                            </div>
                                            {galleryImg ?
                                                <div className="gallery-wrapper">
                                                    <h5 className="sub-page-title">Gallery Image</h5>
                                                    <div className="around-border">
                                                        {galleryImg.map((data, index) => (
                                                            <img className='image-preview' src={config.imageBaseURL + data.image_name} />

                                                        ))}
                                                    </div>
                                                </div>
                                                : ''}

                                            <div className="daily-data-wrapper">
                                                <div className="english-details">
                                                    {dayData ? dayData.map((dataDays, index) => (
                                                        <>
                                                            <h5 key={index} className="sub-page-title">
                                                                Day {dataDays.no_of_day}
                                                            </h5>
                                                            <div className="create-tour-form around-border">
                                                                <div className="days-wrapper">
                                                                    <div className='row'>
                                                                        <div className="col-4"><p className="input-title">Title :</p></div>
                                                                        <div className="col-8"> <p className='viwe-page-data'>{dataDays.title_en}</p> </div>
                                                                        <div className="col-4"><p className="input-title">Tour Description  :</p></div>
                                                                        <div className="col-8">
                                                                            <div className='viwe-page-data' dangerouslySetInnerHTML={{ __html: `${dataDays.description_en}` }}></div>
                                                                            {/* <TextField size='small' multiline className='mb-3' value={dataDays.description_en} style={{ maxWidth: '100%' }} InputProps={{ readOnly: true }} /> */}
                                                                        </div>
                                                                        {/* <div className="col-4"><p className="input-title">Latitude :</p></div>
                                                                        <div className="col-8"> <p className='viwe-page-data'>{dataDays.latitude}</p></div>
                                                                        <div className="col-4"><p className="input-title">Longitude :</p></div>
                                                                        <div className="col-8"> <p className='viwe-page-data'>{dataDays.longitude}</p></div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )) : <p>Daily Data Loading ...</p>}

                                                </div>
                                            </div>

                                            <div className='inclution-exclution-wrapper'>
                                                {userData.inclusions !== "0" && userData.exclusions !== "0" ? <h5 className="sub-page-title">Additional Information</h5> : ''}
                                                {userData.inclusions !== "0" ? <div className='around-border'>
                                                    <div className='row'>
                                                        <div className='col-4'><p className="input-title">Inclusions :</p></div>
                                                        <div className='col-8'>
                                                            {inclutionVal.map((data, index) => (
                                                                <p className='incl-exc-values py-1'>{data.data.name_en}</p>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div> : ''}

                                                {userData.exclusions !== "0" ? <div className='around-border'>
                                                    <div className='row'>
                                                        <div className='col-4'><p className="input-title">Exclusions :</p></div>
                                                        <div className='col-8'>
                                                            {exclutionVal.map((data, index) => (
                                                                <p className='incl-exc-values py-1'>{data.data.name_en}</p>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div> : ''}
                                            </div>
                                            {userData.payment_policy_en ? <div className="privacy-policy-wrapper">
                                                <h5 className="sub-page-title">
                                                    Policies{" "}
                                                </h5>
                                                <div className="create-tour-form around-border">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className='viwe-page-data' dangerouslySetInnerHTML={{ __html: `${userData.payment_policy_en}` }}></div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : ''}
                                        </TabPanel>
                                        <TabPanel className='p-3' value="2">
                                            <h5 className="sub-page-title">Tour Details</h5>
                                            <div className="around-border">
                                                <div className="row arabic-details add-border my-3">

                                                    <div className="col-4"><p className="input-title">Title :</p></div>
                                                    <div className="col-8"> <p className='viwe-page-data'>{userData.title_ar}</p> </div>
                                                    <div className="col-4"><p className="input-title">Tour Description  :</p></div>
                                                    <div className="col-8">
                                                        <p className='viwe-page-data'>{userData.description_ar}</p>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="daily-data-wrapper">
                                                <div className="arabic-details">

                                                    {dayData ? dayData.map((dataDays, index) => (
                                                        <>
                                                            <h5 key={index} className="sub-page-title">
                                                                Day {dataDays.no_of_day}
                                                            </h5>
                                                            <div className="create-tour-form around-border">
                                                                <div className="days-wrapper">
                                                                    <div className='row'>
                                                                        <div className="col-4"><p className="input-title">Title :</p></div>
                                                                        <div className="col-8"> <p className='viwe-page-data'>{dataDays.title_ar}</p> </div>
                                                                        <div className="col-4"><p className="input-title">Tour Description  :</p></div>
                                                                        <div className="col-8">
                                                                            <div className='viwe-page-data' dangerouslySetInnerHTML={{ __html: `${dataDays.description_ar}` }}></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )) : <p>Daily Data Loading ...</p>}

                                                </div>
                                            </div>
                                            {userData.payment_policy_ar ? <div className="privacy-policy-wrapper">
                                                <h5 className="sub-page-title">
                                                    Policies{" "}
                                                </h5>
                                                <div className="create-tour-form around-border">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className='viwe-page-data' dangerouslySetInnerHTML={{ __html: `${userData.payment_policy_ar}` }}></div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : ''}
                                        </TabPanel>
                                        {/* <TabPanel className='p-3' value="3">
                                            <h5 className="sub-page-title">Tour Details</h5>
                                            <div className="around-border">
                                                <div className="row italian-details add-border my-3">

                                                    <div className="col-4"><p className="input-title">Title :</p></div>
                                                    <div className="col-8"> <p className='viwe-page-data'>{userData.title_it}</p> </div>
                                                    <div className="col-4"><p className="input-title">Tour Description  :</p></div>
                                                    <div className="col-8">
                                                        <p className='viwe-page-data'>{userData.description_it}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="daily-data-wrapper">
                                                <div className="italian-details">

                                                    {dayData ? dayData.map((dataDays, index) => (
                                                        <>
                                                            <h5 key={index} className="sub-page-title">
                                                                Day {dataDays.no_of_day}
                                                            </h5>
                                                            <div className="create-tour-form around-border">
                                                                <div className="days-wrapper">
                                                                    <div className='row'>
                                                                        <div className="col-4"><p className="input-title">Title :</p></div>
                                                                        <div className="col-8"> <p className='viwe-page-data'>{dataDays.title_it}</p> </div>
                                                                        <div className="col-4"><p className="input-title">Tour Description  :</p></div>
                                                                        <div className="col-8">
                                                                            <div className='viwe-page-data' dangerouslySetInnerHTML={{ __html: `${dataDays.description_it}` }}></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )) : <p>Daily Data Loading ...</p>}

                                                </div>
                                            </div>
                                            {userData.payment_policy_it ? <div className="privacy-policy-wrapper">
                                                <h5 className="sub-page-title">
                                                    Policies{" "}
                                                </h5>
                                                <div className="create-tour-form around-border">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className='viwe-page-data' dangerouslySetInnerHTML={{ __html: `${userData.payment_policy_it}` }}></div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : ''}
                                        </TabPanel>
                                        <TabPanel className='p-3' value="4">
                                            <h5 className="sub-page-title">Tour Details</h5>
                                            <div className="around-border">
                                                <div className="row japanese-details add-border my-3">

                                                    <div className="col-4"><p className="input-title">Title :</p></div>
                                                    <div className="col-8"> <p className='viwe-page-data'>{userData.title_ja}</p> </div>
                                                    <div className="col-4"><p className="input-title">Tour Description  :</p></div>
                                                    <div className="col-8">
                                                        <p className='viwe-page-data'>{userData.description_ja}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="daily-data-wrapper">
                                                <div className="japanese-details">

                                                    {dayData ? dayData.map((dataDays, index) => (
                                                        <>
                                                            <h5 key={index} className="sub-page-title">
                                                                Day {dataDays.no_of_day}
                                                            </h5>
                                                            <div className="create-tour-form around-border">
                                                                <div className="days-wrapper">
                                                                    <div className='row'>
                                                                        <div className="col-4"><p className="input-title">Title :</p></div>
                                                                        <div className="col-8"> <p className='viwe-page-data'>{dataDays.title_ja}</p> </div>
                                                                        <div className="col-4"><p className="input-title">Tour Description  :</p></div>
                                                                        <div className="col-8">
                                                                            <div className='viwe-page-data' dangerouslySetInnerHTML={{ __html: `${dataDays.description_ja}` }}></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )) : <p>Daily Data Loading ...</p>}

                                                </div>
                                            </div>
                                            {userData.payment_policy_ja ? <div className="privacy-policy-wrapper">
                                                <h5 className="sub-page-title">
                                                    Policies{" "}
                                                </h5>
                                                <div className="create-tour-form around-border">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className='viwe-page-data' dangerouslySetInnerHTML={{ __html: `${userData.payment_policy_ja}` }}></div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : ''}
                                        </TabPanel> */}
                                    </TabContext>

                                </div>
                            </div>

                            <div className="row my-4 function-button-wrapper-payment-details">
                                <div className="col-8">
                                    <div className="row">
                                        <div className="col-3"></div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
