import React from "react";
import Button from "@mui/material/Button";
import { Modal } from "react-bootstrap";

export default function DeleteModal({showModal, onClose, onSubmit}) {
    
  return (
    <div>
      <Modal
        show={showModal}
        onHide={onClose}
        onSubmit={onSubmit}
        bsSize="large"
        centered
        className="primary-popup-wrapper"
      >
        <Modal.Header className="popupmodale login-signin-popup-header">
          Delete Item
        </Modal.Header>

        <Modal.Body className="login-signin-popup-body">
          <p className="pb-2" >Are you sure you want to delete the selected item ?</p>
          <div className="d-flex flex-row-reverse">
            <Button onClick={onSubmit}>Delete</Button>
            <Button onClick={onClose}>Cancel</Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
