import React, { useState } from 'react'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TopHeaderMain from '../../Layout/TopHeader/TopHeaderMain';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

export default function Setting() {
    const [state, setState] = useState({
        english: true,
        arabic: false,
        japanese: false,
        italian: false,
    });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    return (
        <>
            <div className="container-fluid d-flex">
                <div className="content-wrapper">
                    <div className="content-components-wrapper">
                        <TopHeaderMain title='Setting' icons={<SettingsOutlinedIcon fontSize="large" />} />
                        <div className="user-content mt-4">

                            <h5>Language</h5>
                            <div className="setting-language-wrapper mt-4">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-4">
                                                <h6 className='sub-title-language'>Select Language </h6>
                                                <span className='title-small-text'>Admin will be able to create tours in languages selected here</span>
                                            </div>
                                            <div className="col-4">
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={<Checkbox checked={state.english} onChange={handleChange} name="english" style={{ color: '#5C0F87' }} />}
                                                        label="English"
                                                    />
                                                    <FormControlLabel
                                                        control={<Checkbox checked={state.arabic} onChange={handleChange} name="arabic" style={{ color: '#5C0F87' }} />}
                                                        label="Arabic"
                                                    />
                                                    <FormControlLabel
                                                        control={<Checkbox checked={state.japanese} onChange={handleChange} name="japanese" style={{ color: '#5C0F87' }} />}
                                                        label="Japanese"
                                                    />
                                                    <FormControlLabel
                                                        control={<Checkbox checked={state.italian} onChange={handleChange} name="italian" style={{ color: '#5C0F87' }} />}
                                                        label="Italian"
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className="col-3"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
