import React, { useState, useEffect } from 'react';
import TopHeaderMain from '../../../Layout/TopHeader/TopHeaderMain'
import {
    Autocomplete,
    MenuItem,
    FormControl,
    Select,
    TextField,
    Box,
    Button,
    styled,
    Tabs,
    Tab,
    Input
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import EditIcon from '@mui/icons-material/Edit';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import { Link, useParams } from 'react-router-dom';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { useRef } from 'react';
import { Editor, EditorState } from 'draft-js';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import config from '../../../config';
import axiosConfig from '../../../axios';
import authorization from '../../../authorization';
import TopHeaderInternal from '../../../Layout/TopHeader/TopHeaderInternal';
import FullScreenLoader from '../../../Layout/FullScreenLoader/FullScreenLoader';

export default function BlogDetails() {
    const [userData, setUserData] = useState([])
    const [dragActive, setDragActive] = useState(false);
    const [file, setFile] = useState(null);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [fullScreenLoader, setFullScreenLoader] = useState(true)

    const [value, setValue] = React.useState('1');

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };



    let { id } = useParams();
    var gotFromUrlId = id


    useEffect(() => {
        axiosConfig
            .get(`/admin/blog/${gotFromUrlId}`, {
                headers: {
                    'Authorization': authorization.authorizationUrl
                },
            })
            .then((response) => {
                console.log("initial value", response.data.data);
                setUserData(response.data.data);
                if (response.data.status) {
                    setTimeout(() => {
                        setFullScreenLoader(false)
                    }, 800);
                }
            })
            .catch((error) => console.log(error));
    }, []);

    const readOnly = true;

    const modules = {
        toolbar: false,
    };

    const editorStyle = {
        height: '360px', // Adjust the height as needed
    };


    return (
        <>
            <div className="container-fluid d-flex">
                <div className="content-wrapper">
                    <div className="content-components-wrapper blog-details-wrapper">
                        {/* <TopHeaderMain title={`Blog Details (${gotFromUrlId})`} /> */}
                        {fullScreenLoader ? <FullScreenLoader /> : ''}
                        <TopHeaderInternal title={`Blog Details`} linkToBackBtn={'/blogs/blog-list'} />
                        <div className="user-content mt-4">
                            <div className="row">
                                <div className="col-xl-6 col-lg-7 col-md-9 col-sm-12">
                                    <TabContext value={value}>
                                        <Tabs value={value} onChange={handleTabChange} variant="scrollable" scrollButtons allowScrollButtonsMobile aria-label="nav tabs example">
                                            <Tab label="English" value="1" />
                                            <Tab label="Arabic" value="2" />
                                            {/* <Tab label="Italian" value="3" /> */}
                                            {/* <Tab label="Japanese" value="4" /> */}
                                        </Tabs>
                                        <TabPanel className='p-3' value="1">

                                            <div className="row english-details around-border mb-3">
                                                <div className="col-4"><p className='input-title'>Title :</p></div>
                                                <div className="col-8 mb-3">
                                                    <Input
                                                        disableUnderline
                                                        sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                        className='table-inputs-content'
                                                        size='small'
                                                        fullWidth
                                                        value={userData.title_en}
                                                        readOnly
                                                        multiline
                                                        placeholder={`No Data Found`}
                                                    />
                                                </div>

                                                {/* <div className="col-4"><p className='input-title'>Date :</p></div>
                                                <div className="col-8"> <TextField size='small' className='mb-3' value={userData.created_at} style={{ maxWidth: '100%' }} InputProps={{ readOnly: true }} /> </div> */}
                                                <div className="col-4"><p className='input-title'>Category :</p></div>
                                                <div className="col-8 mb-3">
                                                    <Input
                                                        disableUnderline
                                                        sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                        className='table-inputs-content'
                                                        size='small'
                                                        fullWidth
                                                        value={userData.blog_category_en}
                                                        readOnly
                                                        multiline
                                                        placeholder={`No Data Found`}
                                                    />

                                                </div>
                                                <div className="col-4"><p className='input-title'>Cover Image :</p></div>
                                                <div className="col-8 mb-3">
                                                    <div className='blog-details-img-wrapper'>
                                                        <img src={config.imageBaseURL + userData.image} alt={config.imageBaseURL + userData.image} />
                                                    </div>
                                                </div>
                                                <div className="col-4"><p className='input-title'>Blog Description  :</p></div>
                                                <div className="col-8">
                                                    <ReactQuill
                                                        className='create-blog-description-wrapper '
                                                        value={userData.content_en}
                                                        readOnly={readOnly}
                                                        modules={modules}
                                                        style={editorStyle}
                                                    />

                                                </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel className='p-3' value="2">
                                            <div className="row arabic-details around-border mb-3">
                                                <div className="col-4"><p className='input-title'>Title :</p></div>
                                                <div dir='rtl' className="col-8 mb-3">
                                                    <Input
                                                        disableUnderline
                                                        sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                        className='table-inputs-content'
                                                        size='small'
                                                        fullWidth
                                                        value={userData.title_ar}
                                                        readOnly
                                                        multiline
                                                        placeholder={`No Data Found`}
                                                    />
                                                </div>

                                                <div className="col-4"><p className='input-title'>Category :</p></div>
                                                <div dir='rtl' className="col-8 mb-3">
                                                    <Input
                                                        disableUnderline
                                                        sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                        className='table-inputs-content'
                                                        size='small'
                                                        fullWidth
                                                        value={userData.blog_category_ar}
                                                        readOnly
                                                        multiline
                                                        placeholder={`No Data Found`}
                                                    />

                                                </div>
                                                <div className="col-4"><p className='input-title'>Blog Description  :</p></div>
                                                <div dir='rtl' className="col-8">
                                                    <ReactQuill

                                                        className='create-blog-description-wrapper '
                                                        value={userData.content_ar}
                                                        readOnly={readOnly}
                                                        modules={modules}
                                                        style={editorStyle}
                                                    />

                                                </div>
                                            </div>
                                        </TabPanel>
                                        {/* <TabPanel className='p-3' value="3">
                                            <div className="row italian-details around-border mb-3">
                                                <div className="col-4"><p className='input-title'>Title :</p></div>
                                                <div className="col-8 mb-3">
                                                    <Input
                                                        disableUnderline
                                                        sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                        className='table-inputs-content'
                                                        size='small'
                                                        fullWidth
                                                        value={userData.title_it}
                                                        readOnly
                                                        multiline
                                                        placeholder={`No Data Found`}
                                                    />
                                                </div>

                                                <div className="col-4"><p className='input-title'>Category :</p></div>
                                                <div className="col-8 mb-3">
                                                    <Input
                                                        disableUnderline
                                                        sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                        className='table-inputs-content'
                                                        size='small'
                                                        fullWidth
                                                        value={userData.blog_category_it}
                                                        readOnly
                                                        multiline
                                                        placeholder={`No Data Found`}
                                                    />

                                                </div>
                                                <div className="col-4"><p className='input-title'>Blog Description  :</p></div>
                                                <div className="col-8">
                                                    <ReactQuill
                                                        className='create-blog-description-wrapper '
                                                        value={userData.content_it}
                                                        readOnly={readOnly}
                                                        modules={modules}
                                                        style={editorStyle}
                                                    />

                                                </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel className='p-3' value="4">
                                            <div className="row japaneese-details around-border mb-3">

                                                <div className="col-4"><p className='input-title'>Title :</p></div>
                                                <div className="col-8 mb-3">
                                                    <Input
                                                        disableUnderline
                                                        sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                        className='table-inputs-content'
                                                        size='small'
                                                        fullWidth
                                                        value={userData.title_ja}
                                                        readOnly
                                                        multiline
                                                        placeholder={`No Data Found`}
                                                    />
                                                </div>

                                                <div className="col-4"><p className='input-title'>Category :</p></div>
                                                <div className="col-8 mb-3">
                                                    <Input
                                                        disableUnderline
                                                        sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                        className='table-inputs-content'
                                                        size='small'
                                                        fullWidth
                                                        value={userData.blog_category_ja}
                                                        readOnly
                                                        multiline
                                                        placeholder={`No Data Found`}
                                                    />

                                                </div>
                                                <div className="col-4"><p className='input-title'>Blog Description  :</p></div>
                                                <div className="col-8">
                                                    <ReactQuill
                                                        className='create-blog-description-wrapper '
                                                        value={userData.content_ja}
                                                        readOnly={readOnly}
                                                        modules={modules}
                                                        style={editorStyle}
                                                    />

                                                </div>
                                            </div>
                                        </TabPanel> */}
                                    </TabContext>

                                </div>
                            </div>

                            <div className="row my-4 function-button-wrapper-payment-details">
                                <div className="col-8">
                                    <div className="row">
                                        <div className="col-3"></div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
