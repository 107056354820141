import React, { useEffect, useState } from 'react';
import TopHeaderMain from '../../../Layout/TopHeader/TopHeaderMain';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import { TextField, Button, Box, IconButton, InputAdornment, Input, Snackbar, Alert, Tabs, Tab, Autocomplete } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ReactQuill from 'react-quill';
import axiosConfig from '../../../axios';
import TopHeaderInternal from '../../../Layout/TopHeader/TopHeaderInternal';
import authorization from '../../../authorization';
import { useNavigate } from 'react-router-dom';
import FullScreenLoader from '../../../Layout/FullScreenLoader/FullScreenLoader';

const FaqCreate = () => {
    const history = useNavigate();
    const [value, setValue] = useState('1');
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const token = localStorage.getItem('Token-for-login');

    const [faqCategories, setFaqCategories] = useState([])
    const [faqCategoriesIdData, setFaqCategoriesIdData] = useState([])

    const [titleErrors, setTitleErrors] = useState([]);
    const [descriptionErrors, setDescriptionErrors] = useState([]);
    const [buttonloader, setButtonLoader] = useState(false)
    const [fullScreenLoader, setFullScreenLoader] = useState(true)

    const [languages, setLanguages] = useState([
        { id: 'en', label: 'English' },
        { id: 'ar', label: 'Arabic' },
        // { id: 'it', label: 'Italian' },
        // { id: 'ja', label: 'Japanese' },
    ]);

    const handleTabChange = (event, newValue) => {

        setValue(newValue);
    };

    useEffect(() => {
        getFaqCategory()


    }, []);

    const getFaqCategory = () => {
        axiosConfig
            .get('/admin/faqs/category', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                let data = response.data.data;
                // console.log("faq category data", data)
                setFaqCategories(data)
                if (response.data.status) {
                    setTimeout(() => {
                        setFullScreenLoader(false)
                    }, 800);
                } 
            })
            .catch((error) => console.log(error));
    }

    // State variables for each language
    const [categoriesEn, setCategoriesEn] = useState([{ category: '', titles: [{ title: '', description: '', errorTitle: '', errorDesc: '' }] }]);
    const [categoriesAr, setCategoriesAr] = useState([{ category: '', titles: [{ title: '', description: '', errorTitle: '', errorDesc: '' }] }]);
    const [categoriesIt, setCategoriesIt] = useState([{ category: '', titles: [{ title: '', description: '', errorTitle: '', errorDesc: '' }] }]);
    const [categoriesJp, setCategoriesJp] = useState([{ category: '', titles: [{ title: '', description: '', errorTitle: '', errorDesc: '' }] }]);


    const handleTitleChange = (categoryIndex, titleIndex, language, event) => {
        const updatedTitle = event.target.value;

        switch (language) {
            case 'English':
                setCategoriesEn((prevCategories) => {
                    const updatedCategories = [...prevCategories];
                    updatedCategories[categoryIndex].titles[titleIndex].title = updatedTitle;
                    return updatedCategories;
                });
                break;
            case 'Arabic':
                setCategoriesAr((prevCategories) => {
                    const updatedCategories = [...prevCategories];
                    updatedCategories[categoryIndex].titles[titleIndex].title = updatedTitle;
                    return updatedCategories;
                });
                break;
            case 'Italian':
                setCategoriesIt((prevCategories) => {
                    const updatedCategories = [...prevCategories];
                    updatedCategories[categoryIndex].titles[titleIndex].title = updatedTitle;
                    return updatedCategories;
                });
                break;
            case 'Japanese':
                setCategoriesJp((prevCategories) => {
                    const updatedCategories = [...prevCategories];
                    updatedCategories[categoryIndex].titles[titleIndex].title = updatedTitle;
                    return updatedCategories;
                });
                break;
            default:
                break;
        }
    };

    const handleDescriptionChange = (categoryIndex, titleIndex, language, event) => {
        const updatedDescription = event.target.value;

        switch (language) {
            case 'English':
                setCategoriesEn((prevCategories) => {
                    const updatedCategories = [...prevCategories];
                    updatedCategories[categoryIndex].titles[titleIndex].description = updatedDescription;
                    return updatedCategories;
                });
                break;
            case 'Arabic':
                setCategoriesAr((prevCategories) => {
                    const updatedCategories = [...prevCategories];
                    updatedCategories[categoryIndex].titles[titleIndex].description = updatedDescription;
                    return updatedCategories;
                });
                break;
            case 'Italian':
                setCategoriesIt((prevCategories) => {
                    const updatedCategories = [...prevCategories];
                    updatedCategories[categoryIndex].titles[titleIndex].description = updatedDescription;
                    return updatedCategories;
                });
                break;
            case 'Japanese':
                setCategoriesJp((prevCategories) => {
                    const updatedCategories = [...prevCategories];
                    updatedCategories[categoryIndex].titles[titleIndex].description = updatedDescription;
                    return updatedCategories;
                });
                break;
            default:
                break;
        }
    };

    const handleAddTitle = (e, categoryIndex, language) => {
        e.preventDefault()

        const updatedCategoriesEn = [...categoriesEn];
        updatedCategoriesEn[categoryIndex].titles.push({ title: '', description: '' });
        setCategoriesEn(updatedCategoriesEn);

        const updatedCategoriesAr = [...categoriesAr];
        updatedCategoriesAr[categoryIndex].titles.push({ title: '', description: '' });
        setCategoriesAr(updatedCategoriesAr);

        const updatedCategoriesIt = [...categoriesIt];
        updatedCategoriesIt[categoryIndex].titles.push({ title: '', description: '' });
        setCategoriesIt(updatedCategoriesIt);

        const updatedCategoriesJp = [...categoriesJp];
        updatedCategoriesJp[categoryIndex].titles.push({ title: '', description: '' });
        setCategoriesJp(updatedCategoriesJp);
    };

    // const createFaqBlocks = () => {
    //     return FaqBlock;
    // };

    const [valErrTabEn, setValErrTabEn] = useState(true)
    const [valErrTabAr, setValErrTabAr] = useState(true)
    const [valErrTabIt, setValErrTabIt] = useState(true)
    const [valErrTabJa, setValErrTabJa] = useState(true)

    const validateForm = () => {
        let isValid = true;
        setValErrTabEn(true);
        setValErrTabAr(true)
        setValErrTabIt(true)
        setValErrTabJa(true)



        // Validate English language data
        categoriesEn.forEach((category, categoryIndex) => {

            category.titles.forEach((titleBlock, titleIndex) => {
                if (!titleBlock.title.trim()) {
                    isValid = false;
                    setValErrTabEn(false)

                    setCategoriesEn((prevCategoriesEn) => {
                        const updatedCategoriesEn = [...prevCategoriesEn];
                        updatedCategoriesEn[categoryIndex].titles[titleIndex].errorTitle = `Title is required`;
                        return updatedCategoriesEn;
                    });

                } else {
                    setCategoriesEn((prevCategoriesEn) => {
                        const updatedCategoriesEn = [...prevCategoriesEn];
                        updatedCategoriesEn[categoryIndex].titles[titleIndex].errorTitle = ``;
                        return updatedCategoriesEn;
                    });
                }

                if (!titleBlock.description.trim()) {
                    isValid = false;
                    setValErrTabEn(false)
                    setCategoriesEn((prevCategoriesEn) => {
                        const updatedCategoriesEn = [...prevCategoriesEn];
                        updatedCategoriesEn[categoryIndex].titles[titleIndex].errorDesc = `Description is required `;
                        return updatedCategoriesEn;
                    });
                } else {
                    setCategoriesEn((prevCategoriesEn) => {
                        const updatedCategoriesEn = [...prevCategoriesEn];
                        updatedCategoriesEn[categoryIndex].titles[titleIndex].errorDesc = ``;
                        return updatedCategoriesEn;
                    });
                }
            });
        });

        categoriesAr.forEach((category, categoryIndex) => {
            category.titles.forEach((titleBlock, titleIndex) => {
                if (!titleBlock.title.trim()) {
                    isValid = false;
                    setValErrTabAr(false)
                    setCategoriesAr((prevCategoriesEn) => {
                        const updatedCategoriesAr = [...prevCategoriesEn];
                        updatedCategoriesAr[categoryIndex].titles[titleIndex].errorTitle = `Title is required`;
                        return updatedCategoriesAr;
                    });

                } else {
                    setCategoriesAr((prevCategoriesEn) => {
                        const updatedCategoriesAr = [...prevCategoriesEn];
                        updatedCategoriesAr[categoryIndex].titles[titleIndex].errorTitle = ``;
                        return updatedCategoriesAr;
                    });

                }

                if (!titleBlock.description.trim()) {
                    isValid = false;
                    setValErrTabAr(false)
                    setCategoriesAr((prevCategoriesEn) => {
                        const updatedCategoriesAr = [...prevCategoriesEn];
                        updatedCategoriesAr[categoryIndex].titles[titleIndex].errorDesc = `Description is required`;
                        return updatedCategoriesAr;
                    });

                } else {
                    setCategoriesAr((prevCategoriesEn) => {
                        const updatedCategoriesAr = [...prevCategoriesEn];
                        updatedCategoriesAr[categoryIndex].titles[titleIndex].errorDesc = ``;
                        return updatedCategoriesAr;
                    });

                }
            });
        });

        // categoriesIt.forEach((category, categoryIndex) => {
        //     category.titles.forEach((titleBlock, titleIndex) => {
        //         if (!titleBlock.title.trim()) {
        //             isValid = false;
        //             setValErrTabIt(false)
        //             setCategoriesIt((prevCategoriesEn) => {
        //                 const updatedCategoriesIt = [...prevCategoriesEn];
        //                 updatedCategoriesIt[categoryIndex].titles[titleIndex].errorTitle = `Title is required`;
        //                 return updatedCategoriesIt;
        //             });

        //         } else {
        //             setCategoriesIt((prevCategoriesEn) => {
        //                 const updatedCategoriesIt = [...prevCategoriesEn];
        //                 updatedCategoriesIt[categoryIndex].titles[titleIndex].errorTitle = ``;
        //                 return updatedCategoriesIt;
        //             });

        //         }

        //         if (!titleBlock.description.trim()) {
        //             isValid = false;
        //             setValErrTabIt(false)
        //             setCategoriesIt((prevCategoriesEn) => {
        //                 const updatedCategoriesIt = [...prevCategoriesEn];
        //                 updatedCategoriesIt[categoryIndex].titles[titleIndex].errorDesc = `Description is required`;
        //                 return updatedCategoriesIt;
        //             });

        //         } else {
        //             setCategoriesIt((prevCategoriesEn) => {
        //                 const updatedCategoriesIt = [...prevCategoriesEn];
        //                 updatedCategoriesIt[categoryIndex].titles[titleIndex].errorDesc = ``;
        //                 return updatedCategoriesIt;
        //             });


        //         }
        //     });
        // });

        // categoriesJp.forEach((category, categoryIndex) => {
        //     category.titles.forEach((titleBlock, titleIndex) => {
        //         if (!titleBlock.title.trim()) {
        //             isValid = false;
        //             setValErrTabJa(false)
        //             setCategoriesJp((prevCategoriesEn) => {
        //                 const updatedCategoriesJp = [...prevCategoriesEn];
        //                 updatedCategoriesJp[categoryIndex].titles[titleIndex].errorTitle = `Title is required`;
        //                 return updatedCategoriesJp;
        //             });

        //         } else {
        //             setCategoriesJp((prevCategoriesEn) => {
        //                 const updatedCategoriesJp = [...prevCategoriesEn];
        //                 updatedCategoriesJp[categoryIndex].titles[titleIndex].errorTitle = ``;
        //                 return updatedCategoriesJp;
        //             });


        //         }

        //         if (!titleBlock.description.trim()) {
        //             isValid = false;
        //             setValErrTabJa(false)
        //             setCategoriesJp((prevCategoriesEn) => {
        //                 const updatedCategoriesJp = [...prevCategoriesEn];
        //                 updatedCategoriesJp[categoryIndex].titles[titleIndex].errorDesc = `Description is required`;
        //                 return updatedCategoriesJp;
        //             });
        //         } else {
        //             setCategoriesJp((prevCategoriesEn) => {
        //                 const updatedCategoriesJp = [...prevCategoriesEn];
        //                 updatedCategoriesJp[categoryIndex].titles[titleIndex].errorDesc = ``;
        //                 return updatedCategoriesJp;
        //             });

        //         }
        //     });
        // });

        return isValid;
    };


    const HandleSubmiteData = (e) => {
        e.preventDefault()
        setButtonLoader(true)
        if (!validateForm()) {
            // console.log('Validation errors:', {

            //     categoriesEn,
            //     categoriesAr
            //     // errorDataAr,
            //     // errorDataIt,
            //     // errorDataJa,
            //     // Add other errorData states here
            // });
            setButtonLoader(false)
            return;
        }

        let FaqBlock = {};
        // console.log("faqCategoriesIdData.id", faqCategoriesIdData)
        const languageCategories = [
            { code: 'en', categories: categoriesEn, name: 'english' },
            { code: 'ar', categories: categoriesAr, name: 'arabic' },
            { code: 'it', categories: categoriesEn, name: 'italian' },
            { code: 'ja', categories: categoriesEn, name: 'japanese' }
        ];

        languageCategories.forEach(languageCategory => {
            languageCategory.categories[0].titles.forEach((category, index) => {
                FaqBlock[`faqs[${index}][category_id]`] = faqCategoriesIdData.id
                FaqBlock[`faqs[${index}][title_${languageCategory.code}]`] = category.title || '';
                FaqBlock[`faqs[${index}][content_${languageCategory.code}]`] = category.description || '';
            });
        });



        // console.log("FaqBlock", FaqBlock)


        axiosConfig
            .post(`/admin/addfaq`, FaqBlock, {
                headers: {
                    Authorization: authorization.authorizationUrl,
                    'Content-Type': 'multipart/form-data'
                },
            })
            .then((response) => {
                let theData = response.data;
                // console.log('faqBlocks create value', response);
                if (theData.status) {
                    setSuccessMsg('Faq Category added successfully!');
                    // getInclusion()
                    setSuccessSnackbarOpen(true);
                    setButtonLoader(false)
                    setTimeout(() => history("/faq/faq-list"), 1000);

                } else {
                    setButtonLoader(false)
                    setErrorMsg('Err');
                    setErrorSnackbarOpen(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });

    };
    const handleRemoveTitle = (e, titleIndex) => {
        e.preventDefault()
        const updatedCategoriesEn = removeTitleFromLanguage(categoriesEn, titleIndex);
        const updatedCategoriesAr = removeTitleFromLanguage(categoriesAr, titleIndex);
        const updatedCategoriesIt = removeTitleFromLanguage(categoriesIt, titleIndex);
        const updatedCategoriesJp = removeTitleFromLanguage(categoriesJp, titleIndex);

        setCategoriesEn(updatedCategoriesEn);
        setCategoriesAr(updatedCategoriesAr);
        setCategoriesIt(updatedCategoriesIt);
        setCategoriesJp(updatedCategoriesJp);
    };

    const removeTitleFromLanguage = (languageCategories, titleIndex) => {
        const updatedCategories = languageCategories.map(category => {
            const updatedTitles = [...category.titles];
            updatedTitles.splice(titleIndex, 1);
            return { ...category, titles: updatedTitles };
        });

        return updatedCategories;
    };

    const faqBlock = (language) => {
        const nextTabIndex = (languages.findIndex(lang => lang.label === language) + 1) % languages.length;

        let categories;

        switch (language) {
            case 'English':
                categories = categoriesEn;
                break;
            case 'Arabic':
                categories = categoriesAr;
                break;
            // case 'Italian':
            //     categories = categoriesIt;
            //     break;
            // case 'Japanese':
            //     categories = categoriesJp;
            //     break;
            default:
                break;
        }

        return (
            <div>

                <div className='faq-main-block-wrapper'>
                    <div className='around-border'>
                        {fullScreenLoader ? <FullScreenLoader /> : ''}
                        <div className='row'>
                            <div className='col-4'>
                                <p className='input-title'>{`FAQ's Category :`}</p>
                            </div>
                            <div className='col-8 mb-3'>
                                <Autocomplete
                                    id="category"
                                    size="small"
                                    name="category"
                                    value={faqCategoriesIdData}
                                    onChange={(event, newValue) => {
                                        setFaqCategoriesIdData(newValue);
                                    }}
                                    options={faqCategories}
                                    getOptionLabel={(option) =>
                                        option.title_en || option
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === (value ? value.id : null)
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Select Faq Category"
                                            variant="outlined"
                                            style={{ maxWidth: "100%" }}
                                        />
                                    )}
                                    disabled={language !== 'English'}
                                />

                            </div>
                        </div>
                        {categories.map((category, categoryIndex) => (
                            <>
                                {category.titles.map((titleBlock, titleIndex) => (
                                    <div className='faq-quetions-wrapper around-border' key={titleIndex}>
                                        {/* <p>{titleIndex}</p> */}
                                        <div className='row'>
                                            <div className='col-4'>
                                                <p className='input-title'>Title </p>
                                            </div>
                                            <div className='col-8 mb-3'>
                                                <Input
                                                    disableUnderline
                                                    sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                    className='table-inputs-content'
                                                    size='small'
                                                    fullWidth
                                                    value={titleBlock.title}
                                                    onChange={(e) => handleTitleChange(categoryIndex, titleIndex, language, e)}
                                                    placeholder={`Enter title`}

                                                />
                                                {titleBlock.errorTitle ? <span className='error-msg'>{titleBlock.errorTitle}</span> : ''}
                                            </div>
                                            <div className='col-4'>
                                                <p className='input-title'>Description</p>
                                            </div>
                                            <div className='col-8 mb-3'>
                                                <Input
                                                    disableUnderline
                                                    sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                                    className='table-inputs-content'
                                                    size='small'
                                                    fullWidth
                                                    multiline
                                                    value={titleBlock.description}
                                                    onChange={(e) => handleDescriptionChange(categoryIndex, titleIndex, language, e)}
                                                    placeholder={`Enter Description`}

                                                />
                                                {titleBlock.errorDesc ? <span className='error-msg'>{titleBlock.errorDesc}</span> : ''}
                                            </div>
                                            {titleIndex !== 0 ?
                                                <button className='remove-button' onClick={(e) => handleRemoveTitle(e, titleIndex)}>Remove</button>
                                                : ''}
                                        </div>
                                    </div>
                                ))}
                                <div className='add-button'>
                                    <button type='button' className='SecondaryButton' onClick={(e) => handleAddTitle(e, categoryIndex, language)}>
                                        Add +
                                    </button>
                                </div>
                            </>
                        ))}
                    </div>
                </div>

                <div className='submit-button-sub-page mb-4'>
                    {value === '2' ?
                        <button className={`PrimaryButton ${buttonloader ? 'button-loader' : ' '}`} type="submit" variant="contained" onClick={HandleSubmiteData} fullWidth>
                            {!buttonloader ? 'Submit' : ''}
                        </button>
                        :
                        <button
                            className='PrimaryButton w-100'
                            type="button"
                            variant="contained"
                            id={String(nextTabIndex + 1)}
                            onClick={() => setValue(String(nextTabIndex + 1))}
                        >
                            Next
                        </button>
                    }

                </div>


            </div>
        );
    };

    return (
        <div className='container-fluid d-flex'>
            <div className='content-wrapper faq-page-wrapper'>
                <div className='content-components-wrapper'>
                    <TopHeaderInternal title={`Create New FAQ`} linkToBackBtn={'/faq/faq-list'} />
                    <div className='user-content sub-page-details-wrapper mt-4 '>
                        <div className='row'>
                            <div className='col-xl-6 col-lg-7 col-md-9 col-sm-12'>
                                <form>
                                    <TabContext value={value}>
                                        <Tabs value={value} onChange={handleTabChange} variant='scrollable' scrollButtons allowScrollButtonsMobile aria-label='nav tabs example'>
                                            <Tab label='English' value='1' className={`error-msg ${valErrTabEn ? '' : 'error-msg-dot'}`} />
                                            <Tab label='Arabic' value='2' className={`error-msg ${valErrTabAr ? '' : 'error-msg-dot'}`} />
                                            {/* <Tab label='Italian' value='3' className={`error-msg ${valErrTabIt ? '' : 'error-msg-dot'}`} /> */}
                                            {/* <Tab label='Japanese' value='4' className={`error-msg ${valErrTabJa ? '' : 'error-msg-dot'}`} /> */}
                                        </Tabs>
                                        <TabPanel className='p-3' value='1'>
                                            <h5 className='sub-page-title'>FAQ's In English Language</h5>
                                            {faqBlock('English')}

                                        </TabPanel>
                                        <TabPanel className='p-3' value='2'>
                                            <h5 className='sub-page-title'>FAQ's In Arabic Language</h5>
                                            {faqBlock('Arabic')}
                                        </TabPanel>
                                        {/* <TabPanel className='p-3' value='3'>
                                            <h5 className='sub-page-title'>FAQ's In Italian Language</h5>
                                            {faqBlock('Italian')}
                                        </TabPanel>
                                        <TabPanel className='p-3' value='4'>
                                            <h5 className='sub-page-title'>FAQ's In Japanese Language</h5>
                                            {faqBlock('Japanese')}
                                        </TabPanel> */}
                                    </TabContext>


                                </form>


                                <Snackbar open={errorSnackbarOpen} autoHideDuration={3000} onClose={() => setErrorSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                                    <Alert severity='error' sx={{ width: '100%' }}>
                                        {errorMsg}
                                    </Alert>
                                </Snackbar>
                                <Snackbar open={successSnackbarOpen} autoHideDuration={3000} onClose={() => setSuccessSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                                    <Alert severity='success' sx={{ width: '100%' }}>
                                        FAQ's Added successfully!
                                    </Alert>
                                </Snackbar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FaqCreate;
