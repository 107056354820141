import React, { useEffect, useState } from 'react';
import TopHeaderMain from '../../../Layout/TopHeader/TopHeaderMain';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import { TextField, Button, Box, IconButton, InputAdornment, Input, Snackbar, Alert, Tabs, Tab, Autocomplete } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ReactQuill from 'react-quill';
import axiosConfig from '../../../axios';
import TopHeaderInternal from '../../../Layout/TopHeader/TopHeaderInternal';
import authorization from '../../../authorization';
import { useNavigate, useParams } from 'react-router-dom';
import FullScreenLoader from '../../../Layout/FullScreenLoader/FullScreenLoader';

const FaqEdit = () => {
    const history = useNavigate();
    let { id } = useParams();
    var gotFromUrlId = id

    const [value, setValue] = useState('en'); // Set the default language to 'en' (English)

    const [languages, setLanguages] = useState([
        { id: 'en', label: 'English' },
        { id: 'ar', label: 'Arabic' },
        // { id: 'it', label: 'Italian' },
        // { id: 'ja', label: 'Japanese' },
    ]);

    const [data, setData] = useState({
        inclutionArr: [],

    });

    const [inputValues, setInputValues] = useState({
        category_id: '',
        en: '',
        ar: '',
        // it: '',
        // ja: '',
    });
    const [inputValuesDesc, setInputValuesDesc] = useState({
        en: '',
        ar: '',
        // it: '',
        // ja: '',
    });

    const [errInclution, setErrInclution] = useState({
        en: '',
        ar: '',
        // it: '',
        // ja: '',
    })

    const [errInclutionDesc, setErrInclutionDesc] = useState({
        en: '',
        ar: '',
        // it: '',
        // ja: '',
    })


    const [valErrTab, setValErrTab] = useState({
        en: false,
        ar: false,
        it: false,
        ja: false,
    });


    const [valErrTabDesc, setValErrTabDesc] = useState({
        en: false,
        ar: false,
        it: false,
        ja: false,
    });

    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [faqCategories, setFaqCategories] = useState([])
    const [faqCategoriesIdData, setFaqCategoriesIdData] = useState([])
    const [buttonloader, setButtonLoader] = useState(false)
    const [fullScreenLoader, setFullScreenLoader] = useState(true)

    const token = localStorage.getItem('Token-for-login');

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        // console.log(gotFromUrlId)

        getFaqData()
        getFaqCategory()
    }, []);

    const [selectedCategory, setSelectedCategory] = useState(null);

    useEffect(() => {
        const category = faqCategories.find(category => category.id === inputValues.category_id);
        setSelectedCategory(category || '');
    }, [inputValues.category_id, faqCategories]);

    const getFaqCategory = () => {
        axiosConfig
            .get('/admin/faqs/category', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                let data = response.data.data;
                // console.log("faq category data", data)
                setFaqCategories(data)
            })
            .catch((error) => console.log(error));
    }

    const getFaqData = () => {
        // console.log(gotFromUrlId)
        axiosConfig
            .get(`/admin/editfaq/${gotFromUrlId}`, {
                headers: {
                    Authorization: authorization.authorizationUrl,
                },
            })
            .then((response) => {
                let theData = response.data.data;
                // console.log('get faq value', response.data.data);
                // const foundCategory = faqCategories.find(category => category.id === theData.category_id);
                // console.log("foundCategory", foundCategory);
                // setFaqCategoriesIdData(foundCategory)


                // Update the inclutionArr in the data state
                setInputValues({
                    category_id: theData.category_id || '',
                    en: theData.title_en || '', // If title_en is null, set an empty string
                    ar: theData.title_ar || '', // If title_ar is null, set an empty string
                    //it: theData.title_it || '', // If title_it is null, set an empty string
                    //ja: theData.title_ja || '', // If title_ja is null, set an empty string
                });
                setInputValuesDesc({
                    en: theData.content_en || '', // If title_en is null, set an empty string
                    ar: theData.content_ar || '', // If title_ar is null, set an empty string
                    //it: theData.content_it || '', // If title_it is null, set an empty string
                    //ja: theData.content_ja || '', // If title_ja is null, set an empty string
                })

                if (response.data.status) {
                    setTimeout(() => {
                        setFullScreenLoader(false)
                    }, 800);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleApiSubmiteInc = () => {
        // Check if any of the input values are empty
        setButtonLoader(true)
        const invalidLanguages = languages.filter(lang => {
            const inputValue = inputValues[lang.id];
            return !inputValue || inputValue.trim() === '' || inputValue.length < 3;
        });
        const invalidDesc = languages.filter(lang => {
            const inputValueDesc = inputValuesDesc[lang.id];
            return !inputValueDesc || inputValueDesc.trim() === '' || inputValueDesc.length < 3;
        })

        setValErrTab({
            en: false,
            ar: false,
            it: false,
            ja: false,
        });

        setValErrTabDesc({
            en: false,
            ar: false,
            it: false,
            ja: false,
        });

        const newErrInclution = {};
        const newErrDescription = {}

        invalidLanguages.forEach(lang => {
            const inputValue = inputValues[lang.id];
            let errorMessage = '';
            if (!inputValue) {
                setValErrTab(prevValErrTab => ({
                    ...prevValErrTab,
                    [lang.id]: true,
                }));

                errorMessage = `${lang.label} value is empty`;
            } else if (inputValue.trim() === '') {
                setValErrTab(prevValErrTab => ({
                    ...prevValErrTab,
                    [lang.id]: true,
                }));
                errorMessage = `${lang.label} value contains only whitespace`;
            } else if (inputValue.length < 3) {
                setValErrTab(prevValErrTab => ({
                    ...prevValErrTab,
                    [lang.id]: true,
                }));
                errorMessage = `${lang.label} value should be at least 3 characters`;
            }


            newErrInclution[lang.id] = errorMessage;
        });

        invalidDesc.forEach(lang => {
            const inputValueDesc = inputValuesDesc[lang.id];
            let errorMessageDesc = '';

            if (!inputValueDesc) {
                setValErrTab(prevValErrTab => ({
                    ...prevValErrTab,
                    [lang.id]: true,
                }));
                errorMessageDesc = `${lang.label} Description is empty`;
            } else if (inputValueDesc.trim() === '') {
                setValErrTab(prevValErrTab => ({
                    ...prevValErrTab,
                    [lang.id]: true,
                }));
                errorMessageDesc = `${lang.label} Description contains only whitespace`;
            } else if (inputValueDesc.length < 3) {
                setValErrTab(prevValErrTab => ({
                    ...prevValErrTab,
                    [lang.id]: true,
                }));
                errorMessageDesc = `${lang.label} Description should be at least 3 characters`;
            }
            newErrDescription[lang.id] = errorMessageDesc
        })

        const hasErrors = Object.values(newErrInclution).some(errorMsg => errorMsg !== '');
        const hasErrorsDesc = Object.values(newErrDescription).some(errorMsg => errorMsg !== '');

        if (hasErrors || hasErrorsDesc) {
            setErrInclution(newErrInclution);
            setErrorMsg('Fields have errors');
            setErrInclutionDesc(newErrDescription)
            setButtonLoader(false)
            setErrorSnackbarOpen(true);
            // console.log(errInclution, newErrInclution)
        } else {
            const requestData = {
                title_en: inputValues.en,
                title_ar: inputValues.ar,
                title_it: inputValues.en,
                title_ja: inputValues.en,
                content_en: inputValuesDesc.en,
                content_ar: inputValuesDesc.ar,
                content_it: inputValuesDesc.en,
                content_ja: inputValuesDesc.en,
            };
            axiosConfig
                .post(`/admin/editfaq/${gotFromUrlId}`, requestData, {
                    headers: {
                        Authorization: authorization.authorizationUrl,
                    },
                })
                .then((response) => {
                    let theData = response.data;
                    // console.log('updateApiInclusion create value', response.data);
                    if (theData.status) {
                        setSuccessMsg('Faq Updated successfully!');
                        getFaqData()
                        setSuccessSnackbarOpen(true);

                        setErrInclution({
                            en: '',
                            ar: '',
                            // it: '',
                            // ja: '',
                        })
                        setErrInclutionDesc({
                            en: '',
                            ar: '',
                            // it: '',
                            // ja: '',
                        })
                        setButtonLoader(false)
                        setTimeout(() => {
                            history('/faq/faq-list')
                        }, 1000);
                    } else {
                        setButtonLoader(false)
                        setErrorMsg(response.data.message);
                        setErrorSnackbarOpen(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }



    const InclutionContent = (language) => {
        const nextTabIndex = (languages.findIndex(lang => lang.id === language.id) + 1) % languages.length;
        return (
            <div className='inclution-wrapper'>
                {fullScreenLoader ? <FullScreenLoader /> : ''}
                <div className="additional-information-content-wrapper inclution-content-wrapper around-border">
                    <div className='row'>
                        <div className='col-4'>
                            <p className='input-title'>{`FAQ's Category :`}</p>
                        </div>
                        <div className='col-8 mb-3'>
                            <Autocomplete
                                id="category"
                                size="small"
                                name="category"
                                value={selectedCategory}
                                onChange={(event, newValue) => {
                                    setSelectedCategory(newValue.title_en || '');
                                }}
                                options={faqCategories}
                                getOptionLabel={(option) =>
                                    option.title_en || option
                                }
                                isOptionEqualToValue={(option, value) =>
                                    option.id === (value ? value.id : null)
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Select Faq Category"
                                        variant="outlined"
                                        style={{ maxWidth: "100%" }}
                                    />
                                )}
                                disabled={language.id !== 'en'}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                            <p className='input-title'>Title </p>
                        </div>
                        <div className='col-8 mb-3'>
                            <Input
                                disableUnderline
                                sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                className='table-inputs-content'
                                size='small'
                                fullWidth
                                value={inputValues[value]}
                                onChange={(e) =>
                                    setInputValues((prevInputValues) => ({
                                        ...prevInputValues,
                                        [value]: e.target.value,
                                    }))
                                }
                                placeholder={`Enter Title in ${language.label}`}
                                required
                            />
                            {errInclution[value] && <span className="error-msg">{errInclution[value]}</span>}
                        </div>
                        <div className='col-4'>
                            <p className='input-title'>Description</p>
                        </div>
                        <div className='col-8 mb-3'>
                            <Input
                                disableUnderline
                                sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                                className='table-inputs-content'
                                size='small'
                                fullWidth
                                multiline
                                minRows={3}
                                maxRows={6}
                                value={inputValuesDesc[value]}
                                onChange={(e) =>
                                    setInputValuesDesc((prevInputValues) => ({
                                        ...prevInputValues,
                                        [value]: e.target.value,
                                    }))
                                }
                                placeholder={`Enter Description in  ${language.label}`}
                                required
                            />
                            {errInclutionDesc[value] && <span className="error-msg">{errInclutionDesc[value]}</span>}
                        </div>
                    </div>


                </div>


                <div className='submit-button-sub-page mb-4'>

                    {value === 'ar' ?
                        <button className={`PrimaryButton ${buttonloader ? 'button-loader' : ' '}`} type="submit" variant="contained"
                            onClick={handleApiSubmiteInc}
                            fullWidth>
                            {!buttonloader ? 'Update' : ''}
                        </button>
                        :
                        <button
                            className='PrimaryButton w-100'
                            type="button"
                            variant="contained"
                            onClick={() => setValue(languages[nextTabIndex].id)}
                        >
                            Next
                        </button>
                    }

                </div>

            </div>
        );
    };


    return (
        <div className="container-fluid d-flex">
            <div className="content-wrapper">
                <div className="content-components-wrapper">
                    <TopHeaderInternal title={`Edit FAQ`} linkToBackBtn={'/faq/faq-list'} />
                    <div className='user-content'>
                        <div className='row'>
                            <div className='col-xl-6 col-lg-6 col-md-8 col-sm-12'>
                                <div className="additional-information-wrapper sub-page-details-wrapper mt-4">
                                    <TabContext value={value}>
                                        <TabList onChange={handleTabChange} aria-label="nav tabs example">
                                            {languages.map((language) => (
                                                <Tab key={language.id} label={language.label} value={language.id} className={`valErrTab ${valErrTab[language.id] ? 'error-msg-dot' : ''}`} />
                                            ))}
                                        </TabList>
                                        {languages.map((language) => (
                                            <TabPanel key={language.id} value={language.id}>
                                                {InclutionContent(language)}
                                            </TabPanel>
                                        ))}
                                    </TabContext>
                                    <Snackbar open={errorSnackbarOpen} autoHideDuration={3000} onClose={() => setErrorSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                                        <Alert severity='error' sx={{ width: '100%' }}>
                                            {errorMsg}
                                        </Alert>
                                    </Snackbar>
                                    <Snackbar open={successSnackbarOpen} autoHideDuration={3000} onClose={() => setSuccessSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                                        <Alert severity='success' sx={{ width: '100%' }}>
                                            {successMsg}
                                        </Alert>
                                    </Snackbar>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FaqEdit;
