import React, { useEffect, useState } from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SignOutIconImg from "../../assets/images/charm_sign-out.svg";
import styled from "@emotion/styled";
import Dropdown from "react-bootstrap/Dropdown";
import { useStore } from "../../Store/auth";
import { useNavigate } from "react-router-dom";
import authorization from "../../authorization";
import { Alert, Snackbar } from "@mui/material";

const HeaderUtils = styled.div`
  display: flex;
`;

const HeaderUtilButton = styled.span`
  padding: 10%;
  display: inline;
  
  button {
    border: none;
    background-color: initial !important;
  }
`;

export default function TopHeaderMain(props) {
  const logout = useStore((state) => state.logout);
  const isAuth = useStore(((state) => state.isAuth))
  const [buttonloader, setButtonLoader] = useState(false)
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const navigate = useNavigate();

  useEffect(() => {

    if (!isAuth) {
      navigate("/");
    } else if (authorization.authorizationUrl === 'Bearer null') {
      window.location.reload();
    }

  }, [authorization, isAuth])


  const handleLogout = () => {
    setButtonLoader(true)
    setSuccessMsg("Logout successful!")
    setSuccessSnackbarOpen(true)
    localStorage.removeItem('Token-for-login');
    localStorage.removeItem('status');
    sessionStorage.removeItem('Token-for-login');
    sessionStorage.removeItem('status');

    setTimeout(() => {
      logout();
      navigate("/");
      setButtonLoader(false)
    }, 700);
  }

  return (
    <div className="topheader-wrapper topheader-main-wrapper">
      <h3>
        {props.icons} {props.title}
      </h3>
      <HeaderUtils>
        <HeaderUtilButton>
          {/* <NotificationsIcon fontSize="large" /> */}
        </HeaderUtilButton>
        <HeaderUtilButton>
          <Dropdown>
            <Dropdown.Toggle className="UserIcon" id="dropdown-basic">
              <AccountCircleIcon sx={{ color: "black" }} fontSize="large" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <div
                  onClick={() => handleLogout()}
                  className="drop-item-wrapper d-flex align-items-center"
                >
                  <img className="pe-3" src={SignOutIconImg} alt="" />
                  <p className={`red-color-txt ${buttonloader ? 'button-loader' : ''}`}>Logout</p>
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </HeaderUtilButton>
      </HeaderUtils>
      <Snackbar open={errorSnackbarOpen} autoHideDuration={3000} onClose={() => setErrorSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity='error' sx={{ width: '100%' }}>
          {errorMsg}
        </Alert>
      </Snackbar>
      <Snackbar open={successSnackbarOpen} autoHideDuration={3000} onClose={() => setSuccessSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity='success' sx={{ width: '100%' }}>
          {successMsg}
        </Alert>
      </Snackbar>
    </div>
  );
}
