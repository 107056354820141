import React, { useState, useEffect } from 'react';
import { Input, Snackbar, Alert, Tabs, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import axiosConfig from '../../../axios';
import DeleteModal from '../../../Layout/Modal/Modal';
import TopHeaderInternal from '../../../Layout/TopHeader/TopHeaderInternal';
import authorization from '../../../authorization';
import { useNavigate } from 'react-router-dom';
import FullScreenLoader from '../../../Layout/FullScreenLoader/FullScreenLoader';

export default function CreateBlogCategory() {

  const history = useNavigate();
  const [value, setValue] = useState('en'); // Set the default language to 'en' (English)
  const [languages, setLanguages] = useState([
    { id: 'en', label: 'English' },
    { id: 'ar', label: 'Arabic' },
    // { id: 'it', label: 'Italian' },
    // { id: 'ja', label: 'Japanese' },
  ]);

  const [data, setData] = useState({
    inclutionArr: [],

  });

  const [inputValues, setInputValues] = useState({
    en: '',
    ar: '',
    // it: '',
    // ja: '',
  });

  const [errInclution, setErrInclution] = useState({
    en: '',
    ar: '',
    // it: '',
    // ja: '',
  })

  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [buttonloader, setButtonLoader] = useState(false)
  const [fullScreenLoader, setFullScreenLoader] = useState(true)

  const token = localStorage.getItem('Token-for-login');

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const [valErrTab, setValErrTab] = useState({
    en: false,
    ar: false,
    it: false,
    ja: false,
  });

  useEffect(() => {
    setTimeout(() => {
      setFullScreenLoader(false)
    }, 800);
  }, []);

  const handleApiSubmiteInc = () => {
    // Check if any of the input values are empty
    const invalidLanguages = languages.filter(lang => {
      const inputValue = inputValues[lang.id];
      return !inputValue || inputValue.trim() === '' || inputValue.length < 3;
    });

    setValErrTab({
      en: false,
      ar: false,
      it: false,
      ja: false,
    });

    const newErrInclution = {};
    setButtonLoader(true)
    invalidLanguages.forEach(lang => {
      const inputValue = inputValues[lang.id];
      let errorMessage = '';

      if (!inputValue) {
        setValErrTab(prevValErrTab => ({
          ...prevValErrTab,
          [lang.id]: true,
        }));
        errorMessage = `${lang.label} value is empty`;
      } else if (inputValue.trim() === '') {
        setValErrTab(prevValErrTab => ({
          ...prevValErrTab,
          [lang.id]: true,
        }));
        errorMessage = `${lang.label} value contains only whitespace`;
      } else if (inputValue.length < 3) {
        setValErrTab(prevValErrTab => ({
          ...prevValErrTab,
          [lang.id]: true,
        }));
        errorMessage = `${lang.label} value should be at least 3 characters`;
      }

      newErrInclution[lang.id] = errorMessage;
    });

    const hasErrors = Object.values(newErrInclution).some(errorMsg => errorMsg !== '');

    if (hasErrors) {
      setErrInclution(newErrInclution);
      setErrorMsg('Fields have errors');
      setErrorSnackbarOpen(true);
      setButtonLoader(false)
      // console.log(errInclution, newErrInclution)
    } else {
      const requestData = {
        title_en: inputValues.en,
        title_ar: inputValues.ar,
        title_it: inputValues.en,
        title_ja: inputValues.en,
      };
      axiosConfig
        .post(`/admin/category`, requestData, {
          headers: {
            Authorization: authorization.authorizationUrl,
          },
        })
        .then((response) => {
          let theData = response.data;
          // console.log('updateApiInclusion create value', response.data);
          if (theData.status) {
            setSuccessMsg('Blog Category added successfully!');
            // getInclusion()
            setSuccessSnackbarOpen(true);
            setInputValues({
              en: '',
              ar: '',
              // it: '',
              // ja: '',
            })
            setErrInclution({
              en: '',
              ar: '',
              // it: '',
              // ja: '',
            })
            setButtonLoader(false)
            setTimeout(() => {
              history('/blogs/blog-category')
            }, 1000);
          } else {
            setErrorMsg('Error Updating Details!');
            setButtonLoader(false)
            setErrorSnackbarOpen(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }



  const blogCategoryContent = (language) => {
    const nextTabIndex = (languages.findIndex(lang => lang.id === language.id) + 1) % languages.length;
    return (
      <div className='inclution-wrapper'>
        {fullScreenLoader ? <FullScreenLoader /> : ''}
        <div className="additional-information-content-wrapper inclution-content-wrapper around-border">
          <div className='row'>
            <div className="col-4"><p className="input-title" >Category :</p></div>
            <div className="col-8">
              <Input
                disableUnderline
                sx={{ border: '1px solid #C2C8CC;', borderRadius: 1, padding: '8px 15px', width: '100%', maxWidth: '100%' }}
                className='table-inputs-content'
                size='small'
                placeholder={`Enter category name in ${language.label}`}
                required
                fullWidth
                value={inputValues[value]}
                onChange={(e) =>
                  setInputValues((prevInputValues) => ({
                    ...prevInputValues,
                    [value]: e.target.value,
                  }))
                }
              />
              {errInclution[value] && <span className="error-msg">{errInclution[value]}</span>}

            </div>
          </div>
        </div>


        <div className='submit-button-sub-page mb-4'>
          {value === 'ar' ?
            <button className={`PrimaryButton ${buttonloader ? 'button-loader' : ' '}`} type="submit" variant="contained" onClick={handleApiSubmiteInc} fullWidth>
              {!buttonloader ? 'Submit' : ''}
            </button>
            :
            <button
              className='PrimaryButton w-100'
              type="button"
              variant="contained"
              onClick={() => setValue(languages[nextTabIndex].id)}
            >
              Next
            </button>
          }

        </div>

      </div>
    );
  };


  return (
    <div className="container-fluid d-flex">
      <div className="content-wrapper">
        <div className="content-components-wrapper">
          <TopHeaderInternal title={`Create New Blog Category`} linkToBackBtn={'/blogs/blog-category'} />
          <div className='user-content'>
            <div className='row'>
              <div className='col-xl-5 col-lg-6 col-md-8 col-sm-12'>
                <div className="additional-information-wrapper sub-page-details-wrapper mt-4">
                  <TabContext value={value}>
                    <TabList onChange={handleTabChange} aria-label="nav tabs example">
                      {languages.map((language) => (
                        <Tab key={language.id} label={language.label} value={language.id} className={`valErrTab ${valErrTab[language.id] ? 'error-msg-dot' : ''}`} />
                      ))}
                    </TabList>
                    {languages.map((language) => (
                      <TabPanel key={language.id} value={language.id}>
                        {blogCategoryContent(language)}
                      </TabPanel>
                    ))}
                  </TabContext>
                  <Snackbar open={errorSnackbarOpen} autoHideDuration={3000} onClose={() => setErrorSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert severity='error' sx={{ width: '100%' }}>
                      {errorMsg}
                    </Alert>
                  </Snackbar>
                  <Snackbar open={successSnackbarOpen} autoHideDuration={3000} onClose={() => setSuccessSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert severity='success' sx={{ width: '100%' }}>
                      {successMsg}
                    </Alert>
                  </Snackbar>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};
