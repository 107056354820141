import MainPages from './Pages/MainPages';
import './Style/Style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useNavigate } from 'react-router-dom';
import EditUser from './Pages/Users/components/EditUsers';
import Sidebar from './Layout/Sidebar/Sidebar';
import Bookings from './Pages/Bookings/Bookings';
import AddUser from './Pages/Users/components/AddUser';
import UsersTable from './Pages/Users/UsersTable';
import UserDetails from './Pages/Users/components/UserDetails';
import BookingDetails from './Pages/Bookings/components/BookingDetails';
import CreateBooking from './Pages/Bookings/components/CreateBooking';
import EditBooking from './Pages/Bookings/components/EditBooking';
import Categories from './Pages/Tours/Categories/Categories';
import CreateCategory from './Pages/Tours/Categories/components/CreateCategory';
import EditCategory from './Pages/Tours/Categories/components/EditCategory';
import Banners from './Pages/Tours/Banners/Banners';
import EditBanner from './Pages/Tours/Banners/EditBanner';
import Locations from './Pages/Tours/Locations/Locations';
import CreateLocation from './Pages/Tours/Locations/components/CreateLocation';
import EditLocation from './Pages/Tours/Locations/components/EditLocation';
import TourList from './Pages/Tours/TourList';
import ContactUsList from './Pages/Inquiries/ContactUs/ContactUsList';
import ContactUsDetails from './Pages/Inquiries/ContactUs/components/ContactUsDetails';
import InquiryList from './Pages/Inquiries/BookingInquiry/InquiryList';
import InquiryDetails from './Pages/Inquiries/BookingInquiry/components/InquiryDetails';
import MakeTourList from './Pages/Inquiries/MakeTour/MakeTourList';
import MakeTourDetails from './Pages/Inquiries/MakeTour/components/MakeTourDetails';
import Setting from './Pages/Setting/Setting';
import BlogList from './Pages/Blog/BlogList';
import CreateBlog from './Pages/Blog/components/CreateBlog';
import CreateBlogCategory from './Pages/Blog/components/CreateBlogCategory';
import TransactionList from './Pages/Payment/TransactionList';
import PaymentList from './Pages/Payment/PaymentList';
import RefundList from './Pages/Payment/RefundList';
import RefundDetails from './Pages/Payment/components/RefundDetails';
import PaymentDetails from './Pages/Payment/components/PaymentDetails';
import CreatePayment from './Pages/Payment/components/CreatePayment';
import Testimony from './Pages/Testimonials/Testimony';
import CreateTestimony from './Pages/Testimonials/components/CreateTestimony';
import EditTestimony from './Pages/Testimonials/components/EditTestimony';
import TestimonyDetails from './Pages/Testimonials/components/TestimonyDetails';
import EditBlog from './Pages/Blog/components/EditBlog';
import PopularTour from './Pages/Tours/PopularTour/PopularTour';
import CreatePopularTour from './Pages/Tours/PopularTour/CreatePopulerTour';
import RelatedTour from './Pages/Tours/RelatedTour/RelatedTour';
import CreateRelatedTour from './Pages/Tours/RelatedTour/CreateRelatedTour';
import Report from './Pages/Reports/Report';
import TourForm from './Pages/Tours/components/TourForm';
import BlogDetails from './Pages/Blog/components/BlogDetails';
import BlogCategory from './Pages/Blog/components/BlogCategory';
import EditBlogCategory from './Pages/Blog/components/EditBlogCategory';
import EditRelatedTour from './Pages/Tours/RelatedTour/EditRelatedTour';
import EditPopulerToour from './Pages/Tours/PopularTour/EditPopularTour';
import EditPopularTour from './Pages/Tours/PopularTour/EditPopularTour';
import TourDetails from './Pages/Tours/components/TourDetails';
import EditTour from './Pages/Tours/components/EditTour';
import Login from './Pages/Login'
import { useStore } from './Store/auth';
import General from './Pages/Tours/General/General';
import FaqList from './Pages/Faq/FaqList';
import FaqCategory from './Pages/Faq/components/FaqCategory';
import CreateFaqCategory from './Pages/Faq/components/CreateFaqCategory';
import EditFaqCategory from './Pages/Faq/components/EditFaqCategory';
import FaqCreate from './Pages/Faq/components/FaqCreate';
import FaqEdit from './Pages/Faq/components/FaqEdit';
import PageNotFound from './Pages/PageNotFound/PageNotFound';
import TermsOfUse from './Pages/AllPages/TermsOfUse/TermsOfUse';
import PrivacyPolicy from './Pages/AllPages/PrivacyPolicy/PrivacyPolicy';
import AboutUs from './Pages/AllPages/AboutUs/AboutUs';
import ContactUs from './Pages/AllPages/ContactUs/ContactUs';
import BlogPage from './Pages/AllPages/BlogPage/BlogPage';
import ToursPage from './Pages/AllPages/ToursPage/ToursPage';
import HomePage from './Pages/AllPages/Home/HomePage';
import FaqsPage from './Pages/AllPages/FaqsPage/FaqsPage';
import MakeATour from './Pages/AllPages/MakeATour/MakeATour';
import TourInquiry from './Pages/AllPages/TourInquiry/TourInquiry';
import ResetPasswordPage from './Pages/AllPages/ResetPasswordPage/ResetPasswordPage';
import ThankYouPage from './Pages/AllPages/ThankYouPage/ThankYouPage';




function App() {
  const isAuth = useStore(((state) => state.isAuth))

  return (
    <>
      {/* <MainPages /> */}
      <Router>
        {isAuth ? <Sidebar /> : null}

        <Routes>
          <Route path='*' element={<PageNotFound />} />
          <Route path='/' element={<Login />} />

          <Route path='/user' element={<UsersTable />} />
          <Route path='/user/edit-user/:id' element={<EditUser />} />
          <Route path='/user/user-details/:id' element={<UserDetails />} />
          <Route path='/user/add-new-user' element={<AddUser />} />

          {/* <Route path='/tours/bookings' element={<Bookings />} />
          <Route path='/booking/booking-details/:id' element={<BookingDetails />} />
          <Route path='/booking/edit-booking/:id' element={<EditBooking />} />
          <Route path='/booking/create-new-booking' element={<CreateBooking />} /> */}

          <Route path='/tours/all-tours' element={<TourList />} />
          <Route path='/tours/tour-details/:id' element={<TourDetails />} />
          <Route path='/tours/edit-tour/:id' element={<TourForm />} />
          <Route path='/tours/create-new-tour' element={<TourForm />} />

          <Route path='/tours/categories' element={<Categories />} />
          <Route path='/tours/categories/create-new-category' element={<CreateCategory />} />
          <Route path='/tours/categories/edit-category/:id' element={<EditCategory />} />

          <Route path='/tours/locations' element={<Locations />} />
          <Route path='/tours/locations/create-new-location' element={<CreateLocation />} />
          <Route path='/tours/locations/edit-location/:id' element={<EditLocation />} />

          <Route path='/tours/inclutions' element={<General />} />

          {/* <Route path='/tours/popular-tour' element={<PopularTour />} /> */}
          {/* <Route path='/tours/popular-tour/create-new-popular-tour' element={<CreatePopularTour />} /> */}
          {/* <Route path='/tours/popular-tour/edit-popular-tour/:id' element={<EditPopularTour />} /> */}

          {/* <Route path='/tours/related-tour' element={<RelatedTour />} />
          <Route path='/tours/related-tour/create-new-related-tour' element={<CreateRelatedTour />} />
          <Route path='/tours/related-tour/edit-related-tour/:id' element={<EditRelatedTour />} /> */}

          <Route path='/tours/banners' element={<Banners />} />
          <Route path='/tours/banners/:id' element={<EditBanner />} />

          <Route path='/inquiry/contact-us' element={<ContactUsList />} />
          <Route path='/inquiry/contact-us/details/:id' element={<ContactUsDetails />} />
          {/* <Route path='/inquiry/contact-us/Edit/:id' element={<EditContactUs />} /> */}

          <Route path='/inquiry/tour-inquiry' element={<InquiryList />} />
          <Route path='/inquiry/tour-inquiry/details/:id' element={<InquiryDetails />} />

          <Route path='/inquiry/make-a-tour' element={<MakeTourList />} />
          <Route path='/inquiry/make-a-tour/details/:id' element={<MakeTourDetails />} />

          <Route path='/blogs/blog-list' element={<BlogList />} />
          <Route path='/blogs/create-blog' element={<CreateBlog />} />
          <Route path='/blogs/edit-blog/:id' element={<EditBlog />} />
          <Route path='/blogs/blog-details/:id' element={<BlogDetails />} />

          <Route path='/blogs/blog-category' element={<BlogCategory />} />
          <Route path='/blogs/create-blog-category' element={<CreateBlogCategory />} />
          <Route path='/blogs/edit-blog-category/:id' element={<EditBlogCategory />} />

          {/* <Route path='/payments/transaction' element={<TransactionList />} />
          <Route path='/payments/payments' element={<PaymentList />} />
          <Route path='/payments/payments/create-new-payment' element={<CreatePayment />} />
          <Route path='/payments/payment/details/:id' element={<PaymentDetails />} />
          <Route path='/payments/refund-requests' element={<RefundList />} />
          <Route path='/payments/refund-requests/details/:id' element={<RefundDetails />} /> */}

          <Route path='/testimony' element={<Testimony />} />
          <Route path='/testimony/create-new' element={<CreateTestimony />} />
          <Route path='/testimony/edit-testimony/:id' element={<EditTestimony />} />
          <Route path='/testimony/testimony-details/:id' element={<TestimonyDetails />} />

          {/* <Route path='/reports' element={<Report />} /> */}
          <Route path='/pages/home' element={<HomePage />} />
          <Route path='/pages/tours' element={<ToursPage />} />
          <Route path='/pages/blogs' element={<BlogPage />} />
          <Route path='/pages/about-us' element={<AboutUs />} />
          <Route path='/pages/contact-us' element={<ContactUs />} />
          <Route path='/pages/make-a-tour' element={<MakeATour />} />
          <Route path='/pages/tour-inquiry' element={<TourInquiry />} />
          <Route path='/pages/faqs' element={<FaqsPage />} />
          <Route path='/pages/terms-of-use' element={<TermsOfUse />} />
          <Route path='/pages/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/pages/reset-password' element={<ResetPasswordPage />} />
          <Route path='/pages/thank-you' element={<ThankYouPage />} />

          <Route path='/faq/faq-list' element={<FaqList />} />
          <Route path='/faq/create-new' element={<FaqCreate />} />
          <Route path='/faq/edit-faq/:id' element={<FaqEdit />} />
          <Route path='/faq/faq-category' element={<FaqCategory />} />
          <Route path='/faq/faq-category/create-new' element={<CreateFaqCategory />} />
          <Route path='/faq/faq-category/edit-faq-category/:id' element={<EditFaqCategory />} />

          <Route path='/settings' element={<Setting />} />

        </Routes>
      </Router>
    </>
  );
}

export default App; 
