import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import BlackLogo from '../../assets/images/ATHAAR LOGO 2.svg'
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import ReviewsIconOutlinedIcon from '@mui/icons-material/ReviewsOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
export default class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openAccordion: null,
        };
    }

    handleAccordionToggle = (accordionName) => {
        this.setState((prevState) => ({
            openAccordion: prevState.openAccordion === accordionName ? null : accordionName,
        }));
    };

    isAccordionOpen(accordionName) {
        return this.state.openAccordion === accordionName;
    }

    render() {
        return (
            <>
                <div className="sidebar-main-wrapper">
                    <div className='logo-main'>
                        <img src={BlackLogo} alt="" />
                    </div>
                    <div className="sidebar-links">
                        <ul className='sidebar-menu'>
                            <li>
                                <NavLink exact to='/user' className='nounderline' activeClassName='active'>
                                    <PeopleAltOutlinedIcon /> Users
                                </NavLink>
                            </li>
                            <li>
                                <div className="accordion" id="tourAccordion">
                                    <div className="accordion-item" style={{ backgroundColor: 'transparent', border: 'none' }}>
                                        <div className="accordion-header" id="tourItemWrapper">
                                            <span
                                                className={`tourAccordion nav-color accordion-title-sidebar ${this.isAccordionOpen('tourAccordion') ? 'active' : ''}`}
                                                onClick={() => this.handleAccordionToggle('tourAccordion')}
                                                data-bs-toggle="collapse"
                                                data-bs-target="#tourItems"
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <CreditCardOutlinedIcon /> Tours <ArrowDropDownIcon className="arrow-dd" />
                                            </span>
                                        </div>
                                        <div
                                            id="tourItems"
                                            className={`accordion-collapse ${this.isAccordionOpen('tourAccordion') ? 'show' : 'collapse'}`}
                                        >
                                            <div className="accordion-body" style={{ paddingTop: '0', paddingBottom: '0', paddingRight: '0' }}>
                                                <ul>
                                                    <li>
                                                        <NavLink exact to="/tours/all-tours" className="nounderline" activeClassName="active">
                                                            All Tours
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to="/tours/categories" className="nounderline" activeClassName="active">
                                                            Categories
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to='/tours/locations' className='nounderline' activeClassName='active'>
                                                            Location
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to='/tours/banners' className='nounderline' activeClassName='active'>
                                                            Banners
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to='/tours/inclutions' className='nounderline' activeClassName='active'>
                                                            Inclutions
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="accordion" id="accordionExample">
                                    <div class="accordion-item" style={{ backgroundColor: 'transparent', border: 'none' }}>
                                        <div class="accordion-header" id="inquiryWrapper">
                                            <span className={`inquiryAccordion  accordion-title-sidebar nav-color ${this.isAccordionOpen('inquiryAccordion') ? 'active' : ''}`} onClick={() => this.handleAccordionToggle('inquiryAccordion')} data-bs-toggle="collapse" data-bs-target="#inquiryInfo" style={{ cursor: 'pointer' }}>
                                                <ListAltOutlinedIcon /> Inquiry <ArrowDropDownIcon className='arrow-dd' />
                                            </span>
                                        </div>
                                        <div id="inquiryInfo" className={`accordion-collapse ${this.isAccordionOpen('inquiryAccordion') ? 'show' : 'collapse'}`}>
                                            <div class="accordion-body" style={{ paddingTop: '0', paddingBottom: '0', paddingRight: '0' }}>
                                                <ul>
                                                    <li>
                                                        <NavLink exact to='/inquiry/contact-us' className='nounderline' activeClassName='active'>
                                                            Contact Us
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to='/inquiry/tour-inquiry' className='nounderline' activeClassName='active'>
                                                            Tour Inquiry
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to='/inquiry/make-a-tour' className='nounderline' activeClassName='active'>
                                                            Make A Tour
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="accordion-item" style={{ backgroundColor: 'transparent', border: 'none' }}>
                                    <div class="accordion-header" id="blogItemWrapper">
                                        <span class={`blogsAccordion  accordion-title-sidebar nav-color ${this.isAccordionOpen('blogsAccordion') ? 'active' : ''}`} onClick={() => this.handleAccordionToggle('blogsAccordion')} data-bs-toggle="collapse" data-bs-target="#blogItems" style={{ cursor: 'pointer' }}>
                                            <TextSnippetOutlinedIcon />  Blogs <ArrowDropDownIcon className='arrow-dd' />
                                        </span>
                                    </div>
                                    <div id="blogItems" className={`accordion-collapse ${this.isAccordionOpen('blogsAccordion') ? 'show' : 'collapse'}`}>
                                        <div class="accordion-body" style={{ paddingTop: '0', paddingBottom: '0', paddingRight: '0', paddingLeft: '1.25rem' }}>
                                            <ul>
                                                <li>
                                                    <NavLink exact to='/blogs/blog-list' className='nounderline' activeClassName='active'>
                                                        Blogs List
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink exact to='/blogs/blog-category' className='nounderline' activeClassName='active'>
                                                        Category
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <NavLink exact to='/testimony' className='nounderline' activeClassName='active'><ReviewsIconOutlinedIcon /> Testimonials</NavLink>
                            </li>
                            <li>
                                <div class="accordion" id="faqAccordion">
                                    <div class="accordion-item" style={{ backgroundColor: 'transparent', border: 'none' }}>
                                        <div class="accordion-header" id="faqWrapper">
                                            <span className={`faqAccordion  accordion-title-sidebar nav-color ${this.isAccordionOpen('faqAccordion') ? 'active' : ''}`} onClick={() => this.handleAccordionToggle('faqAccordion')} data-bs-toggle="collapse" data-bs-target="#faqInfo" style={{ cursor: 'pointer' }}>
                                                <QuizOutlinedIcon /> FAQs <ArrowDropDownIcon className='arrow-dd' />
                                            </span>
                                        </div>
                                        <div id="faqInfo" className={`accordion-collapse ${this.isAccordionOpen('faqAccordion') ? 'show' : 'collapse'}`}>
                                            <div class="accordion-body" style={{ paddingTop: '0', paddingBottom: '0', paddingRight: '0' }}>
                                                <ul>
                                                    <li>
                                                        <NavLink exact to='/faq/faq-category' className='nounderline' activeClassName='active'>
                                                            Category
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to='/faq/faq-list' className='nounderline' activeClassName='active'>
                                                            FAQs List
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="accordion" id="pagesAccordion">
                                    <div className="accordion-item" style={{ backgroundColor: 'transparent', border: 'none' }}>
                                        <div className="accordion-header" id="pagesItemWrapper">
                                            <span
                                                className={`pagesAccordion nav-color accordion-title-sidebar ${this.isAccordionOpen('pagesAccordion') ? 'active' : ''}`}
                                                onClick={() => this.handleAccordionToggle('pagesAccordion')}
                                                data-bs-toggle="collapse"
                                                data-bs-target="#pagesItems"
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <TextSnippetOutlinedIcon /> Pages <ArrowDropDownIcon className="arrow-dd" />
                                            </span>
                                        </div>
                                        <div
                                            id="pagesItems"
                                            className={`accordion-collapse ${this.isAccordionOpen('pagesAccordion') ? 'show' : 'collapse'}`}
                                        >
                                            <div className="accordion-body" style={{ paddingTop: '0', paddingBottom: '0', paddingRight: '0' }}>
                                                <ul>
                                                    <li>
                                                        <NavLink exact to="/pages/home" className="nounderline" activeClassName="active">
                                                            Home
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to="/pages/tours" className="nounderline" activeClassName="active">
                                                            Tours
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to="/pages/blogs" className="nounderline" activeClassName="active">
                                                            Blogs
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to="/pages/about-us" className="nounderline" activeClassName="active">
                                                            About Us
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to="/pages/contact-us" className="nounderline" activeClassName="active">
                                                            Contact Us
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to="/pages/make-a-tour" className="nounderline" activeClassName="active">
                                                            Make a Tour
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to="/pages/tour-inquiry" className="nounderline" activeClassName="active">
                                                            Tour Inquiry
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to="/pages/faqs" className="nounderline" activeClassName="active">
                                                            FAQs
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to="/pages/privacy-policy" className="nounderline" activeClassName="active">
                                                            Privacy Policy
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to="/pages/terms-of-use" className="nounderline" activeClassName="active">
                                                            Terms Of Use
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to="/pages/reset-password" className="nounderline" activeClassName="active">
                                                            Reset Password
                                                        </NavLink>
                                                    </li>
                                                    <li>
                                                        <NavLink exact to="/pages/thank-you" className="nounderline" activeClassName="active">
                                                            Thank You
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>
            </>
        )
    }
}
