import React from 'react'
import TopHeaderMain from '../../../Layout/TopHeader/TopHeaderMain'
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import { TextField } from '@mui/material';
import AdditionalInformation from './Components/AdditionalInformation';
import PolicyCancelationPayment from './Components/PolicyCancelationPayment';
import ImageUploader from '../components/ImageUploader';

export default function General() {

    return (
        <div className="container-fluid d-flex">
            <div className="content-wrapper">
                <div className="content-components-wrapper">
                    <TopHeaderMain title='Additional Information ' icons={<FeedOutlinedIcon fontSize="large" />} />
                    <div className='user-content'>
                        <div className='row'>
                            <div className='col-xl-5 col-lg-6 col-md-8 col-sm-12'>
                                <AdditionalInformation />
                                {/* <PolicyCancelationPayment /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
