import React, { useEffect, useState } from 'react'
import TopHeaderMain from '../../../Layout/TopHeader/TopHeaderMain'
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined';
import {
    Snackbar,
    Alert,
    Tabs,
    Tab
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ReactQuill from 'react-quill';
import axiosConfig from '../../../axios';
import FullScreenLoader from '../../../Layout/FullScreenLoader/FullScreenLoader';
import MetaInfo from '../../../Layout/MetaInfo/MetaInfo';

const PrivacyPolicy = () => {

    const [privacyEn, setPrivacyEn] = useState()
    const [privacyAr, setPrivacyAr] = useState()
    const [privacyIt, setPrivacyIt] = useState()
    const [privacyJa, setPrivacyJa] = useState()
    const [value, setValue] = React.useState('1');
    const [valueMeta, setValueMeta] = React.useState('1');
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
    const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

    const [errorMsg, setErrorMsg] = useState()
    const [buttonloader, setButtonLoader] = useState(false)
    const [fullScreenLoader, setFullScreenLoader] = useState(true)
    const [editMode, setEditMode] = useState(false);

    const handleEdit = () => {

        setEditMode(true);
    };
    const handleCancel = () => {

        setEditMode(false);
    };

    const token = localStorage.getItem("Token-for-login");
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleTabChangeMeta = (event, newValue) => {
        setValueMeta(newValue);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setButtonLoader(true)
        // console.log("the entered data", privacyEn,
        //     privacyAr,
        //     privacyIt,
        //     privacyJa,)
        // Check if all fields have data and are not "<br>"
        if (
            privacyEn.trim() !== "" &&
            privacyAr.trim() !== ""
            // privacyIt.trim() !== "" &&
            // privacyJa.trim() !== ""
        ) {

            axiosConfig
                .post(
                    `/admin/privacyupdate`,
                    {
                        text_en: privacyEn,
                        text_ar: privacyAr,
                        text_it: privacyEn,
                        text_ja: privacyEn,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((response) => {
                    let data = response.data.status;
                    if (data === true) {
                        setButtonLoader(false)
                        setSuccessSnackbarOpen(true);
                        setEditMode(false);
                    } else {
                        setButtonLoader(false)
                        setErrorSnackbarOpen(true);
                    }
                })
                .catch((error) => console.log(error));
        } else {

            // Prepare error message for empty or <br> fields
            let errors = [];
            if (privacyEn.trim() === '' || privacyEn.includes('<br>')) {
                errors.push('English field cannot be empty.');
            }
            if (privacyAr.trim() === '' || privacyAr.includes('<br>')) {
                errors.push('Arabic field cannot be empty.');
            }
            // if (privacyIt.trim() === '' || privacyIt.includes('<br>')) {
            //     errors.push('Italian field cannot be empty.');
            // }
            // if (privacyJa.trim() === '' || privacyJa.includes('<br>')) {
            //     errors.push('Japanese field cannot be empty.');
            // }

            // Set the error message state with the collected errors
            setErrorMsg(errors.join(' '));
            // console.log(errors.join(' '))

            // Check if multiple fields are empty
            if (errors.length > 1) {
                setErrorMsg('Multiple fields are empty.');
            }
            setButtonLoader(false)
            setErrorSnackbarOpen(true);

        }
    }

    useEffect(() => {
        axiosConfig
            .get(
                `/admin/privacydata`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((response) => {

                let data = response.data.data
                setPrivacyEn(data.text_en)
                setPrivacyAr(data.text_ar)
                setPrivacyIt(data.text_en)
                setPrivacyJa(data.text_en)

                if (response.data.status) {
                    setTimeout(() => {
                        setFullScreenLoader(false)
                    }, 800);
                }

            })
            .catch((error) => console.log(error));
    }, [])

    const modules = {
        toolbar: [
            [{ header: [4, 5, 6] }],
            [{ size: ['small', false, 'large'] }], // Add font size selector
            ['bold', 'italic'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link'],
            // ['clean'],

        ],
        clipboard: {
            matchVisual: false,
        },
    };


    return (
        <div className="container-fluid d-flex">
            <div className="content-wrapper privacy-policy-wrapper">
                <div className="content-components-wrapper">
                    {fullScreenLoader ? <FullScreenLoader /> : ''}
                    <TopHeaderMain title='Privacy Policy ' icons={<PrivacyTipOutlinedIcon fontSize="large" />} />
                    <div className='user-content sub-page-details-wrapper mt-4 '>
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12">
                                <MetaInfo slug="privacy-policy" />
                                <form id="form-file-upload" onSubmit={handleSubmit}>
                                    <h5 className='sub-page-title'>Privacy Policy</h5>

                                    <TabContext value={value}>
                                        <Tabs value={value} onChange={handleTabChange} variant="scrollable" scrollButtons allowScrollButtonsMobile aria-label="nav tabs example">
                                            <Tab label="English" value="1" className="error-msg" />
                                            <Tab label="Arabic" value="2" className="error-msg" />
                                            {/* <Tab label="Italian" value="3" className="error-msg" /> */}
                                            {/* <Tab label="Japanese" value="4" className="error-msg" /> */}
                                        </Tabs>
                                        <div className='around-border pt-2'>
                                            <div className='edit-button-wrapper text-end position-relative'>
                                                {!editMode ? (
                                                    <button
                                                        className='SmallButton'
                                                        onClick={handleEdit}
                                                        type='button'
                                                    >
                                                        Edit
                                                    </button>
                                                ) : <button
                                                    className='SmallButton'
                                                    onClick={handleCancel}
                                                    type='button'
                                                >
                                                    Cancel
                                                </button>}
                                            </div>
                                            <TabPanel className='p-3' value="1">
                                                <div className='around-border-policy'>
                                                    <ReactQuill
                                                        className="policy-description-wrapper mb-3"
                                                        value={privacyEn}
                                                        onChange={(value) =>
                                                            setPrivacyEn(value)
                                                        }
                                                        modules={modules}
                                                        readOnly={!editMode}
                                                    />
                                                </div>
                                            </TabPanel>
                                            <TabPanel className='p-3' value="2">
                                                <div className='around-border-policy' dir="rtl">
                                                    <ReactQuill
                                                        className="policy-description-wrapper policy-arabic mb-3"
                                                        value={privacyAr}
                                                        onChange={(value) =>
                                                            setPrivacyAr(value)
                                                        }
                                                        modules={modules}
                                                        readOnly={!editMode}
                                                    />
                                                </div>
                                            </TabPanel>
                                            {/* <TabPanel className='p-3' value="3">
                                            <h5 className='sub-page-title'>Privacy Policy In Italian Language</h5>
                                            <div className='around-border-policy'>
                                                <ReactQuill
                                                    className="policy-description-wrapper mb-3"
                                                    value={privacyIt}
                                                    onChange={(value) =>
                                                        setPrivacyIt(value)
                                                    }
                                                    modules={modules}
                                                />
                                            </div>
                                        </TabPanel>
                                        <TabPanel className='p-3' value="4">
                                            <h5 className='sub-page-title'>Privacy Policy In Japanese Language</h5>
                                            <div className='around-border-policy'>
                                                <ReactQuill
                                                    className="policy-description-wrapper mb-3"
                                                    value={privacyJa}
                                                    onChange={(value) =>
                                                        setPrivacyJa(value)
                                                    }
                                                    modules={modules}
                                                />
                                            </div>
                                        </TabPanel> */}
                                        </div>
                                    </TabContext>
                                    {editMode ? (
                                        <div className='submit-button-sub-page mb-4'>
                                            <button className={`PrimaryButton ${buttonloader ? 'button-loader' : ' '}`} type="submit" variant="contained" fullWidth>
                                                {!buttonloader ? 'Submit' : ''}
                                            </button>
                                        </div>) : null}
                                </form>
                                <Snackbar open={errorSnackbarOpen} autoHideDuration={3000} onClose={() => setErrorSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                                    <Alert severity="error" sx={{ width: '100%' }}>
                                        {errorMsg}
                                    </Alert>
                                </Snackbar>
                                <Snackbar open={successSnackbarOpen} autoHideDuration={3000} onClose={() => setSuccessSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                                    <Alert severity="success" sx={{ width: '100%' }}>
                                        Privacy Policy Updated successfully!
                                    </Alert>
                                </Snackbar>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default PrivacyPolicy