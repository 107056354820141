import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    FormControl,
    Select,
    MenuItem,
    Menu,
    InputLabel,
    TableSortLabel,
    TableFooter,
    Checkbox,
    IconButton,
    Pagination,
    TablePagination,
    Snackbar,
    Alert,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import PeopleIcon from '@mui/icons-material/People';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import DeleteIcon from '@mui/icons-material/Delete';
import TopHeaderMain from '../../Layout/TopHeader/TopHeaderMain';
import axiosConfig from '../../axios';
import authorization from '../../authorization';

const StyledTextField = styled(TextField)({
    width: '100%',
    marginBottom: '1rem',
});

const StyledFormControl = styled(FormControl)({
    width: '100%',
    marginBottom: '1rem',
});

export default function Bookings() {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('all');
    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(8);
    const [totalPages, setTotalPages] = useState();
    const [openSnackbar, setOpenSnackbar] = useState(false);

    useEffect(() => {
        axiosConfig.get(`/admin/booking?page=${page}`, {
            headers: {
                'Authorization': authorization.authorizationUrl
            }
        })
            .then(response => {
                console.log(typeof (response.data.data.last_page))

                setUsers(response.data.data.data);
                setTotalPages(response.data.data.last_page);
            })
            .catch(error => console.log(error));
    }, [page, rowsPerPage]);


    const handleDeleteClick = (id) => {
        axiosConfig.delete(`/admin/booking/${id}`, {
            headers: {
                'Authorization': authorization.authorizationUrl
            }
        })
            .then(response => {
                // Fetch the updated list of users
                axiosConfig.get(`/admin/booking?page=${page}`, {
                    headers: {
                        'Authorization': authorization.authorizationUrl
                    }
                })
                    .then(response => {

                        setUsers(response.data.data.data);
                        setTotalPages(response.data.data.last_page);
                    })
                    .catch(error => console.log(error));
            })
            .catch(error => console.log(error));
    }



    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };


    const handleSortClick = (fieldName) => {
        if (fieldName === sortBy) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(fieldName);
            setSortOrder('asc');
        }
    };


    const history = useNavigate();
    const handleEditClick = (id) => {
        // logic to handle edit click
        history(`/booking/edit-booking/${id}`);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const filteredUsers = users
        .filter((user) => {
            if (selectedStatus !== 'all' && user.status !== selectedStatus) {
                return false;
            }
            const searchRegex = new RegExp(searchQuery, 'i');
            return searchRegex.test(user.customer_name) || searchRegex.test(user.email) | searchRegex.test(user.tour_name);
        })
        .sort((a, b) => {
            if (!sortBy) {
                return 0;
            }

            const sortOrderMultiplier = sortOrder === 'asc' ? 1 : -1;

            if (sortBy === 'booking_id') {
                return sortOrderMultiplier * (a.booking_id - b.booking_id);
            } else if (sortBy === 'customer_name') {
                return sortOrderMultiplier * a.customer_name.localeCompare(b.customer_name);
            }
            return 0;
        });

    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const paginatedUsers = filteredUsers.slice(startIndex, endIndex);

    const handleDetailClick = (user) => {
        history(`/booking/booking-details/${user.id}`);
    }

    function formatDate(dateString) {
        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
    }

    return (
        <>
            <div className="container-fluid d-flex">
                <div className="content-wrapper">
                    <div className="content-components-wrapper">
                        <TopHeaderMain title='Bookings' icons={<BookmarkAddedIcon fontSize="large" />} />
                        <div className="bookings-wrapper mt-4">

                            <div className="row">
                                <div className="col-4">
                                    <StyledTextField
                                        label="Search by Name"
                                        variant="outlined"
                                        size="small"
                                        onChange={handleSearchInputChange}
                                    />
                                </div>
                                <div className="col-4">
                                    {/* <StyledFormControl variant="outlined">
                                        <InputLabel id="status-select-label">Filter by status</InputLabel>
                                        <Select
                                            size="small"
                                            labelId="status-select-label"
                                            id="status-select"
                                            value={selectedStatus}
                                            onChange={handleStatusSelectChange}
                                            label="Filter by status"
                                        >
                                            <MenuItem value="all">All</MenuItem>
                                            <MenuItem value="Confirmed">Confirmed</MenuItem>
                                            <MenuItem value="Cancelled">Cancelled</MenuItem>
                                        </Select>
                                    </StyledFormControl> */}
                                </div>
                                <div className="col-4 text-end">
                                    <Link to='/booking/create-new-booking'>
                                        <button className='PrimaryButton'><AddIcon />Create Booking</button>
                                    </Link>
                                </div>
                            </div>
                            <div className="user-table-content mt-3">
                                <TableContainer component={Paper} sx={{ boxShadow: 'none', border: '1px solid #E4E4E4' }}>
                                    <Table >

                                        <TableHead style={{ backgroundColor: '#e9ecef' }}>
                                            <TableRow >
                                                <TableCell>
                                                    <TableSortLabel
                                                        className='table-title-bold'
                                                        active={sortBy === "booking_id"}
                                                        direction={sortOrder}
                                                        onClick={() => handleSortClick("booking_id")}
                                                    >
                                                        Booking Id
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell className='table-title-bold'>
                                                    Tour Name
                                                </TableCell>
                                                <TableCell className='table-title-bold'>Date</TableCell>
                                                <TableCell>
                                                    <TableSortLabel
                                                        className='table-title-bold'
                                                        active={sortBy === "customer_name"}
                                                        direction={sortOrder}
                                                        onClick={() => handleSortClick("customer_name")}
                                                    >
                                                        Customer name
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell className='table-title-bold'>Email</TableCell>
                                                {/* <TableCell>Phone no</TableCell> */}


                                                <TableCell className='table-title-bold'>Status</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>

                                        </TableHead>
                                        {users.length > 0 ? (
                                            <TableBody className='table-body-wrapper booking-table-body-wrapper'>
                                                {paginatedUsers.map((user) => (
                                                    <TableRow key={user.id}>
                                                        <TableCell className='table-data booking-table-data-id'>{user.id}</TableCell>
                                                        <TableCell className='table-data'><span style={{ cursor: 'pointer', color: '#4B1569' }} onClick={() => handleDetailClick(user)}>{user.tour_title}</span></TableCell>
                                                        <TableCell className='table-data'> {user.travel_date && formatDate(user.travel_date)}</TableCell>
                                                        <TableCell className='table-data'><span style={{ cursor: 'pointer', color: '#4B1569' }} onClick={() => handleDetailClick(user)}>{user.user_name}</span></TableCell>
                                                        <TableCell className='table-data'>{user.user_email}</TableCell>
                                                        {/* <TableCell>{user.Phone}</TableCell> */}
                                                        <TableCell className='table-data'><span className={user.status == "confirmed" ? "thisActive" : "thisInactive"}>{user.status}</span></TableCell>
                                                        <TableCell className='action-dropdown-btn'>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="secondary" id="dropdown-basic" size="sm" style={{ color: '#000000', border: 'none', background: 'transparent' }}>
                                                                    <MoreVertIcon />
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item>
                                                                        <IconButton
                                                                            color="primary"
                                                                            aria-label="View"
                                                                            onClick={() => handleDetailClick(user)}
                                                                            style={{ fontSize: '14px' }}
                                                                        >
                                                                            <InfoIcon style={{ fontSize: 16 }} className='me-1' /> View
                                                                        </IconButton>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item>
                                                                        <IconButton
                                                                            color="primary"
                                                                            aria-label="Edit"
                                                                            onClick={() => handleEditClick(user.booking_id)}
                                                                            style={{ fontSize: '14px' }}
                                                                        >
                                                                            <EditIcon style={{ fontSize: 16 }} className='me-1' /> Edit
                                                                        </IconButton>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item>
                                                                        <IconButton
                                                                            color="secondary"
                                                                            aria-label="Delete"
                                                                            onClick={() => handleDeleteClick(user.booking_id)}
                                                                            style={{ fontSize: '14px' }}
                                                                        >
                                                                            <DeleteIcon style={{ fontSize: 16 }} className='me-1' /> Delete
                                                                        </IconButton>
                                                                    </Dropdown.Item>

                                                                </Dropdown.Menu>
                                                            </Dropdown>


                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        ) : (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell colSpan={6} align="center">
                                                        No bookings found.
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )}

                                    </Table>
                                </TableContainer>
                                <div className="pagination-wrapper">
                                    {totalPages > 1 ? <Pagination className="custom-pagination" count={totalPages} variant="outlined" shape="rounded" page={page} onChange={handlePageChange} /> : ""}
                                    {/* <TablePagination
                                        component="div"
                                        count={totalPages * rowsPerPage}
                                        page={page - 1}
                                        onPageChange={(event, newPage) => setPage(newPage + 1)}
                                        rowsPerPage={rowsPerPage}
                                        rowsPerPageOptions={[]}
                                    /> */}
                                </div>

                            </div>
                            <Snackbar open={openSnackbar} autoHideDuration={7000} onClose={() => setOpenSnackbar(false)}>
                                <Alert onClose={() => setOpenSnackbar(false)} severity="error" sx={{ width: '100%' }}>
                                    Blog Deleted successfully!
                                </Alert>
                            </Snackbar>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
